import React from "react";
import DirectChannel from "./DirectChannel";
import PublicChannel from "./PublicChannel";

const Index = () => {
  return (
    <>
      <PublicChannel />
      <DirectChannel />
    </>
  );
};

export default Index;
