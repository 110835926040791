import React, { useEffect} from "react";
import { useDispatch} from "react-redux";
import { setSelectedMember } from "../../../../../redux/services/chatServices";
import TagIcon from "@mui/icons-material/Tag";
import { useLocation } from "react-router";

function PublicItem({ item }) {
  const dispatch = useDispatch();
  const { search } = useLocation();

  useEffect(() => {
    notificationHandler();
    // eslint-disable-next-line
  }, [search]);

  const notificationHandler = async () => {
    const searchParams = new URLSearchParams(search);
    const channel_id = searchParams.get("channel_id");
    if (channel_id === item.id) {
      dispatch(setSelectedMember(item, item?.id));
    }
  };
  return (
    <div
      className="d-flex align-items-center justify-content-between mt-2"
      id={item.id}
      onClick={() => {
        dispatch(setSelectedMember(item, item?.id));
      }}
      role="button"
    >
      <div className="d-block">
        <strong className="font-size-16px text-light">
          <TagIcon style={{ color: "#babbbc" }} /> {item?.name}
        </strong>
        <div className="font-size-12px fw-normal text-light">
          {/*  TODO : will show last message  of chat */}
        </div>
      </div>
      { item.unread_count>0 &&<div className="badge bg-secondary">{item.unread_count}</div>}
    </div>
  );
}

export default PublicItem;
