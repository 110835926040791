import React, { useEffect, useState } from 'react'
import { postValuebet, getValuebet, CREATE_CHIPS_REQUEST_URL, GET_CHIPS_REQUEST_URL, HANDLE_CHIPS_REQUEST_URL, } from '../../API-Handle/services/auth_curd'
import { errorNotification, successNotification } from '../CustComp/PPHAlert';

export default function SetRequests({ item }) {
    const [reqData, setReqData] = useState([])
    const [amount, setAmount] = useState('')
    const getReq = async () => {
        let res = await getValuebet(`${GET_CHIPS_REQUEST_URL}?club_id=${item.id}&req_type=request_by`);
        if(res){
            setReqData(res)
        }
    }

    const createReq = async () => {
        if (!(amount !== "" && amount > 0)) {
            errorNotification("Please enter valid Chips amount.")
            return
        }
        let data = {
            "club_id": item.id,
            "chips_amount": amount
        }
        setAmount('')
        let res = await postValuebet(CREATE_CHIPS_REQUEST_URL, data);
        if(res){
            successNotification(res.message)
            getReq()
        }
    }

    const cancelReq = async (req_id, action) => {
        let data = {req_id, action}
        let res = await postValuebet(HANDLE_CHIPS_REQUEST_URL, data);
        if(res){
            let new_req_data = reqData.filter((e) => e.id !== req_id)
            setReqData(new_req_data)
            successNotification(res.message)
        }
    }

    useEffect(() => {
        getReq();
    // eslint-disable-next-line
    }, [])

    return (
        <div>
            {/* <label className="form-label">Send request to your Agent</label> */}
            <label className="form-label text-white">Send request to Club</label>
            <div className='d-flex justify-content-start mb-3'>
                <input type="number" className="form-control bg-dark text-white me-1" placeholder='Chips amount' value={amount} onChange={(e) => setAmount(e.target.value)} />
                <button className="btn btn-outline-danger btn-sm" onClick={createReq}>
                    Request
                </button>
            </div>
            <div className="overflow-x overflow-y" style={{maxHeight:window.innerHeight - 360}}>
                <table className="table full-width table-custom-dark">
                    <thead>
                        <tr>
                            <th scope="col" className="align-top">To<br /></th>
                            <th scope="col" className="align-top">Chips</th>
                            <th scope="col" className="align-top">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reqData.map((val, index) => {
                            return (
                                <tr key={index}>
                                    <td>{val.request_to_username}</td>
                                    <td>{val.chips_amount}</td>
                                    <td>
                                        <button className="btn btn-outline-danger transfer-action-button" onClick={() => cancelReq(val.id, "DELETE")}>
                                            Cancel
                                        </button>
                                    </td>
                                </tr>)
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
