import React, { useContext } from 'react'
import {
    Card,
    CardBody,
} from "reactstrap";
import CloseIcon from '@mui/icons-material/Close';
import ValuebetContext from '../../../../../context/Valuebet/ValuebetContext';
import { OFFLINE_ODD } from '../../../../../constant';

function BetCard({ item }) {
    const { removeSelectedBet} = useContext(ValuebetContext);
    return (
        <Card
            className="bet-card"
        >
            <div className='bet-card-header px-2 py-2 align-items-center'>
                <div className='font-size-12px text-white'>{item.title}</div>
                <div className="ms-2" role={"button"} onClick={() => removeSelectedBet(item)}>
                    <CloseIcon style={{ fontSize: 15 }} />
                </div>
            </div>
            <CardBody className="p-2">
                <div className="mb-1">
                    {item.odd_money !== OFFLINE_ODD ?
                        <small className={item.init_sub_title !== item.sub_title ? 'text-danger' : ''}>{item.sub_title}</small> :
                        <small className={'text-danger'}>{"Currenly, This odd is no longer available! Please remove this ticket."}</small>}

                    {item.init_sub_title !== item.sub_title && <div className={"font-size-10px"}><del>{item.init_sub_title}</del></div>}
                </div>
            </CardBody>

        </Card>
    )
}

export default BetCard