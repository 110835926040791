import React, { useContext } from "react";
import ValuebetContext from "../../context/Valuebet/ValuebetContext";

export const bet_types = [
  {
    id: "Straight",
    title: "Straight",
  },
  {
    id: "Parlay",
    title: "Parlay",
  },
  {
    id: "Teaser",
    title: "Teaser",
  },
  // {
  //     id: "If_Win_or_Push",
  //     title: 'If Win or Push'
  // },
  // {
  //     id: "If_Win_only",
  //     title: 'If Win only'
  // },
  // {
  //     id: "Action_Reverse",
  //     title: 'Action Reverse'
  // }
];

function Linkbutton() {
  const { betDetails, setBetDetails, setLeagueEvents } = useContext(ValuebetContext);

  // Change Bet Type
  const changeBet = async (bet_id) => {
    let bet_details = JSON.parse(JSON.stringify(betDetails));
    if (bet_details?.bet_info?.bet_type !== bet_id) {
      if (bet_details.bet_info.bet_type === "Teaser") {
        setLeagueEvents([])
        bet_details = { bet_info: { bet_type: bet_id }, bets: [] }
      } else {
        bet_details.bet_info.bet_type = bet_id;
        if (bet_id === "Teaser") {
          setLeagueEvents([])
          bet_details = { bet_info: { bet_type: bet_id }, bets: [] }
        } else if (bet_id === "Parlay") {
          for (let bet of bet_details.bets) {
            delete bet?.risking;
            delete bet?.to_win;
            delete bet?.init_risk;
            delete bet?.init_win;
          }
        } else if (bet_id === "Straight") {
          delete bet_details.bet_info.risking;
          delete bet_details.bet_info.to_win;
          delete bet_details.bet_info.init_risk;
          delete bet_details.bet_info.init_win;
        }
      }
      setBetDetails(bet_details);
    }
  };

  return (
    <>
      <div className="side-hader-option">
        {bet_types.map((item) => (
          <button
            item={item}
            key={item.id}
            className={
              item.id === betDetails?.bet_info?.bet_type
                ? "head-button-active"
                : "head-button"
            }
            onClick={() => changeBet(item.id)}
          >
            {item.title}
          </button>
        ))}
      </div>


    </>
  );
}

export default Linkbutton;
