import React, { useContext, useEffect, useState } from 'react'
import ValuebetContext from '../../context/Valuebet/ValuebetContext';
import { getValuebet, GET_CHIPS_REQUEST_URL, postValuebet, HANDLE_CHIPS_REQUEST_URL } from '../../API-Handle/services/auth_curd'
import { successNotification } from '../CustComp/PPHAlert';
import SmSpinner from '../CustLoader/SmSpinner';

export default function ChildRequest({ club_id }) {
    const [reqData, setReqData] = useState([])
    const [reqLoading, setReqLoading] = useState(false)
    const { setRootLoader, fetchSingleClub } = useContext(ValuebetContext)

    const getReq = async () => {
        setReqLoading(true)
        let res = await getValuebet(`${GET_CHIPS_REQUEST_URL}?club_id=${club_id}&req_type=request_to`)
        if(res){
            setReqData(res)
        }
        setReqLoading(false)
    }

    const handleChipsRequest = async (req_id, action) => {
        setRootLoader(true)
        let data = {req_id, action};
        let res = await postValuebet(HANDLE_CHIPS_REQUEST_URL, data);
        if(res){
            let new_req_data = reqData.filter((e) => e.id !== req_id)
            setReqData(new_req_data)
            successNotification(res.message)
            fetchSingleClub(club_id)
        }
        setRootLoader(false)
    }   

    useEffect(() => {
        getReq();
    // eslint-disable-next-line
    }, [])

    return (
        <div>
            <label className="form-label text-white">Accept or Reject requests</label>
            <div className="overflow-x overflow-y" style={{maxHeight:window.innerHeight - 280}}>
                {!reqLoading  ?
                <table className="table full-width table-custom-dark">
                    <thead>
                        <tr>
                            <th scope="col" className="align-top">From<br /></th>
                            <th scope="col" className="align-top">Chips</th>
                            <th scope="col" className="align-top">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reqData.map((val, index) => {
                            return (
                                <tr key={index}>
                                    <td>{val.request_by_username}</td>
                                    <td>{val.chips_amount}</td>
                                    <td>
                                        <button className="btn btn-outline-danger transfer-action-button me-2" onClick={() => handleChipsRequest(val.id, "ACCEPT")}>
                                            Accept
                                        </button>
                                        <button className="btn btn-outline-secondary transfer-action-button" onClick={() => handleChipsRequest(val.id, "DELETE")}>
                                            Reject
                                        </button>
                                    </td>
                                </tr>)
                        })}
                    </tbody>
                </table> 
                : <SmSpinner py={0} />}
            </div>
        </div>
    )
}
