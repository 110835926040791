import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import SubExpandableView from './SubExpandableView';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import Spinner from '../../../../Components/CustLoader/Spinner';

const conditionalRowStyles = [
    {
        when: row => row,
        style: {
            backgroundColor: '#ff5e4f7a',
            color: 'white',
        },
    }
];

const columns = [
    {
        name: '',
        selector: row => row.tier,
        width: "180px",
    },
    {
        name: "",
        selector: row => row.affiliate_coin_won,
    },
    {
        name: "",
        selector: row => row.commission_awarded,
    },
    {
        name: '',
        selector: row => row.active_affiliates,
    },
    {
        name: "",
        selector: row => row.new_affiliates,
    },
    {
        name: "",
        selector: row => "",
    },
    {
        name: "",
        selector: row => "",
    }
];


const expandableIcon = {
    collapsed: <Add style={{ fontSize:"16px"}} />,
    expanded: <Remove style={{ fontSize:"16px"}} />,
  };

export default function ExpandableView({ data }) {
    const [tiers, setTiers] = useState(null)

    const prepareData = () => {
        let tiers_list = ['t1', 't2', 't3', 't4', 't5'];
        let temp_tiers = [];
        tiers_list.forEach((t, idx) => {
            temp_tiers.push(
                {
                    tier: `Tier ${idx + 1}`,
                    tier_name: t,
                    date: data[`pst_report_date`],
                    report_type: data[`report_type`],
                    active_affiliates: data[`${t}_active_affiliates`],
                    new_affiliates: data[`${t}_new_affiliates`],
                    affiliate_coin_won: data[`${t}_affiliate_coin_won`],
                    commission_awarded: data[`${t}_commission_awarded`]
                }
            )
        });
        setTiers(temp_tiers);
    }

    useEffect(() => {
        prepareData()
        // eslint-disable-next-line
    }, [data])

    return (
        <div className='py-1 responsive-expand'>
            {tiers ?
                <DataTable
                    columns={columns}
                    data={tiers}
                    theme="dark"
                    dense={true}
                    header={false}
                    noTableHead={true}
                    expandableIcon={expandableIcon}
                    expandableRows
                    expandableRowsComponent={<SubExpandableView />}
                    conditionalRowStyles={conditionalRowStyles}
                /> :
                <Spinner py={0} />}
        </div>
    )
}
