import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import { CHALLENGE_PLAYER_SUMMARY } from '../../../../API-Handle/services/auth_curd';
import { getValuebet } from '../../../../API-Handle/services/auth_curd';
import CustomYearPicker from '../../../../Components/Calendar/CustomYearPicker';
import { useLocation } from "react-router";
import Spinner from '../../../../Components/CustLoader/Spinner';

const columns = [
    {
        name: 'Months',
        selector: row => row.pst_report_date,
    },
    {
        name: 'Total Risk',
        selector: row => row.total_risk,
    },
    {
        name: 'Total To Win',
        selector: row => row.total_to_win,
    },
    {
        name: 'W/L',
        selector: row => row.wl_count,
    },
    {
        name: 'Results',
        selector: row => row.result,
    },
    {
        name: 'Monthly Leaderboard',
        selector: row => row.leaderboard_result,
    },
    {
        name: 'Rank',
        selector: row => row.rank ? row.rank : "N/A",
    },
    {
        name: 'Coins Won',
        selector: row => row.coin_won,
    },
    {
        name: 'All-Time Coins Won',
        selector: row => row.cumulative_coin_won,
    }
];

export default function MonthlySummary() {
    const [report, setReport] = useState(null)
    const [year, setYear] = useState(null);
    const [totalYear, setTotalYear] = useState({});
    const location = useLocation()

    const getMonthlyReports = async () => {
        setReport(null)

        let player_id = location?.state?.member?.id
        let request_url = CHALLENGE_PLAYER_SUMMARY + `?report_type=Monthly&report_time=${year}`;
        if (player_id) {
            request_url += `&player_id=${player_id}`;
        }
        let reports = await getValuebet(request_url);
        
        // create total row
        let total_risk = 0;
        let total_to_win = 0;
        let win_count = 0;
        let lose_count = 0;
        let result = 0;
        let leaderboard_result = 0;
        let coin_won = 0;

        for (let rep of reports){
            total_risk += rep.total_risk;
            total_to_win += rep.total_to_win;
            win_count += Number(rep.wl_count.split('-')[0]);
            lose_count += Number(rep.wl_count.split('-')[1]);
            result += rep.result;
            leaderboard_result += rep.leaderboard_result
            if (rep.is_final_report){
                coin_won += rep.coin_won;
            }else{
                rep.coin_won = "-";
            }
        }

        let total_year = {
            pst_report_date:"Total for Year",
            total_risk:total_risk.toFixed(2),
            total_to_win:total_to_win.toFixed(2),
            wl_count:`${win_count}-${lose_count}`,
            result:result.toFixed(2),
            leaderboard_result:leaderboard_result.toFixed(2),
            rank:"N/A",
            coin_won : coin_won.toFixed(2),
            cumulative_coin_won : "N/A",
        }
        
        setTotalYear(total_year);
        setReport(reports);
    }

    useEffect(() => {
        if(year) getMonthlyReports();
        // eslint-disable-next-line
    }, [year])

    return (
        <>
            <div className='inline-datepicker-container'>
                <CustomYearPicker getYear={setYear} className={"settled-datepicker"} />
            </div>
            {report ?
                <DataTable
                    columns={columns}
                    data={report}
                    theme="dark"
                    dense={true}
                    header={false}
                    highlightOnHover={true}
                    footer={totalYear}
                /> :
                <Spinner />}
        </>
    )
}
