import React from 'react'

export default function SmSpinner({ text=null, my=5, py=5, inline=false }) {
  return (
    inline ?
      <> <i className="fa-li fa fa-spinner fa-spin position-sticky text-white"></i> {text} </>
      : <div className={`d-flex justify-content-center align-items-center my-${my} py-${py} text-white`}>
        <i className="fa-li fa fa-spinner fa-spin position-sticky"></i> {text}
      </div>
  )
}
