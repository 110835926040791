import React, { useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { postValuebet, AGENT_PLAYERS } from '../../../../API-Handle/services/auth_curd';
import { successNotification, errorNotification } from '../../../../Components/CustComp/PPHAlert';

export default function AgentActions({ val, activeClub, dataCallback }) {
  const [playerModal, setPlayerModal] = useState(false)
  const [playerID, setPlayerId] = useState('')
  const togglePlayerModal = () => {
    setPlayerModal(!playerModal)
  };

  const handleChange = (e) => {
    setPlayerId(e.target.value)
  }

  const addPlayer = async() => {
    if (playerID == '') {
      errorNotification("Please enter Player ID.");
      return;
    }
    togglePlayerModal();
    let data = {
      "club_id": activeClub.id,
      "player_id": playerID,
      "agent_id": val.id,
      "flag": "add"
    }
    setPlayerId("")
    let res = await postValuebet(AGENT_PLAYERS, data)
    if(res){
      successNotification(res.message);
      dataCallback()
    }
  }

  return (
    <>
      <Modal isOpen={playerModal} toggle={togglePlayerModal} className="modal-dialog-centered modal-sm">
        <ModalHeader toggle={() => togglePlayerModal()}>
          Add Player
        </ModalHeader>
        <ModalBody>
          <form className="rename-form-padding">
            <input name="player_id" onChange={(e) => handleChange(e)} placeholder="Player ID" type="text" required />
            <Row>
              <Col className="text-end">
                <Button className='rename-button' color="success" onClick={() => addPlayer()}>
                  {" "}
                  Add{" "}
                </Button>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
      <div className="dropdown pt-2 pe-1 pb-1 text-end">
        <UncontrolledButtonDropdown>
          <DropdownToggle color="none">
            <i className="fa fa-ellipsis-v text-white" aria-hidden="true"></i>
          </DropdownToggle>
          <DropdownMenu end>
            <DropdownItem onClick={() => togglePlayerModal()}>
              <i className="fa fa-plus text-primary" aria-hidden="true"></i>&nbsp; Add Player
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>
    </>
  )
}
