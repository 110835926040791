import React from 'react'
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';

function ImageView({imageURL}) {
  return (
    <PhotoProvider>
        <PhotoView src={imageURL}>
          <div className="chat-image-container mb-1">
            <img src={imageURL} alt=""/>
          </div>
        </PhotoView>
    </PhotoProvider>
  )
}

export default React.memo(ImageView)
