import { Fragment, useContext, useEffect, useRef, useState } from "react";
import LeftBubble from "./LeftBubble";
import RightBubble from "./RightBubble";
import { useDispatch, useSelector } from "react-redux";
import { successNotification } from "../../../../Components/CustComp/PPHAlert";
import ValuebetContext from "../../../../context/Valuebet/ValuebetContext";
import { clubchatAction } from "../../../../redux/features/clubchatSlice";
import { BASEURL } from "../../../../API-Handle/config";


export default function Index({ msg, showDate, msgDate }) {
  const { selectedClub, replyMessage } = useSelector((state) => state.clubChat);
  const { chatConnection } = useContext(ValuebetContext)
  const dispatch = useDispatch();
  const [isEmojiPanelOpen, setIsEmojiPanelOpen] = useState(false);
  const [emojiPanelPosition, setEmojiPanelPosition] = useState({ top: 0, left: 0, right: 0 });
  const messageOptionsRef = useRef(null);

  // Effect to listen for clicks outside the message actions
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (messageOptionsRef.current && !messageOptionsRef.current.contains(event.target)) {
        setIsEmojiPanelOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleMessageReply = (msg) => {
    dispatch(clubchatAction.setReplyMessage({ data: msg }));
    setIsEmojiPanelOpen(!isEmojiPanelOpen)
  };

  const handleMessageCopy = (msg) => {
    navigator.clipboard.writeText(msg.message);
    successNotification(`Message has been copied successfully!`);
    setIsEmojiPanelOpen(!isEmojiPanelOpen)

  }

  const renderReplyMessage = (message) => {
    if (message.reply_to["media_url"]) {
      const url = BASEURL + message.reply_to["media_url"];
      return <img src={url} width={50} height={50} alt="Selected Message" />
    }
    else {
      return message.reply_to["message"];
    }
  }

  const handleUnsendMessage = (msg) => {
    const msgData = {
      command: "message_removed",
      message_id: msg.id,
    };
    chatConnection.current.sendMessage(msgData);
    setIsEmojiPanelOpen(!isEmojiPanelOpen)
    // dispatch(fetchAllMessages(msg.channel))

  }

  const onReactSend = (emoji) => {
    let msgData = {
      command: "react",
      message_id: msg.id,
      react: emoji.native ? emoji.native : emoji,
    };
    chatConnection.current.sendMessage(msgData);
    if (isEmojiPanelOpen) setIsEmojiPanelOpen(!isEmojiPanelOpen)

  };

  const handleClickMessage = (event) => {
    setIsEmojiPanelOpen(!isEmojiPanelOpen);
    if (replyMessage) dispatch(clubchatAction.setReplyMessage({ data: null }))
    const rect = event.currentTarget.getBoundingClientRect();
    setEmojiPanelPosition({
      top: window.scrollY + rect.top > 120 ? rect.top - 60 : rect.top,
      left: rect.left,
      right: rect.right,
    });

  };

  const handleClickInsideMessage = (msgId) => {
    const element = document.getElementById(msgId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <Fragment>
      {msg.sender !== selectedClub?.user_id ? (
        <div>
          {showDate && (
            <div className="d-flex justify-content-center mb-2" >
              <div className="font-size-12px text-light">{msgDate}</div>
            </div>
          )}
          <LeftBubble
            msg={msg}
            handleClickMessage={handleClickMessage}
            handleClickInsideMessage={handleClickInsideMessage}
            isEmojiPanelOpen={isEmojiPanelOpen}
            messageOptionsRef={messageOptionsRef}
            emojiPanelPosition={emojiPanelPosition}
            handleMessageReply={handleMessageReply}
            handleMessageCopy={handleMessageCopy}
            onReactSend={onReactSend}
            handleUnsendMessage={handleUnsendMessage}
            renderReplyMessage={renderReplyMessage}
          />
        </div>
      ) : (
        <Fragment>
          {showDate && (
            <div className="d-flex justify-content-center mb-2">
              <div className="font-size-12px text-light">{msgDate}</div>
            </div>
          )}
          <RightBubble
            msg={msg}
            handleClickMessage={handleClickMessage}
            handleClickInsideMessage={handleClickInsideMessage}
            isEmojiPanelOpen={isEmojiPanelOpen}
            messageOptionsRef={messageOptionsRef}
            emojiPanelPosition={emojiPanelPosition}
            handleMessageReply={handleMessageReply}
            handleMessageCopy={handleMessageCopy}
            onReactSend={onReactSend}
            handleUnsendMessage={handleUnsendMessage}
            renderReplyMessage={renderReplyMessage}
          />
        </Fragment>
      )}
    </Fragment>
  )
}