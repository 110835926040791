import React from 'react'
import ScrollToTop from '../../../Components/CustComp/ScrollToTop'
import PublicHeader from '../../../Components/PublicHeader'
import PublicFooter from '../../../Components/PublicFooter'

export default function index() {
  return (
    <div id='public-page' className='BG-valuebet'>
      <ScrollToTop />
      <PublicHeader />
      <div className='layout-mt-112 container-fluid pretty-card'>
      <h2 className='reguler-heading'>Terms of Service (TOS) for <span className='vb-text-orange'>Valuebet.app</span></h2>
        <div className='letter-space'>
          <p className='mb-3 font-size-16px'>**Last Updated: [26/01/2024]**</p>

          <h6 className='vb-text-orange'>1. Acceptance of Terms</h6>
          <p>Valuebet.app ("we", "us", "our") provides its service to you, subject to the following Terms of Service ("TOS"), which may be updated by us from time to time without notice to you. By using our app, you agree to be bound by this TOS.</p>

          <h6 className='vb-text-orange'>2. Description of Service</h6>
          <p>Valuebet.app offers [insert detailed description of services, e.g., sports betting analytics, betting suggestions, etc.]. Services may change or be updated over time.</p>

          <h6 className='vb-text-orange'>3. User Obligations</h6>
          <p>You are responsible for maintaining the confidentiality of your account, password, and for restricting access to your device. You agree to accept responsibility for all activities that occur under your account or password.</p>

          <h6 className='vb-text-orange'>4. Privacy Policy</h6>
          <p>Your use of Valuebet.app is subject to our Privacy Policy. Please review our policy, which also governs the app and informs users of our data collection practices.</p>

          <h6 className='vb-text-orange'>5. Modifications to Service</h6>
          <p>We reserve the right at any time to modify or discontinue, temporarily or permanently, the service (or any part thereof) with or without notice.</p>

          <h6 className='vb-text-orange'>6. Intellectual Property Rights</h6>
          <p>All content included in or made available through Valuebet.app, such as text, graphics, logos, and software, is the property of Valuebet.app or its content suppliers.</p>

          <h6 className='vb-text-orange'>7. Warranties and Disclaimers</h6>
          <p>Valuebet.app and its services are provided "as is". We make no representations or warranties of any kind, express or implied, as to the operation of the service, or the information, content, materials, or products included on our app.</p>

          <h6 className='vb-text-orange'>8. Limitation of Liability</h6>
          <p>In no event shall Valuebet.app, its officers, directors, employees, or agents be liable for any direct, indirect, incidental, special, consequential or exemplary damages resulting from any aspect of your use of the service.</p>

          <h6 className='vb-text-orange'>9. Governing Law</h6>
          <p>The TOS and the relationship between you and Valuebet.app shall be governed by the laws of United States without regard to its conflict of law provisions.</p>

          <h6 className='vb-text-orange'>10. Changes to the Terms of Service</h6>
          <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time.</p>

          <h6 className='vb-text-orange'>11. Contact Information</h6>
          <p>For any questions about these Terms of Service, please contact us at <span className='text-info'>support@valuebet.app</span></p>
        </div>
      </div>
      <PublicFooter />
    </div>
  )
}