import React, { useEffect, useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import moment from 'moment'
import { APP_OFFSET } from '../../constant';

const getCurrentYear = () => {
    return moment().utcOffset(APP_OFFSET).format("YYYY")
}

export default function CustomYearPicker({getYear, className}) {
    const [displayValue, setDisplayValue] = useState("This Year");
    const [year, setYear] = useState(getCurrentYear());

    useEffect(()=>{
        getYear(year)
    }, [year])

    const onYearChange = (e) => {
        let tmp_year = null;
        if (e.target.value) {
            tmp_year = e.target.value;
        } else {
            tmp_year = getCurrentYear();
        }
        setYear(tmp_year)
        if (tmp_year === getCurrentYear()) {
            setDisplayValue("This Year")
        } else {
            setDisplayValue(tmp_year)
        }
    }

    return (
        <>
            <select
                id="yearpicker"
                style={{ marginRight: "-70px", zIndex: 0, width: "70px", opacity: 0 }}
                type="select"
                onChange={onYearChange}
                value={year}
            >
                <option value="2023">2023</option>
                <option value="2024">2024</option>
            </select>
            <button type="button" className={className}>{displayValue} <ArrowDropDownIcon fontSize="small" /> </button>
        </>
    )
}
