import React, { useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import { Avatar, Button, Chip, Hidden } from '@mui/material';
import moment from 'moment';
import CopyText from '../../../Components/CustComp/CopyText';

const getPayStatusClass = (status) => {
    let cls = 'text-info';
    if (["SUCCESS"].includes(status)) cls = 'text-success';
    else if (["PARTIALLY-PAID"].includes(status)) cls = 'text-warning';
    else if (["PROCESSING", "INITIATING", "REFUNDED"].includes(status)) cls = 'text-info';
    else if (["NOT-INITIATED"].includes(status)) cls = 'text-info';
    else if (["FAILD"].includes(status)) cls = 'text-danger';
    return cls;
}
const getOrderStatusClass = (status) => {
    let cls = '';
    if (["DELIVERED"].includes(status)) cls = 'success';
    else if (["CANCELLED"].includes(status)) cls = 'error';
    else if (["DELIVERING"].includes(status)) cls = 'primary';
    else if (["WAITING"].includes(status)) cls = 'info';
    return cls;
}

export default function OrderItems({ order }) {
    const [expanded, setExpanded] = useState(false);

    const handleExpansion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    const handlePay = (e) => {
        e.target.disabled = true;
        window.location = order.payment_link;
    };

    return (
        <Accordion
            expanded={expanded}
            onChange={handleExpansion}
            slots={{ transition: Fade }}
            slotprops={{ transition: { timeout: 400 } }}
            sx={{
                '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <ListItem className='p-0'>
                    <ListItemAvatar>
                        <Avatar>
                            <LocalMallOutlinedIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{ width: "45%" }}
                        primary={<span className='font-size-13px'>{order.title}</span>}
                        secondary={<span className='font-size-12px'>Created on {moment(order.created_at).format("LL")}</span>}
                    />
                    <Hidden smDown>
                        <ListItemText alignitems="start" className='ps-2'>
                            <Chip label={`$${order.cost}`} color='error' variant="outlined" size="small" sx={{ fontSize:"12px" }}/>
                        </ListItemText>
                    </Hidden>
                    <ListItemText alignitems="start" className='ms-3'>
                        <Chip label={order.delivery_status} color={getOrderStatusClass(order.delivery_status)} size="small" variant="outlined" sx={{ fontSize:"11px" }} />
                    </ListItemText>
                </ListItem>
            </AccordionSummary>

            <AccordionDetails>
                <Divider component="li" />
                <div className='p-3'>
                    <Typography variant="overline">
                        Order Details
                    </Typography>
                    <List disablePadding>
                        <ListItem className='p-0'>
                            <ListItemText
                                sx={{ mr: 2 }}
                                primary={<div className='font-size-13px text-light'>Payment Status</div>}
                            />
                            <Typography variant="caption" className={getPayStatusClass(order.payment_status)}>
                                {order.payment_status}
                            </Typography>
                            {/* {(order.payment_status === 'NOT-INITIATED' && order.payment_link) ?
                                <Button variant="contained" size='small' color="success" className='rounded-pill border py-0' onClick={handlePay}>
                                    Pay Now
                                </Button>:
                                <Typography variant="caption" className={getPayStatusClass(order.payment_status)}>
                                    {order.payment_status}
                                </Typography>
                            } */}
                        </ListItem>
                        <ListItem className='p-0'>
                            <ListItemText
                                sx={{ mr: 2 }}
                                primary={<div className='font-size-13px text-light'>Order Cost</div>}
                            />
                            <Typography variant="caption">
                                ${order.cost}
                            </Typography>
                        </ListItem>
                        <ListItem className='p-0'>
                            <ListItemText
                                sx={{ mr: 2 }}
                                primary={<div className='font-size-13px text-light'>Order Date</div>}
                            />
                            <Typography variant="caption">
                                {moment(order.created_at).format("LLL")}
                            </Typography>
                        </ListItem>
                    </List>
                    <div className='mt-1'>
                        <label variant="caption" className='text-light font-size-13px m-0 p-0'>
                            Order ID
                        </label>
                        <br />
                        <span className='font-size-13px text-secondary m-0 p-0 text-break'>
                            #{order.id}
                        </span>
                    </div>
                </div>

                {order.pay_address &&
                    <>
                        <Divider component="li" />
                        <div className='p-3'>
                            <div className='mt-2'>
                                <Typography variant="caption" className='text-light'>
                                    Pay Address
                                </Typography>
                                <br />
                                <Typography variant="caption" className='text-secondary m-0 p-0 text-break'>
                                    {order.pay_address} <CopyText text={order.pay_address} className={'btn btn-sm text-secondary border-0'} />
                                </Typography>
                            </div>
                            <div className='mt-2 '>
                                <Typography variant="body2" className='vb-text-orange'>
                                    Note: Currently, We received <span className='text-warning'>${order.received_cost}</span> only, please pay the remaining <span className='text-warning'>${order.cost - order.received_cost}</span> to the above Pay Address. If you have already made the payment, please ignore this message. We will update your status soon.
                                </Typography>
                            </div>
                        </div>
                    </>}

                {order.coupon_code &&
                    <div>
                        <Divider component="li" />
                        <div className='p-3'>
                            <Typography variant="overline">
                                Price Details
                            </Typography>
                            <List disablePadding>
                                <ListItem className='p-0'>
                                    <ListItemText
                                        sx={{ mr: 2 }}
                                        primary={<div className='font-size-13px text-light'>Selling Price</div>}
                                        secondary={<span className='font-size-12px text-secondary'>Qty: {order.no_of_items}</span>}
                                    />
                                    <Typography variant="caption">
                                        ${order.original_cost}
                                    </Typography>
                                </ListItem>
                                <ListItem className='p-0'>
                                    <ListItemText
                                        sx={{ mr: 2 }}
                                        primary={<span className='font-size-13px text-light'>Discount</span>}
                                        secondary={<span className='font-size-12px text-secondary'>Coupon: {order.coupon_code}</span>}
                                    />
                                    <Typography variant="caption" className='text-success'>
                                        - ${(order.original_cost * (order.discount_per / 100)).toFixed(2)}
                                    </Typography>
                                </ListItem>
                                <ListItem className='p-0'>
                                    <ListItemText
                                        sx={{ mr: 2 }}
                                        primary={<div className='font-size-13px text-light'>Total Amount</div>}
                                    />
                                    <Typography variant="caption">
                                        ${order.cost}
                                    </Typography>
                                </ListItem>
                            </List>
                        </div>
                    </div>}
            </AccordionDetails>
        </Accordion>
    )
}
