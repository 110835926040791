import React, { useEffect, useContext } from "react";
import { Row, Col, Card } from "reactstrap";
import Header from "../../../Components/UserHeader";
import Footer from "../../../Components/Footer";
import { postValuebet, HANDLE_CLUB_REQUEST_URL, getValuebet, GET_CLUB_APPLICANTS } from "../../../API-Handle/services/auth_curd";
import logoag from "../../../Static/UserManagement/images/common/default-user-avtar.png";
import { successNotification } from "../../../Components/CustComp/PPHAlert";
import ValuebetContext from "../../../context/Valuebet/ValuebetContext";
import { BASEURL } from "../../../API-Handle/config";
import ClubAction from "../Dashboard/ClubCard/ClubComponants/ClubAction";
import { useNavigate, useParams } from 'react-router-dom';
import SmSpinner from "../../../Components/CustLoader/SmSpinner";

export default function Index() {
  const { activeClub, fetchSingleClub, clubApplicants, setClubApplicants } = useContext(ValuebetContext);
  const navigate = useNavigate();
  const { clubId } = useParams();

  const handleReq = async (item, action) => {
    let data = {
      club_id: clubId,
      request_id: item.id,
      action
    }
    let res = await postValuebet(HANDLE_CLUB_REQUEST_URL, data)
    if (res) {
      successNotification(res.message)
    }
  }

  const getApplicants = async() => {
    setClubApplicants(null);
    let res = await getValuebet(GET_CLUB_APPLICANTS + clubId);
    if (res){
      setClubApplicants(res);
    }
  }

  useEffect(() => {
    if (!clubId) {
      navigate("/")
    } else {
      fetchSingleClub(clubId);
      getApplicants();
    }
  // eslint-disable-next-line
  }, [clubId])

  return (
    <React.Fragment>
      <div>
        <Header />
        <div className="sec-header container-fluid">
          <Row>
            <div className="d-flex justify-content-start align-items-center">
              <h6 className="sec-header-title">
                Pending Applications
              </h6>
              {activeClub && <ClubAction item={activeClub} />}
            </div>
          </Row>
        </div>
        <div className='layout-mt-112 fill-view'>
          <div className="container-fluid mt-4">
            {clubApplicants ?
              <Row>
                {clubApplicants.length ? (
                  clubApplicants.map((item, index) => {
                    return (
                      <Col xl={3} lg={4} md={6} sm={12} key={index}>
                        <div className="d-flex justify-content-center">
                          <Card className="applicants-card-inner">
                            <div className="m-2 d-flex justify-content-between">
                              <div>
                                {item.profile_picture ? (
                                  <img className="applicant-avtar" alt="" src={BASEURL + item.profile_picture} />
                                ) : (
                                  <img className="applicant-avtar" alt="" src={logoag} />
                                )}
                              </div>
                              <div className="ms-3 me-5">
                                <h6 className="text-light mt-1">  {item.user_name} </h6>
                                <h6 className="applicant-uuid">
                                  {item.user.substring(0, 8)}
                                </h6>
                                <div className="d-flex">
                                  <button
                                    type="button"
                                    className="btn-shadow btn btn-orange text-white font-size-11px"
                                    onClick={() => handleReq(item, 'ACCEPT')}
                                  >
                                    Accept
                                  </button>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <button
                                    type="button"
                                    className="btn-shadow btn btn-secondary font-size-11px"
                                    onClick={() => handleReq(item, 'REJECT')}
                                  >
                                    Reject
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </div>
                      </Col>
                    );
                  })
                ) : (
                  <Col className="d-flex justify-content-center mt-5 pt-5 text-white">
                    <div>Currently, You have no applications</div>
                  </Col>
                )}
              </Row> :
              <SmSpinner text={"Loading Applications..."}/>}
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  )
}