import React from 'react'
import { Modal } from "reactstrap"

export default function ValuebetFAQ({ isVisible, modToggle }) {
    return (
        <Modal isOpen={isVisible} toggle={modToggle} className="modal-dialog-centered modal-xl">
            <div className="modal-header bg-vb-dark">
                <h5 className="modal-title my-0">Valuebet FAQ</h5>
                <button onClick={modToggle} type="button" className="text-white btn-close" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className='modal-body letter-space'>
                <h6 className='vb-text-orange'>How does the Valuebet.App work?</h6>
                <p>The Valuebet.App is a Social Peer-to-Peer Sports Gaming and Handicapping Platform that enables anyone to start and host a Sports Handicapping Club and <span className='text-warning'>track the members' sports handicapping results.</span> Whoever starts the club is the Host. The Hosts can invite any Player to join their club using the Club ID and compete against the Host by making handicapping sports picks.</p>

                <h6 className='vb-text-orange'>What are Managers?</h6>
                <p>Club managers have similar <span className='text-warning'>capabilities as the Host</span> in order to help manage the Club.</p>

                {/* <h6 className='vb-text-orange'>What are Agents (coming soon)?</h6>
                <p>A Host can make any Player an Agent. Agents are there to help a club grow by inviting new Players and <span className='text-warning'>receive Club Play Chips as rewards known as "Kickbacks"</span> (set by the Host/Manager). A Host can bind any Player to a specific Agent.</p> */}

                {/* <h6 className='vb-text-orange'>What are Sub-Agents (coming soon)?</h6>
                <p>Sub-Agents are Agents that are <span className='text-warning'>binded below another Agent.</span> Sub-Agents have the same roles as an Agent. A Host or Agent of the Sub-Agent can set the rewards for a Sub-Agent.</p> */}

                <h6 className='vb-text-orange'>What is Vig paid?</h6>
                <p>Vig (also known as Juice) is the <span className='text-warning'>difference between the Risk amount and the Win amount,</span> where the Risk amount is greater than the Win amount. For example, if a Player risks 120 Chips to win 100 Chips and loses, the Vig loss is 20 Chips (120 - 100 = 20). If a Player risks 100 Chips to win 120 Chips and loses, there is no Vig paid.</p>

                {/* <h6 className='vb-text-orange'>What is Kickback (coming soon)?</h6>
                <p>Kickback is a percentage of Vig paid given back to Agents and Players in the form of Club Play Chips based on the amount of Vig paid. Below are examples of kickback distributions, based on $20 in Vig paid:
                    <br /><br />
                    <b>IF:</b> <i>Agent’s kickback is set at <span className='text-warning'>70%</span></i>
                    <br /><b>THEN:</b> <i>Agent receive <span className='text-warning'>$14</span> kickback ($20 x 70% = $14)</i>
                    <br /><br />
                    <b>IF:</b> <i>Agent’s kickback is set at <span className='text-warning'>70%</span>, a Sub-Agent under the Agent is set at <span className='text-warning'>50%</span></i>
                    <br /><b>THEN:</b> <i>Agent receives <span className='text-warning'>$4</span> ($14 x (70% - 50% = 20%)) & Sub-Agent receives <span className='text-warning'>$10</span> ($20 x 50%)</i>
                    <br /><br />
                    <b>IF:</b> <i>Agent’s kickback is set at <span className='text-warning'>70%</span>, a Sub-Agent under the Agent is set at <span className='text-warning'>50%</span>, a Player under the Sub-Agent is set at <span className='text-warning'>10%</span></i>
                    <br /><b>THEN:</b> <i>Agent receives <span className='text-warning'>$4</span> ($14 x (70% - 50% = 20%)), Sub-Agent receives <span className='text-warning'>$8</span> ($20 x (50% - 10% = 40%)), & Player receives <span className='text-warning'>$2</span> ($20 x 10%)</i>
                    <br /><br />
                    Kickbacks are <span className='text-warning'>automatically distributed from the Club Wallet.</span></p> */}

                <h6 className='vb-text-orange'>What are the Club Play Chips for?</h6>
                <p>Club Play Chips have no monetary value and are used for placing sports handicapped picks within a club. Picks can only be placed with Chips from the Player Wallet. <span className='text-warning'>Chips from Vig paid are ‘burned/destroyed’.</span> As Chips are burned, clubs must buy more Chips to replace the burned Chips.</p>

                <h6 className='vb-text-orange'>What are the Valuebet Coins for?</h6>
                <p>Valuebet Coins <span className='text-warning'>is the platform currency</span> and must be bought. The Coins are used to pay for account and club upgrades, and to buy additional Chips.</p>

                <h6 className='vb-text-orange'>How do the Club Wallets work?</h6>
                <p>Club Wallets hold the Club’s Chips and are transferred to Player Wallets. <span className='text-warning'>Winnings are automatically distributed out of Club Wallets.</span> Club Wallets can go negative in Chips due to Winnings distribution. Clubs must buy additional chips to go out of the negative before any additional chips can be sent to Player Wallets.</p>

                {/* <h6 className='vb-text-orange'>How do Agent Wallets work (coming soon)?</h6>
                <p>Agent Wallets hold Chips that can only be sent to Player Wallets or reclaimed back to Club Wallets. Agents can <span className='text-warning'>see Player Chip Requests</span> in the Agent Wallet.</p> */}

                <h6 className='vb-text-orange'>How do Player Wallets work?</h6>
                <p>Player Wallets hold Chips for placing handicapped picks. Winning picks receive Chips back to the Player Wallet. <span className='text-warning'>Picks can only be placed with Chips from Player Wallets.</span> Players can request Chips from Player Wallets.</p>
            </div>
        </Modal>
    )
}
