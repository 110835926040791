import React, { useContext, useEffect, useState } from 'react'

import {
    Form,
    Button,
    Col,
    Input,
    InputGroup,
    Row
} from "reactstrap";
import CustomDropdown from '../../../../../../Components/CustInputs/CustomDropdown';
import { DAYS, HOURS } from '../../../../../../constant';
import { getValuebet, postValuebet, GET_CHIPS_RESET_CYCLE, UPDATE_CHIPS_RESET_CYCLE, RESET_CHIPS_URL } from '../../../../../../API-Handle/services/auth_curd'
import { successNotification } from '../../../../../../Components/CustComp/PPHAlert';
import { DefaultConfirm } from '../../../../../../Components/SweetAlerts/DefaultConfirm';
import ValuebetContext from '../../../../../../context/Valuebet/ValuebetContext';
import Switch from "react-switch";

export default function ChipCycle({ club_id, reUseId, callBack }) {
    const [resetChips, setResetChips] = useState('');
    const { setRootLoader, rootLoader } = useContext(ValuebetContext)

    const [cycleID, setCycleId] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [selectedDay, setSelectedDay] = useState('');
    const [selectedHour, setSelectedHour] = useState('');
    const [nextReset, setNextReset] = useState(null);
    const [cycleType, setCycleType] = useState("Weekly"); // or Daily

    const toggleCycleType = () => {
        let cycle_type = 'Weekly';
        if (cycleType === "Weekly"){
            cycle_type = "Daily"
        }
        updateResetCycle(isChecked, resetChips, cycle_type, selectedDay.value, selectedHour.value)
    }

    const getCycles = async () => {
        let URL = `${GET_CHIPS_RESET_CYCLE+club_id}`
        let res = await getValuebet(URL);
        if (res) {
            setCycleId(res.id)
            setResetChips(res.amount)
            setIsChecked(res.auto_reset_enabled)
            setCycleType(res.cycle_type)
            setNextReset(res.next_reset_datetime)
            DAYS.forEach((item) => {
                if (item.value === res.day_of_week.toString()) {
                    setSelectedDay(item)
                }
            })
            setNextReset(res.next_reset_datetime)
            HOURS.forEach((item) => {
                let curr_hour = res.reset_time_pst.split(":")[0]
                if (item.value === curr_hour) {
                    setSelectedHour(item)
                }
            })
        }
    }

    useEffect(() => {
        getCycles()
        // eslint-disable-next-line
    }, [])

    const resetAllChips = async (e) => {
        e.preventDefault();
        let confirmation = await DefaultConfirm("Reset Chips", "Do you want to reset all wallet chips and allow all required transactions?")
        if (confirmation) {
            setRootLoader(true);
            let data = {
                chips_amount: resetChips,
                origin: "CLUB",
                club_id: club_id
            }
            let res = await postValuebet(RESET_CHIPS_URL, data);
            if (res) {
                successNotification(res.message);
                callBack(club_id);
            }
            setRootLoader(false);
        }
    }

    const updateResetCycle = async (temp_checked, temp_amount, cycle_type, temp_day_of_week, temp_hour) => {
        if (!(temp_day_of_week && temp_hour)) return;
        setRootLoader(true);
        console.log(temp_day_of_week)
        let data = {
            cycle_id: cycleID,
            auto_reset_enabled: temp_checked,
            cycle_type: cycle_type,
            amount: temp_amount === '' ? 0 : temp_amount,
            day_of_week: temp_day_of_week,
            reset_time_pst: `${temp_hour}:00`,
        }
        let res = await postValuebet(UPDATE_CHIPS_RESET_CYCLE, data);
        if (res) {
            successNotification(res.message);
            await getCycles();
        }
        setRootLoader(false);
    }

    return (
        <Row>
            <Col xs={12}>
                <div className='d-flex justify-content-between'>
                    <Form onSubmit={resetAllChips} className='flex-grow-1'>
                        <InputGroup size='sm'>
                            <Input
                                className='bg-dark text-white'
                                placeholder='Chips'
                                required
                                type='number'
                                min={0}
                                step={"0.01"}
                                value={resetChips}
                                onChange={(e) => setResetChips(e.target.value)}
                            />
                            {isChecked ?
                                <Button className='text-nowrap' color='success' type='button' disabled={rootLoader} onClick={()=>updateResetCycle(isChecked, resetChips, cycleType, selectedDay.value, selectedHour.value)}>
                                    Set Amount
                                </Button>
                                : <Button color='success' type='submit' disabled={rootLoader}>
                                    Reset
                                </Button>}
                        </InputGroup>
                    </Form>
                    <Button
                        color={isChecked ? 'danger' : 'secondary'}
                        size='sm'
                        className='ms-2 text-nowrap'
                        type='button'
                        onClick={() => document.getElementById(reUseId).click()}
                        disabled={rootLoader}
                    >
                        <Input type='checkbox' id={reUseId} checked={isChecked} onClick={(e) => e.stopPropagation()} onChange={(e) => updateResetCycle(e.target.checked, 0, cycleType, selectedDay.value, selectedHour.value)} /> Auto Reset
                    </Button>
                </div>
            </Col>
            {isChecked && <Col xs={12}>
                <div className='row mt-2'>
                    <Col xs={6}>
                        <div className='d-flex justify-content-between'>
                            <label className='text-light font-size-11px'>Day</label>
                            <Switch
                                onChange={toggleCycleType}
                                checked={cycleType === 'Daily'}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                                height={22}
                                width={67}
                                onColor="#008000"
                                onHandleColor="#1e5e1efa"
                                offColor="#ffac8b"
                                offHandleColor="#e03f00"
                                borderRadius={8}
                                uncheckedIcon={
                                    <div className='d-flex justify-content-center align-items-center h-100 pe-3 text-dark font-size-11px'>
                                        Weekly
                                    </div>
                                }
                                checkedIcon={
                                    <div className='d-flex justify-content-center align-items-center h-100 ps-2 text-white font-size-11px'>
                                        Daily
                                    </div>
                                }
                                className="react-switch mb-1"
                            />
                        </div>
                        <CustomDropdown
                            placeholder="Day"
                            options={DAYS}
                            value={cycleType === "Daily" ? {'value':'7', 'label':'Every Day'} : selectedDay}
                            onChange={(e) => updateResetCycle(isChecked, resetChips, cycleType, e?.value, selectedHour.value)}
                            minWidth={"50px"}
                            isDisabled={cycleType === "Daily"}
                        />
                    </Col>
                    <Col xs={6}>
                        <label className='text-light font-size-11px'>Hour (PST)</label>
                        <CustomDropdown
                            placeholder="Hour"
                            options={HOURS}
                            value={selectedHour}
                            onChange={(e) => updateResetCycle(isChecked, resetChips, cycleType, selectedDay.value, e?.value)}
                            minWidth={"50px"}
                        />
                    </Col>
                </div>
                {nextReset && <div className="text-danger font-size-12px text-wrap mt-2"> Next chips reset - {nextReset}</div>}
            </Col>}
        </Row>
    )
}
