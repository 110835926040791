import React, { useEffect, useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import moment from 'moment'
import { APP_OFFSET } from '../../constant';

const getCurrentMonth = () => {
    return moment().utcOffset(APP_OFFSET).format("YYYY") + "-" + moment().utcOffset(APP_OFFSET).format("MM")
}

export default function CustomMonthPicker({getMonth, className}) {
    const [displayValue, setDisplayValue] = useState("This Month");
    const [month, setMonth] = useState(getCurrentMonth());

    useEffect(()=>{
        getMonth(moment(month).format('MM/YYYY'))
    }, [month])

    const onMonthChange = (e) => {
        let tmp_month = null;
        if (e.target.value) {
            tmp_month = e.target.value;
        } else {
            tmp_month = getCurrentMonth();
        }
        setMonth(tmp_month)
        if (tmp_month === getCurrentMonth()) {
            setDisplayValue("This Month")
        } else {
            const dply = moment(tmp_month);
            const formattedDate = dply.format('MMMM, YYYY');
            setDisplayValue(formattedDate)
        }
    }

    return (
        <>
            <input
                id="datepicker"
                style={{ height: 0, width: 0, border: "none" }}
                type="month"
                onChange={onMonthChange}
                min="2023-01"
                value={month}
                max={getCurrentMonth()}

            />
            <button type="button" onClick={() => document.getElementById("datepicker").showPicker()} className={className}>{displayValue} <ArrowDropDownIcon fontSize="small" /> </button>
        </>
    )
}
