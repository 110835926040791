import React, { useContext, useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import Straight from './Straight';
import Parlay from './Parlay';
import Teaser from "./Teaser"
import ValuebetContext from '../../../../context/Valuebet/ValuebetContext';
import Linkbutton from '../../../../Components/SportComp/Linkbutton';

export default function Index() {
    const {
        isBetVisible,
        setIsBetVisible,
        setIsFilterVisible,
        betDetails,
    } = useContext(ValuebetContext);

    const [height, setHeight] = useState(0);

    const handleClose = () => {
        setIsBetVisible(false);
        setIsFilterVisible(false);
    }

    useEffect(() => {
        getHeight();
        window.addEventListener('resize', getHeight);
        return () => window.removeEventListener('resize', getHeight);
    }, [isBetVisible, betDetails])

    const getHeight = () => {
        let hgt = window.innerHeight - 140
        if (window.innerWidth < 992) {
            if (window.innerWidth < 767) hgt -= 45;
        }
        setHeight(hgt)
    }

    return (
        <div className={isBetVisible ? 'side-right' : 'side-right-inactive'}>
            <div className='d-flex justify-content-between mb-2'>
                <Linkbutton />
                <button className='close-button-right ms-2' onClick={handleClose}>
                    <CloseIcon fontSize='inherit'/>
                </button>
            </div>

            {betDetails?.bets?.length ?
                <div className='py-3 pe-2' style={{ height: height, overflowY: "auto" }}>
                    {betDetails?.bet_info?.bet_type === "Straight" ?
                        <Straight betDetails={betDetails} />
                        : betDetails?.bet_info?.bet_type === "Parlay" ?
                            <Parlay betDetails={betDetails} />
                            : betDetails?.bet_info?.bet_type === "Teaser"?
                                <Teaser betDetails={betDetails}/>
                                :null}
                </div> :
                <div className="px-3 py-4 text-center bg-black text-light rounded ">
                    <b> Your bet slip is empty </b><br /><br />
                    <label> please make one or more selections in order to place bets </label>
                </div>}
        </div>
    )
}
