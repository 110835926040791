import Swal from 'sweetalert2'

export const DefaultConfirm = async (title, text, type) => {
    let result = await Swal.fire({
        title: title,
        html: text,
        icon: type || "warning",
        showCancelButton: true,
        confirmButtonColor: "green",
        cancelButtonColor: "gray",
        confirmButtonText: "Yes",
        reverseButtons: true
    })

    let is_confirmed = result.isConfirmed;
    return is_confirmed
}