import React, { useState } from 'react'
import Bets from './Bets';
import Summary from './Summary';
import PlayerSummary from './PlayerSummary';
import ChallengeSummary from '../Reports/ChallengeSummary'
import Winners from '../Reports/Winners'

function TabSetup({ club }) {
    const [activeTab, setActiveTab] = useState(club?.is_special ? 1 : 3);
    return (
        <div className='border rounded border-danger'>
            <div className="card mb-0 bg-vb-dark overflow-auto text-white">
                <div className="card-header seperater">
                    <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                            <span role='button' className={activeTab === 1 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"}
                                onClick={() => setActiveTab(1)} >Bets</span>
                        </li>
                        {club &&
                            <>
                                {club.is_special ?
                                    <>
                                        <li className="nav-item">
                                            <span role='button' className={activeTab === 4 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"}
                                                onClick={() => setActiveTab(4)} >Summary</span>
                                        </li>
                                        <li className="nav-item">
                                            <span role='button' className={activeTab === 5 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"}
                                                onClick={() => setActiveTab(5)} >Players</span>
                                        </li>
                                    </> :
                                    <>
                                        <li className="nav-item">
                                            <span role='button' className={activeTab === 2 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"}
                                                onClick={() => setActiveTab(2)} >Summary</span>
                                        </li>
                                        <li className="nav-item">
                                            <span role='button' className={activeTab === 3 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"}
                                                onClick={() => setActiveTab(3)} >Players</span>
                                        </li>
                                    </>}
                            </>}
                    </ul>
                </div>
                <div className="card-body">
                    {activeTab === 1 ?
                        <Bets /> :
                        activeTab === 2 ?
                            <Summary /> :
                            activeTab === 3 ?
                                <PlayerSummary /> :
                                activeTab === 4 ?
                                    <ChallengeSummary /> :
                                    activeTab === 5 ?
                                        <Winners /> :
                                        null
                    }
                </div>
            </div>
        </div>
    )
}

export default TabSetup;