import React from 'react'
import BetCard from './BetCard'
import BetBottom from './BetBottom'

export default function Index({ betDetails }) {
    return (
        <>
            {
                betDetails?.bets.map(item => (
                    <BetCard item={item} key={item.id} />
                ))
            }
            <BetBottom/>
        </>
    )
}
