import React, { useEffect, useState } from 'react'
import { getValuebet, GET_WALLET_HISTORY } from '../../API-Handle/services/auth_curd'
import moment from 'moment'
import SmSpinner from '../CustLoader/SmSpinner';

export default function ChipsHistory({ club_id, wallet_type, member_id}) {
    const [hist, setHist] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadMore, setLoadmore] = useState(false);
    const [bets, setBets] = useState(true);
    const [page, setPage] = useState(0);
    const [updater, setUpdater] = useState(true);

    const pageIncreament = () => {
        setPage(prev => prev + 1)
        setUpdater(!updater)
    }

    const changeIncludeCheck = (e) => {
        setHist([])
        setBets(e.target.checked)
        setPage(0)
        setUpdater(!updater)
    }

    const getWalletHistory = async () => {
        setLoading(true);
        setLoadmore(false);
        let url = `${GET_WALLET_HISTORY}?wallet_type=${wallet_type}&club_id=${club_id}&page=${page}&is_bet_history_include=${bets}`;
        if (member_id) url += `&member_id=${member_id}`;
        let res = await getValuebet(url)
        if (res) {
            setHist([...hist, ...res]);
            setLoading(false);
            if (res.length) {
                setLoadmore(true);
            } else {
                setLoadmore(false);
            }
        }
    }

    useEffect(() => {
        getWalletHistory()
        // eslint-disable-next-lin
    }, [updater])

    return (
        <>
            <div className='pb-2'>
                <input disabled={loading} className="me-2" type="checkbox" checked={bets} id="include-bets" onChange={(e)=>changeIncludeCheck(e)} />
                <label htmlFor="include-bets" className="form-check-label text-white">
                    Including Bet Transactions
                </label>
            </div>
            <div className="overflow-x overflow-y" style={{ maxHeight: window.innerHeight - 260 }}>
                <table className="table full-width table-custom-dark">
                    <thead>
                        <tr>
                            <th scope="col" className="align-top">Date<br /></th>
                            <th scope="col" className="align-top">Description</th>
                            <th scope="col" className="align-top">In/Out</th>
                            <th scope="col" className="align-top">Locked</th>
                            <th scope="col" className="align-top">Closing Balance <small>(Including Locked Chips)</small> </th>
                        </tr>
                    </thead>
                    <tbody>
                        {hist.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{moment(item.created_at).format('MMMM Do YYYY, hh:mm:ss A')}</td>
                                    <td>{item.desc ? item.desc : "N/A"}</td>
                                    <td>{item.transaction_type !== 'LOCKED' ? (item.amount > 0 ? <span className='text-success'>{`+${item.amount.toFixed(2)}`}</span> : <span className='text-danger'>{item.amount.toFixed(2)}</span>) : "-"}</td>
                                    <td>{item.transaction_type === 'LOCKED' ? <span className='text-warning'>{Math.abs(item.amount).toFixed(2)}</span> : "-"}</td>
                                    <td>{(item.unlocked_closing_balance + item.locked_closing_balance).toFixed(2)}</td>
                                </tr>)
                        })}
                    </tbody>
                </table>
                {loading && <SmSpinner my={4} py={0} />}
                {loadMore && <div onClick={pageIncreament} role='button' className='d-flex justify-content-center my-4'> <i className="fa fa-plus-circle text-info"> <u>Load More</u> </i></div>}
            </div>
        </>
    )
}
