import React, { useState } from 'react'
import MonthlySummary from './MonthlyWinners'
import WeeklyWinners from './WeeklyWinners '
import DailyWinners from './DailyWinners'

export default function Index() {
    const [reportType, setReportType] = useState("Daily");

    return (
        <div>
            <div className="btn-group" role="group">
                <button type="button" className={reportType == 'Daily' ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setReportType('Daily')}>Daily</button>
                <button type="button" className="btn btn-secondary me-0 ms-0 pe-0 ps-0" disabled></button>
                <button type="button" className={reportType == 'Weekly' ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setReportType('Weekly')}>Weekly</button>
                <button type="button" className="btn btn-secondary me-0 ms-0 pe-0 ps-0" disabled></button>
                <button type="button" className={reportType == 'Monthly' ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setReportType('Monthly')}>Monthly</button>
            </div>
            <div className="no-tbl-header">
                {reportType == 'Daily' ?
                    <DailyWinners /> :
                reportType == 'Weekly' ?
                    <WeeklyWinners /> :
                    <MonthlySummary />
                }
            </div>
        </div>
    )
}