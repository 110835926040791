import React, { useState, useEffect, useContext } from 'react'
import {
    Modal
} from "reactstrap";
import ChipsTab from '../../../../../../../Components/CustComp/ChipsTab'
import ValuebetContext from '../../../../../../../context/Valuebet/ValuebetContext';
import ChipsHistory from '../../../../../../../Components/WalletCommanTabs/ChipsHistory';

export default function PlayerWallet({ item }) {
    const [transferChipModal, setTransferChipModal] = useState(false);
    const [playerAvailableChips, setPlayerAvailableChips] = useState(0);
    const [playerLockedChips, setPlayerLockedChips] = useState(0);
    const [options, setOptions] = useState({});
    const [activeTab, setActiveTab] = useState(1);
    const { fetchSingleClub } = useContext(ValuebetContext)

    const toggleClubChipModal = () => {
        setTransferChipModal(!transferChipModal)
    };

    const manageModal = () => {
        toggleClubChipModal()
    }

    const initialization = () => {
        let playerOpts = [{ member_id: item.user_id, username: item.user_name, chips: item.user_chips.player_chips, locked: item.user_chips.locked_chips, wallet_type: "P", uuid: item.user_id + "P" }]
        setOptions(playerOpts)

        // Chips calculation
        setPlayerAvailableChips(item.user_chips.player_chips)
        setPlayerLockedChips(item.user_chips.locked_chips)
    }

    useEffect(() => {
        initialization()
        // eslint-disable-next-line
    }, [item])


    return (
        <>
            <Modal isOpen={transferChipModal} toggle={toggleClubChipModal} className="modal-dialog-centered modal-xl">
                <div className="modal-header bg-vb-dark">
                    {/* <h5 className="modal-title my-0"> Transfer Chips from/to {item.label}'s Wallet</h5> */}
                    <h5 className="modal-title my-0"> Transfer Chips from/to Club's Wallet</h5>
                    <button onClick={toggleClubChipModal} type="button" className="text-white btn-close" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="card mb-0 text-start bg-vb-dark">
                    <div className="card-header seperater">
                        <ul className="nav nav-tabs card-header-tabs">
                            <li className="nav-item">
                                <span className={activeTab === 1 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"} role='button' onClick={() => setActiveTab(1)}>Chips Management</span>
                            </li>
                            <li className="nav-item">
                                <span className={activeTab === 2 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"} role='button' onClick={() => setActiveTab(2)}>History</span>
                            </li>
                        </ul>
                    </div>
                    {activeTab === 1 ?
                        <div className="card-body">
                            <div className='px-3 py-2 mb-2 bg-dark rounded text-white font-size-13px'>
                                <div className='me-5'>Available Chips : <b>{playerAvailableChips}</b></div>
                                <div className='me-5'> In-Play (unavailable) Chips : <b>{playerLockedChips}</b></div>
                            </div>
                            {/* <ChipsTab options={options} club_id={item.id} agent_id={item.user_id} origin="AGENT" callBack={fetchSingleClub} toggleClubChipModal={toggleClubChipModal} /> */}
                            <ChipsTab options={options} club_id={item.id} agent_id={null} origin="CLUB" callBack={fetchSingleClub} toggleClubChipModal={toggleClubChipModal} showResetStaus={false} />
                        </div>
                        : activeTab === 2 ?
                            <div className="card-body">
                                <ChipsHistory club_id={item.id} wallet_type="PLAYER" />
                            </div>
                            : null}
                </div>
            </Modal>

            <button onClick={() => manageModal('player')} type="button" className='club-chip-button'>
                Player Wallet: <br /> {playerAvailableChips.toFixed(2)} <span className='fw-bold'>(</span>{playerLockedChips.toFixed(2)}<span className='fw-bold'>)</span>
            </button>
        </>
    )
}
