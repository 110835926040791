import React from 'react'
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';

export default function AgentLinkButtons({ val, activeClub }) {

    const highlightAgents = () => {
        for (let sagt of val.more_info.sub_agents) {
            let elmnt = document.getElementById(sagt.id);
            elmnt.scrollIntoView(false);
            elmnt.style.border = "8px solid #E10600";
            elmnt.firstElementChild.style.background = "#480000";
            setTimeout(() => { 
                elmnt.style.border = null;
                elmnt.firstElementChild.style.background = null;
            }, 5000);
        }
    }

    return (
        <div className='d-flex justify-content-between'>
            <span role='button' onClick={()=> highlightAgents()} className="text-primary">
                Sub-Agents({val.more_info?.sub_agents?.length})
            </span>
            <Link 
                to="/agent-players"
                state={{ agent_username: val.username, agent_players: val.more_info.players, agent_id: val.id, club_data: activeClub }}
                className="text-primary">
                Players({val.more_info.players?.length})
            </Link>
            <Link 
                to="/agent-report"
                state={{ item: activeClub }}
                className="text-primary">
                Reports
            </Link>
        </div>
    )
}
