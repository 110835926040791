import React from 'react';
import { Col, Row } from 'reactstrap';
import { postValuebet, LOGIN_URL } from '../../../API-Handle/services/auth_curd'
import { Modal } from 'reactstrap';
import { beforeLogin, manageAllInputsErrors, refineUsername } from '../../../Utils/comman';
import SmSpinner from '../../../Components/CustLoader/SmSpinner';
import { registerServiceWorker, updateSubscription } from '../../../firebase/firebaseConfig'

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input_errors: {
        username: '',
        password: ''
      },
      username: "", password: "",
      loading: false,
      isVisible: false,
      is_field_tracker_on: false,
    }
  }


  handleChange = (e) => {
    if (['username'].includes(e.target.name)){
      e.target.value = refineUsername(e.target.value)
    }
    if (this.state.is_field_tracker_on) {
      const { username_error, password_error } = manageAllInputsErrors(e);
      this.setState({ input_errors: { username: username_error, password: password_error } })
    }
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = async () => {
    this.setState({ loading: true });
    const { username_error, password_error } = beforeLogin(this.state.username, this.state.password);
    this.setState({ input_errors: { username: username_error, password: password_error } })
    if (username_error === '' && password_error === '') {
      let data = { username: this.state.username, password: this.state.password }
      let res = await postValuebet(LOGIN_URL, data);
      if (res) {
        registerServiceWorker();
        localStorage.setItem('authToken', res.access)
        localStorage.setItem('userData', JSON.stringify({ ...data, password: "" }))
        await updateSubscription(true);
        if (this.props !== undefined) {
          window.location = window.location.pathname
        }
      }
    } else {
      this.setState({ is_field_tracker_on: true });
    }
    this.setState({ loading: false })
  }

  toggle = () => {
    this.setState({ isVisible: !this.state.isVisible });
  }

  openSignup = () => {
    this.toggle()
    document.getElementById("signup_1").click()
  }

  openForgot = () => {
    this.toggle()
    document.getElementById("forgot_1").click()
  }

  render() {
    const { loading, isVisible } = this.state;
    return (
      <React.Fragment>
        <Modal isOpen={isVisible} className="modal-dialog-centered modal-md">
          <div className="modal-header bg-vb-dark border-bottom-0">
            <button onClick={this.toggle} type="button" className="text-white btn-close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className='pb-5'>
            <div className="overflow-hidden account-card mx-2">
              <div className="text-white text-center position-relative">
                <h5 >Welcome Back !</h5>
                <p> Sign in to continue.</p>
              </div>
              <div className="px-5 pb-4 pt-4">
                <form className='formcntr'>
                  <div>
                    <label htmlFor="username" className='text-light font-size-14px'>Username</label>
                    <input className='form-control bg-dark text-white border-secondary' type="text" name="username" onChange={this.handleChange} value={this.state.username} id="username" placeholder="" />
                  </div>
                  {this.state.input_errors.username ? <div className='inputerrors'> {this.state.input_errors.username} </div> : null}
                  <div className="mt-3">
                    <label htmlFor="password" className='text-light font-size-14px'>Password</label>
                    <input className='form-control bg-dark text-white border-secondary' type="password" name="password" id="password" onChange={this.handleChange} value={this.state.password} placeholder="" />
                  </div>
                  {this.state.input_errors.password ? <div className='inputerrors'> {this.state.input_errors.password} </div> : null}
                  <Row>
                    <Col className="text-center">
                      <button className="signbtn" onClick={this.handleSubmit} type="button" disabled={loading}> {loading ? <SmSpinner inline={true}/> : "Sign In"}
                        <i className="fa fa-arrow-right submticon" aria-hidden="true"></i> </button>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
            <div className="text-center">
              <div className="mb-0" >
                <span className='link clryllo' role='button' onClick={this.openForgot}>Forgot your password?</span>
              </div>
              <div className='mt-3 text-white'>Don't have an account ? <span className={"font-500 clryllo"} role='button' onClick={this.openSignup}> Signup now </span> </div>
            </div>
          </div>
        </Modal>
        <span className={this.props.className} role='button' onClick={this.toggle} id="login_1">
          {this.props.title}
        </span>
      </React.Fragment>
    )
  }
}
export default Login;