import React from 'react'

function Checkbox({ label, onChange }) {
  return (
      <label className='check-text-style'>
          <input type="checkbox" onChange={onChange} />
          {label}
      </label>
  )
}

export default Checkbox