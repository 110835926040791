import React from 'react'
import PlayerWallet from './PlayerWallet'
// import AgentWallet from './AgentWallet'
import ClubWallet from './ClubWallet'

export default function ClubLabel({ item }) {
    return (
        <>
            <div className={`text-center ${item.label === "Owner" ? "club-owner-label-container" : item.label === "Manager" ? "club-manager-label-container" : ""}`}>
                <b> <p className="mb-0 fw-bold"> {item.label} </p> </b>
                <div className="text-center d-flex justify-content-between">
                    <ClubWallet item={item}/>
                    {/* <AgentWallet item={item}/> */}
                    <PlayerWallet item={item}/>
                </div>
            </div>
        </>
    )
}
