import React from 'react'
import { Modal } from "reactstrap"

export default function Support({telModal, telToggle}) {
  return (
    <Modal isOpen={telModal} toggle={telToggle} className="modal-dialog-centered">
          <div className="modal-header bg-vb-dark">
            <h5 className="modal-title my-0">Valuebet.App Support Group</h5>
            <button onClick={telToggle} type="button" className="text-white btn-close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className='modal-body letter-space text-center py-5'>
              Join our Telegram Support Group: <a className="vb-text-orange" href="https://t.me/+SrsT9XiwBkA3NTVh" target={"_blank"}>Join Now</a>
          </div>
        </Modal>
  )
}
