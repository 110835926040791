import React from 'react'
import logov from '../../../../Static/UserManagement/images/common/logov.png';
import PayFooter from '../../../../Components/CustComp/PayFooter';
// import Spinner from '../../../../Components/CustLoader/Spinner';
// import { useLocation } from 'react-router';
// import { PAYMENT_STATUS_URL, getValuebet } from '../../../../API-Handle/services/auth_curd';

export default function ThankYou() {
    // const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);
    // const NP_id = searchParams.get('NP_id');
    // const [orderDetails, setOrderDetails] = useState(null);

    // const getOrderDetails = async() => {
    //     let request_url = PAYMENT_STATUS_URL + NP_id;
    //     let res = await getValuebet(request_url);
    //     if(res){
    //         setOrderDetails(res);
    //     }
    // }

    // useEffect(()=>{
    //     getOrderDetails()
    // }, [])

    return (
        <div className='valuebet-bg-color'>
            <div className='BG-valuebet thank-you-card'>
                {/* <Spinner /> */}
                <div className="container text-center py-5">
                    <img src={logov} className="img-fluid shadow-lg mt-5 mb-4" alt="Affiliate Header" width="60" height="60" loading="lazy" />
                    <h2 className='reguler-heading'><span className='text-success'>Payment Successful. Thank You!</span></h2>
                    <p className='reguler-fonts'>Your Valuebet Coins/Tokens have been credited to your Wallet. We appreciate your business and support. If you have any inquiries, please reach out to us at <span className='text_orange'>support@valuebet.app</span>.</p>
                </div>
                <PayFooter />
            </div>
        </div>
    )
}