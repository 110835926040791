import React, { useEffect, useState } from 'react'
import {
    Button,
    Modal,
    ModalBody
} from "reactstrap";
import { getValuebet, GET_FOR_SALE_TOKENS } from '../../../../../../API-Handle/services/auth_curd'
import TokenDetails from './TokenDetails';
import RedeemCoupon from '../../Common/RedeemCoupon';

export default function BuyTokens() {
    const [buyModal, setBuyModal] = useState(false);
    const [allDisabled, setAllDisabled] = useState(false);
    const [tokens, setTokens] = useState([]);
    const [discount, setDiscount] = useState(null);
    const [code, setCode] = useState('');

    const toggleBuyModal = () => {
        setBuyModal(!buyModal)
    }

    const getForSaleTokens = async () => {
        let res = await getValuebet(GET_FOR_SALE_TOKENS)
        if (res) {
            setTokens(res);
        }
    }

    useEffect(() => {
        getForSaleTokens()
    }, [])

    return (
        <div>
            <Modal isOpen={buyModal} toggle={toggleBuyModal} className="modal-dialog-centered modal-xl">
                <div className="modal-header">
                    <h5 className="modal-title my-0">Buy Tokens </h5>
                    <button onClick={toggleBuyModal} type="button" className="text-white btn-close" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody>
                    <div className='row coin-modal-margin'>
                        <RedeemCoupon 
                            discount={discount} 
                            setDiscount={setDiscount}
                            code={code}
                            setCode={setCode}
                            access_type={"Token"}
                        />
                        <div className='overflow-x'>
                            <table className="table text-start full-width table-custom-dark">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th className='ps-3'>Description</th>
                                        <th>Airdrops</th>
                                        <th>LTD</th>
                                        <th>Cost</th>
                                        {discount && <th><div className='ms-2'>Discount Cost</div></th>}
                                        <th>Qty</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className="mx-2">
                                    {tokens.map((item) => {
                                        return (
                                            <TokenDetails key={item.id} item={item} setAllDisabled={setAllDisabled} allDisabled={allDisabled} discount={discount} code={code} />
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Button
                color="success"
                className='btn-sm text-nowrap'
                outline
                onClick={toggleBuyModal}
            > Buy Tokens
            </Button>
        </div>
    )
}