import React, { useState, useEffect, useContext } from 'react'
import ChipsTab from '../../../../../../../Components/CustComp/ChipsTab';
import AllInclusive from '@mui/icons-material/AllInclusive';
import ChipCycle from '../../ResetChipsCycle';
import { GET_CHILD_WALLETS, getValuebet } from '../../../../../../../API-Handle/services/auth_curd';
import ValuebetContext from '../../../../../../../context/Valuebet/ValuebetContext';

export default function ChipMgt({ club, toggleClubChipModal }) {
    const { total_chips, available_chips, locked_chips, os_total_chips, os_available_chips, os_locked_chips } = club.club_wallet;
    const [options, setOptions] = useState([]);
    const { fetchSingleClub } = useContext(ValuebetContext);

    const getMemberWallets = async(club_id) => {
        const members_chips = await getValuebet(GET_CHILD_WALLETS+club_id);
        if (members_chips) setOptions(members_chips);
    }

    useEffect(() => {
        getMemberWallets(club.id)
        // eslint-disable-next-line
    }, [club])

    return (
        <>
            <div className='d-flex text-nowrap rounded flex-wrap text-white font-size-13px justify-content-between mb-2'>
                <div className='d-flex flex-wrap justify-content-between align-items-center chip-info-responsive-view p-3 rounded border border-secondary my-1 bg-dark'>
                    <div className='border-start border-danger border-2 ps-2 me-2'>
                        <div>Total Club Chips : <b>{club.is_once_ltd_assigned ? <AllInclusive style={{ fontSize: 14 }} /> : total_chips.toFixed(2)}</b></div>
                        <div>Club Available Chips : <b>{club.is_once_ltd_assigned ? <AllInclusive style={{ fontSize: 14 }} /> : available_chips.toFixed(2)}</b></div>
                        <div>Club Locked Chips : <b>{locked_chips.toFixed(2)}</b></div>
                    </div>
                    <div className='border-start border-danger border-2 ps-2'>
                        <div> Total Outstanding Chips : <b>{os_total_chips.toFixed(2)}</b></div>
                        <div> Unlocked Outstanding Chips : <b>{os_available_chips.toFixed(2)}</b></div>
                        <div> Locked Outstanding Chips : <b>{os_locked_chips.toFixed(2)}</b></div>
                    </div>
                </div>
                <div className="resetchip-responsive-view align-items-center p-3 rounded border border-secondary my-1 bg-dark">
                    <ChipCycle club_id={club.id} reUseId={"toggle-chip-reset1"} callBack={fetchSingleClub} />
                </div>
            </div>
            <ChipsTab
                options={options}
                club_id={club.id}
                agent_id={null}
                origin="CLUB"
                callBack={fetchSingleClub}
                toggleClubChipModal={toggleClubChipModal}
                childComponant={
                    <div className="resetchip-responsive-scrollable-view align-items-center pb-2 border-bottom border-white w-100 mb-2">
                        <ChipCycle club_id={club.id} reUseId={"toggle-chip-reset2"} callBack={fetchSingleClub} />
                    </div>}
                showResetStaus={true}
            />
        </>
    )
}
