import React from 'react'

function NoData({msg}) {
  return (
    <div className='p-2 py-5 no-data text-center text-white m-1 font-size-12px'>
      {msg}
    </div>
  )
}

export default NoData
