import React from "react";
import { useNavigate } from "react-router";
import { Row } from "reactstrap";

const TableItemsTab = ({ title, daily_count, weekly_count }) => {
  return (
    <tr>
      <th scope="row">{title}</th>
      <td className="py-1 text-end">{daily_count}</td>
      <td className="py-1 text-end">{weekly_count}</td>
    </tr>
  );
};
export default function ClubStats({ item }) {
  const navigate = useNavigate();

  const goToClubReport = () => {
    navigate(
      `/sportbook/club-reports`,
      { state: { club: item } }
    );
  }

  return (
    <>
      {
        <Row className="mt-3 mx-2">
          <div className="table-responsive bg-vb-dark rounded">
            <table className="table table-sm table-custom-dark text-nowrap table-borderless mb-0">
              <thead>
                <tr className="font-size-11px">
                  <th scope="col"></th>
                  <th scope="col" className="text-end">
                    Daily
                  </th>
                  <th scope="col" className="text-end">
                    Weekly
                  </th>
                </tr>
                <tr>
                  <td className="px-0 pb-1 pt-0">
                    <div className="horizantal-line" />
                  </td>
                  <td className="px-0 pb-1 pt-0">
                    <div className="horizantal-line" />
                  </td>
                  <td className="px-0 pb-1 pt-0">
                    <div className="horizantal-line" />
                  </td>
                </tr>
              </thead>
              <tbody className="font-size-12px" onClick={goToClubReport} role="button">
                <TableItemsTab
                  title={"Active Player"}
                  daily_count={item.club_dashboard?.daily_active_members}
                  weekly_count={item.club_dashboard?.weekly_active_members}
                />
                 <TableItemsTab
                  title={"Volume"}
                  daily_count={item.club_dashboard?.daily_risk_amount ?? 0}
                  weekly_count={item.club_dashboard?.weekly_risk_amount ?? 0}
                />
                <TableItemsTab
                  title={"Net"}
                    daily_count={item.club_dashboard?.daily_avg_win_loss ?? 0}
                    weekly_count={item.club_dashboard?.weekly_avg_win_loss ?? 0}
                //   daily_count={1000000}
                //   weekly_count={200004}
                />

              </tbody>
            </table>
          </div>
        </Row>
      }
    </>
  );
}