import React from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const getArrow = (current, previous) => {
    let upArrow = <ArrowUpwardIcon style={{ fontSize: 14, marginBottom:4, color:"green" }} />;
    let downArrow = <ArrowDownwardIcon style={{ fontSize: 14, color:"red" }} />;

    if (current === previous){
        return "";
    }else if ((current === null) && (previous !== null)){
        return downArrow;
    }else if ((current !== null) && (previous === null)){
        return upArrow;
    }else if (current > previous){
        return downArrow;
    }else {
        return upArrow;
    }
}

const getColorClassName = (current, previous) => {
    let equal = "text-white";
    let down = "vb-text-orange";
    let up = "text-success";

    if (current === previous){
        return equal;
    }else if ((current === null) && (previous !== null)){
        return down;
    }else if ((current !== null) && (previous === null)){
        return up;
    }else if (current > previous){
        return down;
    }else {
        return up;
    }
}

export default function LabelInfo({item}) {
    const challenge_info = item.challenge_info;
    return (
        <div className='club-member-label-container bg-vb-dark border border-dark'>
            <div className="text-center d-flex justify-content-around py-1">
                {challenge_info && <div>
                    <span className='letter-space font-size-12px'> <span className={getColorClassName(challenge_info?.daily_rank, challenge_info?.prev_daily_rank)}>{challenge_info?.daily_rank ? `#${challenge_info?.daily_rank}` : "N/A"}</span> <span className='vb-text-orange'>({challenge_info && getArrow(challenge_info?.daily_rank, challenge_info?.prev_daily_rank)}#{challenge_info?.prev_daily_rank ? challenge_info?.prev_daily_rank : "N/A"})</span></span>
                    <br/><span className='font-size-11px letter-space'>Daily Rank <br/>(Last Rank)</span>
                </div>}
                {challenge_info && <div>
                    <span className='letter-space font-size-12px'> <span className={getColorClassName(challenge_info?.weekly_rank, challenge_info?.prev_weekly_rank)}>{challenge_info?.weekly_rank ? `#${challenge_info?.weekly_rank}` : "N/A"}</span> <span className='vb-text-orange'>({challenge_info && getArrow(challenge_info?.weekly_rank, challenge_info?.prev_weekly_rank)}#{challenge_info?.prev_weekly_rank ? challenge_info?.prev_weekly_rank : "N/A"})</span></span>
                    <br/><span className='font-size-11px letter-space'>Weekly Rank <br/>(Last Rank)</span>
                </div>}
                {challenge_info && <div>
                    <span className='letter-space font-size-12px'> <span className={getColorClassName(challenge_info?.monthly_rank, challenge_info?.prev_monthly_rank)}>{challenge_info?.monthly_rank ? `#${challenge_info?.monthly_rank}` : "N/A"}</span> <span className='vb-text-orange'>({challenge_info && getArrow(challenge_info?.monthly_rank, challenge_info?.prev_monthly_rank)}#{challenge_info?.prev_monthly_rank ? challenge_info?.prev_monthly_rank : "N/A"})</span></span>
                    <br/><span className='font-size-11px letter-space'>Monthly Rank <br/>(Last Rank)</span>
                </div>}
            </div>
        </div>
    )
}
