import React, { useState } from 'react'
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function CopyText({ text, className }) {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1500)
    }

    return (
        <CopyToClipboard text={text} onCopy={() => handleCopy()}>
            <button title="Copy" className={className ? className : 'owner-id-copy-button'}>
                {isCopied ? <span className='copied-id'><i className="fa fa-check" aria-hidden="true"></i></span> : <i className="fa fa-clone" aria-hidden="true"></i>}
            </button>
        </CopyToClipboard>
    )
}
