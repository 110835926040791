import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import ExpandableView from './ExpandableView';
import moment from 'moment'
import { PLAYER_BET_HISTORY } from '../../../../API-Handle/services/auth_curd';
import { getValuebet } from '../../../../API-Handle/services/auth_curd';
import { useLocation } from 'react-router-dom'
import { APP_OFFSET } from '../../../../constant';
import CustomWeekPicker from '../../../../Components/Calendar/CustomWeekPicker';
import Spinner from '../../../../Components/CustLoader/Spinner';
import { getBetslipTitle } from '../../../../Utils/sportsbook';

const conditionalRowStyles = [
    {
        when: row => row.status === "WON",
        style: {
            backgroundColor: 'rgb(82 167 123 / 90%)',
            color: 'white',
        },
    },
    {
        when: row => row.status === "LOSE",
        style: {
            backgroundColor: 'rgb(255 94 79 / 48%)',
            color: 'white',
            // '&:hover': {
            // 	cursor: 'not-allowed',
            // },
        },
    },
    {
        when: row => row.status === "REFUNDED",
        style: {
            backgroundColor: '#193438',
            color: 'white',
        },
    },
    {
        when: row => row.status === "TOTAL",
        style: {
            backgroundColor: '#ffb100',
            marginBottom: "5px",
            color: '#000000',
        },
    },
];

const columns = [
    {
        name: 'Participant',
        selector: row => row.status === "TOTAL" ? <b>{row.participant}</b> : row.participant,
        minWidth: "240px"
    },
    {
        name: 'Risk',
        selector: row => row.risking,
    },
    {
        name: 'To Win',
        selector: row => row.winnings,
    },
    {
        name: 'Results',
        selector: row => row.result,
    },
    {
        name: 'Vig Chips Burned',
        selector: row => row.vig_paid,
    },
    // {
    //     name: 'Kickbacks',
    //     selector: row => row.kickback,
    // },
    // {
    //     name: 'NET',
    //     selector: row => row.net,
    // },
    {
        name: 'Bet Type',
        selector: row => row.bet_type,
    },
    {
        name: 'Settled Date',
        selector: row => row.settled_date,
        minWidth: "200px"
    },
    {
        name: 'Game Date',
        selector: row => row.game_date,
        minWidth: "200px"
    },
    {
        name: 'Bet Date',
        selector: row => row.bet_date,
        minWidth: "200px"
    }
];

export default function Settled() {
    const [preparedData, setPreparedData] = useState(null)
    const [totalForWeek, setTotalForWeek] = useState({})
    const [week, setWeek] = useState(null);
    const [dynmcolmn, setDynmcolmn] = useState([]);

    const location = useLocation()

    const getBets = async () => {
        setPreparedData(null)
        let club = location?.state?.club;
        if (!club) return;

        if (club?.is_special) {
            let temp_column = [...columns]
            temp_column.splice(4, 1);
            setDynmcolmn(temp_column);
        } else {
            setDynmcolmn(columns);
        }

        let player_id = location?.state?.member?.id
        let club_id = club.id;
        let request_url = PLAYER_BET_HISTORY + club_id + `?bet_type=SETTLED&report_time=${week}`;
        if (player_id) {
            request_url += `&player_id=${player_id}`;
        }
        let bets = await getValuebet(request_url);
        prepareData(bets);
    }

    const prepareData = async (data) => {
        if (data) {
            for (let row of data) {
                row.participant = getBetslipTitle(row);
                row.net = row.kickback + row.result
                row.game_date = moment.utc(row.event_time).utcOffset(APP_OFFSET).format('L LT') + " PST";
                row.settled_date = moment.utc(row.updated_at).utcOffset(APP_OFFSET).format('L LT') + " PST";
                row.bet_date = moment.utc(row.created_at).utcOffset(APP_OFFSET).format('L LT') + " PST";
            }
            groupByDay(data)
        }

    }

    const groupByDay = async (data) => {
        let club = location?.state?.club;
        let total_for_day = null
        let total_for_week = {
            id: `TOTALWEEK`,
            status: 'TOTALWEEK',
            participant: `Total for Week (${week})`,
            risking: 0,
            winnings: 0,
            result: 0,
            kickback: 0,
            vig_paid: 0,
            net: 0,
            bet_type: "-",
            game_date: "-",
            bet_date: "-"
        }
        let prev_date = null
        let collections = []
        for (let row of data) {
            let date;
            if(club.is_special){
                date = moment.utc(row.event_time).utcOffset(APP_OFFSET).format('L');
            }else{
                date = moment.utc(row.updated_at).utcOffset(APP_OFFSET).format('L');
            }
            if (date === prev_date) {
                total_for_day['risking'] += row.risking
                total_for_day['winnings'] += row.winnings
                total_for_day['result'] += row.result
                total_for_day['kickback'] += row.kickback
                total_for_day['vig_paid'] += row.vig_paid
                total_for_day['net'] += row.net
            } else {
                if (total_for_day) {
                    collections.push(total_for_day)
                }
                total_for_day = {
                    id: `TOTAL${date}`,
                    status: 'TOTAL',
                    participant: `Total for Day (${date})`,
                    risking: row.risking,
                    winnings: row.winnings,
                    result: row.result,
                    kickback: row.kickback,
                    vig_paid: row.vig_paid,
                    net: row.net,
                    bet_type: "-",
                    game_date: "-",
                    bet_date: "-"
                }
                prev_date = date;
            }
            collections.push(row)
            total_for_week['risking'] += row.risking
            total_for_week['winnings'] += row.winnings
            total_for_week['result'] += row.result
            total_for_week['kickback'] += row.kickback
            total_for_week['vig_paid'] += row.vig_paid
            total_for_week['net'] += row.net
        }
        if (total_for_day) {
            collections.push(total_for_day)
        }

        // Fixed decimal number 
        for (let one_coll of collections) {
            one_coll['risking'] = one_coll['risking'].toFixed(2)
            one_coll['winnings'] = one_coll['winnings'].toFixed(2)
            one_coll['result'] = one_coll['result'].toFixed(2)
            one_coll['kickback'] = one_coll['kickback'].toFixed(2)
            one_coll['vig_paid'] = one_coll['vig_paid'].toFixed(2)
            one_coll['net'] = one_coll['net'].toFixed(2)
        }
        setPreparedData(collections)

        // Fixed decimal number 
        total_for_week['risking'] = total_for_week['risking'].toFixed(2)
        total_for_week['winnings'] = total_for_week['winnings'].toFixed(2)
        total_for_week['result'] = total_for_week['result'].toFixed(2)
        total_for_week['kickback'] = total_for_week['kickback'].toFixed(2)
        total_for_week['vig_paid'] = total_for_week['vig_paid'].toFixed(2)
        total_for_week['net'] = total_for_week['net'].toFixed(2)
        setTotalForWeek(total_for_week)
    }

    useEffect(() => {
        if (week) getBets();
        // eslint-disable-next-line
    }, [week])

    return (
        <>
            <div className='inline-datepicker-container'>
                <CustomWeekPicker setWeek={setWeek} className={"settled-datepicker"} position={"bottom right"}/>
            </div>
            {preparedData ?
                <DataTable
                    columns={dynmcolmn}
                    data={preparedData}
                    expandableRows
                    expandableRowsComponent={<ExpandableView isModal={false}/>}
                    expandableRowDisabled={(row) => (row.status === 'TOTAL')}
                    theme="dark"
                    dense={true}
                    header={false}
                    highlightOnHover={true}
                    conditionalRowStyles={conditionalRowStyles}
                    footer={totalForWeek}
                // noTableHead={index}
                /> :
                <Spinner />}
        </>
    )
}
