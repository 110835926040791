import React, { useContext, useEffect, useState } from 'react'
import ValuebetContext from '../../../../../../../context/Valuebet/ValuebetContext';
import { handleClickOnMarket } from '../Utils/BetData';
import { OFFLINE_ODD } from '../../../../../../../constant';

const MoneylineOdd = ({ team, odd, data,item,title }) => {
    const { betDetails, setBetDetails, removeSelectedBet} = useContext(ValuebetContext);
    const [isSelected, setIsSelected] = useState(false);
    let odd_points=odd.odd_points.toString();
    let odd_money=odd.odd_money.toString();
    let team_name;
    if(data.name === "winning_margin_overtime" &&  team.type !== "TYPE_RESULT")
    {
        team_name=`${team.name} By ${odd.odd_points}`; 
    }
    else{
        team_name=team.name
    }

    useEffect(() => {
        if (betDetails.bets.some((bet) => bet.id === odd.odd_id)) {
            setIsSelected(true);
        }
        else {
            setIsSelected(false);
        }

    }, [betDetails])
    
    return (
        (odd_points === OFFLINE_ODD || odd_points === `+${OFFLINE_ODD}` || odd_money === OFFLINE_ODD) || odd_money === `+${OFFLINE_ODD}`?
                <div id={odd.id} className="odd-disable-button mt-3"> - </div> :
        <div className={`d-flex justify-content-between p-1 rounded-1 mt-3 bet-container odd-border ${isSelected ? "selected-odd" : ""}`} onClick={() => handleClickOnMarket(team,data, betDetails, setBetDetails, removeSelectedBet, odd,item,title)} role='button'>
            <div className='header-title ps-2 props-odd-font-size'>{team_name}</div>
            <div className={`header-title pe-2 props-odd-font-size`}>{odd.odd_money>0?`+${odd.odd_money}`:odd.odd_money}</div>
        </div>
    )

}
export default MoneylineOdd