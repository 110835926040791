import React, {useState} from 'react'
import DataTable from 'react-data-table-component-footer';
import BettingHistory from '../BettingHistory';

export default function PlayerReport() {
    const [isActive, setIsActive] = useState(false);

    const columns = [
        {
            name: 'Player',
            selector: row => row.player,
            width: "150px",
            sortable: true
        },
        {
            name: 'Total Volume',
            selector: row => row.year,
            sortable: true
        },
        {
            name: 'Total No. Bets',
            selector: row => row.year,
            sortable: true
        },
        {
            name: 'Total Winnings',
            selector: row => row.year,
            sortable: true
        },
        {
            name: ' Total Losses',
            selector: row => row.year,
            sortable: true
        },
        {
            name: 'Total Vig',
            selector: row => row.year,
            sortable: true
        },
        {
            name: 'Win/Loss Net',
            selector: row => row.year,
            sortable: true
        },
    ];
    
    const data = [
        {
            id: 3,
            player: 'demo1',
            year: '1984',
        },
        {
            id: 4,
            player: 'demo2',
            year: '1984',
        },
        {
            id: 5,
            player: 'demo3',
            year: '1984',
        }
    ]

    return (
        <div>
            <div className="mx-4 py-1 bg-dark">
                <div className='my-1'>
                    <span role="button" className='mx-4 font-size-12px bg-primary p-1' onClick={() => setIsActive(!isActive)}>Sub Agents  {isActive ? <i className="fa fa-angle-down" aria-hidden="true"></i> : <i className="fa fa-angle-right" aria-hidden="true"></i>}</span>
                    {isActive && 
                    <ul className="my-2 agent-tree-fonts">
                        <li>
                            demo1
                            <ul>
                                <li>
                                    demo2
                                    <ul>
                                        <li>
                                            demo3
                                        </li>
                                        <li>
                                            demo4
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            demo5
                        </li>
                        <li>
                            demo6
                        </li>
                    </ul>}
                </div>
                <div className='mx-4 width-85-per'>
                    <DataTable
                        title={<span className='font-size-14px'>Players Details</span>}
                        columns={columns}
                        data={data}
                        expandableRows
                        expandableRowsComponent={<BettingHistory/>}
                        theme="dark"
                        dense={true}
                        highlightOnHover={true}
                        expandOnRowClicked={true}
                    />
                </div>
            </div>
        </div>
    )
}
