import React from 'react'
import DataTable from 'react-data-table-component-footer';
import BettingHistory from '../BettingHistory';

export default function PlayerTabReport() {
    const columns = [
        {
            name: 'Player',
            selector: row => row.player,
            width: "150px",
            sortable: true
        },
        {
            name: 'Total Volume',
            selector: row => row.year,
            sortable: true
        },
        {
            name: 'Total No. Bets',
            selector: row => row.year,
            sortable: true
        },
        {
            name: 'Total Winnings',
            selector: row => row.year,
            sortable: true
        },
        {
            name: ' Total Losses',
            selector: row => row.year,
            sortable: true
        },
        {
            name: 'Total Vig',
            selector: row => row.year,
            sortable: true
        },
        {
            name: 'Win/Loss Net',
            selector: row => row.year,
            sortable: true
        },
    ];

    const data = [
        {
            id: 3,
            player: 'demo1',
            year: '1984',
        },
        {
            id: 4,
            player: 'demo2',
            year: '1984',
        },
        {
            id: 5,
            player: 'demo3',
            year: '1984',
        },
        {
            id: 6,
            player: 'demo4',
            year: '1984',
        },
        {
            id: 7,
            player: 'demo5',
            year: '1984',
        },
        {
            id: 8,
            player: 'demo6',
            year: '1984',
        },
        {
            id: 9,
            player: 'demo7',
            year: '1984',
        },
        {
            id: 10,
            player: 'demo8',
            year: '1984',
        },
    ]

    return (
        <div className='no-tbl-header'>
            <DataTable
                columns={columns}
                data={data}
                expandableRows
                expandableRowsComponent={<BettingHistory />}
                theme="dark"
                dense={true}
                highlightOnHover={true}
                expandOnRowClicked={true}
            />
        </div>
    )
}
