export const SCHEDULED_STATUS = [
    'STATUS_SCHEDULED'
];

export const DELAY_STATUS = [
    'STATUS_RAIN_DELAY',
    'STATUS_DELAYED'
];

export const LIVE_INFO_STATUS = [
    'STATUS_PRE_FIGHT', 
    'STATUS_FIGHTERS_WALKING',
    'STATUS_FIGHTERS_INTRODUCTION',
    'STATUS_END_OF_ROUND', 
    'STATUS_END_PERIOD',
    'STATUS_END_OF_REGULATION',
    'STATUS_END_OF_FIGHT',
    'STATUS_END_OF_EXTRATIME',
    'STATUS_HALFTIME',
    'STATUS_HALFTIME_ET',
    'STATUS_OVERTIME'
];

export const PROGRESS_STATUS = [
    'STATUS_IN_PROGRESS_2', 
    'STATUS_IN_PROGRESS',
    'STATUS_FIRST_HALF',
    'STATUS_SECOND_HALF'
];

export const FINAL_STATUS = [
    'STATUS_FINAL', 
    'STATUS_FULL_TIME'
];

// Offile
export const BEFORE_START_STATUS = SCHEDULED_STATUS + DELAY_STATUS;

// INFO
export const INFO_STATUS = DELAY_STATUS + LIVE_INFO_STATUS;

// Live
export const LIVE_STATUS = PROGRESS_STATUS + LIVE_INFO_STATUS;

export const RUNNING_STATUS = LIVE_STATUS + FINAL_STATUS;


// Offline odd default value
export const OFFLINE_ODD = "0.0001";


// App Offset
export const APP_OFFSET = -480;

// Sportsbooks
export const LINES = [
    {'value':'3', 'label':'Pinnacle'},
    {'value':'22', 'label':'BetMGM'},
    {'value':'2', 'label':'Bovada'},
    // {'value':'12', 'label':'Bodog'},
    {'value':'19', 'label':'Draftkings'},
    {'value':'20', 'label':'Pointsbet'},
    {'value':'16', 'label':'Matchbook'},
    {'value':'6', 'label':'BetOnline'},
    {'value':'11', 'label':'Lowvig'},
    {'value':'4', 'label':'Sportsbetting'},
    {'value':'14', 'label':'Intertops'},
    {'value':'18', 'label':'YouWager'},
    {'value':'21', 'label':'Unibet'}
]

// Day Pickers
export const DAYS = [
    {'value':'0', 'label':'Mon'},
    {'value':'1', 'label':'Tue'},
    {'value':'2', 'label':'Wed'},
    {'value':'3', 'label':'Thu'},
    {'value':'4', 'label':'Fri'},
    {'value':'5', 'label':'Sat'},
    {'value':'6', 'label':'Sun'},
]

// Hour Pickers
export const HOURS = [
    {'value':'00', 'label':'00'},
    {'value':'01', 'label':'01'},
    {'value':'02', 'label':'02'},
    {'value':'03', 'label':'03'},
    {'value':'04', 'label':'04'},
    {'value':'05', 'label':'05'},
    {'value':'06', 'label':'06'},
    {'value':'07', 'label':'07'},
    {'value':'08', 'label':'08'},
    {'value':'09', 'label':'09'},
    {'value':'10', 'label':'10'},
    {'value':'11', 'label':'11'},
    {'value':'12', 'label':'12'},
    {'value':'13', 'label':'13'},
    {'value':'14', 'label':'14'},
    {'value':'15', 'label':'15'},
    {'value':'16', 'label':'16'},
    {'value':'17', 'label':'17'},
    {'value':'18', 'label':'18'},
    {'value':'19', 'label':'19'},
    {'value':'20', 'label':'20'},
    {'value':'21', 'label':'21'},
    {'value':'22', 'label':'22'},
    {'value':'23', 'label':'23'},
]


// Club Channel Config
export const CHANNELTYPE = {
    personal: 'PERSONAL',
    group: 'GROUP'
};

// Firebase Key
export const REACT_APP_VAPID_KEY = process.env.REACT_APP_VAPID_KEY
