import React, { useState } from 'react'
import MonthlySummary from './MonthlySummary'
import WeeklySummary from './WeeklySummary'
import DailySummary from './DailySummary'

export default function Index() {
    const [betType, setBetType] = useState("Daily");

    return (
        <div>
            <div className="btn-group" role="group">
                <button type="button" className={betType == 'Daily' ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setBetType('Daily')}>Daily</button>
                <button type="button" className="btn btn-secondary me-0 ms-0 pe-0 ps-0" disabled></button>
                <button type="button" className={betType == 'Weekly' ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setBetType('Weekly')}>Weekly</button>
                <button type="button" className="btn btn-secondary me-0 ms-0 pe-0 ps-0" disabled></button>
                <button type="button" className={betType == 'Monthly' ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setBetType('Monthly')}>Monthly</button>
            </div>
            <div className="no-tbl-header">
                {betType == 'Daily' ?
                    <DailySummary /> :
                betType == 'Weekly' ?
                    <WeeklySummary />:
                    <MonthlySummary />
                }
            </div>
        </div>
    )
}