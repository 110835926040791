import React, { useState, useContext, useEffect } from 'react'
import { postValuebet, CHIPS_TRANSACTION_URL, UPDATE_CHIP_AUTO_RESET_STATUS } from '../../API-Handle/services/auth_curd';
import { successNotification, errorNotification } from './PPHAlert';
import {
    ModalBody,
    ModalFooter,
    Button,
} from "reactstrap";
import ValuebetContext from '../../context/Valuebet/ValuebetContext';

export default function ChipsTab({ options, club_id, origin, agent_id, callBack, toggleClubChipModal, childComponant, showResetStaus }) {
    const [bulkReset, setBulkReset] = useState(true);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [opts, setOpts] = useState([]);
    const { setRootLoader } = useContext(ValuebetContext);

    useEffect(() => {
        let temp_opts = JSON.parse(JSON.stringify(options))
        setOpts(temp_opts)
        if(showResetStaus){
            let bulk_rest = true;
            for (let opt of temp_opts) {if (!opt.auto_reset) {bulk_rest = false; break;}}
            setBulkReset(bulk_rest);
        }
        // eslint-disable-next-line
    }, [options])

    const handleUserAdd = (e) => {
        setSelectedUsers([...selectedUsers, e]);
    }

    const handleAllUserAdd = () => {
        // if(from === "club"){
        // let filtered_opts = opts.filter((itm) => itm.wallet_type !== 'P')
        // setSelectedUsers(filtered_opts);
        // }else{
        setSelectedUsers(opts);
        // }
    }

    const handleUserRemove = (e) => {
        let updated_users = selectedUsers.filter((item) => e.uuid !== item.uuid);
        setSelectedUsers(updated_users);
    }

    const handleAllUserRemove = () => {
        setSelectedUsers([]);
    }

    const handleTransactionChip = (e, value) => {
        if (value < 1 && value !== "") {
            errorNotification("Please enter vaild chips")
            return
        }
        let updated_users = selectedUsers.map((item) => {
            if (e.uuid === item.uuid) {
                item["amount"] = value
            }
            return item
        });
        setSelectedUsers(updated_users);
    }

    const transactionApplyOnAll = (e) => {
        if (e.target.value < 1 && e.target.value !== "") {
            errorNotification("Please enter vaild chips")
            return
        }
        let updated_users = opts.map((item) => {
            item["amount"] = e.target.value
            return item
        });
        setSelectedUsers(updated_users);
    }

    const handleTransaction = async (trans_type, singleItem) => {
        setRootLoader(true)
        let targets = [];
        if (singleItem) {
            targets = [singleItem];
        } else {
            targets = selectedUsers;
            if (targets.length > 0) {
                for (let item of targets) {
                    if (!(item.amount && item.amount > 0)) {
                        errorNotification(`You have selected ${item.username} but not enterd valid chips amount!`)
                        setRootLoader(false)
                        return
                    }
                }
            } else {
                errorNotification("Please select any one!")
                setRootLoader(false)
                return
            }
        }
        // console.log(targets);
        let data = {
            "trans_type": trans_type,
            "origin": origin,
            "targets": targets,
            "club_id": club_id,
        }
        if (origin === "AGENT") data["agent_id"] = agent_id;
        let res = await postValuebet(CHIPS_TRANSACTION_URL, data)
        if (res) {
            successNotification(res.message);
            callBack(club_id)
            toggleClubChipModal()
        }
        setRootLoader(false)
    }

    const updateResetStatus = async(user_id, reset_status) => {
        setRootLoader(true)
        let data = { club_id, reset_status}
        if (user_id) data['user_id'] = user_id;
        let res = await postValuebet(UPDATE_CHIP_AUTO_RESET_STATUS, data);
        if(res){
            callBack(club_id);
            successNotification(res.message)
        }
        setRootLoader(false)
    }

    return (
        <>
            <ModalBody className='overflow-x overflow-y px-1 pt-0' style={{ maxHeight: "43vh" }}>
                {childComponant}
                <table className="table table-custom-dark">
                    <thead>
                        <tr>
                            <th scope="col" className="align-top">#<br /></th>
                            <th scope="col" className="align-top">Username</th>
                            <th scope="col" className="align-top">Chips</th>
                            <th scope="col" className="align-top">
                                <div>
                                    Select
                                </div>
                                <div className='bulk-select-font'>
                                    <input className='select-input-custom-height' type="checkbox" onChange={(e) => {
                                        if (e.target.checked) {
                                            handleAllUserAdd()
                                        } else {
                                            handleAllUserRemove()
                                        }
                                    }} /> in bulk
                                </div>
                            </th>
                            {showResetStaus && <th scope="col" className="align-top">
                                <div>
                                    Auto Reset
                                </div>
                                <div className='bulk-select-font'>
                                    <input className='select-input-custom-height' type="checkbox" checked={bulkReset} onChange={(e) => updateResetStatus(null, e.target.checked)} /> in bulk
                                </div>
                            </th>}
                            <th scope="col" className="align-top">
                                Transaction Chips
                                <div>
                                    <input type="number" className="form-control chip-input-custom-height bg-dark text-white" placeholder="Chips: Apply on All" onChange={transactionApplyOnAll} />
                                </div>
                            </th>
                            <th scope="col" className="align-top"> Action </th>
                        </tr>
                    </thead>
                    <tbody>
                        {opts.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    {/* if from === "club" */}
                                    {/*TODO: when Agent & Kickback feature will enable then uncomment this code*/}

                                    {/* <td className={item.wallet_type === "P" && modalType === "club" ? "text-secondary" : ""}><span className='text-nowrap'>{item.username} <b>({item.wallet_type})</b></span></td>
                                    <td className={item.wallet_type === "P" && modalType === "club" ? "text-secondary" : ""}><span className='text-nowrap'>{item.chips} {item.locked ? `(${item.locked})` : null}</span></td>
                                    <td><input type="checkbox" disabled={item.wallet_type === "P" && modalType === "club" ? true : false} checked={selectedUsers.filter((sel) => sel.uuid === item.uuid).length} onChange={(e) => {
                                        if (e.target.checked) {
                                            handleUserAdd(item)
                                        } else {
                                            handleUserRemove(item)
                                        }
                                    }} /></td> */}
                                    <td><span className='text-nowrap'>{item.username} <b>({item.wallet_type})</b></span></td>
                                    <td><span className='text-nowrap'>{item.chips} {item.locked ? `(${item.locked})` : null}</span></td>
                                    <td><input type="checkbox" checked={selectedUsers.filter((sel) => sel.uuid === item.uuid).length} onChange={(e) => {
                                        if (e.target.checked) {
                                            handleUserAdd(item)
                                        } else {
                                            handleUserRemove(item)
                                        }
                                    }} /></td>
                                    {showResetStaus && <td><input type="checkbox" checked={item.auto_reset} onChange={(e) => updateResetStatus(item.member_id, e.target.checked)} /></td>}
                                    <td><input type="number" value={item.amount ? item.amount : ""} disabled={!(selectedUsers.filter((sel) => sel.uuid === item.uuid).length)} onChange={(e) => handleTransactionChip(item, e.target.value)} className="form-control chip-input-custom-height bg-dark text-white" placeholder="Chips" /></td>
                                    <td>
                                        <span className='d-flex'>
                                            <button className='me-2 transfer-action-button btn-outline-custom-orange' disabled={item.amount ? item.amount === "" : true} onClick={() => handleTransaction('SEND', item)} >
                                                Send
                                            </button>
                                            <button className='transfer-action-button btn btn-outline-secondary text-secondary' disabled={item.amount ? item.amount === "" : true} onClick={() => handleTransaction('RECLAIM', item)} >
                                                Reclaim
                                            </button>
                                        </span>
                                    </td>
                                </tr>)
                        })}
                    </tbody>
                </table>
            </ModalBody>
            <ModalFooter>
                <span className='text-white font-size-13px'>If you want to transfer in bulk, use this buttons: </span> <span className='text-light font-size-12px'>(5% Sending Fee)</span>
                <div className='d-flex'>
                    <Button color="danger" size='sm' onClick={() => handleTransaction('SEND')}>
                        Send
                    </Button>
                    <Button color="dark" size='sm' className='ms-2' onClick={() => handleTransaction('RECLAIM')}>
                        Reclaim
                    </Button>
                    <Button color="secondary" size='sm' className='ms-2' onClick={() => toggleClubChipModal()}>
                        Close
                    </Button>
                </div>
            </ModalFooter>
        </>
    )
}
