import React, { useContext, useState } from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { ASSIGN_TOKEN_TO_CLUB_URL, postValuebet } from '../../../../../../API-Handle/services/auth_curd'
import { successNotification } from '../../../../../../Components/CustComp/PPHAlert'
import ValuebetContext from '../../../../../../context/Valuebet/ValuebetContext';
import { DefaultConfirm } from '../../../../../../Components/SweetAlerts/DefaultConfirm';


export default function Index({ token, fetchTokens }) {
    const [assignModal, setAssignModal] = useState(false);
    const [clubid, setClubid] = useState('')
    const { fetchSingleClub } = useContext(ValuebetContext)

    const toggleAssignModal = () => {
        setAssignModal(!assignModal)
    }

    const asignToken = async () => {
        if (clubid === '') return
        let message = `<p class="font-size-14px text-warning">Please Note: once assigned as Lifetime, unassigning a club will freeze club activities until reassigned a Lifetime Deal again.</p> Do you want to assign token?`;
        let confirmation = await DefaultConfirm("Lifetime Deal", message)
        if (confirmation) {
            let data = {
                clubid: clubid,
                tokenid: token.id
            }
            let res = await postValuebet(ASSIGN_TOKEN_TO_CLUB_URL, data)
            if (res) {
                successNotification(res.message)
                toggleAssignModal()
                fetchTokens()
                fetchSingleClub(clubid)
            }
        }
    }

    return (
        <>
            <Modal isOpen={assignModal} toggle={toggleAssignModal} className="modal-dialog-centered modal-lg">
                <div className="modal-header">
                    <h5 className="modal-title my-0">Assign Lifetime Deal</h5>
                    <button onClick={toggleAssignModal} type="button" className="text-white btn-close" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody>
                    <div>
                        <div className='mb-2'>
                            <label>Enter Club ID to assign a Lifetime Deal to the Club: </label>
                            <div className="input-group input-group-sm">
                                <span className="input-group-text bg-dark text-white">@</span>
                                <input type="text" className="form-control bg-dark text-white" value={clubid} onChange={(e) => setClubid(e.target.value)} placeholder="Club ID" aria-label="Recipient's uuid" aria-describedby="basic-addon1" />
                                <button type="button" className="btn btn-outline-danger" onClick={asignToken}>Assign</button>
                            </div>
                        </div>
                        <div className="alert alert-info" role="alert">
                            Please NOTE: once assigned as Lifetime, unassigning a club will freeze club activities until reassigned a Lifetime Deal again.
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <button className='transfer-action-button btn-outline-custom-orange ms-2' onClick={toggleAssignModal}>
                +
            </button>
        </>
    )
}
