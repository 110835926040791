import React from 'react'

export default function PrimaryAction({ item }) {    
    return (
        <>
            <span className='font-size-16px letter-space'>
                <span className='text-white'>Total Coins Awarded :</span> <span className='text-white vb-text-orange'><b>{item.challenge_info.total_coins_rewarded}</b></span>
            </span>
        </>
    )
}
