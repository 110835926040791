import React, { useState } from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function ChatReact({onReactSend}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () =>{setIsOpen(!isOpen)};

 
  const handleEmojiClick = (emoji) => {
    onReactSend(emoji);
    setIsOpen(false);
  };

  const emojiList = ["👍", "😀", "🤣", "🔥", "❤️", "✅"];
  return (
    <>
      <div className="emoji-panel">
        {emojiList.map((emoji, index) => (
          <span
            key={index}
            type="button"
            className="fs-4"
            onClick={() => handleEmojiClick(emoji)}
          >
            {emoji}
          </span>
        ))}
        <span
          id="UncontrolledPopover"
          type="button"
          onClick={toggle}
  
        >
          {/* <KeyboardArrowDownIcon sx={{ color: "black" }} /> */}
        </span>
      </div>
      { isOpen &&<Picker
          data={data}
          onEmojiSelect={onReactSend}
          theme="dark"
          previewPosition="none"
        />}
    </>
  );
}

export default ChatReact;