import React from 'react';
import { shrinkNumber } from '../../Utils/comman';

export default function ChipButton({ title, chipA, chipB, toggleClubChipModal }) {
    return (
        <button className='mem-action-button' onClick={toggleClubChipModal} type="button" >
            {title}: {shrinkNumber((chipA.toFixed(2)))} ({shrinkNumber(chipB.toFixed(2))})
        </button>
    )
}
