import React, { useRef } from "react";

const CustomForm = (props) =>{
    const formEl = useRef(null);

    const submitHandler = (event) => {
        event.preventDefault()
        event.stopPropagation()
        formEl.current.classList.add('was-validated')
        if (formEl.current.checkValidity()) {
            props.onSubmit(formEl.current);
        }
    }

    return(
        <form ref={formEl} id="formAuthentication" onSubmit={submitHandler} className="needs-validation" noValidate>
            {props.children}
        </form>
  )
};

export default CustomForm;