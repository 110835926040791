import React from 'react';
import routes from './routes';
import { Route, Routes } from 'react-router-dom';
import ValuebetState from './context/Valuebet/ValuebetState';
import CheckAuth from './Pages/Essential/Auth/CheckAuth'
import PPHAlert from './Components/CustComp/PPHAlert';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { store } from './redux/app/Store';
// import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  
  return (
    <React.Fragment>
      <Provider store={store}>
      <ThemeProvider theme={darkTheme}>
      {/* <CssBaseline /> */}
      <ValuebetState>
        <PPHAlert />
        <Routes>
          <Route path='/' exact={true} element={<CheckAuth/>} />
          {routes.map((route, idx) => {
            if (localStorage.getItem("authToken")) {
              return <Route path={route.path} exact={true} element={<route.component/>} key={idx} />
            } else if (route.ispublic) {
              return <Route path={route.path} exact={true} element={<route.component/>} key={idx} />
            } else {
              return <Route path={route.path} exact={true} element={<CheckAuth/>} key={idx} />
            }
          })}
        </Routes>
        
      </ValuebetState>
      </ThemeProvider>
      </Provider>
      
    </React.Fragment>
  );

}

export default App;
