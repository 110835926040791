import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import Popup from "reactjs-popup";
import { postValuebet, SEND_EMAIL_CHANGE_OTP, UPDATE_EMAIL } from '../../../API-Handle/services/auth_curd'
import { successNotification } from '../../../Components/CustComp/PPHAlert';
import { manageAllInputsErrors, beforeSendOtp, beforeVerifyEmail, refineUsername } from '../../../Utils/comman';

export default class EmailPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            input_errors: {
                email: '',
                otp: '',
            },
            email: "", otp: "",
            is_email_read_only: false,
            is_field_tracker_on: false,
            has_email_sent: false,
            is_loading: false,
        }
    }

    handleChange = (e) => {
        if (['email'].includes(e.target.name)){
            e.target.value = refineUsername(e.target.value)
        }
        if (this.state.is_field_tracker_on) {
            const { email_error, otp_error } = manageAllInputsErrors(e);
            this.setState({ input_errors: { email: email_error, otp: otp_error } })
        }
        this.setState({ [e.target.name]: e.target.value })
    }

    async sendOtp() {
        this.setState({ is_loading : true });
        const { email_error } = beforeSendOtp(this.state.email);
        this.setState({ input_errors: { email: email_error } })
        if (email_error === '') {
            let data = { email: this.state.email }
            let res = await postValuebet(SEND_EMAIL_CHANGE_OTP, data);
            if (res) {
                successNotification(res.message);
                this.setState({ is_email_read_only: true, has_email_sent: true });
            }
        }else {
            this.setState({ is_field_tracker_on: true });
        }
        this.setState({ is_loading : false });
    }

    async verifyAndUpdateEmail() {
        this.setState({ is_loading : true });
        const { email_error, otp_error } = beforeVerifyEmail(this.state.email, this.state.otp);
        this.setState({ input_errors: { email: email_error, otp: otp_error } })
        if (email_error === '' && otp_error === '') {
            let data = { otp: this.state.otp }
            let res = await postValuebet(UPDATE_EMAIL, data);
            if (res) {
                document.getElementById('emailupdt').click();
                successNotification(res.message);
                this.props.callback();
            }
            else{
                this.setState({ is_email_read_only: false, has_email_sent: false , otp: ""});
            }
        } else {
            this.setState({ is_field_tracker_on: true });
        }
        this.setState({ is_loading : false });
    }

    render() {
        return (
            <div>
                <div className='popup-btn-prfl'>
                    <Popup
                        contentStyle={{ background: "#0b0b0b", border: "none" }}
                        overlayStyle={{ background: "black" }}
                        trigger={<button className='mt-2 btn btn-danger btn-sm btn-orange' id='emailupdt' type="button"> Change </button>} modal>
                        {close => (
                            <div className='popup-container' >
                                <span className='clz-btn' onClick={close}> &times;</span>
                                <div className='p-3'>
                                    <form>
                                        <div className="field">
                                            <label className='text-secondary' htmlFor="email"> New Email </label>
                                            <input 
                                                type="email" 
                                                name="email"
                                                className='form-control form-control-sm bg-dark text-white border-secondary'
                                                onChange={this.handleChange}
                                                id="email" 
                                                readOnly={this.state.is_email_read_only || this.state.is_loading}
                                                value={this.state.email}
                                                required />
                                        </div>
                                        {this.state.input_errors.email ? <div className='inputerrors'> {this.state.input_errors.email} </div> : null}
                                        {this.state.has_email_sent && <>
                                            <div className="field mt-3">
                                                <label className='text-secondary' htmlFor="otp"> OTP </label>
                                                <input 
                                                    type="number" 
                                                    name="otp"
                                                    className='form-control form-control-sm bg-dark text-white border-secondary'
                                                    onChange={this.handleChange}
                                                    id="otp" 
                                                    value={this.state.value}
                                                    required />
                                            </div>
                                            {this.state.input_errors.otp ? <div className='inputerrors'> {this.state.input_errors.otp} </div> : null}
                                        </>}
                                        <Row className="form-group mt-4">
                                            <Col md="12" className="text-center">
                                                {this.state.has_email_sent ?
                                                <Button onClick={() => this.verifyAndUpdateEmail()}
                                                    className="btn btn-danger btn-sm btn-orange w-md" disabled={this.state.is_loading} type="button"> Verify And Update
                                                </Button> : 
                                                <Button onClick={() => this.sendOtp()}
                                                    className="btn btn-danger btn-sm btn-orange w-md" disabled={this.state.is_loading} type="button"> {this.state.is_loading ? 'Sending...' : 'Send OTP'}
                                                </Button>}
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        )}
                    </Popup>
                </div>
            </div>
        )
    }
}
