import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import CheckCircleTwoTone from '@mui/icons-material/CheckCircleTwoTone' 
import PlacedBets from './PlacedBets'

export default function BetConfirmation({isActive, toggleHandler, data}) {
    return (
        <Modal isOpen={isActive} className="modal-dialog-centered modal-xl">
            <div className="card text-start bg-vb-dark">
                 <ModalBody>
                    <div className='d-flex justify-content-center mb-3'>
                        <div className='text-center'>
                            <CheckCircleTwoTone style={{ fontSize: 80, color: "green" }} />
                            <div className="text-success">{data?.message}</div>
                        </div>
                    </div>
                    <div className='overflow-x no-tbl-header'>
                        {data?.bets && <PlacedBets data={data.bets} />}
                    </div>
                </ModalBody>
                <div className='text-end me-3'>
                    <button className='btn btn-danger btn-sm' onClick={toggleHandler}>Close</button>
                </div>
            </div>
        </Modal>
    )
}
