import React, { useContext, useEffect, useRef, useState } from 'react'
import ValuebetContext from '../../context/Valuebet/ValuebetContext';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import moment from 'moment';
import { postValuebet, MARKED_AS_READ_NOTIFICATIONS_URL, DELETE_NOTIFICATIONS_URL } from '../../API-Handle/services/auth_curd';
import { successNotification } from './PPHAlert';
import { DefaultConfirm } from '../SweetAlerts/DefaultConfirm';
import { useNavigate } from 'react-router';
import PushNotiStatus from './PushNotiStatus';
import SettingsIcon from "@mui/icons-material/Settings";


export default function NotiMenu() {
    const {notiData, getAllNotifications} = useContext(ValuebetContext);
    const [unreadCount, setUnreadCount] = useState(0);
    const [notis, setNotis] = useState([]);
    const navigate = useNavigate();
    const menuRef=useRef(null);

    const getUnreadCount = () => {
        let count = 0;
        notiData.forEach(element => {
            if(!element.seen) count+=1;
        });
        setUnreadCount(count);
    }

    const selectOne = (e, noti_id) => {
        if(e.target.checked){
            setNotis((prev)=> [...prev, noti_id])
        }else{
            setNotis((prev)=> {
                const newArray = prev.filter(item => item !== noti_id);
                return newArray;
            })
        }
    }

    const selectAll = () => {
        if(notiData.length !== notis.length){
            let all_ids = notiData.map((item)=>item.id);
            setNotis(all_ids)
        }else{
            setNotis([])
        }
    }

    const markedAllAsRead = async() => {
        if (unreadCount){
            let res = await postValuebet(MARKED_AS_READ_NOTIFICATIONS_URL)
            if(res){
                getAllNotifications();
            }
        }
    }

    const deleteNotifications = async() => {
        if(notis.length){
            let confirmation = await DefaultConfirm("Delete Notifications", "Do you want to delete notifications?")
            if (confirmation) {
                let data = { ids: notis};
                let res = await postValuebet(DELETE_NOTIFICATIONS_URL, data)
                if(res){
                    setNotis([]);
                    successNotification(res.message)
                    getAllNotifications();
                }
            }
        }
    }

    useEffect(()=>{
        getUnreadCount()
        // eslint-disable-next-line
    }, [notiData])

    const onPressNotifiication = (data) =>{
        if(menuRef.current) menuRef.current.click();
        switch(data.type){
            case 'CLUB_REQUEST':
                navigate(`/club/${data.club}/applicants`)
                break;
            case 'CHIPS_REQUEST':
                navigate(`/dashboard?type=${data.type}&club_id=${data.club}`)
                break;
            case 'SENT_MESSAGE':
                navigate(`/dashboard?type=${data?.type}&club_id=${data?.club}&channel_id=${data?.reference_id}&channel_type=${data?.channel_type}`);
                break;
            case 'NEWS':
                navigate(`/dashboard?type=${data?.type}`)
                break;
            default:
                navigate('/dashboard')
                break;
        }
       
    }
    return (
        <>
            <li className="list-inline-item me-4 pe-2">
                <PushNotiStatus/>
                <button ref={menuRef} className="notification border-0 rounded-circle position-relative" id="VBNoti">
                    <NotificationsOutlinedIcon className='vb-text-orange font-size-30px-imp' />
                    {unreadCount ? <span className="position-absolute top-2 start-100 translate-middle badge rounded-pill bg-danger">
                        {unreadCount > 99 ? "99+" : unreadCount}
                    </span>: null}
                </button>
                <UncontrolledPopover
                    placement="bottom"
                    
                    target="VBNoti"
                    popperClassName="dark-popover"
                    trigger="legacy"
                >
                    <PopoverHeader>
                        <div className='d-flex justify-content-between'>
                            <div>Notifications</div> 
                            <SettingsIcon onClick={()=>document.getElementById("notification-status").click()} className="text-white" role="button" />

                            <div> <span role='button' onClick={selectAll} className='border border-white rounded-pill font-size-12px py-1 px-2'>{(notiData.length !== notis.length || !notis.length) ? "Select" : "Deselect"} All</span></div>
                            <div><DeleteOutlineIcon onClick={deleteNotifications} role={notis.length ? 'button' : 'none'} className={notis.length ? 'text-white' :'text-light'} /></div>
                        </div>
                    </PopoverHeader>
                    <PopoverBody>
                        {unreadCount ?
                        <div className='p-2 ms-1'>
                            <span role='button' className='border border-white rounded-pill font-size-12px py-1 px-2' onClick={markedAllAsRead}>Marked All As Read</span>
                        </div>: null}
                        <div className="list-group p-1">
                            {notiData.length ? notiData.map((item, index) => {
                                return (
                                    <span onClick={()=>{onPressNotifiication(item)}} className={`list-group-item list-group-item-action border-0 mb-1 rounded ${!item.seen && 'active'}`} key={index} role='button'>
                                        <div className="d-flex w-100 justify-content-between">
                                            <small>{moment(item.time_stamp).fromNow()}</small>
                                            <input className="form-check-input" type="checkbox" checked={notis.includes(item.id)} onClick={(e)=>e.stopPropagation()} onChange={(e)=> selectOne(e, item.id)}/>
                                        </div>
                                        <div className="my-1" dangerouslySetInnerHTML={{ __html: item.message }} />
                                    </span>
                                )
                            }) :
                                <span className="list-group-item list-group-item-action text-center">
                                    No Notification Here!
                                </span>}
                        </div>
                    </PopoverBody>
                </UncontrolledPopover>
            </li>
        </>
    )
}
