import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import { PLAYER_BET_HISTORY_REPORT } from '../../../../../../../API-Handle/services/auth_curd';
import { getValuebet } from '../../../../../../../API-Handle/services/auth_curd';
import Spinner from '../../../../../../../Components/CustLoader/Spinner';
import CustomYearPicker from '../../../../../../../Components/Calendar/CustomYearPicker';

const columns = [
    {
        name: '',
        selector: row => row.detail,
    },
    {
        name: 'Total Wager',
        selector: row => row.total_wagers,
    },
    {
        name: 'No. Wager',
        selector: row => row.number_of_wagers,
    },
    {
        name: 'Ave. bet',
        selector: row => row.average_bet,
    },
    {
        name: 'Jan',
        selector: row => row.Jan,
    },
    {
        name: 'Feb',
        selector: row => row.Feb,
    },
    {
        name: 'Mar',
        selector: row => row.Mar,
    },
    {
        name: 'Apr',
        selector: row => row.Apr,
    },
    {
        name: 'May',
        selector: row => row.May,
    },
    {
        name: 'Jun',
        selector: row => row.Jun,
    },
    {
        name: 'Jul',
        selector: row => row.Jul,
    },
    {
        name: 'Aug',
        selector: row => row.Aug,
    },
    {
        name: 'Sep',
        selector: row => row.Sep,
    },
    {
        name: 'Oct',
        selector: row => row.Oct,
    },
    {
        name: 'Nov',
        selector: row => row.Nov,
    },
    {
        name: 'Dec',
        selector: row => row.Dec,
    }
];

export default function MonthlyMemberHistory({ club, item }) {
    const [report, setReport] = useState(null)
    const [year, setYear] = useState(null);

    useEffect(() => {
        if (year) getDailyReports();
        // eslint-disable-next-line
    }, [year])

    const getDailyReports = async () => {
        setReport(null)
        let request_url = PLAYER_BET_HISTORY_REPORT + club.id +`?user_id=${item.id}&report_type=Monthly&report_time=${year}`;
        let reports = await getValuebet(request_url);
        if (reports) setReport(reports);
    }

    return (
        <>
            <div className='inline-datepicker-container'>
                <CustomYearPicker getYear={setYear} className={"settled-datepicker"} />
            </div>
            {report ?
                <DataTable
                    columns={columns}
                    data={report}
                    theme="dark"
                    dense={true}
                    header={false}
                    highlightOnHover={true}
                /> :
                <Spinner />}
        </>
    )
}
