import { OFFLINE_ODD } from "../constant";

// Getting sport limits
export function getSportLimits(sportsData, leagueName, limitsType) {
  const leagues = sportsData ? sportsData.reduce((acc, sport) => acc.concat(sport.leagues), []) : [];
  for (const league of leagues) {
    if (league.league_name === leagueName) {
      return league[limitsType];
    }
  }
  return null;
};

// Calculating Multi-Bet Decimal Odd using multipule american odds
export function getMultiDecimalOdd(bets) {
  let decimal_odd = 1;
  for (let bet of bets) {
    if (bet.odd_money !== OFFLINE_ODD) {
      if (bet.odd_money < 0) {
        decimal_odd *= (1 - (100 / bet.odd_money));
      } else if (bet.odd_money > 0) {
        decimal_odd *= (1 + (bet.odd_money / 100));
      }
    } else {
      return OFFLINE_ODD;
    }
  }
  return decimal_odd;
}

export function AmrcToDec(odd_money) {
  let decimal_odd = 1;
  if (odd_money < 0) {
    decimal_odd *= (1 - (100 / odd_money));
  } else if (odd_money > 0) {
    decimal_odd *= (1 + (odd_money / 100));
  }
  return decimal_odd;
}

// Soccer/Hockey: Favorite Spreads cannot be combined with Over; Underdog Spreads cannot be combined with Under
export function soccer_hockey_restrictions(bets) {
  let favorite_spreads = false;
  let underdog_spreads = false;
  let total_over = false;
  let total_under = false;

  for (let bet of bets) {
    if (["spread", 'handicap', 'handicap_in_play'].includes(bet['market_name'])) {
      if (bet['odd_points'] <= 0) favorite_spreads = true;
      else underdog_spreads = true;
    }
    if (["total", "totals", "totals_in_play"].includes(bet['market_name'])) {
      if (bet['participant_id'] === "9") total_over = true;
      else if (bet['participant_id'] === "10") total_under = true;
    }
  }

  if ((favorite_spreads && total_over) || (underdog_spreads && total_under)) return true;
  return false;
}

// Football: For Restrict 1
// Favorites with Spread -13 or more cannot be combined with Over 42 or less; 
// Underdog with Spread +13 or more cannot be combined with Under 42 or less.
// --------------------------------------------------------------------------
// Football: For Restrict 2
// If Total is not greater than 2x Spread; do not allow (-Spread + Over) and (+Spread + Under)
export function football_restrictions(bets) {
  // For Restrict 1
  let favorite_spreads = false;
  let underdog_spreads = false;
  let total_over = false;
  let total_under = false;

  // For Restrict 2
  let favorite_spreads_points = null;
  let underdog_spreads_points = null;
  let total_over_points = null;
  let total_under_points = null;

  for (let bet of bets) {
    if (["spread", 'handicap', 'handicap_in_play'].includes(bet['market_name'])) {
      // For Restrict 1
      if (bet['odd_points'] <= -13) favorite_spreads = true;
      else if (bet['odd_points'] >= 13) underdog_spreads = true;

      // For Restrict 2
      if (bet['odd_points'] < 0) favorite_spreads_points = bet['odd_points'];
      else if (bet['odd_points'] > 0) underdog_spreads_points = bet['odd_points'];
    }

    if (["total", "totals", "totals_in_play"].includes(bet['market_name'])) {
      // For Restrict 1
      if ((bet['participant_id'] === "9") && (bet['odd_points'] <= 42)) total_over = true;
      else if ((bet['participant_id'] === "10") && (bet['odd_points'] <= 42)) total_under = true;

      // For Restrict 2
      if (bet['participant_id'] === "9") total_over_points = bet['odd_points'];
      else if (bet['participant_id'] === "10") total_under_points = bet['odd_points'];
    }
  }

  // For Restrict 1
  if ((favorite_spreads && total_over) || (underdog_spreads && total_under)) return true;

  // For Restrict 2
  if (favorite_spreads_points && total_over_points) {
    if (!((-2 * favorite_spreads_points) < total_over_points)) return true;
  }
  if (underdog_spreads_points && total_under_points) {
    if (!((2 * underdog_spreads_points) < total_under_points)) return true;
  }

  return false;
}

// Baseball: Overs of 7 or less cannot be combined with Favorite Spreads; 
// Unders of 7 or less cannot be combined with Underdog Spreads.
export function baseball_restrictions(bets) {
  let favorite_spreads = false;
  let underdog_spreads = false;
  let total_over = false;
  let total_under = false;

  for (let bet of bets) {
    if (["spread", 'handicap', 'handicap_in_play'].includes(bet['market_name'])) {
      if (bet['odd_points'] <= 0) favorite_spreads = true;
      else underdog_spreads = true;
    }
    if (["total", "totals", "totals_in_play"].includes(bet['market_name'])) {
      if ((bet['participant_id'] === "9") && (bet['odd_points'] <= 7)) total_over = true;
      else if ((bet['participant_id'] === "10") && (bet['odd_points'] <= 7)) total_under = true;
    }
  }
  if ((favorite_spreads && total_over) || (underdog_spreads && total_under)) return true
  return false;
}

// All sports: Restrictions
export function all_game_restrictions(bets) {
  if (bets.length > 1){
    // Checking unique bet by odd_id
    if (bets.length !== new Set(bets.map(bet => bet.id)).size) return true
    
    // Not allow same participant(player/team) in more than one selections
    if (bets.length !== new Set(bets.map(bet => bet.participant_id)).size) return true
    
    // Not allow player props more than 2 selection
    if (bets.filter(bet => bet.participant_type === 'TYPE_PLAYER').length > 2) return true
  }
  return false;
}

// Multi-Bet Restrictions
export const checkBetCombination = (bets) => {
  let comb = true;
  if (!bets) return comb

  let data = {}
  for (let item of bets) {
    let sport = item.sport_name;
    let game = item.match_id;
    let period = item.period
    
    // Selection from the 1st half and full-game will be considered as same period for the game and it is 
    // usually not allowed if the outcomes are correlated that's why merging period_first_half with period_full_game
    if (['period_first_half', 'period_full_game'].includes(period)) period = 'full_game'

    // Creating structured bets data for checking restrictions
    if (Object.keys(data).includes(sport)) {
      if (Object.keys(data[sport]).includes(game)) {
        if (Object.keys(data[sport][game]).includes(period))
          data[sport][game][period].push(item)
        else{
          data[sport][game][period] = [item]
        }
      } else {
        data[sport][game] = {[period]: [item]}
      }
    } else {
      data[sport] = {[game]: {[period]: [item]}}
    }
  }

  // checking restrictions
  for (let spt of Object.keys(data)) {
    for (let gm of Object.keys(data[spt])) {
      for (let pd of Object.keys(data[spt][gm])) {
      let bets = data[spt][gm][pd];
      if (bets.length > 1) {
        // Cricket: no same-game parlays
        if (spt === "CRICKET") {
          comb = false;
          break;
        }

        // Soccer/Hockey restrictions
        if (spt === "SOCCER" || spt === "HOCKEY") {
          if (soccer_hockey_restrictions(bets)) {
            comb = false;
            break;
          }
        }

        // Football restrictions
        if (spt === "FOOTBALL") {
          if (football_restrictions(bets)) {
            comb = false;
            break;
          }
        }

        // Baseball restrictions
        if (spt === "BASEBALL") {
          if (baseball_restrictions(bets)) {
            comb = false;
            break;
          }
        }

        // All sports restrictions
        if (all_game_restrictions(bets)) {
          comb = false;
          break;
        }
      }
      }
    }
    if (!comb) {
      break;
    }
  }
  return comb;
}




// To get readble period using period id
export const getReadablePeriod = (period) => {
  const periods = {
    period_full_game: "Game",
    period_live_full_game: "Game(Live)",
    period_first_half: "1st Half",
    period_second_half: "2nd Half",
    period_first_period: "1st Quarter",

  }
  return periods[period]
}

// Generate title of betslip to show in table
export const getBetslipTitle = (betslip) => {
  let participant = ''
  if (betslip.bet_type === "Straight") {
    let temp_target = betslip.bets[0].bet?.targeted_participant
    if (betslip.bets[0].bet?.market_id) { // For props markets
      temp_target = betslip.bets[0].bet.selected_participant_name
    }

    if (['moneyline', 'moneyline_in_play', 'player_goals', 'touchdowns'].includes(betslip.bets[0].bet.market_name)) {
      let short_market_name = ''
      if (betslip.bets[0].bet.market_name === 'moneyline' && !betslip.bets[0].bet?.market_id) short_market_name = 'ML'
      else if (['moneyline', 'moneyline_in_play'].includes(betslip.bets[0].bet.market_name)) short_market_name = 'WO'
      else if (['player_goals'].includes(betslip.bets[0].bet.market_name)) short_market_name = 'PG'
      else if (['touchdowns'].includes(betslip.bets[0].bet.market_name)) short_market_name = 'TD'
      let temp_participant = `${temp_target} - ${short_market_name}`
      if (temp_participant.length > 28) {
        let removable = temp_target.length - ((temp_participant.length - 28) + 3);
        temp_target = temp_target.substring(0, removable) + "..."
      }
      participant = `${temp_target} - ${short_market_name}`;
    }

    else if (['spread', 'handicap', 'handicap_in_play'].includes(betslip.bets[0].bet.market_name)) {
      let short_market_name = ''
      if (['spread'].includes(betslip.bets[0].bet.market_name)) short_market_name = 'SP'
      if (['handicap', 'handicap_in_play'].includes(betslip.bets[0].bet.market_name)) short_market_name = 'HDCP'
      let temp_participant = `${temp_target} ${betslip.bets[0].bet.selected_odd_points > 0 ? "+" + betslip.bets[0].bet.selected_odd_points : betslip.bets[0].bet.selected_odd_points} - ${short_market_name}`
      if (temp_participant.length > 28) {
        let removable = temp_target.length - ((temp_participant.length - 28) + 3);
        temp_target = temp_target.substring(0, removable) + "..."
      }
      participant = `${temp_target} ${betslip.bets[0].bet.selected_odd_points > 0 ? "+" + betslip.bets[0].bet.selected_odd_points : betslip.bets[0].bet.selected_odd_points} - ${short_market_name}`
    }

    else if (['total', 'totals', 'totals_in_play'].includes(betslip.bets[0].bet.market_name)) {
      let odd_name = '';
      let key = betslip.bets[0].bet?.market_id ? 'selected_participant_name' : 'selected_odd_name'
      if (betslip.bets[0].bet[key].toLowerCase() === "over") {
        odd_name = "O";
        temp_target = betslip.bets[0].match?.away_team_name
      } else if (betslip.bets[0].bet[key].toLowerCase()  === "under") {
        odd_name = "U";
        temp_target = betslip.bets[0].match?.home_team_name
      }

      let temp_participant = `${temp_target} ${odd_name} ${betslip.bets[0].bet.selected_odd_points} - T`
      if (temp_participant.length > 28) {
        let removable = temp_target.length - ((temp_participant.length - 28) + 3);
        temp_target = temp_target.substring(0, removable) + "..."
      }
      participant = `${temp_target} ${odd_name} ${betslip.bets[0].bet.selected_odd_points} - T`
    }

    else if (['team_points', 'team_points_in_play', 'team_totals', 'team_totals_in_play', 
      'player_assists', 'winning_margin', 'winning_margin_in_play', 'winning_margin_overtime', 
      'hits', 'pitcher_strikeouts', 'pitcher_strikeouts_in_play', 'home_runs', 'runs', 'passing_touchdowns', 
      'passing_touchdowns_in_play', 'passing_yards', 'passing_yards_in_play', 'rushing_yards', 
      'rushing_yards_in_play', 'player_receiving_yards', 'player_receiving_yards_in_play', 'player_receptions', 
      'player_receptions_in_play', 'shots', 'player_shots', 'player_points'].includes(betslip.bets[0].bet.market_name)) {
      let short_market_name = ''
      if (['team_points', 'team_points_in_play'].includes(betslip.bets[0].bet.market_name)) short_market_name = 'TP'
      if (['team_totals', 'team_totals_in_play'].includes(betslip.bets[0].bet.market_name)) short_market_name = 'TT'
      if (['player_assists'].includes(betslip.bets[0].bet.market_name)) short_market_name = 'PA'
      if (['winning_margin', 'winning_margin_in_play'].includes(betslip.bets[0].bet.market_name)) short_market_name = 'WM'
      if (['winning_margin_overtime'].includes(betslip.bets[0].bet.market_name)) short_market_name = 'WM OT'
      if (['hits'].includes(betslip.bets[0].bet.market_name)) short_market_name = 'HT'
      if (['pitcher_strikeouts', 'pitcher_strikeouts_in_play'].includes(betslip.bets[0].bet.market_name)) short_market_name = 'PS'
      if (['home_runs'].includes(betslip.bets[0].bet.market_name)) short_market_name = 'HR'
      if (['runs'].includes(betslip.bets[0].bet.market_name)) short_market_name = 'RN'
      if (['passing_touchdowns', 'passing_touchdowns_in_play'].includes(betslip.bets[0].bet.market_name)) short_market_name = 'PT'
      if (['passing_yards', 'passing_yards_in_play'].includes(betslip.bets[0].bet.market_name)) short_market_name = 'PY'
      if (['rushing_yards', 'rushing_yards_in_play'].includes(betslip.bets[0].bet.market_name)) short_market_name = 'RY'
      if (['player_receiving_yards', 'player_receiving_yards_in_play'].includes(betslip.bets[0].bet.market_name)) short_market_name = 'PRY'
      if (['player_receptions', 'player_receptions_in_play'].includes(betslip.bets[0].bet.market_name)) short_market_name = 'PR'
      if (['shots'].includes(betslip.bets[0].bet.market_name)) short_market_name = 'SHOTS'
      if (['player_shots'].includes(betslip.bets[0].bet.market_name)) short_market_name = 'P SHOTS'
      if (['player_points'].includes(betslip.bets[0].bet.market_name)) short_market_name = 'PP'
      let temp_participant = `${temp_target} ${betslip.bets[0].bet.selected_odd_points} - ${short_market_name}`
      if (temp_participant.length > 28) {
        let removable = temp_target.length - ((temp_participant.length - 28) + 3);
        temp_target = temp_target.substring(0, removable) + "..."
      }
      participant = `${temp_target} ${betslip.bets[0].bet.selected_odd_points} - ${short_market_name}`
    }

    // Adding Red Dot if period is from live periods
    if (betslip.bets[0].bet.period_name === 'period_live_full_game') {
      participant = <span>{participant} <span className='text-danger'>●</span></span>
    }
  } else {
    participant = "Multi-Participants"
  }
  return participant;
}

// Generate sub-title of betslip to show in table
export const getBetslipSubTitle = (bet, match) => {
  let bet_name = '';
  let targeted_participant = bet.targeted_participant
  let market_desc = bet.market_name;
  if (bet?.market_id) { // For props markets
    targeted_participant = bet.selected_participant_name;
    market_desc = bet.short_description;
  }

  if (['moneyline', 'moneyline_in_play', 'player_goals', 'touchdowns'].includes(bet.market_name)) {
    bet_name = `${targeted_participant} - ${market_desc}`
  }
  else if (['spread', 'handicap', 'handicap_in_play'].includes(bet.market_name)) {
    bet_name = `${targeted_participant} ${bet.selected_odd_points > 0 ? "+" + bet.selected_odd_points : bet.selected_odd_points} - ${market_desc}`
  }
  else if (bet.market_name === 'total') {
    bet_name = `${targeted_participant} ${bet.selected_odd_name} ${bet.selected_odd_points} - ${market_desc}`
  }
  else if (['totals', 'totals_in_play'].includes(bet.market_name)) {
    if (bet.selected_participant_name === "Over") targeted_participant = match?.away_team_name;
    if (bet.selected_participant_name === "Under") targeted_participant = match?.home_team_name;
    bet_name = `${targeted_participant} ${bet.selected_participant_name} ${bet.selected_odd_points} - ${market_desc}`;
  }
  else if (['team_points', 'team_points_in_play', 'team_totals', 'team_totals_in_play', 
    'player_assists', 'winning_margin', 'winning_margin_in_play', 'winning_margin_overtime', 
    'hits', 'pitcher_strikeouts', 'pitcher_strikeouts_in_play', 'home_runs', 'runs', 'passing_touchdowns', 
    'passing_touchdowns_in_play', 'passing_yards', 'passing_yards_in_play', 'rushing_yards', 'rushing_yards_in_play', 
    'player_receiving_yards', 'player_receiving_yards_in_play', 'player_receptions', 'player_receptions_in_play', 
    'shots', 'player_shots', 'player_points'].includes(bet.market_name)) {
    bet_name = `${targeted_participant} ${bet.selected_odd_points} - ${market_desc}`
  }
  return bet_name;
}