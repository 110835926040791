import { createSlice } from "@reduxjs/toolkit";
const clubchatSlice = createSlice({
  name: "clubchat",
  initialState: {
    isClubChatOpen: false,
    selectedClub: "",
    isloading: false,
    isAddChannel: false,
    isDrawerOpen: false,
    clubMember: [],
    personalGroupMember: [],
    clubGroup: [],
    clubGroupMemer: [],
    activeChannel: null,
    activeChats: [],
    mobileOpen: false,
    chatList: [],
    isPulicChannel: false,
    isPersonalChannel: true,
    allPersonalChat: [],
    selectedChannelMessages: [],
    selectedChannelID: null,
    isClubAdmin: false,
    replyMessage: null,
    chatInputFieldHeight: null,
  },
  reducers: {
    openClubChat(state, actions) {
      state.isClubChatOpen = actions.payload.data;
    },

    setReplyMessage(state, actions) {
      state.replyMessage = actions.payload.data;
    },

    setChatInputHeight(state, actions) {
      state.chatInputFieldHeight = actions.payload;
    },

    selectedClub(state, actions) {
      state.selectedClub = actions.payload.data;
    },

    openAddChannel(state, actions) {
      state.isAddChannel = actions.payload.data;
    },

    openDrawer(state, actions) {
      state.isDrawerOpen = actions.payload.data;
    },

    openMobile(state, actions) {
      state.mobileOpen = actions.payload.data;
    },

    setClubMember(state, actions) {
      let club_member = [];
      actions.payload.data?.forEach((item) => {
        club_member.push({ value: item.id, label: item.username, ...item });
      });
      state.clubMember = club_member;
    },

    setPersonalMember(state, actions) {
      state.allPersonalChat = actions.payload.data;
    },

    setClubGroup(state, actions) {
      state.clubGroup = actions.payload.data;
    },

    setClubGroupMember(state, actions) {
      state.clubGroupMemer = actions.payload.data;
    },

    setActiveChats(state, actions) {
      state.activeChats = actions.payload.data;
    },

    setActiveChannel(state, actions) {
      state.activeChannel = actions.payload.data;
    },

    loadigData(state, actions) {
      state.isloading = actions.payload.data;
    },

    fetchChatData(state, actions) {
      state.chatList = actions.payload.data;
    },

    setIsPublicChannel(state, actions) {
      state.isPulicChannel = actions.payload.data;
    },

    setIsPersonalChannel(state, actions) {
      state.isPersonalChannel = actions.payload.data;
    },

    setSelectedChannelMessages(state, actions) {
      state.selectedChannelMessages = actions.payload.data;
    },

    setCurrentReactedEmoji(state, actions) {
      let messages = state.selectedChannelMessages;
      let data = actions.payload.data;
      for (let message of messages) {
        if (data && data.message === message.id) {
          if (data.action_type === "Update") {
            message.reactions?.forEach(item => {
              if (item.id === data.id) item.react = data.react
            });
          }
          else if (data.action_type === "Remove") {
            message.reactions = message.reactions.filter(item => item.id !== data.id)
            message.reaction_count -= 1;
          }
          else if (data.action_type === "Add") {
            message.reactions = [...message.reactions, data]
            message.reaction_count += 1;
          }
          break;
        }
      }
      state.selectedChannelMessages = messages;
    },

    updateChannelInfo(state, actions) {
      const data = actions.payload.data;
      if (state.isClubChatOpen) {
        // Updating Last Message of Channel
        const personal_channels = state.allPersonalChat;
        personal_channels?.forEach((item) => {
          if (item.id === data.channel) item.last_message = { message: data.message, media_type: data.media_type };
        })
        state.allPersonalChat = personal_channels;

        if (!(state.activeChannel && data.channel === state.activeChannel.id)) {
          // Updating Unread Message Count for Personal Channel
          personal_channels?.forEach((item) => {
            if (item.id === data.channel) item.unread_count += 1;
          })
          state.allPersonalChat = personal_channels;

          // Updating Unread Message Count for Public Channel
          const public_channels = state.clubGroup;
          public_channels?.forEach((item) => {
            if (item.id === data.channel) item.unread_count += 1;
          })
          state.clubGroup = public_channels;
        }
      }
    },

    clearChannelUnreadMessage(state, action) {
      const { channelId, message } = action.payload.data
      if (message.channel_type === "PERSONAL") {
        const personalChat = JSON.parse(JSON.stringify(state.allPersonalChat));
        personalChat?.forEach((item) => {
          if (item.id === channelId) item.unread_count = 0;
        })
        state.allPersonalChat = personalChat;
      } else if (message.channel_type === "GROUP") {
        const groupChannel = JSON.parse(JSON.stringify(state.clubGroup));
        groupChannel?.forEach((item) => {
          if (item.id === channelId) item.unread_count = 0;
        })
        state.clubGroup = groupChannel;
      }
    },

    appendNewMessage(state, actions) {
      if (state.selectedChannelID === actions.payload.channel) {
        state.selectedChannelMessages = [...state.selectedChannelMessages, actions.payload];
      }
    },

    unsendChannelMessage(state, action) {
      const deletedMessages = action.payload.data;
      const updatedMessages = JSON.parse(JSON.stringify(state.selectedChannelMessages)).filter((item) => item.id !== deletedMessages.id);
      state.selectedChannelMessages = updatedMessages;
    },

    setSelectedChannelID(state, actions) {
      state.selectedChannelID = actions.payload.data;
    },

    setClubAdmin(state, actions) {
      state.isClubAdmin = actions.payload.data;
    },
  },
});

export const clubchatAction = clubchatSlice.actions;
export default clubchatSlice.reducer;
