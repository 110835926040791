import React, { useContext, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { Link } from 'react-router-dom';
import ValuebetContext from "../../context/Valuebet/ValuebetContext";
import user4 from "../../Static/UserManagement/images/common/default-user-avtar.png";
import { BASEURL } from '../../API-Handle/config';
import Settings from "../../Pages/UserManagement/Dashboard/ClubCard/Settings";

const ProfileMenu = () => {
  const [menu, setmenu] = React.useState(false);
  const [settingModal, setSettingModal] = useState(false)
  const { userDetails, activeClub, accountLogout } = useContext(ValuebetContext);

  const toggle = () => {
    setmenu(!menu)
  }

  const toggleClubSetting = () => {
    setSettingModal(!settingModal)
  };

  return (
    <>
      <Settings item={activeClub} isOpen={settingModal} toggleClubSetting={toggleClubSetting} />
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="list-inline-item"
        tag="li"
      >
        <DropdownToggle className="arrow-none nav-user" tag="a">
          {userDetails?.profile_picture ? (
            <img
              src={BASEURL + userDetails?.profile_picture}
              alt="user"
              className="rounded-circle"
            />
          ) : (
            <img src={user4} alt="user" className="rounded-circle" />
          )}
        </DropdownToggle>
        <DropdownMenu className="profile-dropdown" >
          <Link role="menuitem" className="dropdown-item" to="/dashboard">
            <i className="mdi mdi-wallet m-r-5"></i>Go to Dashboard
          </Link>
          {(activeClub?.label === "Owner" || activeClub?.label === "Manager")  &&
          <>
            <Link role="menuitem" className="dropdown-item" to="/sportbook/club-reports" state={{ club:activeClub }}>
              <i className="mdi mdi-lock-open-outline m-r-5"></i>Club Bet Reports
            </Link>
            <span role="button" className="dropdown-item" onClick={() => toggleClubSetting()}>
              <i className="mdi mdi-lock-open-outline m-r-5"></i>Club Settings
            </span>
          </>}
          <Link role="menuitem" className="dropdown-item" to="/profile-setting">
            <i className="mdi mdi-wallet m-r-5"></i>Settings
          </Link>
          <div className="dropdown-divider"></div>
          <span role="button" className="dropdown-item text-danger" onClick={accountLogout} >
            <i className="mdi mdi-power text-danger"></i> Logout
          </span>
        </DropdownMenu>
      </Dropdown>
    </>
  );
}

export default ProfileMenu;
