import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import Header from '../../../Components/UserHeader';
import Footer from '../../../Components/Footer';
import { postValuebet, CREATE_CLUB_REQUEST_URL, getValuebet, GET_CLUB_IDENTITIES_URL } from '../../../API-Handle/services/auth_curd';
import { successNotification } from '../../../Components/CustComp/PPHAlert';
import withRouter from '../../../Components/CustComp/withRouter';
import { beforeClubRequest, manageAllInputsErrors } from '../../../Utils/comman';
import SmSpinner from '../../../Components/CustLoader/SmSpinner';

class ClubJoin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      club_name: null,
      fetching: false,
      club_id: "",
      referral_id: "",
      input_errors:
      {
        club_id: '',
        referral_id: ''
      },
      loading: false,
      is_field_tracker_on: false,
    };
  }

  changeHandler = (e) => {
    if (this.state.is_field_tracker_on) {
      const { club_id_error, referral_id_error } = manageAllInputsErrors(e);
      this.setState({ input_errors: { club_id: club_id_error, referral_id: referral_id_error } })
    }
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === 'club_id') {
      if (e.target.value.length === 8) {
        this.fetchClubIdentities(e.target.value);
      } else {
        this.setState({ club_name: null })
      }
    }
  }

  submitHandler = async () => {
    this.setState({ loading: true });
    const { club_id_error, referral_id_error } = beforeClubRequest(this.state.club_id, this.state.referral_id);
    this.setState({ input_errors: { club_id: club_id_error, referral_id: referral_id_error } })
    if (club_id_error === '' && referral_id_error === '') {
      let data = { club_id: this.state.club_id }
      if (this.state.referral_id !== '') data['referral_id'] = this.state.referral_id;
      let res = await postValuebet(CREATE_CLUB_REQUEST_URL, data);
      if (res) {
        successNotification(res.message)
        window.gtag('event', 'conversion', {'send_to': 'AW-16724820378/IQvfCKOigd0ZEJqDgqc-'});
        this.props.navigate('/dashboard');
      }
    } else {
      this.setState({ is_field_tracker_on: true });
    }
    this.setState({ loading: false })
  }

  fetchClubIdentities = async (cid) => {
    this.setState({ fetching: true });
    let res = await getValuebet(GET_CLUB_IDENTITIES_URL + cid);
    if (res) {
      this.setState({ club_name: res.name });
    }
    this.setState({ fetching: false });
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <div className='fill-view'>
          <div className='cardwapper container-fluid'>
            <div className="wrapper-page cardcontainer">
              <div className="content-page">
                <div className="content">
                  <Container fluid className='overridemargintop'>
                    <Row>
                      <Col className='text-center clubbanner'>
                        <h4 className='clubbanner-text m-0'>Club Join</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card className='crtmclubcard'>
                          <CardBody>
                            <form className='formcntrclub'>
                              <div className="field">
                                <label className='text-light font-size-14px' htmlFor="club_id"> Club ID {this.state.club_name && <small className='text-success'>({this.state.club_name.toUpperCase()})</small>} </label>
                                <input className='form-control bg-dark text-white border-secondary' type="text" name="club_id"
                                  onChange={this.changeHandler}
                                  value={this.state.club_id}
                                  id="club_id"
                                  maxLength={8}
                                  placeholder=""
                                  required />
                              </div>
                              {this.state.input_errors.club_id ? <div className='inputerrors'> {this.state.input_errors.club_id} </div> : null}
                              <div className="mt-2">
                                <label className='text-light font-size-14px' htmlFor="referral_id">Referral ID (Optional)</label>
                                <input className='form-control bg-dark text-white border-secondary' type="text" name="referral_id"
                                  onChange={this.changeHandler}
                                  value={this.state.referral_id}
                                  id="referral_id"
                                  maxLength={8}
                                  placeholder="" />
                              </div>
                              {this.state.input_errors.referral_id ? <div className='inputerrors'> {this.state.input_errors.referral_id} </div> : null}
                              <Row className="form-group m-t-20">
                                <Col md="12" className="text-center">
                                  <button onClick={this.submitHandler} className="signbtn" disabled={this.state.loading || this.state.fetching} type="button">{ this.state.loading ? <SmSpinner inline={true} /> : "Join Club" } <i className="fa fa-arrow-right submticon" aria-hidden="true"></i> </button>
                                </Col>
                              </Row>
                            </form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default withRouter(ClubJoin);