import React, { useEffect, useState } from 'react'
import {
    Modal,
    ModalBody
} from "reactstrap";
import Plan from './Plan';
import { getValuebet, GET_CLUB_LEVEL_PRICING_URL } from '../../../../../../../API-Handle/services/auth_curd';

export default function UpgradeLevel({clubInfo}) {
    const [levelModal, setLevelModal] = useState(false);
    const [levels, setLevels] = useState([]);

    const toggleLevelModal = () => {
        setLevelModal(!levelModal)
    }

    const getLevelList = async () => {
        let res = await getValuebet(GET_CLUB_LEVEL_PRICING_URL)
        if(res){
            setLevels(res)
        }
    }

    useEffect(() => {
        getLevelList()
    }, [])

    return (
        <>
            <Modal isOpen={levelModal} toggle={toggleLevelModal} className="modal-dialog-centered modal-xl">
                <div className="modal-header">
                    <h5 className="modal-title my-0"> Club Upgrade Level </h5>
                    <button onClick={toggleLevelModal} type="button" className="btn-close text-white" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody>
                    <div className='row coin-modal-margin overflow-x'>
                        <table className="table text-center table-custom-dark">
                            <thead>
                                <tr>
                                    <th scope="col">Level</th>
                                    <th scope="col">Max Members</th>
                                    <th scope="col">Max Agents</th>
                                    <th scope="col">Odds Selection</th>
                                    <th scope="col">Sport Limits</th>
                                    <th scope="col">Messaging</th>
                                    <th scope="col">Leaderboard</th>
                                    <th scope="col">Days</th>
                                    <th scope="col">Cost</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody className="mx-2">
                                {levels.map((item) => {
                                    return (
                                        <Plan key={item.id} item={item} clubInfo={clubInfo} toggleLevelModal={toggleLevelModal}/>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
            </Modal>
            <button disabled={clubInfo.is_once_ltd_assigned} type="button" className="btn btn-danger btn-orange btn-sm me-4" onClick={toggleLevelModal}>
                Upgrade Now
            </button>
        </>
    )
}
