import React, { useState } from 'react';
import MailOutlineOutlined from '@mui/icons-material/MailOutlineOutlined'
import { CONTACT_URL, postValuebet } from '../../../API-Handle/services/auth_curd';
import { successNotification } from '../../../Components/CustComp/PPHAlert';
import CustomForm from '../../../Components/CustComp/CustomForm';
import PublicHeader from '../../../Components/PublicHeader';
import PublicFooter from '../../../Components/PublicFooter';
import ScrollToTop from '../../../Components/CustComp/ScrollToTop';

export default function Index() {
    const [sending, setSending] = useState(false);
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [name, setName] = useState('');
    const [nameErr, setNameErr] = useState('');
    const [comment, setComment] = useState('');
    const [commentErr, setCommentErr] = useState('');

    const sendMail = async (form) => {
        setSending(true);
        let data = {
            name,
            email,
            comment
        }

        let res = await postValuebet(CONTACT_URL, data);
        if (res) {
            setEmail('');
            setName('');
            setComment('');
            form.reset();
            form.classList.remove('was-validated')
            successNotification(res?.message);
        }
        setSending(false);
    }

    return (
        <div id='public-page' className='BG-valuebet'>
            <ScrollToTop />
            <PublicHeader />
            <div className='layout-mt-112 container-fluid pretty-card'>
                <h2 className='reguler-heading vb-text-orange'>Contact Us</h2>
                <div>
                    <p>Contact us and we'll get back to you within 24 hours.</p>
                    <p><MailOutlineOutlined style={{ fontSize: 18, color: "orange" }} /> support@valuebet.app</p>
                </div>
                <CustomForm onSubmit={sendMail}>
                    <div className="row">
                        <div className="col-sm-6 form-group mt-3">
                            <input className="form-control bg-vb-dark text-white" id="name" name="name" placeholder="Name" type="text" minLength={3} maxLength={30} onChange={(e) => setName(e.target.value)} required onInvalid={(e) => setNameErr(e.target.validationMessage)} />
                            <div className="invalid-feedback">
                                {nameErr}
                            </div>
                        </div>
                        <div className="col-sm-6 form-group mt-3">
                            <input className="form-control bg-vb-dark text-white" id="email" name="email" placeholder="Email" type="email" onChange={(e) => setEmail(e.target.value)} required onInvalid={(e) => setEmailErr(e.target.validationMessage)} />
                            <div className="invalid-feedback">
                                {emailErr}
                            </div>
                        </div>
                    </div>
                    <textarea className="form-control bg-vb-dark text-white mt-3" id="comments" name="comments" placeholder="Post your message here..." rows="5" minLength={20} maxLength={1000} onChange={(e) => setComment(e.target.value)} required onInvalid={(e) => setCommentErr(e.target.validationMessage)}></textarea>
                    <div className="invalid-feedback">
                        {commentErr}
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-sm-12 form-group">
                            <button className="btn btn-danger btn-sm pull-right" type="submit" disabled={sending} >{sending ? 'Sending...' : 'Send'}</button>
                        </div>
                    </div>
                </CustomForm>
                <div className='mt-5 text-center'>
                    {/* eslint-disable-next-line  */}
                    Join our Telegram Support Group: <a className="vb-text-orange" href="https://t.me/+SrsT9XiwBkA3NTVh" target={"_blank"}>Join Now</a>
                </div>
            </div>
            <PublicFooter />
        </div>
    )
}
