import React from 'react'
import DataTable from 'react-data-table-component-footer';

const conditionalRowStyles = [
	{
		when: row => row.status === "WON",
		style: {
			backgroundColor: 'rgba(63, 195, 128, 0.9)',
			color: 'white',
		// 	'&:hover': {
		// 		cursor: 'pointer',
		// 	},
		},
	},
	{
		when: row => row.status === "LOSE",
		style: {
			backgroundColor: 'rgba(242, 38, 19, 0.9)',
			color: 'white',
			// '&:hover': {
			// 	cursor: 'not-allowed',
			// },
		},
	},
];

const columns = [
    {
        name: 'Bet ID',
        selector: row => row.id,
        width: "150px",
        sortable: true
    },
    {
        name: 'Market',
        selector: row => row.market,
        sortable: true
    },
    {
        name: 'Odds',
        selector: row => row.odds,
        sortable: true
    },
    {
        name: 'Betted',
        selector: row => row.betted,
        sortable: true
    },
    {
        name: 'Reward If Won',
        selector: row => row.won,
        sortable: true
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true
    },
    {
        name: 'More',
        selector: row => row.more,
        sortable: true
    },
];

const data = [
    {
        id: "DFGHGFHUYTRD",
        market: 'WIN/DROW',
        odds: 'WIN',
        betted: '50',
        won: '120',
        status: 'WON',
        more: '...',
    },
    {
        id: "XFGUHBNFGHDFT",
        market: '1st GOAL',
        odds: 'No GOAL',
        betted: '10',
        won: '30',
        status: 'WON',
        more: '...',
    },
    {
        id: "XFGUGHGHDFT",
        market: 'ODD/EVEN',
        odds: 'EVEN',
        betted: '12',
        won: '48',
        status: 'LOSE',
        more: '...',
    },
    {
        id: "XFCGJHGJKHKHTG",
        market: 'CORRECT SCORE',
        odds: '3-3',
        betted: '25',
        won: '250',
        status: 'WON',
        more: '...',
    },
    
]

export default function BettingHistory() {
    return (
        <div>
            <div className="mx-4 py-1 bg-dark">
                <div className='mx-4'>
                    <DataTable
                        title={<span className='font-size-14px'>Betting History</span>}
                        columns={columns}
                        data={data}
                        theme="dark"
                        dense={true}
                        highlightOnHover={true}
                        conditionalRowStyles={conditionalRowStyles}
                    />
                </div>
            </div>
        </div>
    )
}
