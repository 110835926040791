import React, { useEffect, useState } from 'react'
import { getValuebet, COIN_HISTORY_URL } from '../../../../../API-Handle/services/auth_curd'
import moment from 'moment'
import ListLoader from '../../../../../Components/CustLoader/ListLoader';

export default function Transactions({ coin }) {
    const [transactions, setTransactions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        fetchTransactionHistory();
    }, [coin])

    const fetchTransactionHistory = async () => {
        setIsLoading(true);
        let res = await getValuebet(COIN_HISTORY_URL);
        if (res) {
            setTransactions(res);
        }
        setIsLoading(false);
    }

    return (
        <div>
            <label className='text-white'>Transaction History: </label>
            { !isLoading ? transactions.length ?
            <div className="overflow-x">
                <table className="table table-sm table-custom-dark">
                    <thead>
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Description</th>
                            <th scope="col">Sent</th>
                            <th scope="col">Received</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map((trans) => {
                            return (
                                <tr key={trans.id}>
                                    <td>{moment(trans.time_stamp).format('MMMM Do YYYY, hh:mm:ss A')}</td>
                                    <td>{trans.desc}</td>
                                    <td>{trans.sent ? trans.sent : "-"}</td>
                                    <td>{trans.received ? trans.received : "-"}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            : <div className="alert alert-dark" role="alert">
                No transaction record found!
            </div>
            : <ListLoader rowsCount={5} headHeight={60}rowsHeight={45}/> }
        </div>
    )
}
