import React from 'react'
import MlbTable from './MlbTable';
import FootballTable from './FootballTable';
import NbaTable from './BasketBall/NbaTable';
import HockeyTable from './Hockey/HockeyTable';

const renderTable=(sport_name,item,title)=>{
    switch(sport_name){
        case 'BASEBALL':
            return <MlbTable item={item} title={title}/>;
        case 'FOOTBALL':
          return <FootballTable item={item} title={title}/>
        case 'BASKETBALL':
          return <NbaTable item={item} title={title}/>
        case 'HOCKEY':
          return <HockeyTable item={item} title={title}/>
        
        
        default:
            return null; 
    }
}

const ScoreTable = ({item,title}) => {
  return (
    <div>{renderTable(item?.sport_name,item,title)}</div>
  )
}

export default ScoreTable;