import { APP_SOCKET_PATH, CHAT_SOCKET_PATH } from "../config";

class WebSocketService {

  constructor(type) {
    this.callbacks = {};
    this.socketRef = null;
    this.messageQueue = [];
    this.processing = false;
    this.time_out_interval = 500;
    this.intentionalClosure = false;

    const authToken = localStorage.getItem("authToken");

    if (type === "chat") {
      this.url = `${CHAT_SOCKET_PATH}${authToken}`;
    } else if (type === "private") {
      this.url = `${APP_SOCKET_PATH}${authToken}`;
    } else if (type === "public") {
      this.url = APP_SOCKET_PATH;
    }
  }

  connect = () => {
    this.socketRef = new WebSocket(this.url);

    this.socketRef.onopen = () => {
      console.log("Websocket connection opened.");
      this.time_out_interval = 500;
    };

    this.socketRef.onmessage = (e) => {
      const parsedData = JSON.parse(e.data);
      this.addToQueue(parsedData);
    };

    this.socketRef.onerror = (e) => {
      console.log(`Websocket connection error raised - `, e);
    };

    this.socketRef.onclose = () => {
      if (!this.intentionalClosure) {
        console.log("Websocket connection closed.");
        console.log(`Websocket reconnecting in ${this.time_out_interval}ms`);
        setTimeout(() => {
          this.connect();
          this.time_out_interval = Math.min(this.time_out_interval + 100, 10000);
        }, this.time_out_interval);
      } else {
        console.log("Websocket connection closed intentionally.");
        this.intentionalClosure = false;
      }
    };
  };

  sendMessage(data) {
    try {
      console.log("called socket",data)
      this.socketRef.send(JSON.stringify(data));
    } catch (err) {
      console.log(err.message);
    }
  }
  
  close() {
    if (this.socketRef) {
      this.intentionalClosure = true;
      this.socketRef.close();
      this.callbacks = {};
      this.socketRef = null;
      this.messageQueue = [];
      this.processing = false;
      this.time_out_interval = 500;
    }
  }

  reconnect(){
    if (this.socketRef) this.socketRef.close();
  }

  addCallbacks(all_callbacks) {
    this.callbacks = all_callbacks;
  }

  addToQueue(data) {
    this.messageQueue.push(data);
    this.startQueue();
  }

  startQueue() {
    if (!this.processing && this.messageQueue.length) {
      this.processing = true;
      this.processQueue();
    }
  }

  async processQueue() {
    if (!this.messageQueue.length) {
      this.processing = false;
      setTimeout(() => this.startQueue(), 10000);
      return;
    }
    const msg = this.messageQueue.shift();
    try {
      const command = msg.command;
      if (this.callbacks[command] !== undefined) {
        await this.callbacks[command](JSON.parse(msg.data));
      }
    } catch (error) {
      console.log(error);
    }
    this.processQueue();
  }
}

export default WebSocketService;
