import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../Components/UserHeader'
import Footer from '../../../Components/Footer'
import { Row } from 'reactstrap'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import MainTabReport from './MainTabReport';
import AgentsTabReport from './AgentsTabReport';
import PlayerTabReport from './PlayerTabReport';
import ClubAction from '../Dashboard/ClubCard/ClubComponants/ClubAction';
import ValuebetContext from '../../../context/Valuebet/ValuebetContext';

export default function Index({ location }) {
    const [activeTab, setActiveTab] = useState(1);
    const {activeClub, setActiveClub } = useContext(ValuebetContext);

    const navigate = useNavigate();;
    if (!location?.state?.item) {
        navigate("/")
    }

    useEffect(()=>{
        setActiveClub(location?.state?.item)
    }, [])

    return (
        <>
            <Header />
            <div className="sec-header container-fluid">
                <Row>
                    <div className="d-flex justify-content-start align-items-center">
                        <h6 className="sec-header-title">
                            Agent report
                        </h6>
                        {activeClub && <ClubAction item={activeClub} />}
                    </div>
                </Row>
            </div>
            <div className='layout-mt-112 fill-view'>
                <div className="container-fluid mt-4">
                    <div className='player-report-container'>
                        <div className="overflow-auto text-white">
                            <div className="card-header">
                                <ul className="nav nav-tabs card-header-tabs border-bottom-report">
                                    <li className="nav-item">
                                        <span role='button' className={activeTab === 1 ? "nav-link active" : "nav-link custom-deactive text-white"}
                                            onClick={() => setActiveTab(1)} >Main</span>
                                    </li>
                                    <li className="nav-item">
                                        <span className={activeTab === 2 ? "nav-link active" : "nav-link custom-deactive text-white"}
                                            onClick={() => setActiveTab(2)} >Agents</span>
                                    </li>
                                    <li className="nav-item">
                                        <span className={activeTab === 3 ? "nav-link active" : "nav-link custom-deactive text-white"}
                                            onClick={() => setActiveTab(3)} >Players</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="card-body">
                                {activeTab === 1 && <MainTabReport />}
                                {activeTab === 2 && <AgentsTabReport />}
                                {activeTab === 3 && <PlayerTabReport />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
