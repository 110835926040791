import React, { useContext, useEffect, useState, useRef, useLayoutEffect } from 'react'
import ValuebetContext from '../../../../../context/Valuebet/ValuebetContext';
import { errorNotification } from '../../../../../Components/CustComp/PPHAlert';
import { OFFLINE_ODD } from '../../../../../constant';
import { useSelector } from 'react-redux';
import { getReadablePeriod } from '../../../../../Utils/sportsbook';


export default function TeaserBetOption({ value, market_type, team_type, league_id, match_id, team_code, team_name, line, period }) {
    const [isSelected, setIsSelected] = useState(false);
    const timer = useRef(null);
    const preVal = useRef(null);
    const { leagueEvents, betDetails, setBetDetails, removeSelectedBet } = useContext(ValuebetContext);
    const { selectedTeaserPoints } = useSelector((state) => state.sportBook);
    
    // Creating Bets
    const createBetData = async () => {
        if (betDetails?.bets?.length >= 6) {
            errorNotification("Bets must be less-than or equal to 6.")
            return
        }
        let id = value.id
        let odd_money = value.odd_money > 0 ? `+${value.odd_money}` : value.odd_money
        let sport_name = '';
        let league_name = '';
        let short_description = '';
        let temp_odd_points = '';
        let odd_points = value.odd_points;
        let odd_name = team_type;
        let participant_type = 'TYPE_TEAM'
        let participant_id = team_code;
        let period_name = getReadablePeriod(period)
        let sub_title = '';

        if (market_type === 'spread') {
            short_description = "Spread";
            temp_odd_points = parseFloat(value.odd_points)+selectedTeaserPoints.teaser_points;
            sub_title = `${short_description} ${temp_odd_points > 0 ? `+${temp_odd_points}` : temp_odd_points === 0 ? `pk` : temp_odd_points} for ${period_name}`
        } else if (market_type === 'total') {
            short_description = "Total";
            participant_type = "TYPE_RESULT"
            if (team_type === "HOME") {
                temp_odd_points = parseFloat(value.odd_points)+selectedTeaserPoints.teaser_points;
                odd_name = "UNDER";
                participant_id = "10"
            } else if (team_type === "AWAY") {
                temp_odd_points = parseFloat(value.odd_points)-selectedTeaserPoints.teaser_points;
                odd_name = "OVER";
                participant_id = "9"
            }
            sub_title = `${short_description}(${odd_name}) ${temp_odd_points} for ${period_name}`
        }

        let title = '';
        for (let league of leagueEvents) {
            if (league.league_id === league_id) {
                league_name = league.league_name;
                sport_name = league.sport_name;
                title = league.title;
                if (market_type === 'moneyline' || market_type === 'spread') {
                    title += ` - ${team_name}`
                } else if (market_type === 'total') {
                    for (let match of league.events) {
                        if (match.id === match_id) {
                            title += ` - ${match.home_team_name} / ${match.away_team_name}`
                            break;
                        }
                    }
                }
                break;
            }
        }

        let tmp_bet = JSON.parse(JSON.stringify(betDetails))
        tmp_bet.bets.push({
            id: id,
            odd_points: odd_points,
            odd_money: odd_money,
            market_name: market_type,
            odd_name: odd_name,
            sport_name: sport_name,
            league_name: league_name,
            league_id: league_id,
            match_id: match_id,
            title: title,
            sub_title: sub_title,
            init_sub_title: sub_title,
            sportsbook_id: line,
            period_name: period_name,
            period: period,
            participant_type: participant_type,
            participant_id: participant_id,
            proposition: false,
        })

        setBetDetails(tmp_bet);
    }

    const betAction = () => {
        if (!isSelected) {
            createBetData();
        } else {
            removeSelectedBet(value);
        }
    }

    useLayoutEffect(() => {
        let flag = false;
        for (let bet of betDetails.bets) {
            if (bet?.id === value?.id) {
                flag = true;
                break;
            }
        }
        setIsSelected(flag);
    // eslint-disable-next-line
    }, [betDetails, value])

    const highlighterTool = () => {
        if (value?.is_updated) {
            value.is_updated = false;
            try {
                if (timer.current) {
                    clearTimeout(timer.current)
                }
                let ele = document.getElementById(value.id);
                ele.classList.add(isSelected ? 'orange-highlight' : 'blue-highlight');
                timer.current = setTimeout(() => {
                    if (ele) {
                        ele.classList.remove('orange-highlight', 'blue-highlight');
                    }
                }, 10000)
            } catch (err) {
                console.log(err)
            }
        } else if (preVal?.current?.id !== value?.id) {
            if (timer.current) {
                let ele = document.getElementById(value.id);
                if (ele) {
                    ele.classList.remove('orange-highlight', 'blue-highlight');
                    clearTimeout(timer.current)
                }
            }
        }
    }

    useEffect(() => {
        highlighterTool();
        return (() => {
            preVal.current = value;
        })
    // eslint-disable-next-line
    }, [value])

    return (
        (!value) ?
            <button className="odd-disable-button" disabled> N/A </button> :
            (value?.odd_points === OFFLINE_ODD || value?.odd_money === OFFLINE_ODD || market_type === "moneyline") ?
                <div id={value.id} className="odd-disable-button"> - </div> :
                <div id={value.id} role="button" className={`odd-button text-nowrap ${isSelected && 'odd-button-active'}`} onClick={betAction}>
                    {market_type === 'spread' && <div ><sapn>{(parseFloat(value?.odd_points) + selectedTeaserPoints.teaser_points > 0) ? '+' + (parseFloat(value?.odd_points) + selectedTeaserPoints.teaser_points) : (parseFloat(value?.odd_points) + selectedTeaserPoints.teaser_points === 0) ? 'pk' : parseFloat(value?.odd_points)+selectedTeaserPoints.teaser_points}</sapn></div>}
                    {market_type === 'total' && <div><span>{team_type === 'AWAY' ? "O" : "U"} {team_type === 'AWAY' ? parseFloat(value?.odd_points) - selectedTeaserPoints.teaser_points : parseFloat(value?.odd_points) + selectedTeaserPoints.teaser_points}</span></div>}
                </div>
    )
}