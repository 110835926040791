import React, { useEffect, useState } from "react";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { postValuebet, SWITCH_MEMBERS } from "../../../../../API-Handle/services/auth_curd";
import { DefaultConfirm } from "../../../../../Components/SweetAlerts/DefaultConfirm";
import { successNotification } from "../../../../../Components/CustComp/PPHAlert";

const DropItem = ({ member_type, onClick, className }) => {
  return (<DropdownItem
    className={className ? className : 'ptyp mb-2'}
    onClick={onClick}
  >
    {member_type}
  </DropdownItem>)
}

function RoleActon({ row, activeClub }) {
  const [typecls, setTypeCls] = useState('');

  const updateLabelClass = () => {
    if (row.member_type === "PLAYER") {
      setTypeCls("ptyp-text")
    } else if (row.member_type === "AGENT") {
      setTypeCls("atyp-text")
    } else if (row.member_type === "MANAGER") {
      setTypeCls("mtyp-text")
    } else if (row.member_type === "OWNER") {
      setTypeCls("otyp-text")
    }
  }

  useEffect(() => {
    updateLabelClass();
    // eslint-disable-next-line
  }, [row])


  const switchMember = async (item) => {
    let confirmation = await DefaultConfirm("Role Change", "Do you want to change role of this member?")
    if (confirmation) {
      item['club_id'] = activeClub.id;
      let res = await postValuebet(SWITCH_MEMBERS, item)
      if (res) {
        row.member_type = item.switch_type;
        successNotification(res.message);
        updateLabelClass()
      }
    }
  }

  return (
    <>
      <span className="member-dropdown">
        <UncontrolledButtonDropdown direction="right">
          <DropdownToggle role='menu' color="none" disabled={row.member_type === "OWNER"} className="btn-sm p-0 m-0">
            <div className="member-label">
              <span className={"badge " + typecls}>{row.member_type}</span>
            </div>
          </DropdownToggle>
          <DropdownMenu center className="member-actions">
            <DropItem member_type={"MANAGER"} onClick={() => { switchMember({ member_id: row.id, switch_type: "MANAGER" }) }} className={"mtyp-text rounded mb-3"} />
            {/* <DropItem member_type={"AGENT"} onClick={()=>{onRoleChange({member_id: row.id, switch_type: "AGENT"})}} className={"atyp-text rounded mb-3"}/> */}
            <DropItem member_type={"PLAYER"} onClick={() => { switchMember({ member_id: row.id, switch_type: "PLAYER" }) }} className={"ptyp-text rounded"} />
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </span>
    </>
  )
}

export default RoleActon
