import React, { useState, useContext } from 'react'
import {
    Button,
} from "reactstrap";
import { BUY_CHIPS_URL, postValuebet } from '../../../../../../API-Handle/services/auth_curd'
import { successNotification } from '../../../../../../Components/CustComp/PPHAlert';
import ValuebetContext from '../../../../../../context/Valuebet/ValuebetContext';
import { DefaultConfirm } from '../../../../../../Components/SweetAlerts/DefaultConfirm';

export default function Plan({ item, club, toggleBuyModal }) {
    const [isBuying, setIsBuying] = useState(false);
    const { clubData, setClubData, userDetails, setUserDetails } = useContext(ValuebetContext)

    const updateClubs = () => {
        if (clubData) {
            let currentClubData = JSON.parse(JSON.stringify(clubData))
            currentClubData.forEach(element => {
                if (element.id === club.id) {
                    element.club_wallet.available_chips += item.chips;
                }
            });
            setClubData(currentClubData)
            let user_details = JSON.parse(JSON.stringify(userDetails))
            user_details.coins -= item.cost
            setUserDetails(user_details)
            toggleBuyModal()
        }
    }

    const buyChpsNow = async () => {
        let message = `Do you want to purchase ${item.chips} Chips by spending ${item.cost} Coins?`
        let confirmation = await DefaultConfirm("Buy Chips", message)
        if (!confirmation) return
        setIsBuying(true)
        let data = {
            club_id: club.id,
            plan_id: item.id
        }
        let res = await postValuebet(BUY_CHIPS_URL, data)
        if (res) {
            successNotification(res.message)
            updateClubs()
        }
        setIsBuying(false)
    }

    return (
        <tr key={item.id}>
            <td className='align-middle'>{item.cost} Coins</td>
            <td className='align-middle'>{item.chips} Chips</td>
            <td className='align-middle'> <Button disabled={isBuying} color='success' className='btn-sm' onClick={buyChpsNow}> {isBuying ? 'Buying...' : 'Buy Now'} </Button> </td>
        </tr>
    )
}
