import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { useNavigate } from "react-router";

export default function AgentOverview({ item }) {
  const [agentInfo, setAgentInfo] = useState(null);
  const navigate = useNavigate();
  const initialization = () => {
    // set agent info
    let agentInfo = "";
    item.agents.forEach((agnt) => {
      if (agnt.username === item.user_name) {
        agentInfo = agnt;
      }
    });
    setAgentInfo(agentInfo);
  };

  useEffect(() => {
    if (item.label === "Agent" || item.label === "Sub-Agent") initialization();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToPlayer = () => {
    navigate(`/club/${item.id}/members`);
  };

  const goToAgents = () => {
    navigate(`/agents`, { state: { club_data: item } });
  };

  return (
    <>
      {(item.label === "Agent") | (item.label === "Sub-Agent") ? (
        <Row className="text-center">
          <Col>
            {" "}
            <h6 className="text-white">
              {" "}
              {agentInfo?.more_info.agents_kickbacks}%{" "}
            </h6>{" "}
            <div className="font-size-12px text-white"> Kickbacks </div>{" "}
          </Col>
          <Col>
            <span role={"button"} onClick={goToAgents}>
              <h6 className="text-white"> {item.agents.length} </h6>{" "}
              <div className="font-size-12px text-white"> Agents </div>{" "}
            </span>
          </Col>
          <Col>
            <span role={"button"} onClick={goToPlayer}>
              <h6 className="text-white">
                {" "}
                {item.agents.length + item.members.length}
              </h6>{" "}
              <div className="font-size-12px text-white"> Players </div>{" "}
            </span>
          </Col>
        </Row>
      ) : null}
    </>
  );
}
