import { CHANNELTYPE } from "../../constant";
import {clubchatAction} from "../features/clubchatSlice";
import { ADD_CLUB_CHANNEL,GET_ALL_CLUB_MEMBER,GET_ALL_MESSAGE,GET_CLUB_CHANNEL, GET_CLUB_CHANNEL_MEMBER, MARK_CHANNEL_MESSAGES_SEEN, getValuebet,postValuebet} from "../../API-Handle/services/auth_curd" 
import { successNotification } from "../../Components/CustComp/PPHAlert";

export const fetchAllClubMember = (clubId) =>{
    return async (dispatch) =>{
        let URL = `${GET_ALL_CLUB_MEMBER}${clubId}`;
        let res = await getValuebet(URL)
        dispatch(clubchatAction.setClubMember({data: res}))
    }
}

export const markChannelMessageSeen=async(chId)=>{
        let URL = `${MARK_CHANNEL_MESSAGES_SEEN}${chId}`;
        postValuebet(URL);

    }

export const setSelectedMember = (data, chID) =>{
    return async (dispatch) =>{
        dispatch(clubchatAction.setReplyMessage({ data: null }))
        dispatch(clubchatAction.setActiveChannel({data: data}))
        dispatch(clubchatAction.openMobile({data: false}))
        dispatch(clubchatAction.setSelectedChannelID({data: chID}))
        dispatch(clubchatAction.setSelectedChannelMessages({data: []}))
        if(chID){
            dispatch(fetchAllMessages(chID));
            markChannelMessageSeen(chID);
            dispatch(clubchatAction.clearChannelUnreadMessage({data:{channelId:chID, message:data}}))
        }
    }
}

export const toggleMessanger = (item, isClubChatOpen) => {
    return async (dispatch) =>{
        dispatch(clubchatAction.selectedClub({data: item}))
        dispatch(clubchatAction.setSelectedChannelID({data: null}))
        dispatch(clubchatAction.setClubMember({data: []}))
        setTimeout(() => {
            dispatch(clubchatAction.openClubChat({data: !isClubChatOpen}))
        }, 100);
    }
};

export const handleDrawerToggle = (isDrawerOpen,mobileOpen) => {
    return async (dispatch) =>{
        if (!isDrawerOpen) {
        dispatch(clubchatAction.openMobile({data: !mobileOpen}))
        }
    }
};

export const fetchClubChannel = (clubId, type) =>{
    return async (dispatch) =>{
        let URL = `${GET_CLUB_CHANNEL}${clubId}?channel_type=${type}`;
        let res = await getValuebet(URL)
        if(type === CHANNELTYPE.personal){
            dispatch(clubchatAction.setPersonalMember({data: res}))
        }else{
            dispatch(clubchatAction.setClubGroup({data: res})) 
        }
    } 
}

export const fetchChannelMember = (channelId) =>{
    return async (dispatch) =>{
        let URL = `${GET_CLUB_CHANNEL_MEMBER}${channelId}`;
        let res = await getValuebet(URL)
        dispatch(clubchatAction.setClubGroupMember({data: res}))
    } 
}

export const createChannel = (channelId, name, members,channel_type) =>{
    return async (dispatch) =>{
        dispatch(clubchatAction.loadigData({data: true}))
        let URL = `${ADD_CLUB_CHANNEL}${channelId}`;

        let payload = {
            members: members,
            channel_type: channel_type,
        };
        if(name) payload["name"] = name;
        let res = await postValuebet(URL,payload)
        dispatch(clubchatAction.loadigData({data: false}))
        if(res){
            dispatch(clubchatAction.openAddChannel({data: false}))
            successNotification("Channel Created Successfully!")
        }
    } 
}

export const toggleAddChannel = (isAddChannel) =>{
    return async (dispatch) =>{
        dispatch(clubchatAction.openAddChannel({data: !isAddChannel}))
    }
}

export const collapsePublicChannel = (value, clubId) =>{
    return async (dispatch) =>{
        dispatch(clubchatAction.setIsPublicChannel({data: !value}))
        dispatch(clubchatAction.setIsPersonalChannel({data: false}))
        if(value !== true){
            dispatch(fetchClubChannel(clubId, CHANNELTYPE.group))
        }
    }
}

export const collapsePersonalChannel = (value, clubId) =>{
    return async (dispatch) =>{
        dispatch(clubchatAction.setIsPersonalChannel({data: !value}))
        dispatch(clubchatAction.setIsPublicChannel({data: false}))
        if(value !== true){
            dispatch(fetchClubChannel(clubId, CHANNELTYPE.personal))
        }
    }
}

export const fetchAllMessages = (chID)=> {
    return async (dispatch) =>{
        let URL = `${GET_ALL_MESSAGE}${chID}`;
        let res = await getValuebet(URL)
        dispatch(clubchatAction.setSelectedChannelMessages({data: res}))
    }
}

export const chatReconnection = (clubId, isClubChatOpen, isPersonalChannel, isPulicChannel, res_data) =>{
    return async (dispatch) =>{
        if (isClubChatOpen){
            if (isPersonalChannel && res_data.channel_type === CHANNELTYPE.personal) dispatch(fetchClubChannel(clubId, CHANNELTYPE.personal))
            if (isPulicChannel && res_data.channel_type === CHANNELTYPE.group) dispatch(fetchClubChannel(clubId, CHANNELTYPE.group))
        }
    }
}

export const setClubAdminStatus = (value)=> {
    return async (dispatch) =>{
        dispatch(clubchatAction.setClubAdmin({data: value}))
    }
}