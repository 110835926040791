import React, { useState } from 'react'
import Header from '../../../Components/UserHeader'
import AffiliateSummary from './AffiliateSummary';
import Members from './Members'
import { Row } from 'reactstrap';
import Footer from '../../../Components/Footer';


function Index() {
    const [activeTab, setActiveTab] = useState(1);

    return (
        <div>
            <Header />
            <div className="sec-header container-fluid">
                <Row>
                    <div className="d-flex py-2 justify-content-start align-items-center">
                        <h6 className="sec-header-title font-size-14px">
                            Affiliate Reports
                        </h6>
                    </div>
                </Row>
            </div>
            <div className='layout-mt-112 fill-view pb-3'>
                <div className="container-fluid mt-4">
                    <div className='border rounded border-danger'>
                        <div className="card mb-0 bg-vb-dark overflow-auto text-white">
                            <div className="card-header seperater">
                                <ul className="nav nav-tabs card-header-tabs">
                                    <li className="nav-item">
                                        <span role='button' className={activeTab === 1 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"}
                                            onClick={() => setActiveTab(1)} >Summary</span>
                                    </li>
                                    <li className="nav-item">
                                        <span role='button' className={activeTab === 2 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"}
                                            onClick={() => setActiveTab(2)} >Members</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="card-body">
                                {activeTab === 1 ?
                                    <AffiliateSummary /> :
                                    activeTab === 2 ?
                                        <Members /> :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Index;