import React from 'react'
import PayFooter from '../../../../Components/CustComp/PayFooter';
import CancelIcon from '@mui/icons-material/Cancel';

export default function CancelPay() {
    return (
        <div className='valuebet-bg-color'>
            <div className='BG-valuebet thank-you-card'>
                <div className="container text-center py-5">
                    <CancelIcon className='mt-5 mb-4' sx={{ color: '#e03f00', fontSize: 60 }}/>
                    <h2 className='reguler-heading'><span className='text_orange'>Payment Failed! </span></h2>
                    <p className='reguler-fonts'>We regret to inform you that there was an issue processing your payment, and as a result, your transaction for Valuebet Coins/Tokens could not be completed. Please check your payment details and try again. If the issue persists, kindly contact our support team via the support chat or email at <span className='text_orange'>support@valuebet.app</span> for further assistance. We appreciate your understanding and apologize for any inconvenience caused.</p>
                </div>
                <PayFooter />
            </div>
        </div>
    )
}