import { getReadablePeriod } from '../../../../../../../Utils/sportsbook';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SportsCricketIcon from '@mui/icons-material/SportsCricket';
import SportsHockeyIcon from '@mui/icons-material/SportsHockey';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import { errorNotification } from '../../../../../../../Components/CustComp/PPHAlert';


const createBetData = (team, data, setBetDetails, betDetails, odd,item,title) => {
    const league_name = title && title.split("-")[1].replace(/\s+/g, '');
    const odd_money = odd?.odd_money;
    const period_name = getReadablePeriod(data?.period_name);
    const odd_points=parseFloat(odd?.odd_points);

    if (betDetails?.bets?.length >= 6) {
      errorNotification("Bets must be less-than or equal to 6.")
      return
  }
  
    const betData = {
        id: odd.odd_id,
        odd_points: odd?.odd_points,
        odd_money: odd_money,
        market_name: data.name,
        odd_name: team.name,
        sport_name: item.sport_name,
        league_name: league_name,
        league_id: item.league,
        match_id: item.id,
        event_id: item.event_id,
        title: `${title} - ${team.name}`,
        sub_title: `${data.short_description} ${odd_points !== 0 ? `${odd?.odd_points} ` : ''}${odd_money > 0 ? `+${odd_money}` : odd_money} for ${period_name}`,
        init_sub_title: `${data.short_description} ${odd_points !== 0 ? `${odd?.odd_points} ` : ''}${odd_money > 0 ? `+${odd_money}` : odd_money} for ${period_name}`,
        period_name: period_name,
        period: data?.period_name,
        market_id:data.market_id,
        period_id:data.period_id,
        sportsbook_id:data.sportsbook_id,
        participant_type:team.type,
        participant_id:team.id?.toString(),
        proposition: true,
    };
    let tmp_bet = JSON.parse(JSON.stringify(betDetails));
    tmp_bet.bets.push(betData);
    setBetDetails(tmp_bet);
    console.log(tmp_bet);
  }
  
  export const handleClickOnMarket = (team,data, betDetails, setBetDetails, removeSelectedBet, odd ,item,title) => {
    const isPresent = betDetails?.bets?.some(bet => bet?.id === odd?.odd_id);
    
  
    if (isPresent) {
        removeSelectedBet({ id: odd.odd_id });
    }
    else {
        createBetData(team, data, setBetDetails, betDetails, odd,item,title);
  
    }
  
  
  
  
  }

  export const accordionSummaryStyles = {
    backgroundColor: "#3F3D3D",
    '&.MuiAccordionSummary-root': {
        minHeight: '36px',
        '&.Mui-expanded': {
            minHeight: '36px',
        },
    },
    '& .MuiAccordionSummary-content': {
        margin: '4px 0',
        '&.Mui-expanded': {
            margin: '4px 0', 
        },
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        paddingRight: '8px',
    },
};


export const getImg = (i) => {
    if (i === 'FOOTBALL') {
      return <SportsFootballIcon />
    } else if (i === 'BASKETBALL') {
      return <SportsBasketballIcon />
    } else if (i === 'BASEBALL') {
      return <SportsBaseballIcon />
    } else if (i === 'HOCKEY') {
      return <SportsHockeyIcon />
    } else if (i === 'FIGHTING') {
      return <SportsKabaddiIcon />
    } else if (i === 'SOCCER') {
      return <SportsSoccerIcon />
    } else if (i === 'CRICKET') {
      return <SportsCricketIcon />
    }
  }

  const processOddPoints=(data) =>{
    const normalizeOddPoints = (oddPoint) => {
      if (typeof oddPoint === 'string') {
        return parseFloat(oddPoint.replace(/Over|Under\s*/, ''));
      }
      return oddPoint;
    };
  
    const grouped = data.reduce((acc, obj) => {
      const normalizedPoints = normalizeOddPoints(obj.odd_points);
      
      if (!acc[normalizedPoints]) {
        acc[normalizedPoints] = [];
      }
  
      acc[normalizedPoints].push(obj);
      
      return acc;
    }, {});
  
    return Object.values(grouped).flat();
  }

  export const  updatedParticipants=(market)=>{
    let participants=market.participants;
    const updatedParticipants=participants.map((participant)=>{
      participant["lines"]=processOddPoints(participant.lines);
      return participant;
    })
    const updatedMarket={...market};
    updatedMarket["participants"]=updatedParticipants;
    return updatedMarket;

  }
  