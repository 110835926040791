import React, { useContext, useState, useEffect } from 'react'
import ValuebetContext from '../../../../context/Valuebet/ValuebetContext'

function SportButton({ item, value }) {
  const { leagueEvents, setLeagueEvents, getEvent } = useContext(ValuebetContext);
  const [ isChecked, setIsChecked ] = useState(false);

  useEffect(()=>{
      let active = leagueEvents.filter((lg)=>lg.league_id === value.id)
      if (active.length){
          setIsChecked(true);
      }else{
          setIsChecked(false);
      }
    // eslint-disable-next-line
  }, [leagueEvents])

  const removeEvent = (league_id) => {
      let remaining_events = leagueEvents.filter((event) => event.league_id !== league_id)
      setLeagueEvents(remaining_events)
  }

  const eventAction = async (flage, sport_id, league_id) => {
      if (flage) {
          getEvent(sport_id, league_id)
      }else{
          removeEvent(league_id)
      }
  }

  return (
    <button
      className={isChecked ? 'head-button-active' : 'head-button'}
      onClick={() => eventAction(!isChecked, item.id, value.id)}
      >
        {value.league_name}  {/*{value.league_name === "WNBA" && <sup className={`text-${isChecked? 'white' : 'warning'}`} style={{top:"-6px"}}><small>New</small></sup>} */}
    </button>
  )
}

export default SportButton