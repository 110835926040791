import React, { useContext, useEffect, useState } from 'react'
import ValuebetContext from '../../../../context/Valuebet/ValuebetContext';

export default function LeagueButton({value, item}) {
    const { leagueEvents, setLeagueEvents, getEvent } = useContext(ValuebetContext);
    const [ isChecked, setIsChecked ] = useState(false);

    useEffect(()=>{
        let active = leagueEvents.filter((item)=>item.league_id === value.id)
        if (active.length){
            setIsChecked(true);
        }else{
            setIsChecked(false);
        }
    }, [leagueEvents])

    const removeEvent = (league_id) => {
        let remaining_events = leagueEvents.filter((event) => event.league_id !== league_id)
        setLeagueEvents(remaining_events)
    }

    const eventAction = async (e, sport_id, league_id) => {
        if (e.target.checked) {
            getEvent(sport_id, league_id)
        }else{
            removeEvent(league_id)
        }
    }
    
    return (
        <div className='side-check' role={"button"} onClick={() => document.getElementById(value.id).click()}>
            <input className="me-2" type="checkbox" checked={isChecked} id={value.id} onClick={(e) => e.stopPropagation()} onChange={(e) => eventAction(e, item.id, value.id)} />
            <label className="form-check-label">
                {value.league_name}
            </label>
        </div>
    )
}
