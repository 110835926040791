import React, { useState } from 'react'
import { Modal, ModalBody } from "reactstrap";
import { Avatar, Box, List, ListItem, ListItemText } from "@mui/material";
import CustomAvatar from './CustomAvatar';

export default function ReactionModal({ msg, reactions }) {
    const [reactedModal, setReactedModal] = useState(false);

    const toggle = () => {
        setReactedModal((prev) => !prev);
    }

    return (
        <>
            <Modal
                isOpen={reactedModal}
                className="modal-dialog-centered modal-md"
                toggle={toggle}
            >
                <div className="modal-header">
                    <h5 className="modal-title my-0" role="button"> Reactions </h5>
                    <button
                        onClick={toggle}
                        type="button"
                        className="text-white btn-close"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody>
                    <div>
                        <List>
                            {reactions && reactions.length && reactions.map((type, index) => (
                                <ListItem
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        transition: "background-color 0.3s",
                                        "&:hover": {
                                            backgroundColor: "action.hover",
                                        },
                                        padding: "2px 15px",
                                    }}
                                >
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                        {type.media_url ? <Avatar alt="user-image" src={type.media_url} /> : <CustomAvatar name={type.sender_name[0]} />}
                                        <ListItemText primary={type.sender_name} />
                                    </Box>
                                    <div className='font-size-24px'>{type.react}</div>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </ModalBody>
            </Modal>
            <button onClick={toggle} id={`reaction_${msg.id}`} className="d-none">toggle</button>
        </>
    )
}
