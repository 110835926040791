import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import { GET_WINNERS_URL } from '../../../../API-Handle/services/auth_curd';
import { getValuebet } from '../../../../API-Handle/services/auth_curd';
import CustomWeekPicker from '../../../../Components/Calendar/CustomWeekPicker';
import { LinkToPlayerReport } from '../../../../Components/CustComp/LinkToPlayerReport';
import Spinner from '../../../../Components/CustLoader/Spinner';

export default function WeeklyWinners() {
    const [winners, setWinners] = useState(null)
    const [week, setWeek] = useState(null);
    const columns = [
        {
            name: 'Rank',
            selector: row => row.rank,
            minWidth:"55px"
        },
        {
            name: 'Name',
            selector: row => row.username,
            cell: row => <LinkToPlayerReport row={row} />,
            minWidth:"130px"
        },
        {
            name: 'Risk',
            selector: row => row.total_risk,
        },
        {
            name: 'To Win',
            selector: row => row.total_to_win,
        },
        {
            name: 'W/L',
            selector: row => row.wl_count,
        },
        {
            name: 'Results',
            selector: row => row.result,
        },
        {
            name: 'Weekly Leaderboard',
            selector: row => row.leaderboard_result,
        },
        {
            name: 'Weekly Coins Won',
            selector: row => row.is_final_report ? row.coin_won : "-",
        },
        {
            name: 'Total Daily Coins Won',
            selector: row => row.total_daily_coin_won,
        },
        {
            name: 'All-Time Winnings',
            selector: row => row.cumulative_winnings,
        },
        {
            name: 'All-Time Rank',
            selector: row => row.cumulative_rank,
        },
        {
            name: 'All-Time Coins Won',
            selector: row => row.cumulative_coin_won,
        }
    ];

    const getWeeklyWinners = async () => {
        setWinners(null)
        let request_url = GET_WINNERS_URL + `?report_type=Weekly&report_time=${week}`;
        let winners_list = await getValuebet(request_url);
        setWinners(winners_list);
    }

    useEffect(() => {
        if(week) getWeeklyWinners();
        // eslint-disable-next-line
    }, [week])

    return (
        <>
            <div className='inline-datepicker-container'>
                <CustomWeekPicker setWeek={setWeek} className={"settled-datepicker"} position={"bottom right"}/>
            </div>
            {winners ?
                <DataTable
                    columns={columns}
                    data={winners}
                    theme="dark"
                    dense={true}
                    header={false}
                    highlightOnHover={true}
                /> :
                <Spinner />}
        </>
    )
}
