import React, { useEffect, useState } from 'react'
import Winners from './Winners';
import { GET_WINNERS_REPORT, getValuebet } from '../../../../../API-Handle/services/auth_curd';

export default function Leaderboard({ club }) {
    const [activeTab, setActiveTab] = useState('Daily');
    // To avoid showing loader multipule time using below status 
    const [challengeDailyLeaderboard, setChallengeDailyLeaderboard] = useState(null);
    const [challengeWeeklyLeaderboard, setChallengeWeeklyLeaderboard] = useState(null);
    const [challengeMonthlyLeaderboard, setChallengeMonthlyLeaderboard] = useState(null);

    const fetchWinners = async () => {
        let res = await getValuebet(`${GET_WINNERS_REPORT}?report_type=${activeTab}`)
        if (res) {
            if (activeTab === 'Daily') setChallengeDailyLeaderboard(res)
            if (activeTab === 'Weekly') setChallengeWeeklyLeaderboard(res)
            if (activeTab === 'Monthly') setChallengeMonthlyLeaderboard(res)
        }
    }

    useEffect(()=>{
        fetchWinners();
        // eslint-disable-next-line
    }, [activeTab])

    return (
        <>
            <div className="card text-start bg-vb-dark mb-0">
                <div className="card-header seperater">
                    <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                            <span className={activeTab === 'Daily' ? "nav-link custom-active active bg-vb-dark font-size-12px" : "nav-link custom-deactive text-white font-size-12px"} role='button' onClick={() => setActiveTab('Daily')}>Daily</span>
                        </li>
                        <li className="nav-item">
                            <span className={activeTab === 'Weekly' ? "nav-link custom-active active bg-vb-dark font-size-12px" : "nav-link custom-deactive text-white font-size-12px"} role='button' onClick={() => setActiveTab('Weekly')}>Weekly</span>
                        </li>
                        <li className="nav-item">
                            <span className={activeTab === 'Monthly' ? "nav-link custom-active active bg-vb-dark font-size-12px" : "nav-link custom-deactive text-white font-size-12px"} role='button' onClick={() => setActiveTab('Monthly')}>Monthly</span>
                        </li>
                    </ul>
                </div>
                {activeTab === 'Daily' ?
                    <div className="card-body px-3 py-2 mt-1">
                        <Winners club={club} data={challengeDailyLeaderboard} type={"Daily"}/>
                    </div>
                : activeTab === 'Weekly' ?
                    <div className="card-body px-3 py-2 mt-1">
                        <Winners club={club} data={challengeWeeklyLeaderboard} type={"Weekly"}/>
                    </div>
                : activeTab === 'Monthly' ?
                    <div className="card-body px-3 py-2 mt-1">
                        <Winners club={club} data={challengeMonthlyLeaderboard} type={"Monthly"}/>
                    </div>
                : null}
            </div>
        </>
    )
}
