import React, { useContext, useEffect, useState } from 'react';
import PublicHeader from '../../../Components/PublicHeader';
import UserHeader from '../../../Components/UserHeader';
import ValuebetContext from '../../../context/Valuebet/ValuebetContext';
import Spinner from '../../../Components/CustLoader/Spinner';


const ShopifyComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { userDetails } = useContext(ValuebetContext);

  useEffect(() => {
    let script = null;
    // Function to load the Shopify script
    const loadShopifyScript = (callback) => {
      script = document.createElement('script');
      script.async = true;
      script.src = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
      script.onload = callback;
      document.head.appendChild(script);
    };

    // Function to initialize the Shopify Buy Button
    const ShopifyBuyInit = () => {
      const client = window.ShopifyBuy.buildClient({
        domain: '5f791b-dc.myshopify.com',
        storefrontAccessToken: '6f9722c7aff636e85a85c97a54926f51',
      });

      window.ShopifyBuy.UI.onReady(client).then((ui) => {
        ui.createComponent('product', {
          id: '7973998690444',
          node: document.getElementById('product-component-1729367263033'),
          moneyFormat: '%24%7B%7Bamount%7D%7D',
          options: {
            product: {
              styles: {
                product: {
                  '@media (min-width: 601px)': {
                    'max-width': '100%',
                    'margin-left': '0',
                    'margin-bottom': '50px',
                  },
                  'text-align': 'left',
                },
                title: {
                  'font-size': '26px',
                  color: '#ffffff',
                },
                button: {
                  'font-family': 'Roboto, sans-serif',
                  'font-weight': 'bold',
                  ':hover': {
                    'background-color': '#c43d00',
                  },
                  'background-color': '#da4400',
                  ':focus': {
                    'background-color': '#c43d00',
                  },
                  'border-radius': '5px',
                  'padding-left': '30px',
                  'padding-right': '30px',
                },
                price: {
                  'font-family': 'Roboto, sans-serif',
                  'font-size': '20px',
                  color: '#ededed',
                },
                compareAt: {
                  'font-family': 'Roboto, sans-serif',
                  'font-size': '17px',
                  color: '#ededed',
                },
                unitPrice: {
                  'font-family': 'Roboto, sans-serif',
                  'font-size': '17px',
                  color: '#ededed',
                },
                description: {
                  'font-family': 'Roboto, sans-serif',
                  color: "#e6e6e6"
                },
              },
              layout: 'horizontal',
              contents: {
                img: false,
                imgWithCarousel: true,
                description: true,
              },
              width: '100%',
              text: {
                button: 'Add to cart',
              },
              googleFonts: ['Roboto'],
            },
            productSet: {
              styles: {
                products: {
                  '@media (min-width: 601px)': {
                    'margin-left': '-20px',
                  },
                },
              },
            },
            modalProduct: {
              contents: {
                img: false,
                imgWithCarousel: true,
                button: false,
                buttonWithQuantity: true,
              },
              styles: {
                product: {
                  '@media (min-width: 601px)': {
                    'max-width': '100%',
                    'margin-left': '0px',
                    'margin-bottom': '0px',
                  },
                },
                button: {
                  'font-family': 'Roboto, sans-serif',
                  'font-weight': 'bold',
                  ':hover': {
                    'background-color': '#c43d00',
                  },
                  'background-color': '#da4400',
                  ':focus': {
                    'background-color': '#c43d00',
                  },
                  'border-radius': '5px',
                  'padding-left': '30px',
                  'padding-right': '30px',
                },
                title: {
                  'font-family': 'Helvetica Neue, sans-serif',
                  'font-weight': 'bold',
                  'font-size': '26px',
                  color: '#4c4c4c',
                },
                price: {
                  'font-family': 'Helvetica Neue, sans-serif',
                  'font-weight': 'normal',
                  'font-size': '18px',
                  color: '#4c4c4c',
                },
                compareAt: {
                  'font-family': 'Helvetica Neue, sans-serif',
                  'font-weight': 'normal',
                  'font-size': '15.3px',
                  color: '#4c4c4c',
                },
                unitPrice: {
                  'font-family': 'Helvetica Neue, sans-serif',
                  'font-weight': 'normal',
                  'font-size': '15.3px',
                  color: '#4c4c4c',
                },
                description: {
                  'font-family': 'Helvetica Neue, sans-serif',
                  'font-weight': 'normal',
                  'font-size': '14px',
                  color: '#4c4c4c',
                },
              },
              googleFonts: ['Roboto'],
              text: {
                button: 'Add to cart',
              },
            },
            option: {
              styles: {
                label: {
                  color: '#ffffff',
                },
              },
            },
            cart: {
              styles: {
                button: {
                  'font-family': 'Roboto, sans-serif',
                  'font-weight': 'bold',
                  ':hover': {
                    'background-color': '#c43d00',
                  },
                  'background-color': '#da4400',
                  ':focus': {
                    'background-color': '#c43d00',
                  },
                  'border-radius': '5px',
                },
              },
              text: {
                total: 'Subtotal',
                button: 'Checkout',
                noteDescription: 'Valuebet User ID',
              },
              contents:{
                note:true
              },
              note: userDetails ? userDetails.id : '',
              googleFonts: ['Roboto'],
            },
            toggle: {
              styles: {
                toggle: {
                  'font-family': 'Roboto, sans-serif',
                  'font-weight': 'bold',
                  'background-color': '#da4400',
                  ':hover': {
                    'background-color': '#c43d00',
                  },
                  ':focus': {
                    'background-color': '#c43d00',
                  },
                },
              },
              googleFonts: ['Roboto'],
            },
          },
        });
        setTimeout(()=> {setIsLoading(false)}, 1000)
      });
    };

    // Check if the ShopifyBuy object is loaded
    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadShopifyScript(ShopifyBuyInit);
      }
    } else {
      loadShopifyScript(ShopifyBuyInit);
    }

    // Cleanup when the component is unmounted
    return () => {
      console.log("watch...")
      // Find all iframes using DOM traversal
      const iframes = document.getElementsByTagName('iframe');

      // Remove each iframe from its parent
      for (let i = iframes.length - 1; i >= 0; i--) {
        iframes[i].parentNode.removeChild(iframes[i]);
      }
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array ensures useEffect only runs once

  return (
    <div id='public-page' className='BG-valuebet'>
      {localStorage.getItem("authToken") ?
        <UserHeader /> :
        <PublicHeader />}
      <div className='layout-mt-75-112'>
        {isLoading && <Spinner /> }
        <div className='vb_product' id="product-component-1729338460655" />
      </div>
    </div>
  )
}

export default ShopifyComponent;
