import React, { useEffect, useState } from "react";
import ReplyIcon from "@mui/icons-material/Reply";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SeenByModel from "./SeenByModel";
import ReactionModal from "./ReactionModal";
import { GET_MESSAGE_REACTION, getValuebet } from "../../../../../API-Handle/services/auth_curd";

const MessageOptions = ({ isRight, msg, handleMessageCopy, handleMessageReply, handleUnsendMessage }) => {
  const [reactions, setReactions] = useState([])

  useEffect(() => {
    if (msg.reaction_count > 5){
      fetchMessageSeenByMember();
    }else{
      setReactions(msg.reactions)
    }
    // eslint-disable-next-line 
  }, [msg])

  const fetchMessageSeenByMember = async () => {
    let URL = `${GET_MESSAGE_REACTION}${msg.id}`;
    const msg_reactions = await getValuebet(URL);
    if (msg_reactions) setReactions(msg_reactions);
  }

  return (
    <>
      {isRight && <SeenByModel msg={msg} />}
      <ReactionModal msg={msg} reactions={reactions} />
      <div className="message-options font-size-16px">
        <div
          className="icon-button message-action-hoverable mb-3"
          onClick={() => handleMessageReply(msg)}
        >
          <ReplyIcon className="me-3" /> Reply
        </div>
        <div
          className="icon-button message-action-hoverable mb-3"
          onClick={() => handleMessageCopy(msg)}
        >
          <FileCopyIcon className="me-3" /> Copy
        </div>
        {isRight && (
          <>
            <div
              className="icon-button message-action-hoverable mb-3"
              onClick={() => handleUnsendMessage(msg)}
            >
              <DeleteIcon className="me-3 " /> Unsend
            </div>
            <div
              className="icon-button message-action-hoverable"
              onClick={() => {
                document.getElementById(`seenby_${msg.id}`)?.click();
              }}
            >
              <VisibilityIcon className="me-3 " /> Seen By
            </div>
          </>
        )}

        {reactions.length > 0 && (
          <>
            <hr />
            <div
              onClick={() => document.getElementById(`reaction_${msg.id}`)?.click()}
              role="button"
            >
              {`${reactions.length} Reactions`}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MessageOptions;
