import React from "react";
import { Collapse } from "reactstrap";
import {List } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import { collapsePersonalChannel } from "../../../../../redux/services/chatServices";
import NoData from "../DrawerCommon/NoData";
import SearchMembers from "./SearchMembers";
import DirectItem from "./DirectItems";

function DirectChannel() {
  const dispatch = useDispatch();
  const { selectedClub, allPersonalChat, isPersonalChannel } = useSelector((state) => state.clubChat);
  
  return (
    <div className="position-relative">
      <div className="px-3 py-2">
        {/* Direct Message */}
        <div
          onClick={() => {
            dispatch(
              collapsePersonalChannel(isPersonalChannel, selectedClub.id)
            );
          }}
          className="d-flex p-2"
        >
          {isPersonalChannel ? (
            <KeyboardArrowDownIcon className="me-2" />
          ) : (
            <ChevronRightIcon className="me-2" />
          )}
          Direct Message
        </div>
        <Collapse isOpen={isPersonalChannel}>
          <div className="ps-4">
            <SearchMembers />
            <div>
              {allPersonalChat && allPersonalChat.length > 0 ? (
                allPersonalChat.map((item, index) => (
                  <List key={index}>
                    <DirectItem key={index} item={item} />
                  </List>
                ))
              ) : (
                <NoData msg={"No Personal Chat Found yet.."} />
              )}
            </div>
          </div>
        </Collapse>

      </div>
    </div>
  );
}

export default DirectChannel;
