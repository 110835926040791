import React, { useEffect, useState } from 'react'
import { putValuebet, SUB_AGENT_URL } from '../../../../../../API-Handle/services/auth_curd'
import { errorNotification, successNotification } from '../../../../../../Components/CustComp/PPHAlert'
import { Link } from 'react-router-dom'
import Switch from 'react-switch'

export default function AgentPermission({ item }) {

    const [superAgents, setSuperAgents] = useState([])
    const [agentID, setAgentID] = useState('')
    const [checked, setChecked] = useState(false)

    const fetchSupers = () => {
        let supagt = item.agents.filter((val) => val.more_info.issuper)
        setSuperAgents(supagt)
        if (item.agents.length === supagt.length) {
            setChecked(true)
        } else {
            setChecked(false)
        }
    }

    useEffect(() => {
        fetchSupers()
    }, [])

    const updatePermission = async (agent_id, action) => {
        if (agent_id == '') {
            errorNotification("Please enter Agent ID")
            return
        }
        let data = {
            agent_id,
            club_id: item.id,
            action
        }
        setAgentID("");
        let res = await putValuebet(`${SUB_AGENT_URL}/${agent_id}`, data);
        if (res) {
            successNotification(res.message);
            if (agent_id !== "ALLAGTID") {
                item.agents.map((val) => {
                    if (agent_id == val.id) {
                        if (action == "add") val.more_info.issuper = true;
                        if (action == "remove") val.more_info.issuper = false;
                    }
                })
            } else {
                item.agents.map((val) => {
                    if (action == "add") val.more_info.issuper = true;
                    if (action == "remove") val.more_info.issuper = false;
                })
            }
            fetchSupers()
        }
    }

    return (
        <div>
            <div className="tab-content">
                <div className="tab-pane fade active show" id="account-general">
                    <div className="card-body">
                        <div className="form-group">
                            <label className="form-label">Agents permission for accepting applications</label>
                            <div className='row mt-2 mb-3'>
                                <div className='col-md-12'>
                                    <Switch
                                        checked={checked}
                                        onChange={() => updatePermission("ALLAGTID", checked ? "remove" : "add")}
                                        onColor="#ffac8b"
                                        onHandleColor="#e03f00"
                                        handleDiameter={30}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch mb-1"
                                        id="material-switch"
                                    />
                                    <p>
                                        If Turn-On then club's agent can accept an application whose referral ID of the application contains the ID of the agent accepting the application.
                                    </p>
                                </div>
                            </div>
                            {!checked &&
                                <>
                                    <label className="form-label">For custom agent permission add agents here</label>
                                    <div className='row mb-3'>
                                        <div className='col-md-8'>
                                            <input type="text" className="form-control mb-1 bg-dark text-white form-control-sm" onChange={(e) => setAgentID(e.target.value)} value={agentID} placeholder='Agent UID here' />
                                        </div>
                                        <div className='col-md-4'>
                                            <button className="btn btn-outline-danger btn-sm" onClick={() => updatePermission(agentID, "add")}>
                                                + Add
                                            </button>
                                        </div>
                                    </div>
                                    {superAgents.length ?
                                        <ul className="list-group">
                                            {superAgents.map((supAg, indx) => {
                                                return (
                                                    <li key={indx} className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                                                        {supAg.username}
                                                        <span>{supAg.email}</span>
                                                        <span role='button' className="badge bg-danger badge-pill" onClick={() => updatePermission(supAg.id, "remove")}>Remove</span>
                                                    </li>
                                                )
                                            })}
                                        </ul> :
                                        <div className="alert alert-dark bg-dark text-white mt-3">
                                            No super agents available yet
                                        </div>}
                                </>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
