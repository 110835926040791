import React, { useState } from 'react'

export default function MainTabReport() {
    return (
        <>
            <p className="card-text">Your final report including your all agents and players.</p>
            <div className="row">
                <div className="col-md-12 mb-2">
                    <ul className="list-group">
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Vig Back %
                            <span className="badge bg-danger badge-pill">60%</span>
                        </li>
                    </ul>
                </div>
                <div className="col-md-6 mb-2">
                    <ul className="list-group">
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Total Player Volume
                            <span className="badge bg-danger badge-pill">2523</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Total Player Winnings
                            <span className="badge bg-danger badge-pill">57</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Total Player Losses
                            <span className="badge bg-danger badge-pill">14</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Net Player Win/Loss
                            <span className="badge bg-danger badge-pill">2</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Total Player Vig
                            <span className="badge bg-danger badge-pill">17</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Total Vig Back
                            <span className="badge bg-danger badge-pill">117</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Grand Total
                            <span className="badge bg-danger badge-pill">1765</span>
                        </li>
                    </ul>
                </div>
                <div className="col-md-6 mb-2">
                    <ul className="list-group">
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Main Player Volume
                            <span className="badge bg-danger badge-pill">1523</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Main Player Winnings
                            <span className="badge bg-danger badge-pill">37</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Main Player Losses
                            <span className="badge bg-danger badge-pill">10</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Net Win/Loss
                            <span className="badge bg-danger badge-pill">1</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Total Vig
                            <span className="badge bg-danger badge-pill">10</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Total Back
                            <span className="badge bg-danger badge-pill">110</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Sub Total
                            <span className="badge bg-danger badge-pill">1100</span>
                        </li>
                    </ul>
                </div>
                <div className="col-md-12">
                    <ul className="list-group">
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Sub Player Volume
                            <span className="badge bg-danger badge-pill">523</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Sub Player Winnings
                            <span className="badge bg-danger badge-pill">17</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Sub Player Losses
                            <span className="badge bg-danger badge-pill">5</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Net Win/Loss
                            <span className="badge bg-danger badge-pill">1</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Total Vig
                            <span className="badge bg-danger badge-pill">7</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Sub-Agent Vig Back
                            <span className="badge bg-danger badge-pill">95</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Agent Vig Back
                            <span className="badge bg-danger badge-pill">13</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Total Vig Back
                            <span className="badge bg-danger badge-pill">3</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center bg-dark">
                            Sub-Total
                            <span className="badge bg-danger badge-pill">165</span>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
