import React from "react";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  handleDrawerToggle,
  toggleAddChannel,
} from "../../../../../redux/services/chatServices";

function PublicChatAction() {
  const dispatch = useDispatch();
  const { isAddChannel, isDrawerOpen, mobileOpen } = useSelector(
    (state) => state.clubChat
  );

  return (
    <>
      <span className="dropdown">
        <UncontrolledButtonDropdown>
          <DropdownToggle color="none" className="btn-sm text-nowrap">
            <i className="fa fa-ellipsis-v text-white" aria-hidden="true"></i>
          </DropdownToggle>
          <DropdownMenu end className="club-actions">
            <DropdownItem
              className="text-white"
              onClick={() => {
                dispatch(handleDrawerToggle(isDrawerOpen, mobileOpen));
                dispatch(toggleAddChannel(isAddChannel));
              }}
            >
              <i className="fa fa-user-plus"></i> Add Channel
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </span>
    </>
  );
}

export default React.memo(PublicChatAction);
