import Swal from 'sweetalert2'

export const PaymentConfirm = async () => {
    let result = await Swal.fire({
        title: `<h4 class="valuebet-bg-color rounded pb-1">Payment Instructions</h4>`,
        html: `
        <div class="text-start">
            <h6>You will be taken to a NOWpayment screen with instructions for send a crypto payment. Please Note:</h6>
            <div class="font-size-15px fw-lighter fst-italic ms-2">
                <p>1. You can choose any Coins to pay with.</p>                    
                <p>2. Please send exact amount to avoid delivery issues.</p>                    
                <p>3. You will be redirected from NOWpayment back to our site once payment is confirmed.</p>                    
                <p>4. Depending on the coin you choose as payment, NETWORK FEES may require a higher minimum purchase. You can <a class="text-decoration-none text-primary" href="https://nowpayments.io/status-page" target="_blank">check here</a> by choosing USDC Solana as the 'From' and your payment currency as 'To'.</p>
            </div>
        </div>
        `,
        // customClass:{
        //     cancelButton: "btn btn-sm btn-secondary",
        //     confirmButton: "btn btn-sm btn-success"
        // },
        showCancelButton: true,
        confirmButtonColor: "green",
        cancelButtonColor: "gray",
        confirmButtonText: "Ok",
        reverseButtons: true
    })
    
    let is_confirmed = result.isConfirmed;
    return is_confirmed
}