import React, { useEffect, useState } from "react";
import CustomAvatar from "./CustomAvatar";

const Reactions = ({msg, onReactSend, isLeft }) => {
  const [reactions, setReactions] = useState({});

  useEffect(() => {
    const finalData = {};
    for (const ele of msg.reactions) {
      if (Object.keys(finalData).includes(ele.react)){
        finalData[ele.react].push(ele);
      }else{
        finalData[ele.react] = [ele];
      }
    }
    setReactions(finalData)
  }, [msg])

  return (
    <>
      {Object.keys(reactions).length > 0 && <div className="reacted-container mt-2">
        {Object.keys(reactions).map((key, index) => (
          <div
            className={`reacted-item ${isLeft ? "left-reaction" : "right-reaction"
              }`}
            key={index}
            role="button"
            onClick={(e) => {
              onReactSend(key);
              e.stopPropagation();
            }}
          >
            <span className="font-size-20px">
              {key}
            </span>
            <CustomAvatar data={reactions[key]} />
          </div>
        ))}
      </div>}
    </>
  );
};

export default Reactions;
