import React, { useEffect, useState } from 'react'
import { MY_TOKEN_URL, UNASSIGN_TOKEN_URL, getValuebet, postValuebet } from '../../../../../API-Handle/services/auth_curd';
import moment from 'moment';
import CopyText from '../../../../../Components/CustComp/CopyText';
import TransferToken from './TransferToken'
import LTD from './LTD'
import { successNotification } from '../../../../../Components/CustComp/PPHAlert';
import { DefaultConfirm } from '../../../../../Components/SweetAlerts/DefaultConfirm';
import BuyTokens from './BuyTokens';
import ListLoader from '../../../../../Components/CustLoader/ListLoader';

export default function Index() {
  const [tokens, setTokens] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  const fetchTokens = async () => {
    setIsLoading(true)
    let res = await getValuebet(MY_TOKEN_URL);
    if (res) {
      setTokens(res);
    }
    setIsLoading(false)
  }

  const unasignToken = async (token_id) => {
    let message = `<p class="font-size-14px text-warning">Please NOTE: unassigning a club will freeze club activities until reassigned a Lifetime Deal again. </p> Do you want to unassign token?`;
    let confirmation = await DefaultConfirm("Lifetime Deal", message)
    if (confirmation) {
      let res = await postValuebet(`${UNASSIGN_TOKEN_URL}${token_id}`, {})
      if (res) {
        successNotification(res.message)
        fetchTokens()
      }
    }
  }

  useEffect(() => {
    fetchTokens();
  }, [])

  return (
    <>
      <div className='d-flex justify-content-between align-items-center text-white mb-3 mt-1'>
            <div>
                <span>Total Tokens - </span>
                <span className='border border-dark rounded p-2 ms-2'>{tokens.length}</span>
            </div>
            <BuyTokens />
        </div>
      <div className='row'>
        <div className='col-md-12'>
        {!isLoading ? tokens.length ?
          <div className="overflow-x">
            <table className="table table-sm table-custom-dark">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col" className="text-nowrap">Token ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Airdrops</th>
                  <th scope="col">From</th>
                  <th scope="col">LTD</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {tokens.map((tok) => {
                  return (
                    <tr key={tok.id}>
                      <td className='w-md'>{moment(tok.date_received).format('MMM Do YYYY, hh:mm:ss A')}</td>
                      <td className='text-nowrap pe-3'> {tok.id.substring(0, 8)}<CopyText text={tok.id} /> </td>
                      <td className='w-lg pe-2'>{tok.token_name}</td>
                      <td className='w-lg pe-2'>{tok.token_description}</td>
                      <td className='text-nowrap pe-3'>{tok.airdrops}</td>
                      <td className='text-nowrap pe-3'>{tok.last_owner_id ? `${tok.last_owner_username} (${tok.last_owner_id})` : 'Valuebet.App'}</td>
                      <td className='text-nowrap pe-3'>
                        {tok.is_lifetime_deal ?
                          <>
                            {tok.assigned_to_club_name ? tok.assigned_to_club_name : 'N/A'}
                            {tok.assigned_to_club_name ?
                              <button className='transfer-action-button btn-outline-custom-orange ms-2' onClick={() => unasignToken(tok.id)}>-</button>
                              : <LTD token={tok} fetchTokens={fetchTokens} />}
                          </>
                          : 'No LTD'}
                      </td>
                      <td> <TransferToken token={tok} fetchTokens={fetchTokens} /> </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          : <div className="alert alert-dark" role="alert">
            No tokens own!
          </div>
          : <ListLoader rowsCount={5} headHeight={60}rowsHeight={45}/>}
        </div>
      </div>
    </>
  )
}
