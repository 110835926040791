import React, { useContext, useEffect } from 'react'
import { sportsbookAction } from "../../../../redux/features/sportsbookSlice";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { GET_TEASER_SPORTS, getValuebet } from "../../../../API-Handle/services/auth_curd";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import ValuebetContext from '../../../../context/Valuebet/ValuebetContext';

export default function SelectTeaser() {
    const { resetTeaserOnPointChange, getSports } = useContext(ValuebetContext);
    const { teaserPoints, selectedTeaserPoints } = useSelector(state => state.sportBook)
    const dispatch=useDispatch();
    const location = useLocation();

    const getTeaserName = async () => {
        let res = await getValuebet(GET_TEASER_SPORTS);
        if (res) {
            res = res.sort((a, b) => a.teaser_points - b.teaser_points);
            dispatch(sportsbookAction.setTeaserPoints({ data: res }))
        }
    };

    const handleTeaserChange = (e) => {
        resetTeaserOnPointChange();
        const selectedPoints = teaserPoints.find((item) => item.id === e.target.value);
        dispatch(sportsbookAction.setSelectedTeaserPoints({ data: selectedPoints }))
    };

    useEffect(() => {
        if (selectedTeaserPoints){
            let club_id = location?.state?.club?.id;
            getSports(club_id, selectedTeaserPoints?.id);
        }
    // eslint-disable-next-lin
    }, [selectedTeaserPoints])

    useEffect(() => {
        resetTeaserOnPointChange();
        getTeaserName()
        return () => getSports(location?.state?.club?.id);
    // eslint-disable-next-line
    }, []);

    return (
        <FormControl className="mt-3 mb-2" sx={{ minWidth: 200, maxWidth:"95%" }} variant="outlined">
            <InputLabel id="api-select-label" sx={{ top: -8 }}>Select Teaser</InputLabel>
            <Select
                labelId="api-select-label"
                value={selectedTeaserPoints ? selectedTeaserPoints.id : ''}
                onChange={handleTeaserChange}
                label="Select Option"
                sx={{ height: 40, fontSize:14 }}
            >
                {teaserPoints.map((option) => (
                    <MenuItem key={option.id} value={option.id} sx={{fontSize:14}}>
                        {`${option.teaser_points} Point ${option.is_super_teaser ? " Super Teaser" : " Teaser"} ${option.leagues.includes("NBA") ? " - Basketball" : " - Football"} ${option.is_super_teaser ? " (3 Teams)" : ""}`}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
