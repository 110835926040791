import React, { useEffect, useState, useContext } from 'react'
import {
    Modal,
    ModalBody
} from "reactstrap";
import ValuebetContext from '../../../../context/Valuebet/ValuebetContext';
import Coins from './Coins'
import Tokens from './Tokens'
import { shrinkNumber } from '../../../../Utils/comman';

export default function Index() {
    const [walletModal, setWalletModal] = useState(false);
    const { userDetails, getUserInfo } = useContext(ValuebetContext)
    const [activeTab, setActiveTab] = useState(1);

    const toggleWalletModal = () => {
        setWalletModal(!walletModal)
    }

    useEffect(() => {
        getUserInfo()
    }, [])

    return (
        <>
            <Modal isOpen={walletModal} toggle={toggleWalletModal} className="modal-dialog-centered modal-xl">
                <div className="modal-header">
                    <h5 className="modal-title my-0">Manage Valuebet Currencies & Tokens</h5>
                    <button onClick={toggleWalletModal} type="button" className="text-white btn-close" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody>
                    <div className="card text-start bg-vb-dark">
                        <div className="card-header seperater">
                            <ul className="nav nav-tabs card-header-tabs">
                                <li className="nav-item">
                                    <span className={activeTab === 1 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"} role='button' onClick={() => setActiveTab(1)}>Coins</span>
                                </li>
                                <li className="nav-item">
                                    <span className={activeTab === 2 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"} role='button' onClick={() => setActiveTab(2)}>Tokens</span>
                                </li>
                            </ul>
                        </div>
                        {activeTab === 1 ?
                            <div className="card-body pb-0">
                                <Coins userDetails={userDetails} />
                            </div>
                            : activeTab === 2 ?
                                <div className="card-body pb-0">
                                    <Tokens />
                                </div>
                                : null}
                    </div>
                </ModalBody>
            </Modal>
            <span onClick={toggleWalletModal} className="coin-wallet-valubet" role='button'>
                {userDetails ? shrinkNumber(userDetails.coins) : "00"} &nbsp; <i className="fas fa-coins"></i>
            </span>
        </>
    )
}
