import React, { useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check';
import { GET_SEENBY_MEMBERS, getValuebet } from '../../../../../API-Handle/services/auth_curd';
import { Modal, ModalBody } from 'reactstrap';
import SmSpinner from '../../../../../Components/CustLoader/SmSpinner';
import { Box, ListItem, ListItemText } from '@mui/material';
import CustomAvatar from './CustomAvatar';

export default function SeenByModel({ msg }) {
    const [seenByModal, setSeenByModal] = useState(false);
    const [seenByData, setSeenByData] = useState(null);

    const toggleSeenBy = () => {
        setSeenByModal((prev) => !prev);
    }

    useEffect(() => {
        if (seenByModal) fetchMessageSeenByMember();
    // eslint-disable-next-line 
    }, [msg, seenByModal])

    const fetchMessageSeenByMember = async () => {
        let URL = `${GET_SEENBY_MEMBERS}${msg.id}`;
        const seenByMembers = await getValuebet(URL);
        if (seenByMembers) setSeenByData(seenByMembers);
    }

    return (
        <>
            <Modal
                isOpen={seenByModal}
                className="modal-dialog-centered modal-md"
                toggle={toggleSeenBy}

            >
                <div className="modal-header">
                    <h5 className="modal-title my-0" role="button"> Seen By</h5>
                    <button
                        onClick={toggleSeenBy}
                        type="button"
                        className="text-white btn-close"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody>
                    <div>
                        {seenByData ?
                            seenByData.length ?
                                seenByData.map((type, index) => (
                                    <ListItem
                                        key={index}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            cursor: "pointer",
                                            transition: "background-color 0.3s",
                                            "&:hover": {
                                                backgroundColor: "action.hover",
                                            },
                                            padding: "2px 15px",
                                        }}
                                    >
                                        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                            <CustomAvatar name={type.username[0]} />
                                            <ListItemText primary={type.username} />
                                        </Box>
                                        <CheckIcon style={{ fontSize: "24px", color: "green" }}/>
                                    </ListItem>
                                )) :
                                <h6 className='text-center'>No one has seen this message yet</h6> :
                            <SmSpinner my={"0"} py={"2"} />
                        }
                    </div>
                </ModalBody>
            </Modal>
            <button onClick={toggleSeenBy} id={`seenby_${msg.id}`} className="d-none">toggle</button>
        </>
    )
}
