import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import Popup from "reactjs-popup";
import { postValuebet, UPDATE_PASSWORD } from '../../../API-Handle/services/auth_curd'
import { successNotification, errorNotification } from '../../../Components/CustComp/PPHAlert';
import { manageAllInputsErrors, beforePassUpdate } from '../../../Utils/comman';

export default class PasswordPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            input_errors: {
                npass: '',
                cnpass: '',
            },
            npass: '', cnpass: '',
            is_field_tracker_on: false
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) => {
        if (this.state.is_field_tracker_on) {
            const { npass_error, cnpass_error } = manageAllInputsErrors(e);
            this.setState({ input_errors: { npass: npass_error, cnpass: cnpass_error } })
        }
        this.setState({ [e.target.name]: e.target.value })
    }

    async updatePassword() {
        const { npass_error, cnpass_error } = beforePassUpdate(this.state.npass, this.state.cnpass);
        this.setState({ input_errors: { npass: npass_error, cnpass: cnpass_error } })
        if (npass_error === '' && cnpass_error === '') {
            if (this.state.npass !== this.state.cnpass) {
                errorNotification("New Password and Confirm New Password doesn't match.")
                return
            }
            let data = { cnpass: this.state.cnpass }
            let res = await postValuebet(UPDATE_PASSWORD, data);
            if (res) {
                successNotification(res.message);
                this.setState({ npass: '', cnpass: '' });
                document.getElementById('passupdt').click();
            }
        } else {
            this.setState({ is_field_tracker_on: true });
        }
    }

    render() {
        return (
            <div>
                <div className='popup-btn-prfl'>
                    <Popup
                        contentStyle={{ background: "#0b0b0b", border: "none" }}
                        overlayStyle={{ background: "black" }}
                        trigger={<button id='passupdt' className='mt-2 btn btn-danger btn-sm btn-orange' type="button"> Change </button>} modal>
                        {close => (
                            <div className='popup-container'>
                                <span className='clz-btn' onClick={close}> &times;</span>
                                <div className='p-3'>
                                    <form>
                                        <div>
                                            <label className='text-secondary' htmlFor="npass">New Password</label>
                                            <input type="password" name="npass" id="npass" className='form-control form-control-sm bg-dark text-white border-secondary'
                                                onChange={this.handleChange} value={this.state.npass}
                                                placeholder="" />
                                        </div>
                                        {this.state.input_errors.npass ? <div className='inputerrors'> {this.state.input_errors.npass} </div> : null}
                                        <div className="mt-3" >
                                            <label className='text-secondary' htmlFor="cnpass">Confirm New Password</label>
                                            <input type="password" name="cnpass" id="cnpass" className='form-control form-control-sm bg-dark text-white border-secondary'
                                                onChange={this.handleChange} value={this.state.cnpass}
                                                placeholder="" />
                                        </div>
                                        {this.state.input_errors.cnpass ? <div className='inputerrors'> {this.state.input_errors.cnpass} </div> : null}
                                        <Row className="form-group mt-4">
                                            <Col md="12" className="text-center">
                                                <Button onClick={() => this.updatePassword()}
                                                    className="btn btn-danger btn-sm btn-orange w-md" type="button">Update
                                                </Button>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>)}
                    </Popup>
                </div>
            </div>
        )
    }
}
