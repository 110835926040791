import React from 'react'
import BetaInfo from './BetaInfo'
import InfoPopup from './InfoPopup'

export default function Index() {
  return (
    <div>
      {/* <BetaInfo /> */}
      <InfoPopup />
    </div>
  )
}
