import React from 'react'
import ScrollToTop from '../../../Components/CustComp/ScrollToTop'
import PublicHeader from '../../../Components/PublicHeader'
import PublicFooter from '../../../Components/PublicFooter'

export default function index() {
  return (
    <div id='public-page' className='BG-valuebet'>
      <ScrollToTop />
      <PublicHeader />
      <div className='layout-mt-112 container-fluid pretty-card'>
        <h2 className="reguler-heading">Privacy Policy for <span className='vb-text-orange'>Valuebet.app</span></h2>
        <div className='letter-space'>
          <p className='mb-3 font-size-16px'>**Last Updated: [26/01/2024]**</p>
          <p>Welcome to Valuebet.app ("we", "us", "our"). We respect your privacy and are committed to protecting your personal data. This Privacy Policy will inform you about how we look after your personal data when you visit our app and tell you about your privacy rights and how the law protects you.</p>

          <h6 className='vb-text-orange'>1. Important Information and Who We Are</h6>
          <p>This Privacy Policy aims to give you information on how Valuebet.app collects and processes your personal data through your use of this app, including any data you may provide through the app when you sign up, place a bet, or use our services.</p>

          <h6 className='vb-text-orange'>2. Data We Collect About You</h6>
          <p>We may collect, use, store, and transfer different kinds of personal data about you which we have grouped together as follows: </p>
          <div className='ps-2 pb-4'>
            <div><span className='text-warning'> - Identity Data</span> includes first name, last name, username or similar identifier.</div>
            <div><span className='text-warning'> - Contact Data</span> includes billing address, email address, and telephone numbers.</div>
            <div><span className='text-warning'> - Financial Data</span> includes bank account and payment card details.</div>
            <div><span className='text-warning'> - Transaction Data</span> includes details about payments to and from you and other details of services you have purchased from us.</div>
          </div>


          <h6 className='vb-text-orange'>3. How Is Your Personal Data Collected?</h6>
          <p>We use different methods to collect data from and about you including through: </p>
          <div className='ps-2 pb-4'>
            <div> <span className='text-warning'> - Direct interactions:</span> You may give us your Identity, Contact, and Financial Data by filling in forms or by corresponding with us by post, phone, email, or otherwise. </div>
            <div><span className='text-warning'> - Automated technologies or interactions:</span> As you interact with our app, we may automatically collect Technical Data about your equipment, browsing actions, and patterns. </div>
          </div>

          <h6 className='vb-text-orange'>4. How We Use Your Personal Data</h6>
          <p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances: </p>
          <div className='ps-2 pb-4'>
            <div> - Where we need to perform the contract we are about to enter into or have entered into with you. </div>
            <div> - Where it is necessary for our legitimate interests and your interests and fundamental rights do not override those interests. </div>
            <div> - Where we need to comply with a legal obligation. </div>
          </div>

          <h6 className='vb-text-orange'>5. Disclosures of Your Personal Data</h6>
          <p>We may share your personal data with external third parties, such as service providers, regulatory authorities, or as part of legal processes.</p>

          <h6 className='vb-text-orange'>6. Data Security</h6>
          <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used, or accessed in an unauthorized way, altered, or disclosed. </p>

          <h6 className='vb-text-orange'>7. Data Retention</h6>
          <p>We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>

          <h6 className='vb-text-orange'>8. Your Legal Rights</h6>
          <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data, such as the right to request access, correction, erasure, or transfer of your personal data.</p>

          <h6 className='vb-text-orange'>9. Changes to This Privacy Policy</h6>
          <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

          <h6 className='vb-text-orange'>10. Contact Us</h6>
          <p>If you have any questions about this Privacy Policy, please contact us at <span className='text-info'>support@valuebet.app</span></p>
        </div>
      </div>
      <PublicFooter />
    </div>
  )
}