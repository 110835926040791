import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken, isSupported } from "firebase/messaging";
import { REACT_APP_VAPID_KEY } from "../constant"
import { UPDATE_SUBSCRIPTION, postValuebet } from "../API-Handle/services/auth_curd";
import { errorNotification } from "../Components/CustComp/PPHAlert";

// Initialize Firebase
const app = initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
});

let messaging = null;

// Check if messaging is supported in the current browser
const initializeMessaging = async () => {
    const isMessagingSupported = await isSupported();
    if (isMessagingSupported) {
        messaging = getMessaging(app);
        onMessage(messaging, (payload) => {
            console.log('Message received: ', payload);
            // infoNotification(payload.notification.body);
            // Add notification display logic here
        });
    } else {
        console.log("This browser doesn't support Firebase Cloud Messaging.");
    }
};

initializeMessaging();

const requestPermission = async (show_error) => {
    let permission_status = false
    if ('Notification' in window) {
        let permission = await Notification.requestPermission()
        if (permission === 'granted') {
            permission_status = true
            console.log('Permission granted');
        } else {
            console.log('Permission denied');
            if (show_error) errorNotification("Please go to your browser's site settings and enable notifications for this site to receive updates and alerts.");
        }
    } else {
        console.log('Push Notifications are not supported in this browser.');
        if (show_error) errorNotification("Push Notifications are not supported in this browser.")
    }
    return permission_status;
}

const getFCMToken = async (show_error = false, timeout = 10000) => {
    let token = null;
    let permission_status = await requestPermission(show_error);

    if (permission_status && messaging) {
        try {
            const tokenPromise = getToken(messaging, { vapidKey: REACT_APP_VAPID_KEY });
            const timeoutPromise = new Promise((_, reject) =>
                setTimeout(() => reject(new Error('Timeout')), timeout)
            );
            const fcm_token = await Promise.race([tokenPromise, timeoutPromise]);
            token = fcm_token;
        } catch (err) {
            if (err.message === 'Timeout') {
                console.log('Token retrieval timed out.');
            } else {
                console.log('An error occurred while retrieving token. ', err);
            }
            if (show_error) errorNotification("The browser is encountering issues when trying to retrieve the FCM token.")
        }
    }
    return token;
};

const updateSubscription = async (subscription) => {
    let fcm_token = await getFCMToken()
    if (fcm_token) {
        console.log('notification status updating on server...');
        await postValuebet(UPDATE_SUBSCRIPTION, { fcm_token, subscription })
    } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
    }
}

const registerServiceWorker = async () => {
    try {
        let registration = await navigator.serviceWorker.register('firebase-messaging-sw.js')
        console.log('Service Worker registered with scope:', registration.scope);
    } catch (err) {
        console.log('Service Worker registration failed:', err);
    }
}

export { registerServiceWorker, updateSubscription, getFCMToken };