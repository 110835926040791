import DataTable from 'react-data-table-component-footer';
import React from 'react';
import PlayerReport from './PlayerReport';

const columns = [
    {
        name: 'Agent',
        selector: row => row.agent,
        width: "150px",
        sortable: true
    },
    {
        name: 'Vig Back %',
        selector: row => row.year,
        sortable: true
    },
    {
        name: 'Total Volume',
        selector: row => row.year,
        sortable: true
    },
    {
        name: 'Total Winnings',
        selector: row => row.year,
        sortable: true
    },
    {
        name: ' Total Losses',
        selector: row => row.year,
        sortable: true
    },
    {
        name: 'Win/Loss Net',
        selector: row => row.year,
        sortable: true
    },
    {
        name: 'Tier1 Total Vig',
        selector: row => row.year,
        sortable: true
    },
    {
        name: 'Tier2 Total Vig',
        selector: row => row.year,
        sortable: true
    },
    {
        name: 'Total Vig',
        selector: row => row.year,
        sortable: true
    },
    {
        name: 'Tier1 Vig Back',
        selector: row => row.year,
        sortable: true
    },
    {
        name: 'Tier2 Vig Back',
        selector: row => row.year,
        sortable: true
    },
    {
        name: 'Total Vig Back',
        selector: row => row.year,
        sortable: true
    },
    {
        name: 'Grand Total',
        selector: row => row.year,
        sortable: true
    }
];

const data = [
    {
        id: 1,
        agent: 'Beetlejuice',
        year: '1988',
    },
    {
        id: 2,
        agent: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 3,
        agent: 'demo1',
        year: '1984',
    },
    {
        id: 4,
        agent: 'demo2',
        year: '1984',
    },
    {
        id: 5,
        agent: 'demo3',
        year: '1984',
    },
    {
        id: 6,
        agent: 'demo4',
        year: '1984',
    },
    {
        id: 7,
        agent: 'demo5',
        year: '1984',
    },
    {
        id: 8,
        agent: 'demo6',
        year: '1984',
    },
    {
        id: 9,
        agent: 'demo7',
        year: '1984',
    },
    {
        id: 10,
        agent: 'demo8',
        year: '1984',
    },
    {
        id: 11,
        agent: 'demo9',
        year: '1984',
    },
]

export default function AgentsTabReport() {
    return (
        <div className='no-tbl-header'>
            <DataTable
                columns={columns}
                data={data}
                expandableRows
                expandableRowsComponent={<PlayerReport/>}
                theme="dark"
                dense={true}
                highlightOnHover={true}
                expandOnRowClicked={true}
                footer={{ agent: "Total Agents (11)", year: 3625 }}
            />
        </div>
    );
};