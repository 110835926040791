import React, { useEffect, useState } from 'react'
import {
    Button,
    Modal,
    ModalBody
} from "reactstrap";
import { getValuebet, GET_CHIPS_PRICING_URL } from '../../../../../../API-Handle/services/auth_curd'
import Plan from './Plan';

export default function BuyChips({club}) {
    const [buyModal, setBuyModal] = useState(false);
    const [pricing, setPricing] = useState([]);

    const toggleBuyModal = () => {
        setBuyModal(!buyModal)
    }

    const getPricingList = async () => {
        let res = await getValuebet(GET_CHIPS_PRICING_URL);
        if(res){
            setPricing(res)
        }
    }

    useEffect(() => {
        getPricingList()
    }, [])

    return (
        <div>
            <Modal isOpen={buyModal} toggle={toggleBuyModal} className="modal-dialog-centered modal-lg">
                <div className="modal-header">
                    <h5 className="modal-title my-0">Buy Chips </h5>
                    <button onClick={toggleBuyModal} type="button" className="text-white btn-close" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody>
                    <div className='row coin-modal-margin'>
                        <table className="table text-center table-custom-dark">
                            <thead>
                                <tr>
                                    <th scope="col">Cost</th>
                                    <th scope="col">Chips</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody className="mx-2">
                                {pricing.map((item) => {
                                    return (
                                        <Plan key={item.id} item={item} club={club} toggleBuyModal={toggleBuyModal}/>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
            </Modal>
            <Button
                color="success"
                className='ms-3 btn-sm text-nowrap'
                outline
                onClick={toggleBuyModal}
            > Buy Chips
            </Button>
        </div>
    )
}
