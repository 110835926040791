import React, { useEffect, useState} from "react";
import { AppBar, Avatar, IconButton, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logoag from "../../../Static/UserManagement/images/common/default-user-avtar.png";
import TagIcon from "@mui/icons-material/Tag";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  handleDrawerToggle,
  setSelectedMember,
  toggleMessanger,
} from "../../../redux/services/chatServices";
import { BASEURL } from "../../../API-Handle/config";
import PBCHSetting from "./PBCHSetting";
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation, useNavigate } from "react-router";
import { DefaultConfirm } from "../../SweetAlerts/DefaultConfirm";
import { DELETE_CLUB_CHANNEL, postValuebet } from "../../../API-Handle/services/auth_curd";
import { clubchatAction } from "../../../redux/features/clubchatSlice";
import { successNotification } from "../../CustComp/PPHAlert";


const drawerWidth = 300;
function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const [isDeleteChannelLoading, setIsDeleteChannelLoading] = useState(false);
  const { isClubChatOpen, mobileOpen, isDrawerOpen, activeChannel, isClubAdmin,selectedChannelID} = useSelector((state) => state.clubChat);

  
const onCloseChat = () => {
    dispatch(toggleMessanger(null, isClubChatOpen));
    dispatch(setSelectedMember(null));
    if (isClubChatOpen) navigate(pathname)
  };

  const handleDeleteDirectChannel=async()=>{
    let confirmation = await DefaultConfirm(
      "Delete Direct Channel",
      "Are you sure do you want to delete this Channel?",
      "warning"
    );
    if (confirmation) {
      setIsDeleteChannelLoading(true);
      let URL = `${DELETE_CLUB_CHANNEL}${selectedChannelID}`;
      let res = await postValuebet(URL);
      setIsDeleteChannelLoading(false)
      if (res) {
        dispatch(clubchatAction.setActiveChannel({ data: null }));
        successNotification(res.message);
      }
    }
  }

  useEffect(() => {
    if (!mobileOpen) {
      dispatch(handleDrawerToggle(isDrawerOpen, mobileOpen));
    }
  }, []);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: "60px",
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => {
              dispatch(handleDrawerToggle(isDrawerOpen, mobileOpen));
            }}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <div className="d-flex justify-content-between align-items-center w-100">
            {activeChannel && (
              <div className="d-flex align-items-center">
                {activeChannel.channel_type === "GROUP" ? (
                    <div className="d-block">
                    <strong className="font-size-16px">
                      {activeChannel.name}
                    </strong>
                    <div className="font-size-12px fw-normal text-light">
                        {activeChannel.members} Members
                    </div>
                    </div>
                ) : (
                  <>
                    <Avatar
                      alt={activeChannel?.detail?.username}
                      src={
                        activeChannel?.detail?.profile_picture
                          ? BASEURL + activeChannel?.detail?.profile_picture
                          : logoag
                      }
                      sx={{ width: 36, height: 36 }}
                    />
                    <div className="d-block ms-3">
                      <strong className="font-size-16px">
                        {activeChannel?.detail?.username}
                      </strong>
                      <div className="font-size-12px fw-normal text-light">
                        Available
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            <div className="d-flex align-items-center" />{" "}
            <div className="d-flex align-items-center">
              {activeChannel && isClubAdmin && (
                <div className="pe-3">
                  {activeChannel.channel_type === "GROUP" && isClubAdmin ? (
                    <PBCHSetting/>
                  ) : <DeleteIcon onClick={handleDeleteDirectChannel} role="button"/>}
                </div>
              )}

              <CloseIcon onClick={onCloseChat} fontSize="medium" />
            </div>
          </div>
        </Toolbar>
      </AppBar>

    </>
  );
}

export default Index;
