import React from 'react'
import Skeleton from '@mui/material/Skeleton';

export default function OrderLoader() {
    return (
        <>
            <div className='border rounded border-dark mb-2 p-3'>
                <div className='d-flex justify-content-between align-items-center'>
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="rounded" height={50} className='ms-2 flex-fill' />
                </div>
            </div>
            <div className='border rounded border-dark mb-2 p-3'>
                <div className='d-flex justify-content-between align-items-center'>
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="rounded" height={50} className='ms-2 flex-fill' />
                </div>
            </div>
            <div className='border rounded border-dark mb-2 p-3'>
                <div className='d-flex justify-content-between align-items-center'>
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="rounded" height={50} className='ms-2 flex-fill' />
                </div>
            </div>
            <div className='border rounded border-dark mb-2 p-3'>
                <div className='d-flex justify-content-between align-items-center'>
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="rounded" height={50} className='ms-2 flex-fill' />
                </div>
            </div>
            <div className='border rounded border-dark mb-2 p-3'>
                <div className='d-flex justify-content-between align-items-center'>
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="rounded" height={50} className='ms-2 flex-fill' />
                </div>
            </div>
            <div className='border rounded border-dark mb-2 p-3'>
                <div className='d-flex justify-content-between align-items-center'>
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="rounded" height={50} className='ms-2 flex-fill' />
                </div>
            </div>
        </>
    )
}
