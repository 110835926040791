import React, { Component } from 'react';
import footer_logo from '../../Static/UserManagement/images/common/vb_logo_white.png';
import facebook from '../../Static/UserManagement/images/social_ic/fb.svg';
import instagram from '../../Static/UserManagement/images/social_ic/insta.svg';
import tw_twitter from '../../Static/UserManagement/images/social_ic/twitter.svg';
import lk_linkdin from '../../Static/UserManagement/images/social_ic/linkd.svg';
import { Link } from 'react-router-dom';

class PublicFooter extends Component {
    render() {
        return (
            <React.Fragment>
                <footer className='vb_fppter'>
                    <div className='container-lg'>
                        <div className='row justify-content-center'>
                            <div className='col-md-12 text-center'>
                                <p className='copyright text-center'>© {new Date().getFullYear()} <span className='text_orange'>Valuebet Holdings Inc. </span> <span className='text-nowrap'> All Rights Reserved </span></p>
                                <div className='footer_logo'>
                                    <img src={footer_logo} className="img-fluid" alt="f_logo" />
                                </div>
                                <ul className='ftco-footer-social p-0'>
                                    <li className="ftco-animate"><a href="https://www.facebook.com/valuebetapp" target='_blank' rel="noreferrer" data-toggle="tooltip" data-placement="top" title="" data-original-title="fb"><img src={facebook} className="img-fluid" alt="f_logo" /></a></li>
                                    <li className="ftco-animate"><a href="https://www.instagram.com/valuebet.app/" target='_blank'rel="noreferrer"  data-toggle="tooltip" data-placement="top" title="" data-original-title="fb"><img src={instagram} className="img-fluid" alt="f_logo" /></a></li>
                                    <li className="ftco-animate"><a href="https://twitter.com/valuebet_app" target='_blank' rel="noreferrer" data-toggle="tooltip" data-placement="top" title="" data-original-title="fb"><img src={tw_twitter} className="img-fluid" alt="f_logo" /></a></li>
                                    <li className="ftco-animate"><a href="https://www.linkedin.com/company/valuebet-llc/" rel="noreferrer" target='_blank' data-toggle="tooltip" data-placement="top" title="" data-original-title="fb"><img src={lk_linkdin} className="img-fluid" alt="f_logo" /></a></li>
                                </ul>
                                <p className="footer_menu">
                                    {/* <a href="#">About |</a> */}
                                    <Link to="/contact">Contact </Link> |
                                    <Link to="/terms-of-services"> Terms of Services </Link> | 
                                    <Link to="/privacy-policy"> Policy </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}

export default PublicFooter;






