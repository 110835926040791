import React, { useContext, useState } from 'react'
import { successNotification } from '../../../../../../Components/CustComp/PPHAlert';
import ValuebetContext from '../../../../../../context/Valuebet/ValuebetContext';
import { UPDATE_PLAYER_BET_LIMIT_URL, postValuebet } from '../../../../../../API-Handle/services/auth_curd';
import LimitModal from './LimitModal';

export default function PlayerRow({ item, club}) {
    const { setRootLoader } = useContext(ValuebetContext);
    const [limitModal, setLimitModal] = useState(false);

    const updateLimitStatus = async (status) => {
        setRootLoader(true)
        let data = {
            "club_id": club.id,
            "user_id": item.id,
            "bet_limits_enabled": status,
        }

        let res = await postValuebet(`${UPDATE_PLAYER_BET_LIMIT_URL}`, data)
        if (res) {
            item.bet_limits_enabled = !item.bet_limits_enabled
            successNotification(res.message);
        }
        setRootLoader(false)
    }

    const toggleLimitModal = () => {
        setLimitModal(!limitModal)
    };

    return (
        <>
            {limitModal && <LimitModal item={item} club={club} limitModal={limitModal} toggleLimitModal={toggleLimitModal}/>}
            <tr>
                <td> <span role='button' onClick={toggleLimitModal}> {item.username} </span> </td>
                <td><input type="checkbox" checked={item.bet_limits_enabled} onChange={(e) => updateLimitStatus(e.target.checked)} /></td>
                <td> <span role='button' onClick={toggleLimitModal}> ... </span> </td>
            </tr>
        </>
    )
}
