import React from 'react'
import Popup from 'reactjs-popup';
import { postValuebet, SUB_AGENT_URL } from '../../../../API-Handle/services/auth_curd'
import { successNotification } from '../../../../Components/CustComp/PPHAlert';
import { useLocation } from "react-router-dom";
import {
    Button,
    Row,
    Col
} from "reactstrap";

export default function PopupButtons({dataCallback, val, activeClub}) {
    const location = useLocation();
    let subagentId = '';
    let kickBack = ''

    const addSubAgent = async(agent_id, club_id) => {
        let data = {
            "agent_id": agent_id,
            "club_id": club_id,
            "sub_agent_id": subagentId,
            "action": "add"
        }

        let res = await postValuebet(SUB_AGENT_URL, data);
        if(res){
            successNotification(res.message);
            dataCallback();
        }
        subagentId = '';
    }

    const addKickbacks = async(agent_id, club_id) => {
        let data = {
            "agent_id": agent_id,
            "club_id": club_id,
            "agents_kickbacks": kickBack,
            "action": "add"
        };

        let res = await postValuebet(SUB_AGENT_URL, data);
        if(res){
            successNotification(res.message);
            dataCallback();
        }
        kickBack = '';
    }

    return (
        <div className="px-2 mx-1 my-1 pb-1 d-flex justify-content-between">
            <Popup trigger={
                <button type="button" className='agent-bottom-button'> Add Sub-Agent </button>}
                position="top left"
            >
                {close => (
                    <form className="px-2">
                        <input className="bg-dark text-white" label="Add Sub-Agent" name="subagent_id" value={subagentId} onChange={(e) => subagentId = e.target.value} placeholder="Enter UID" type="text" required />
                        <Row>
                            <Col className="text-end">
                                <Button
                                    color="danger"
                                    className='agent-popup-button me-2'
                                    onClick={() => {
                                        addSubAgent(val.id, location.state?.club_data.id);
                                        close();
                                    }}
                                    type="submit"
                                >
                                    Submit
                                </Button>
                                <Button
                                    color="secondary"
                                    className='agent-popup-button'
                                    onClick={() => { close(); }}> Close
                                </Button>
                            </Col>
                        </Row>
                    </form>)}
            </Popup>
            <Popup trigger={
                <button type="button" disabled={val.id === activeClub.user_id} className={val.id === activeClub.user_id ? 'agent-bottom-button-disabled' : 'agent-bottom-button'}> Edit Kickbacks% </button>}
                position="top center"
            >
                {close => (
                    <form className="px-2" >
                        <input className="bg-dark text-white" label="Edit Kickbacks" name="kick_back" onChange={(e) => kickBack = e.target.value}
                            value={kickBack} placeholder="Enter Kickbacks (%)" type="number" required />
                        <Row>
                            <Col className="text-end">
                                <Button
                                    color="danger"
                                    className='agent-popup-button me-2'
                                    onClick={() => {
                                        addKickbacks(val.id, location.state?.club_data.id, val.platform_id);
                                        close();
                                    }}
                                    type="submit">
                                    Submit
                                </Button>
                                <Button
                                    color="secondary"
                                    className='agent-popup-button'
                                    onClick={() => { close(); }}> Close
                                </Button>
                            </Col>
                        </Row>
                    </form>)}
            </Popup>
        </div>
    )
}
