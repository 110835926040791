import React from "react";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import MemerSettings from "../MembersSettings";
import { useNavigate } from "react-router";
import { postValuebet, REMOVE_MEMBERS } from "../../../../../API-Handle/services/auth_curd";
import { DefaultConfirm } from "../../../../../Components/SweetAlerts/DefaultConfirm";
import { successNotification } from "../../../../../Components/CustComp/PPHAlert";

function MemberAction({ row, activeClub, dataCallback }) {
  const navigate = useNavigate();
  const [memberSettingsModal, setMemberSettingsModal] = React.useState(false);

  const goToPlayersReport = () => {
    navigate("/players-report", { state: { club: activeClub, member: row } })
  }

  const toggleSettingModal = () => {
    setMemberSettingsModal(!memberSettingsModal)
  };

  const removePlayer = async () => {
    let confirmation = await DefaultConfirm("Remove Member", "Do you want to remove this member?")
    if (confirmation) {
      let payloads = {
        club_id: activeClub.id,
        member_id: row.id
      }
      let res = await postValuebet(REMOVE_MEMBERS, payloads)
      if (res) {
        successNotification(res.message);
        dataCallback();
      }
    }
  }

  return (
    <>
      {memberSettingsModal && <MemerSettings club={activeClub} item={row} memberSettingsModal={memberSettingsModal} toggleSettingModal={toggleSettingModal} />}
      <span className="dropdown">
        <UncontrolledButtonDropdown direction="right">
          <DropdownToggle color="none" className="btn-sm text-nowrap">
            <i className="fa fa-ellipsis-v text-white" aria-hidden="true"></i>{" "}
          </DropdownToggle>
          <DropdownMenu end className="club-actions">
            {["PLAYER"].includes(row.member_type) && (
              <>
                <DropdownItem className="text-white py-2" onClick={removePlayer}>
                  <i className="fa fa-user-times" aria-hidden="true"></i>
                  Remove from Club
                </DropdownItem>
              </>
            )}

            {["OWNER", "MANAGER", "PLAYER"].includes(row.member_type) && (
              <>
                <DropdownItem
                  className="text-white py-2"
                  onClick={goToPlayersReport}
                >
                  <i className="fa fa-database" aria-hidden="true"></i>{' '} Reports
                </DropdownItem>
                <DropdownItem
                  className="text-white py-2"
                  onClick={toggleSettingModal}
                >
                  <i className="fa fa-cog" aria-hidden="true"></i>{' '} Settings
                </DropdownItem>
              </>
            )}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </span>
    </>
  );
}

export default MemberAction;
