import React from 'react'
import Select from 'react-select'

export default function CustomDropdown({ placeholder, options, value, onChange, isDisabled, isMD, minWidth }) {
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#444' : '#333', // Control background color when focused and unfocused
        borderColor: state.isFocused ? '#444' : '#444', // Control border color when focused and unfocused
        boxShadow: state.isFocused ? '0 0 0 1px #444' : 'none', // Add a box shadow when focused
        height: isMD ? '40px' : '30px', // (small view) Adjust the height as needed
        minHeight: '30px', // (small view) Adjust the min height as needed
        fontSize: isMD ? '15px' : '14px', // (small view) Adjust the font size as needed
        flexWrap: "nowrap"
      }),
      valueContainer: (provided) => ({
        ...provided,
        minHeight: '1px', // (small view)
        height: '28px', // (small view)
        paddingTop: '0', // (small view)
        paddingBottom: '0', // (small view)
        minWidth: minWidth ? minWidth : '100px',
      }),
      option: (provided, state) => ({
        ...provided,
        height: '30px', // (small view) Adjust the height as needed
        fontSize: '14px', // (small view) Adjust the font size as needed
        backgroundColor: state.isSelected ? '#e03f00' : '#444', // Option background color when selected and unselected
        color: '#fff', // Option text color
        ':hover': {
          backgroundColor: state.isSelected ? '#e03f00' : '#666', // Option background color when hovering
          color: '#fff', // Option text color when hovering
        },
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: '#444', // Menu background color
      }),
      singleValue: (provided) => ({
        ...provided,
        color: '#fff', // Color for the selected option's label text
      }),
      input: (provided) => ({
        ...provided,
        color: '#fff', // Color for the search input text
      }),
      placeholder: (provided) => ({
        ...provided,
        color: '#bbb', // Color for the placeholder text
      }),
      multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#555', // Multi-value background color (when multiple options are selected)
      }),
      multiValueLabel: (provided) => ({
        ...provided,
        color: '#fff', // Color for the label text of multi-value
      }),
      multiValueRemove: (provided) => ({
        ...provided,
        color: '#ccc', // Color for the "x" icon to remove multi-value
        ':hover': {
          backgroundColor: '#555', // Background color of the "x" icon when hovering
          color: '#fff', // Color of the "x" icon when hovering
        },
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        paddingTop: '0', // (small view) Adjust the top padding as needed
        paddingBottom: '1px', // (small view) Adjust the bottom padding as needed
      }),
      clearIndicator: (provided) => ({
        ...provided,
        paddingTop: '0', // (small view) Adjust the top padding as needed
        paddingBottom: '1px', // (small view) Adjust the bottom padding as needed
      }),
    };
    return (
        <div>
            <Select
                options={options}
                styles={customStyles}
                isSearchable={true}
                isClearable={true}
                className='react-select-container'
                classNamePrefix="react-select"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                isDisabled={isDisabled}
                required
            />
        </div>
    )
}