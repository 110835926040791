import React from 'react'
import { Row, Col } from 'reactstrap';

export default function ListOverview({ val, activeClub }) {
    return (
        <div className='agent-info'>

            <Row >
                <Col className="col-md-8">
                    <p className='font-size-12px remove-margin'> Vig Back (%)  </p>
                </Col>
                <Col className="col-md-4">
                    <p className='font-size-12px remove-margin'> {val.id === activeClub.user_id && activeClub.label === "Owner" ? <b>N/A</b>: <b>{val.more_info.agents_kickbacks} %</b>} </p>
                </Col>
            </Row>

            <Row >
                <Col className="col-md-8">
                    <p className='font-size-12px remove-margin'> Total Volume </p>
                </Col>
                <Col className="col-md-4">
                    <p className='font-size-12px remove-margin'> <b> 2523 </b> </p>
                </Col>
            </Row>

            <Row>
                <Col className="col-md-8">
                    <p className='font-size-12px remove-margin'> Total Winnings  </p>
                </Col>
                <Col className="col-md-4">
                    <p className='font-size-12px remove-margin'> <b>57</b>  </p>
                </Col>
            </Row>

            <Row >
                <Col className="col-md-8">
                    <p className='font-size-12px remove-margin'> Total Losses  </p>
                </Col>
                <Col className="col-md-4">
                    <p className='font-size-12px remove-margin'> <b> 14 </b> </p>
                </Col>
            </Row>

            <Row >
                <Col className="col-md-8">
                    <p className='font-size-12px remove-margin'> Win/Loss Net  </p>
                </Col>
                <Col className="col-md-4">
                    <p className='font-size-12px remove-margin'> <b> 2 </b>  </p>
                </Col>
            </Row>
        </div>
    )
}
