import React, { useState } from 'react';
import {
    Button,
    Row,
    Col,
    Card
} from "reactstrap";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import AgentLinkButtons from './AgentLinkButtons';
// import { postValuebet, UPDATE_PLATFORM_URL } from '../../../../API-Handle/services/auth_curd'
import logoag from '../../../../Static/UserManagement/images/common/default-user-avtar.png';
import ListOverview from './ListOverview';
import AgentAction from './AgentActions'
import PopupButtons from './PopupButtons';
import { BASEURL } from '../../../../API-Handle/config';
import ChipAgent from './ChipAgent';
// import { successNotification } from '../../../../Components/PPHAlert';

export default function Index({ val, checked, agentData, activeClub, switchMember, dataCallback }) {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1500)
    }

    const toScroll = (ids) => {
        let elmnt = document.getElementById(ids);
        elmnt.scrollIntoView(false);
        elmnt.style.border = "8px solid #E10600";
        elmnt.firstElementChild.style.background = "#480000";
        setTimeout(() => { 
            elmnt.style.border = null;
            elmnt.firstElementChild.style.background = null;
        }, 2500);
    }

    const CardView = () => {
        return (
            <>
                <div className='agent-card-cap'>
                    <AgentAction val={val} activeClub={activeClub} dataCallback={dataCallback} />
                </div>
                <div className='d-flex justify-content-center'>
                    {val.profile_picture ?
                        <img className='agent-avatar' src={BASEURL + val.profile_picture} /> :
                        <img className='agent-avatar' src={logoag} />
                    }
                </div>
                <div className='agent-card-move-button-container'>
                    {val.id !== activeClub.user_id ?
                        <button className='mem-action-button' type="button" onClick={() => switchMember({'member_id': val.id, 'switch_type': 'MEMBER'})}> Move To Player </button> :
                        <button className='mem-action-button' type="button"> You </button>}
                </div>

                <div className='agent-card-chip-button-container'>
                    <ChipAgent
                        val={val}
                        agentData={agentData}
                        activeClub={activeClub}
                        dataCallback={dataCallback}
                    />
                </div>
                <Row>
                    <Col>
                        <h5 className='list-agent-title'> {val.username} </h5>
                    </Col>
                </Row>
                <Row className='agent-card-identity'>
                    <Col>
                        <p> UID <b className='platform-id-color'>{(val.id).substring(0, 8)} </b>
                            <CopyToClipboard text={val.id} onCopy={() => handleCopy()}>
                                <button className='agent-list-copy-button' title="Copy" >
                                    <i className="fa fa-clone" aria-hidden="true"></i>
                                </button>
                            </CopyToClipboard>
                            <div className='agent-list-copied-text'>
                                {isCopied ? <span>Copied</span> : null} </div>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col className='agent-tree-container'>
                        {val.more_info.super_agents.map((val2, index2) => {
                            return (
                                <span key={index2} onClick={() => toScroll(val2.id)}>
                                    <button className='tree-button'>
                                        <u>{val2.username}</u> {' (' + val2.kickbacks + '%)'}
                                    </button>
                                    <i className="fa fa-arrow-right tree-arrow" aria-hidden="true"></i>
                                </span>
                            )
                        })}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h5 className='agent-card-overview-label'> OVER VIEW </h5>
                    </Col>
                </Row>
                <ListOverview val={val} activeClub={activeClub}/>
                <div className='horizantal-line' />
                <div className='px-2  mx-1 agent-link-info border-0'>
                    <AgentLinkButtons val={val} activeClub={activeClub} />
                </div>
                <hr className='horizantal-line' />
                <PopupButtons 
                    dataCallback = {dataCallback}
                    val={val}
                    activeClub={activeClub}
                    // togglePlayerDetail={togglePlayerDetail}
                />
            </>
        )
    }


    const ListView = () => {
        return (
            <>
                <Row>
                    <Col>
                        <h5 className='list-agent-title'> {val.username} </h5>
                    </Col>
                </Row>
                <div className='horizantal-line' />
                <div className='px-2 ms-1 agent-identity text-white'>
                    <span> ID {val.platform_id ? <b className='platform-id-color' >{val.platform_id} </b> : <b className='platform-id-color' > N/A </b>}</span>
                    <span> UID <b className='platform-id-color'>{(val.id).substring(0, 8)} </b>
                        <CopyToClipboard text={val.id} onCopy={() => handleCopy()}>
                            <button title="Copy" className='agent-list-copy-button' >
                                <i className="fa fa-clone" aria-hidden="true"></i>
                            </button>
                        </CopyToClipboard>
                        <div className='agent-list-copied-text'>
                            {isCopied ? <span>Copied</span> : null} </div>
                    </span>
                </div>
                {val.more_info.super_agents.length ?
                <>
                <div className='horizantal-line' />
                <div className='px-2'>
                    <span className='agent-tree-container-list'>
                        {val.more_info.super_agents.map((val2, index2) => {
                            return (
                                <span key={index2} onClick={() => toScroll(val2.id)}>
                                    <button className='tree-button'>
                                        <u>{val2.username}</u> {' (' + val2.kickbacks + '%)'}
                                    </button>
                                    <i className="fa fa-arrow-right tree-arrow" aria-hidden="true"></i>
                                </span>
                            )
                        })}
                    </span>
                </div>
                </>:null}
                <div className='horizantal-line' />
                <div className="px-2 ms-1 d-flex justify-content-between">
                    <span>
                        <Button className='agent-overview-button d-flex justify-content-center align-items-center'>
                            <span className='font-size-12px'>
                                {val.id === activeClub.user_id && activeClub.label === "Owner" ?                                 
                                <b>N/A</b>:
                                <b>{val.more_info.agents_kickbacks} %</b>}
                            </span>
                        </Button>
                    </span>
                    <span>
                        <div className='agent-move-button-container'>
                            <ChipAgent
                                val={val}
                                agentData={agentData}
                                activeClub={activeClub}
                                dataCallback={dataCallback}
                            />
                        </div>
                    </span>
                    <span>
                        <div className='agent-move-button-container'>
                            {val.id !== activeClub.user_id ?
                                <button className='mem-action-button' type="button" onClick={() => switchMember({'member_id': val.id, 'switch_type': 'MEMBER'})}> Move To Player </button>
                                : <button className='mem-action-button' type="button"> You </button>}
                        </div>
                    </span>
                </div>
                <div className='horizantal-line' />
                <div className='px-2 my-1 mx-1 agent-link-info'>
                    <AgentLinkButtons val={val} activeClub={activeClub} />
                </div>
                <div className='horizantal-line' />
                <PopupButtons 
                    dataCallback = {dataCallback}
                    val={val}
                    activeClub={activeClub}
                />
            </>
        )
    }

    return (
        <Col xl={3} lg={6} className="agent-card-container">
            <div className="d-flex justify-content-center">
                <Card id={val.id} className="list-view-card">
                    {!checked ?
                        <div>
                            <ListView />
                        </div> :
                        <div>
                            <CardView />
                        </div>
                    }
                </Card>
            </div>
        </Col>
    )
}
