import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../Components/UserHeader'
import Bets from '../../Sportbook/Reports/Bets';
import Summary from '../../Sportbook/Reports/Summary';
import ChallengeSummary from '../../Sportbook/Reports/ChallengeSummary';
import Winners from '../../Sportbook/Reports/Winners';
import { useLocation } from "react-router";
import { useNavigate } from "react-router";
import { Row } from 'reactstrap';
import ValuebetContext from '../../../context/Valuebet/ValuebetContext';


function Index() {
    const location = useLocation()
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(2);
    const { activeClub, setActiveClub } = useContext(ValuebetContext)

    useEffect(() => {
        let club = location?.state?.club;
        if (!club) {
            navigate("/dashboard")
        } else {
            if (club.is_special) {
                setActiveTab(3);
            }
            setActiveClub(club)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return (
        <div>
            <Header />
            <div className="sec-header container-fluid">
                <Row>
                    <div className="d-flex py-2 justify-content-start align-items-center">
                        <h6 className="sec-header-title font-size-14px">
                            {location?.state?.member && `${location?.state?.member?.username?.toUpperCase()}'s Reports (${activeClub?.name?.toUpperCase()})`}
                        </h6>
                    </div>
                </Row>
            </div>
            <div className='layout-mt-112 fill-view'>
                <div className="container-fluid mt-4">
                    <div className='border rounded border-danger'>
                        <div className="card mb-0 bg-vb-dark overflow-auto text-white">
                            <div className="card-header seperater">
                                <ul className="nav nav-tabs card-header-tabs">
                                    <li className="nav-item">
                                        <span role='button' className={activeTab === 1 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"}
                                            onClick={() => setActiveTab(1)} >Bets</span>
                                    </li>
                                    {activeClub &&
                                        <>
                                            {activeClub.is_special ?
                                                <>
                                                    <li className="nav-item">
                                                        <span role='button' className={activeTab === 3 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"}
                                                            onClick={() => setActiveTab(3)} >Summary</span>
                                                    </li>
                                                    <li className="nav-item">
                                                        <span role='button' className={activeTab === 4 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"}
                                                            onClick={() => setActiveTab(4)} >Players</span>
                                                    </li>
                                                </> :
                                                <li className="nav-item">
                                                    <span role='button' className={activeTab === 2 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"}
                                                        onClick={() => setActiveTab(2)} >Summary</span>
                                                </li>}
                                        </>}
                                </ul>
                            </div>
                            <div className="card-body">
                                {activeTab === 1 ?
                                    <Bets /> :
                                    activeTab === 2 ?
                                        <Summary /> :
                                        activeTab === 3 ?
                                            <ChallengeSummary /> :
                                            activeTab === 4 ?
                                                <Winners /> :
                                                null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index;