import React, { useEffect, useState } from 'react';
import Header from '../../../Components/UserHeader';
import { Row } from 'reactstrap';
import { GET_ORDER_HISTORY, getValuebet } from '../../../API-Handle/services/auth_curd';
import OrderItems from './OrderItems';
import OrderLoader from '../../../Components/CustLoader/OrderLoader';

export default function Index() {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    const getOrderHistory = async () => {
        setIsLoading(true);
        let res = await getValuebet(GET_ORDER_HISTORY);
        if (res) {
            setData(res);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getOrderHistory()
    }, [])

    return (
        <div>
            <Header />
            <div className="sec-header container-fluid">
                <Row>
                    <div className="d-flex justify-content-start align-items-center">
                        <h6 className="sec-header-title py-2">
                            Order Histroy
                        </h6>
                    </div>
                </Row>
            </div>
            <div className='layout-mt-112 fill-view'>
                <div className="container-fluid mt-4">
                    {!isLoading ? data.length ? data?.map((item) => {
                        return (
                            <div key={item.id} className='border rounded border-danger mb-2'>
                                <OrderItems order={item}/>
                            </div>
                        )
                    }) :
                        <div className='d-flex justify-content-center align-items-center mt-5 pt-5'>
                            <div className='text-white'>There are no records to display.</div>
                        </div> :
                        <OrderLoader />
                    }
                </div>
            </div>
        </div>
    )
}
