
import Home from './Pages/Essential/Home';
import Prices from './Pages/Essential/Prices';
import Shop from './Pages/Essential/Shop';
import Contact from './Pages/Essential/Contact';
import TOS from './Pages/Essential/TOS';
import Policy from './Pages/Essential/Policy';

import Dashboard from './Pages/UserManagement/Dashboard';
import CreateClub from './Pages/UserManagement/CreateClub';
import JoinClub from './Pages/UserManagement/JoinClub';
import UploadPic from './Pages/UserManagement/UploadPic'
import ProfileSetting from './Pages/UserManagement/Profile'
import AffiliateReports from './Pages/UserManagement/AffiliateReports'

import ThankYou from './Pages/UserManagement/Payments/ThankYou';
import CancelPay from './Pages/UserManagement/Payments/CancelPay';
import PartialPay from './Pages/UserManagement/Payments/PartialPay';
import OrderHistory from './Pages/UserManagement/OrderHistory'

import UploadClubIcon from './Pages/UserManagement/UploadClubIcon'
import Applicant from './Pages/UserManagement/Applications';

import Members from './Pages/UserManagement/Members';
import PlayersReport from './Pages/UserManagement/PlayersReport';

import Agent from './Pages/UserManagement/Agent';
import AgentPlayers from './Pages/UserManagement/AgentPlayers';
import AgentReport from './Pages/UserManagement/AgentReport';

import Sportbook from './Pages/Sportbook/Sports';
import Reports from './Pages/Sportbook/Reports';
import ClubReports from './Pages/Sportbook/ClubReports';
import QuickClubReport from './Pages/Sportbook/QuickClubReport';
import RewardReports from './Pages/Sportbook/RewardReports';

import ValueMarkets from './Pages/ValueMarkets';

const routes = [
    // public Routes
    { path: '/home', component: Home, ispublic: true },
    { path: '/contact', component: Contact, ispublic: true },
    { path: '/terms-of-services', component: TOS, ispublic: true },
    { path: '/privacy-policy', component: Policy, ispublic: true },
    { path: '/prices', component: Prices, ispublic: true },
    { path: '/shop', component: Shop, ispublic: true },

    // protected Routes
    { path: '/dashboard', component: Dashboard, ispublic: false },
    { path: '/new-club', component: CreateClub, ispublic: false },
    { path: '/club-join', component: JoinClub, ispublic: false },
    { path: '/upload-pic', component: UploadPic, ispublic: false },
    { path: '/profile-setting', component: ProfileSetting, ispublic: false },   
    { path: '/affiliate-reports', component: AffiliateReports, ispublic: false },   

    { path: '/thank-you', component: ThankYou, ispublic: false },
    { path: '/cancel-payment', component: CancelPay, ispublic: false },
    { path: '/partial-payment', component: PartialPay, ispublic: false },
    { path: '/order-history', component: OrderHistory, ispublic: false },   
    
    { path: '/upload-club-icon', component: UploadClubIcon, ispublic: false },
    { path: '/club/:clubId/applicants', component: Applicant, ispublic: false },

    { path: '/club/:clubId/members', component: Members, ispublic: false },
    { path: '/players-report', component: PlayersReport, ispublic: false },  

    { path: '/agents', component: Agent, ispublic: false },
    { path: '/agent-players', component: AgentPlayers, ispublic: false },
    { path: '/agent-report', component: AgentReport, ispublic: false },

    { path: '/sportbook/sports', component: Sportbook, ispublic: false },
    { path: '/sportbook/reports', component: Reports, ispublic: false },
    { path: '/sportbook/club-reports', component: ClubReports, ispublic: false },
    { path: '/sportbook/club-reports-quickview', component: QuickClubReport, ispublic: false },
    { path: '/sportbook/reward-reports', component: RewardReports, ispublic: false },

    { path: '/value-markets', component: ValueMarkets, ispublic: false },
];

export default routes;