import React, { Component } from 'react';
class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer className="footer container-fluid">
                    <div className="text-center">
                        {new Date().getFullYear()} © Valuebet Holdings Inc. <span className="d-none d-sm-inline-block"> </span>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}

export default Footer;






