import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function MoreDetailsButton({item,setIsClickedMoreDetails,setCuurentBetMoreDetails,currentBetMoreDetails}) {
  const handleClickMoreDetails=()=>{
    setIsClickedMoreDetails(true);
    setCuurentBetMoreDetails({...currentBetMoreDetails,...item});

  }
  return (
    <div className="vb-text-orange font-size-15px d-flex align-items-center" role="button" onClick={handleClickMoreDetails}>
        Props + <ArrowForwardIosIcon sx={{fontSize:15}} />
    </div>
  )
  // return <></>
}
