import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import { AFFILIATE_TIER_DETAILS, getValuebet } from '../../../../API-Handle/services/auth_curd';
import SmSpinner from '../../../../Components/CustLoader/SmSpinner';

const conditionalRowStyles = [
    {
        when: row => row,
        style: {
            backgroundColor: '#52a77be6',
            color: 'white',
        },
    }
];

const columns = [
    {
        name: '',
        selector: row => row.username,
        width: "200px",
    },
    {
        name: '',
        selector: row => row.coins_bought,
    },
    {
        name: "",
        selector: row => row.coins_spent,
    },
    {
        name: "",
        selector: row => row.coins_awarded,
    },
    {
        name: "",
        selector: row => row.commission_paid,
    },
    {
        name: "",
        selector: row => "",
    },
    {
        name: "",
        selector: row => "",
    },
];

export default function ExpandableView({ data }) {
    const [members, setMembers] = useState(null)

    const fetchMembers = async() => {
        let request_url = AFFILIATE_TIER_DETAILS + `?report_time=${data.date}&tier=${data.tier_name}&report_type=${data.report_type}`;
        let resp = await getValuebet(request_url);
        if (resp) {
            setMembers(resp);
        }
    }

    useEffect(() => {
        fetchMembers()
        // eslint-disable-next-line
    }, [data])

    return (
        <div className='ps-5 py-1'>
            {members ?
                <DataTable
                    columns={columns}
                    data={members}
                    theme="dark"
                    dense={true}
                    header={false}
                    noTableHead={true}
                    conditionalRowStyles={conditionalRowStyles}
                /> :
                <SmSpinner py={0} />}
        </div>
    )
}
