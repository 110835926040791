import React from 'react'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

export function LinkToPlayerReport({ row }) {
    const location = useLocation();
    const navigate = useNavigate();

    const playerReport = async () => {
        navigate(
            "/players-report",
            {state: { member: { id: row.user, username: row.username }, club: location?.state?.club }}
        )
    }

    return (
        <div role={"button"} onClick={playerReport} className='text-nowrap'>{row.username}</div>
    )
}