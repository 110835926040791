import React, { useState } from 'react';
import Slide1 from '../../../Static/UserManagement/images/landing/hero-slide1.png';
import Slide2 from '../../../Static/UserManagement/images/landing/hero-slide2.png';
// import Herovbtab from '../../../Static/UserManagement/images/landing/slide_tab.png';
// import Herovbmob from '../../../Static/UserManagement/images/landing/2nd-s-mobile.png';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
} from 'reactstrap';

const items = [
    {
        id: 1,
        src: Slide1,
    },
    {
        id: 2,
        src: Slide2,
    },
    // {
    //     id: 3,
    //     src: Herovbtab,
    // },
    // {
    //     id: 4,
    //     src: Herovbmob,
    // },
    
];

function HeroSlider() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = items.map((item) => {
        return (
            <CarouselItem
                className="vb_hero"
                tag="div"
                key={item.id}
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
            >
                <ul>
                    <li className="right_carousel_img">
                        <img src={item.src} className="img-fluid " alt="hero"/>
                    </li>
                    
                </ul>
            </CarouselItem>
        );
    });

    return (
        <div>
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                />
                {slides}
                <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={previous}
                />
                <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={next}
                />
            </Carousel>
        </div>
    );
}

export default HeroSlider;