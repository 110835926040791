import React from 'react';
import { Col, Row } from 'reactstrap';
import { postValuebet, FORGOT_PASSWORD_URL, SEND_FORGOT_PASSWORD_OTP } from '../../../API-Handle/services/auth_curd'
import { errorNotification, successNotification } from '../../../Components/CustComp/PPHAlert'
import { Modal } from 'reactstrap';
import { manageAllInputsErrors, beforeSendOtp, beforeVerifyEmail, beforePassUpdate, refineUsername } from '../../../Utils/comman';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input_errors: {
        email: '',
        otp: '',
        npass: '',
        cnpass: ''
      },
      email: "", otp: "", npass: '', cnpass: '',
      isVisible: false,
      emailField: true,
      otpField: false,
      loading: false,
      emailFieldReadOnly: false,
      forms: true,
      is_field_tracker_on: false,
    }
  }

  handleChange = (e) => {
    if (['email'].includes(e.target.name)){
      e.target.value = refineUsername(e.target.value)
    }
    if (this.state.is_field_tracker_on) {
      const { email_error, otp_error, npass_error, cnpass_error } = manageAllInputsErrors(e);
      this.setState({ input_errors: { email: email_error, otp: otp_error, npass: npass_error, cnpass: cnpass_error } })
    }

    this.setState({ [e.target.name]: e.target.value })
  }

  sendOTP = async () => {
    this.setState({ 'loading': true })
    const { email_error } = beforeSendOtp(this.state.email);
    this.setState({ input_errors: { email: email_error } })
    if (email_error === '') {
      let data = { email: this.state.email }
      let res = await postValuebet(SEND_FORGOT_PASSWORD_OTP, data);
      if (res) {
        successNotification(res.message)
        this.setState({ 'emailFieldReadOnly': true, 'otpField': true })
      }
    } else {
      this.setState({ 'is_field_tracker_on': true })
    }
    this.setState({ 'loading': false })
  }

  verifyOtp = async () => {
    const { email_error, otp_error } = beforeVerifyEmail(this.state.email, this.state.otp);
    this.setState({ input_errors: { email: email_error, otp: otp_error } })
    if (email_error === '' && otp_error === '') {
      let data = { email: this.state.email, otp: this.state.otp }
      let res = await postValuebet(FORGOT_PASSWORD_URL, data);
      if (res) {
        successNotification(res.message)
        this.setState({ 'forms': false })
      } else {
        this.setState({ 'emailFieldReadOnly': false, 'otpField': false, otp: '' })
      }
    } else {
      this.setState({ 'is_field_tracker_on': true })
    }
    this.setState({ 'loading': false })
  }

  resetPassword = async () => {
    const { email_error, otp_error } = beforeVerifyEmail(this.state.email, this.state.otp);
    const { npass_error, cnpass_error } = beforePassUpdate(this.state.npass, this.state.cnpass);
    this.setState({ input_errors: { email: email_error, otp: otp_error, npass: npass_error, cnpass: cnpass_error } })
    if (email_error === '' && otp_error === '' && npass_error === '' && cnpass_error === '') {
      if (this.state.npass !== this.state.cnpass) {
        errorNotification("New Password and Confirm New Password doesn't match.")
        return
      }
      let data = { email: this.state.email, otp: this.state.otp, cnpass: this.state.cnpass };
      let res = await postValuebet(FORGOT_PASSWORD_URL, data);
      if (res) {
        this.setState({ 'forms': true, 'emailFieldReadOnly': false, 'otpField': false, otp: '' })
        successNotification(res.message)
        this.openLogin()
      }
    } else {
      this.setState({ 'is_field_tracker_on': true })
    }
    this.setState({ 'loading': false })
  }

  toggle = () => {
    this.setState({ isVisible: !this.state.isVisible });
  }

  openLogin = () => {
    this.toggle()
    document.getElementById("login_1").click()
  }

  render() {
    return (
      <React.Fragment>
        <Modal isOpen={this.state.isVisible} className="modal-dialog-centered modal-md">
          <div className="modal-header bg-vb-dark border-bottom-0">
            <button onClick={this.toggle} type="button" className="text-white btn-close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className='pb-5'>
            <div className="overflow-hidden account-card mx-2">
              <div className="text-white text-center position-relative">
                <h5 className='my-3'>Forgot Password !</h5>
              </div>
              <div className="px-5 pb-4 pt-4">
                {this.state.forms ?
                  <form className='formcntr'>
                    <div>
                      <label className='text-light font-size-14px' htmlFor="email">Email</label>
                      <input className='form-control bg-dark text-white border-secondary' type="email" name="email" id="email"
                        onChange={this.handleChange} value={this.state.email}
                        placeholder=""
                        readOnly={this.state.emailFieldReadOnly} />
                    </div>
                    {this.state.input_errors.email ? <div className='inputerrors'> {this.state.input_errors.email} </div> : null}
                    {this.state.otpField ?
                      <div className="mt-3">
                        <label className='text-light font-size-14px' htmlFor="otp">OTP</label>
                        <input className='form-control bg-dark text-white border-secondary' name="otp" type="number" id='otp' onChange={this.handleChange} value={this.state.otp} placeholder="" />
                      </div>
                      : null}
                    {this.state.input_errors.otp ? <div className='inputerrors'> {this.state.input_errors.otp} </div> : null}
                    <Row>
                      <Col md="12" className="text-center mt-4">
                        {this.state.otpField ?
                          <button className="signbtn" disabled={this.state.loading} onClick={this.verifyOtp} type="button"> {this.state.loading ? 'Please Wait...' : 'Verify OTP'} <i className="fa fa-arrow-right submticon" aria-hidden="true"></i> </button>
                          : <button className="signbtn" disabled={this.state.loading} onClick={this.sendOTP} type="button"> {this.state.loading ? 'Please Wait...' : 'Send OTP'} <i className="fa fa-arrow-right submticon" aria-hidden="true"></i> </button>
                        }
                      </Col>
                    </Row>
                  </form> :
                  <form>
                    <div className="field">
                      <label className='text-light font-size-14px' htmlFor="npass">New Password</label>
                      <input className='form-control bg-dark text-white border-secondary' type="password" name="npass" id="npass"
                        onChange={this.handleChange} value={this.state.npass}
                        placeholder="" />
                    </div>
                    {this.state.input_errors.npass ? <div className='inputerrors'> {this.state.input_errors.npass} </div> : null}
                    <div className="mt-3">
                      <label className='text-light font-size-14px' htmlFor="cnpass">Confirm New Password</label>
                      <input className='form-control bg-dark text-white border-secondary' type="password" name="cnpass" id="cnpass"
                        onChange={this.handleChange} value={this.state.cnpass}
                        placeholder="" />
                    </div>
                    {this.state.input_errors.cnpass ? <div className='inputerrors'> {this.state.input_errors.cnpass} </div> : null}
                    <Row className="form-group m-t-20">
                      <Col md="12" className="text-center">
                        <button
                          className="signbtn"
                          type='button'
                          onClick={this.resetPassword}
                          disabled={this.state.loading}
                        >
                          {this.state.loading ? 'Please Wait...' : 'Change'} <i className="fa fa-arrow-right submticon" aria-hidden="true"></i>
                        </button>
                      </Col>
                    </Row>
                  </form>
                }
              </div>
            </div>
            <div className="text-center text-white">
              <p>Already have an account ?
                <span role='button' className="font-500 clryllo" onClick={this.openLogin}> Login </span> </p>
            </div>
          </div>
        </Modal>
        <span className={this.props.className} role='button' onClick={this.toggle} id="forgot_1">
          {this.props.title}
        </span>
      </React.Fragment>
    );
  }
}

export default ForgotPassword;