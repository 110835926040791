import React from 'react'
import { postValuebet, CHECK_COUPON_URL } from '../../../../../API-Handle/services/auth_curd'
import { errorNotification } from '../../../../../Components/CustComp/PPHAlert';
import { noSpaceCapitalize } from '../../../../../Utils/comman';

export default function RedeemCoupon({ discount, setDiscount, code, setCode, access_type }) {
    const removeCoupon = async () => {
        setCode('');
        setDiscount(null);
    }

    const redeemCode = async () => {
        if (4 > code.length || code.length > 30) {
            errorNotification("Please enter valid Coupon Code!")
            return;
        }
        let data = { coupon_code: code, access_type }
        let res = await postValuebet(CHECK_COUPON_URL, data)
        if (res) {
            setDiscount(res.discount);
        }
    }

    return (
        <div className='mb-3'>
            <label htmlFor="coupon_code" className="form-label">Have coupon?</label>
            <div className="input-group input-group-sm">
                <input type="text" className="form-control bg-dark text-white" id='coupon_code' placeholder='Coupon Code' aria-label="Coupon Code" readOnly={discount} value={code} onChange={(e) => setCode(noSpaceCapitalize(e.target.value))} minLength={4} maxLength={30} />
                <button className="input-group-text btn btn-success text-white" onClick={redeemCode} disabled={discount}>Redeem</button>
            </div>
            {discount &&
                <div className="alert text-success alert-info alert-dismissible fade show mt-2 mb-0" role="alert">
                    <strong>Congratulations!</strong> Now you can get {discount}% discount on each plan.
                    <button type="button" className="text-white btn-alert-close btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={removeCoupon}></button>
                </div>}
        </div>
    )
}
