import React, { useState } from 'react'
import DailyMemberHistory from './DailyMemberHistory';
import MonthlyMemberHistory from './MonthlyMemberHistory';
import YearlyMemberHistory from './YearlyMemberHistory';

function MemberHistory({ club, item }) {
  const [reportType, setReportType] = useState("Daily");
  return (
    <div>
      <div className="btn-group" role="group">
        <button type="button" className={reportType === 'Daily' ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setReportType('Daily')}>Daily</button>
        <button type="button" className="btn btn-secondary me-0 ms-0 pe-0 ps-0" disabled></button>
        <button type="button" className={reportType === 'Monthly' ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setReportType('Monthly')}>Monthly</button>
        <button type="button" className="btn btn-secondary me-0 ms-0 pe-0 ps-0" disabled></button>
        <button type="button" className={reportType === 'Yearly' ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setReportType('Yearly')}>Yearly</button>
      </div>
      <div className="no-tbl-header">
        {reportType === 'Daily' &&
          <DailyMemberHistory club={club} item={item} />}
        {reportType === 'Monthly' &&
          <MonthlyMemberHistory club={club} item={item} />}
        {reportType === 'Yearly' &&
          <YearlyMemberHistory club={club} item={item} />}
      </div>
    </div>
  )
}

export default MemberHistory
