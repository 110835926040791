import React from 'react'
import PayFooter from '../../../../Components/CustComp/PayFooter';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

export default function PartialPay() {
    return (
        <div className='valuebet-bg-color'>
            <div className='BG-valuebet thank-you-card'>
                <div className="container text-center py-5">
                    <ReportGmailerrorredIcon className='mt-5 mb-4' sx={{ color: '#ffc107', fontSize: 60 }}/>
                    <h2 className='reguler-heading'><span className='text-warning'> Partial Payment! </span></h2>
                    <p className='reguler-fonts'>We appreciate your recent payment; however, it appears to be a partial amount. In order to complete the transaction, please send the remaining balance to the same payment address. Unfortunately, we are unable to deliver the Valuebet Coins/Tokens until the payment is completed. Please contact our support team at <span className='text_orange'>support@valuebet.app</span> with any inquiries. Thank you for your understanding.</p>
                </div>
                <PayFooter />
            </div>
        </div>
    )
}