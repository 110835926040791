import React from 'react'
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'

export const successNotification = (message) => toast.success(message);
export const infoNotification = (message) => toast.info(message);
export const errorNotification = (message) => toast.error(message);

export default function PPHAlert() {
    return (
        <ToastContainer autoClose={5000} pauseOnHover draggable closeOnClick />
    )
}
