import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import ExpandableView from '../ExpandableView';
import moment from 'moment'
import { CLUB_DETAILED_BET_HISTORY } from '../../../../../API-Handle/services/auth_curd';
import { getValuebet } from '../../../../../API-Handle/services/auth_curd';
import { useLocation } from 'react-router-dom'
import { APP_OFFSET } from '../../../../../constant';
import Spinner from '../../../../../Components/CustLoader/Spinner';
import { getBetslipTitle } from '../../../../../Utils/sportsbook';

const conditionalRowStyles = [
    {
        when: row => row.status === "WON",
        style: {
            backgroundColor: 'rgb(255 94 79 / 48%)',
            color: 'white',
        },
    },
    {
        when: row => row.status === "LOSE",
        style: {
            backgroundColor: 'rgb(82 167 123 / 90%)',
            color: 'white',
            // '&:hover': {
            // 	cursor: 'not-allowed',
            // },
        },
    },
    {
        when: row => row.status === "REFUNDED",
        style: {
            backgroundColor: '#193438',
            color: 'white',
        },
    }
];

const columns = [
    {
        name: 'Participant',
        selector: row => row.participant,
        minWidth: "240px"
    },
    {
        name: 'Risk',
        selector: row => row.risking,
    },
    {
        name: 'To Win',
        selector: row => row.winnings,
    },
    {
        name: 'Club Results',
        selector: row => row.result,
    },
    {
        name: 'Vig Chips Burned',
        selector: row => row.vig_paid,
    },
    // {
    //     name: 'Kickbacks',
    //     selector: row => row.kickback,
    // },
    {
        name: 'Club Chips Win/Loss',
        selector: row => row.net,
    },
    {
        name: 'Bet Type',
        selector: row => row.bet_type,
    },
    {
        name: 'Settled Date',
        selector: row => row.settled_date,
        minWidth: "200px"
    },
    {
        name: 'Game Date',
        selector: row => row.game_date,
        minWidth: "200px"
    },
    {
        name: 'Bet Date',
        selector: row => row.bet_date,
        minWidth: "200px"
    }
];

export default function ExpView({ data }) {
    const [preparedData, setPreparedData] = useState(null)
    const location = useLocation()

    const getBets = async () => {
        setPreparedData(null)
        
        let club = location?.state?.club;
        if (!club) return;

        let club_id = club?.id;        
        let request_url = CLUB_DETAILED_BET_HISTORY + club_id + `?bet_type=SETTLED&report_time=${data.date}&player_id=${data.user_id}`;
        let bets = await getValuebet(request_url);
        if (bets) prepareData(bets);
    }

    const prepareData = async (bets) => {
        if (bets) {
            for (let row of bets) {
                row.participant = getBetslipTitle(row);
                // Getting Net Win/Loss for club
                if (row.status === "WON") {
                    row.net = row.result;
                } else if (row.status === "LOSE") {
                    row.net = row.result + row.vig_paid + row.kickback; // vig_paid & kickback always negitive for club
                } else if (row.status === "REFUNDED") {
                    row.net = 0
                }

                row.game_date = moment.utc(row.event_time).utcOffset(APP_OFFSET).format('L LT') + " PST";
                row.settled_date = moment.utc(row.updated_at).utcOffset(APP_OFFSET).format('L LT') + " PST";
                row.bet_date = moment.utc(row.created_at).utcOffset(APP_OFFSET).format('L LT') + " PST";
            }
            setPreparedData(bets)
        }
    }

    useEffect(() => {
        getBets ();
        // eslint-disable-next-line
    }, [])

    return (
        <div className='px-4 py-2 head-yellow responsive-expand'>
            {preparedData ?
                <DataTable
                    columns={columns}
                    data={preparedData}
                    expandableRows
                    expandableRowsComponent={<ExpandableView />}
                    expandableRowDisabled={(row) => (row.status === 'TOTAL')}
                    theme="dark"
                    dense={true}
                    header={false}
                    highlightOnHover={true}
                    conditionalRowStyles={conditionalRowStyles}
                    footer={undefined}
                // noTableHead={index}
                /> :
                <Spinner />}
        </div>
    )
}
