import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component-footer';
import { REWARD_SUMMARY } from '../../../../API-Handle/services/auth_curd';
import { getValuebet } from '../../../../API-Handle/services/auth_curd';
import CustomMonthPicker from '../../../../Components/Calendar/CustomMonthPicker';
import ExpandableView from './ExpandableView';
import Spinner from '../../../../Components/CustLoader/Spinner';

const conditionalRowStyles = [
    {
        when: row => row.report_type === "Weekly",
        style: {
            backgroundColor: '#FFFF00',
            color: 'black',
            '& button:first-child': {
                color: 'black', // Change this to your desired expander icon color
            },
        },
    },
    {
        when: row => row.report_type === "Monthly",
        style: {
            backgroundColor: 'green',
            color: 'white',
        },
    }
];

const columns = [
    {
        name: 'Details',
        selector: row => row.details,
        width:"180px"
    },
    {
        name: "Coins Won By Affiliates",
        selector: row => row.total_affiliate_coin_won,
    },
    {
        name: "Coins Awarded To User",
        selector: row => row.total_commission_awarded,
    },
    {
        name: 'Active Affiliates',
        selector: row => row.total_active_affiliates,
    },
    {
        name: "New Signups",
        selector: row => row.total_new_affiliates,
    },
    {
        name: "User Coins Won",
        selector: row => row.user_coin_won,
    },
    {
        name: "Total Awarded To User",
        selector: row => row.total_coin_awarded,
        minWidth:"115px"
    },
];

export default function Index() {
    const [report, setReport] = useState(null)
    const [month, setMonth] = useState(null);
    const [totalMonth, setTotalMonth] = useState({});

    const getReports = async () => {
        setReport(null)
        let request_url = REWARD_SUMMARY + `?report_time=${month}`;
        let resp = await getValuebet(request_url);
        if (resp) {
            // create total row
            let total_active_affiliates = 0;
            let total_new_affiliates = 0;
            let total_affiliate_coin_won = 0;
            let total_commission_awarded = 0;
            let user_coin_won = 0;
            let total_coin_awarded = 0;

            for (let rep of resp.reports) {
                if (rep.total_active_affiliates > total_active_affiliates) total_active_affiliates = rep.total_active_affiliates;
                rep.details = rep.report_type + " " + rep.pst_report_date;
                total_new_affiliates += rep.total_new_affiliates
                total_affiliate_coin_won += rep.total_affiliate_coin_won
                total_commission_awarded += rep.total_commission_awarded
                user_coin_won += rep.user_coin_won
                total_coin_awarded += rep.total_coin_awarded
            }

            let total_month = {
                details: "Total",
                total_active_affiliates: total_active_affiliates,
                total_new_affiliates: total_new_affiliates,
                total_affiliate_coin_won: total_affiliate_coin_won.toFixed(4) * 1,
                total_commission_awarded: total_commission_awarded.toFixed(4) * 1,
                user_coin_won: user_coin_won.toFixed(4) * 1,
                total_coin_awarded: total_coin_awarded.toFixed(4) * 1,
            }

            setTotalMonth(total_month);
            setReport(resp);
        }
    }

    useEffect(() => {
        if (month) {
            getReports();
        }
        // eslint-disable-next-line
    }, [month])

    return (
        <>
            <span>Total Referrals: {report?.total_affiliates}</span>
            <div className="no-tbl-header">
                <div className='inline-datepicker-container'>
                    <CustomMonthPicker getMonth={setMonth} className={"settled-datepicker"} />
                </div>
                {report ?
                    <DataTable
                        columns={columns}
                        data={report?.reports}
                        theme="dark"
                        dense={true}
                        header={false}
                        conditionalRowStyles={conditionalRowStyles}
                        expandableRows
                        expandableRowsComponent={<ExpandableView />}
                        footer={totalMonth}
                    /> :
                    <Spinner />}
            </div>
        </>
    )
}