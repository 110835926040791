import React, { useContext, useEffect, useState } from 'react'
import LimitTable from '../Comman/LimitTable';
import { errorNotification, successNotification } from '../../../../../../Components/CustComp/PPHAlert';
import ValuebetContext from '../../../../../../context/Valuebet/ValuebetContext';
import { GET_PLAYER_BET_LIMIT_URL, UPDATE_PLAYER_BET_LIMIT_URL, getValuebet, postValuebet } from '../../../../../../API-Handle/services/auth_curd';
import { Modal } from 'reactstrap';

export default function LimitModal({ club, item, limitModal, toggleLimitModal }) {
    const [loader, setLoader] = useState(false);
    const [playerBetLimits, setPlayerBetLimits] = useState([]);
    const { fetchSingleClub } = useContext(ValuebetContext);

    const fetchPlayerData = async () =>{
        let request_url = `${GET_PLAYER_BET_LIMIT_URL}?club_id=${club.id}&user_id=${item?.id}`;
        let res = await getValuebet(request_url);
        if (res){
            let limits = res.bet_limits;
            let player_bet_limits = [];
            Object.keys(limits).forEach(element => {
                player_bet_limits.push(limits[element]);
            });
            setPlayerBetLimits(player_bet_limits);
        }
    }

    const savePlayerBetLimits = async () => {
        setLoader(true)
        let is_error = false;
        playerBetLimits.forEach((it) => {
            if (it.max_bet_all === "" || it.max_bet_all < 50 ||
                it.max_bet_live === "" || it.max_bet_live < 50 ||
                it.max_parlay_all === "" || it.max_parlay_all < 50 ||
                it.max_parlay_live === "" || it.max_parlay_live < 50) {
                is_error = true;
            }
        })

        if (is_error) {
            setLoader(false);
            errorNotification("Please enter valid inputs. Inputs are should not be less-than 50.");
            return;
        }
        let data = {
            "club_id": club.id,
            "user_id": item.id,
            "bet_limits_enabled": item.bet_limits_enabled,
            "bet_limits": playerBetLimits
        }

        let res = await postValuebet(`${UPDATE_PLAYER_BET_LIMIT_URL}`, data)
        if (res) {
            successNotification(res.message);
            fetchSingleClub(club.id);
        }
        setLoader(false)
    }

    useEffect(() => {
        if (limitModal) fetchPlayerData();
        // eslint-disable-next-line
    }, [limitModal])

    return (
        <>
            <Modal isOpen={limitModal} toggle={toggleLimitModal} className="modal-dialog-centered modal-lg">
                <div className="modal-header bg-vb-dark">
                    <h5 className="modal-title my-0">Update {item.username}'s Bet Limits</h5>
                    <button onClick={toggleLimitModal} type="button" className="text-white btn-close" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="card text-start bg-vb-dark px-3">
                    <LimitTable
                        height={220}
                        loader={loader}
                        limits={playerBetLimits}
                        setUpdatedLimits={setPlayerBetLimits}
                        // isDisable={!club?.level_details?.sport_limits} {/* // TODO: Temp Unlocked */}
                        saveLimits={savePlayerBetLimits}
                        toggleModal={toggleLimitModal}
                    />
                </div>
            </Modal>
        </>
    )
}
