import React, { useContext, useLayoutEffect, useState } from 'react'
import NavigationBar from '../../../Components/SportsbookHeader/NavigationBar'
import { Link } from 'react-router-dom';
import Bets from './Bets';
import Summary from './Summary';
import { useLocation } from 'react-router-dom'
import ValuebetContext from '../../../context/Valuebet/ValuebetContext';
import ChallengeSummary from './ChallengeSummary'
import Winners from './Winners'


function Index() {
    const [activeTab, setActiveTab] = useState(1);
    const { activeClub } = useContext(ValuebetContext)
    const location = useLocation()

    useLayoutEffect(() => {
        if (location?.state?.summary_active) {
            setActiveTab(2);
        }
    }, [])

    return (
        <div>
            <NavigationBar />
            <div className='layout-mt-65-100 fill-view'>
                <div className="container-fluid mt-4">
                    <div className='border rounded border-danger'>
                        <div className="card mb-0 bg-vb-dark overflow-auto text-white">
                            <div className="card-header seperater">
                                <ul className="nav nav-tabs card-header-tabs">
                                    <li className="nav-item">
                                        <span role='button' className={activeTab === 1 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"}
                                            onClick={() => setActiveTab(1)} >Bets</span>
                                    </li>
                                    {activeClub &&
                                        <>
                                            {activeClub.is_special ?
                                                <>
                                                    <li className="nav-item">
                                                        <span role='button' className={activeTab === 3 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"}
                                                            onClick={() => setActiveTab(3)} >Summary</span>
                                                    </li>
                                                    { window.location.origin.includes("dev") &&
                                                    <li className="nav-item">
                                                        <span role='button' className={activeTab === 4 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"}
                                                            onClick={() => setActiveTab(4)} >Players</span>
                                                    </li>}
                                                </> :
                                                <li className="nav-item">
                                                    <span role='button' className={activeTab === 2 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"}
                                                        onClick={() => setActiveTab(2)} >Summary</span>
                                                </li>}
                                        </>}
                                </ul>
                            </div>
                            <div className="card-body">
                                {activeTab === 1 ?
                                    <Bets /> :
                                    activeTab === 2 ?
                                        <Summary /> :
                                        activeTab === 3 ?
                                            <ChallengeSummary /> :
                                            activeTab === 4 ?
                                                <Winners /> :
                                                null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index;