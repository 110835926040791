import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { postValuebet, REMOVE_CLUB_URL } from '../../../../../../API-Handle/services/auth_curd';
import { successNotification } from '../../../../../../Components/CustComp/PPHAlert';
import club_avtar from "../../../../../../Static/UserManagement/images/common/club_avtar.png";
import { BASEURL } from '../../../../../../API-Handle/config';
import BasicInfo from './BasicInfo';
import AgentPermission from './AgentPermission';
import ValuebetContext from '../../../../../../context/Valuebet/ValuebetContext';
import { DefaultConfirm } from '../../../../../../Components/SweetAlerts/DefaultConfirm';


export default function General({ item, toggleClubSetting }) {
    const [activeMenu, setActiveMenu] = useState(1);
    const { fetchClub } = useContext(ValuebetContext)

    const removeClub = async() => {
        let message = `<p class="font-size-14px text-danger">Everything will be deleted and not restored.</p> Do you want to delete this club?`
        let confirmation = await DefaultConfirm("Delete Club", message)
        if (confirmation) {
            let res = await postValuebet(`${REMOVE_CLUB_URL + item.id}`);
            if(res){
                successNotification(res.message);
                toggleClubSetting();
                fetchClub();
            }
        }
    }

    return (
        <div className="tab-content">
            <div className="tab-pane fade active show" id="account-general">
                <div className="my-3 d-flex align-items-center">
                    <img  onClick={()=>setActiveMenu(1)} src={item.club_icon ? BASEURL+item.club_icon : club_avtar} alt="" className="d-block ui-w-80 rounded-circle" />
                    <div className="media-body ms-4 align-self-center">
                        <Link to="/upload-club-icon" state={{ club_data: item }} className="btn btn-danger btn-orange btn-sm mb-1">
                            Upload club avatar
                        </Link> &nbsp;
                        {/* <button onClick={()=>setActiveMenu(2)} type="button" className="btn btn-outline-danger btn-sm mb-1">Agent Permission</button>
                        &nbsp; &nbsp; */}
                        {item.label === "Owner" ?
                            <button onClick={removeClub} type="button" className="btn btn-secondary btn-sm mb-1">Delete Club</button> :
                            null}
                    </div>
                </div>
                <hr className="border-dark m-0" />
                <div className='mt-3'>
                    {activeMenu === 1 ?
                    <BasicInfo toggleClubSetting={toggleClubSetting} item={item} />:
                    activeMenu === 2 ?
                    <AgentPermission item={item}/>:
                    null}
                </div>
            </div>
        </div>
    )
}
