import React, { useState, useEffect } from 'react';
import ChipButton from '../../../../Components/CustComp/ChipButton';
import {
    Modal
} from "reactstrap";
import ChipsTab from '../../../../Components/CustComp/ChipsTab';


function ChipAgent({ val, agentData, activeClub, dataCallback }) {
    const [agentAvailChips, setAgentAvailChips] = useState(0);
    const [agentOsChips, setAgentOsChips] = useState(0);
    const [options, setOptions] = useState([]);
    const [transferChipModal, setTransferChipModal] = useState(false);

    const initialization = () => {
        setAgentAvailChips(val.agent_chips)

        let osc = 0;

        // Nested sub-agents for outstanding chips calucation
        let sub_agent_array = []
        let is_sub_agent = val?.more_info?.sub_agents?.map((sub) => sub.username);
        while (is_sub_agent) {
            let temp_sub_agent = []
            for (let sa of is_sub_agent) {
                sub_agent_array.push(sa);
                let subagent = agentData.filter((agt) => agt.username == sa);
                let subsubagent = subagent[0]?.more_info?.sub_agents?.map((sub) => sub.username);
                temp_sub_agent = temp_sub_agent.concat(subsubagent)
                // console.log(temp_sub_agent)
            }
            is_sub_agent = temp_sub_agent.length ? temp_sub_agent : false
        }

        // Nested players for outstanding chips calucation
        // let agent_players_array = val?.more_info?.players
        let agent_players_array_with_subagent_players = val?.more_info?.players
        for (let said of sub_agent_array) {
            let subagent = agentData.filter((agt) => agt.username == said);
            let sub_agent_players = subagent[0]?.more_info?.players;
            agent_players_array_with_subagent_players = agent_players_array_with_subagent_players.concat(sub_agent_players)
        }
        let playerData = activeClub.members?.filter((ply) => agent_players_array_with_subagent_players.indexOf(ply.id) !== -1)

        // set OSC and Agents
        let agentOpts = []
        osc += val.player_chips
        osc += val.locked_chips
        agentOpts.push({ id: val.id, username: val.username, chips: val.player_chips, locked: val.locked_chips, label: "P", uuid: val.id + "P" })
        agentData.map((agent) => {
            if (sub_agent_array.indexOf(agent.username) !== -1) {
                osc += agent.agent_chips
                osc += agent.player_chips
                osc += agent.locked_chips
                agentOpts.push({ id: agent.id, username: agent.username, chips: agent.agent_chips, label: "A", uuid: agent.id + "A" })
                agentOpts.push({ id: agent.id, username: agent.username, chips: agent.player_chips, locked: agent.locked_chips, label: "P", uuid: agent.id + "P" })
            }
        })


        // set OSC and Players
        // let playerOpts = []
        playerData.map((ply) => {
            osc += ply.player_chips
            osc += ply.locked_chips
            agentOpts.push({ id: ply.id, username: ply.username, chips: ply.player_chips, locked: ply.locked_chips, label: "P", uuid: ply.id + "P" })
            // if (agent_players_array.indexOf(ply.id) != -1) {
            //     playerOpts.push({ id: ply.id, username: ply.username, chips: ply.player_chips, label: "P", uuid: ply.id + "P" })
            // }
        })

        // setPlayerOptions(playerOpts)
        setOptions(agentOpts)
        setAgentOsChips(osc)
    }

    useEffect(() => {
        initialization()
    }, [activeClub, agentData])

    const toggleClubChipModal = () => {
        setTransferChipModal(!transferChipModal)
    };

    return (
        <>
            <Modal isOpen={transferChipModal} toggle={toggleClubChipModal} className="modal-dialog-centered modal-xl">
                <div className="modal-header">
                    <h5 className="modal-title my-0"> Transfer Chips from/to {val.username}'s AgentWallet</h5>
                    <button onClick={toggleClubChipModal} type="button" className="text-white btn-close" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ChipsTab options={options} club_id={activeClub.id} agent_id={val.id} origin="AGENT" callBack={dataCallback} toggleClubChipModal={toggleClubChipModal} showResetStaus={false} />
            </Modal>
            <ChipButton
                title={"Chips"}
                chipA={agentAvailChips}
                chipB={agentOsChips}
                toggleClubChipModal={toggleClubChipModal}
            />
        </>
    )
}


export default ChipAgent;