import React, { Component } from "react";
import {
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import Header from "../../../Components/UserHeader";
import Footer from "../../../Components/Footer";
import ClubCard from './ClubCard'
import ValuebetContext from "../../../context/Valuebet/ValuebetContext";
import CoinWallet from './CoinWallet'
import withRouter from "../../../Components/CustComp/withRouter";
import SmSpinner from "../../../Components/CustLoader/SmSpinner";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
    };
    if (localStorage.getItem("authToken") == null) {
      this.props.navigate("/home");
    }
  }

  static contextType = ValuebetContext;

  componentDidMount() {
    let userdata = localStorage.getItem("userData");
    this.setState({ username: JSON.parse(userdata).username });
    this.context.fetchClub()
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="sec-header container-fluid">
          <Row>
            <Col md="6" className="sec-header-first-items">
              <h6 className="sec-header-title welcome-title">
                Welcome, {this.state.username}
              </h6>
            </Col>
            <Col md="6" className="sec-header-sec-items">
              <CoinWallet />
              &nbsp; &nbsp;
              <Link className="create-club-button" to="/new-club">
                Create Club
              </Link>
              &nbsp; &nbsp;
              <Link className="join-club-button" to="/club-join">
                Join Club
              </Link>
            </Col>
          </Row>
        </div>
        <div className='layout-mt-112 fill-view'>
          <div className="container-fluid valuebet-bg-color">
            <div role="button" onClick={()=> document.getElementById("reward_faq_2").click()} className='text-center py-1 font-size-18px'>Earn Real Rewards as an Affiliate!</div>
          </div>
          <div className="container-fluid mt-4">
            {this.context.clubData ? (
              <Row>
                {this.context.clubData.length ? (
                  this.context.clubData.map((item, index) => {
                    return (
                      <ClubCard key={index} item={item} />
                    );
                  })
                ) : (
                  <Col md="12" className="d-flex justify-content-center mt-5 pt-5 text-white">
                    <div>You have no clubs.</div>{" "}
                  </Col>
                )}
              </Row>
            ) : <SmSpinner text={"Loading Clubs..."} />}
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default withRouter(index);
