import React, { useEffect, useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import moment from 'moment'
import { APP_OFFSET } from '../../constant';

const getCurrentDay = () => {
    return moment().utcOffset(APP_OFFSET).format("YYYY") + "-" + moment().utcOffset(APP_OFFSET).format("MM") + "-" + moment().utcOffset(APP_OFFSET).format("DD")
}

export default function CustomDayPicker({getDay, className}) {
    const [displayValue, setDisplayValue] = useState("Today");
    const [day, setDay] = useState(getCurrentDay());

    useEffect(()=>{
        getDay(moment(day).format('DD/MM/YYYY'))
    }, [day])

    const onDayChange = (e) => {
        let tmp_day = null;
        if (e.target.value) {
            tmp_day = e.target.value;
        } else {
            tmp_day = getCurrentDay();
        }
        setDay(tmp_day)
        if (tmp_day === getCurrentDay()) {
            setDisplayValue("Today")
        } else {
            const dply = moment(tmp_day);
            const formattedDate = dply.format('MMMM DD, YYYY');
            setDisplayValue(formattedDate)
        }
    }

    return (
        <>
            <input
                id="datepicker"
                style={{ height: 0, width: 0, border: "none" }}
                type="date"
                onChange={onDayChange}
                min="2023-01-01"
                value={day}
                max={getCurrentDay()}

            />
            <button type="button" onClick={() => document.getElementById("datepicker").showPicker()} className={className}>{displayValue} <ArrowDropDownIcon fontSize="small" /> </button>
        </>
    )
}
