import { createSlice } from "@reduxjs/toolkit";

const sportsbookSlice = createSlice({
  name: "sportsbook",
  initialState: {
    events: null,
    selectedTeaserPoints:null,
    teaserPoints:[],
  },
  reducers: {
    setEvents(state, actions) {
      state.events = actions.payload.data;
    },
    setSelectedTeaserPoints(state,actions){
      state.selectedTeaserPoints=actions.payload.data;
    },
    setTeaserPoints(state,actions){
      state.teaserPoints=actions.payload.data;
    },
  },
});

export const sportsbookAction = sportsbookSlice.actions;
export default sportsbookSlice.reducer;
