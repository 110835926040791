import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component-footer';
import { AFFILIATE_SUMMARY } from '../../../../API-Handle/services/auth_curd';
import { getValuebet } from '../../../../API-Handle/services/auth_curd';
import CustomMonthPicker from '../../../../Components/Calendar/CustomMonthPicker';
import ExpandableView from './ExpandableView';
import Spinner from '../../../../Components/CustLoader/Spinner';

const conditionalRowStyles = [
    {
        when: row => row.report_type === "Weekly",
        style: {
            backgroundColor: 'green',
            color: 'white',
        },
    },
    {
        when: row => row.report_type === "Monthly",
        style: {
            backgroundColor: '#e03f00',
            color: 'white',
        },
    }
];

const columns = [
    {
        name: 'Details',
        selector: row => row.details,
        width:"200px"
    },
    {
        name: "Coins Bought By Affiliates",
        selector: row => row.total_affiliate_coins_bought,
    },
    {
        name: "Coins Spent By Affiliates",
        selector: row => row.total_affiliate_coins_spent,
    },
    {
        name: "Coins Awarded To Affiliates",
        selector: row => row.total_affiliate_coins_awarded,
    },
    {
        name: "Coins Awarded To User",
        selector: row => row.total_commission_awarded,
    },
    {
        name: 'Active Affiliates',
        selector: row => row.total_active_affiliates,
    },
    {
        name: "New Signups",
        selector: row => row.total_new_affiliates,
    }
];

export default function Index() {
    const [report, setReport] = useState(null)
    const [month, setMonth] = useState(null);

    const getReports = async () => {
        setReport(null)
        let request_url = AFFILIATE_SUMMARY + `?report_time=${month}`;
        let resp = await getValuebet(request_url);
        if (resp) {
            setReport(resp);
        }
    }

    useEffect(() => {
        if (month) {
            getReports();
        }
        // eslint-disable-next-line
    }, [month])

    return (
        <>
            <span>Total Referrals: {report?.total_affiliates}</span>
            <div className="no-tbl-header">
                <div className='inline-datepicker-container'>
                    <CustomMonthPicker getMonth={setMonth} className={"settled-datepicker"} />
                </div>
                {report ?
                    <DataTable
                        columns={columns}
                        data={report?.reports}
                        theme="dark"
                        dense={true}
                        header={false}
                        conditionalRowStyles={conditionalRowStyles}
                        expandableRows
                        expandableRowsComponent={<ExpandableView />}
                    /> :
                    <Spinner />}
            </div>
        </>
    )
}