import React, { useContext } from "react";
import Linkbutton from "../../../../Components/SportComp/Linkbutton";
import SideList from "./SideList";
import ValuebetContext from "../../../../context/Valuebet/ValuebetContext";
import Spinner from "../../../../Components/CustLoader/Spinner";
import SelectTeaser from "./SelectTeaser";

export default function Index() {
  const { isFilterVisible, isBetVisible, onFilterClick, sports, betDetails } = useContext(ValuebetContext);
  return (
    <div
      className={
        isFilterVisible && isBetVisible !== true
          ? "side-bar"
          : "side-bar-inactive"
      }
    >
      <div>
        <Linkbutton />
        {betDetails?.bet_info?.bet_type === "Teaser" && <SelectTeaser />}
        {sports ? sports.map((item, index) => <SideList item={item} key={item.id} idx={index} />) : <Spinner />}
        <div className="continue-box">
          <button className="continue-button" onClick={onFilterClick}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}
