import React from 'react'
import ListLoader from '../../../../../../../../Components/CustLoader/ListLoader';

export default function MemberLimitTable({ height, limits, setUpdatedLimits, isDisable, saveLimits }) {
    const updateAllVal = (amount, type) => {
        let new_limits = [...limits];
        new_limits.forEach((ele) => {
            if (type === "PRE_ALL") {
                ele.max_bet_all = amount;
            } else if (type === "LIVE_ALL") {
                ele.max_bet_live = amount;
            } else if (type === "PRE_PARLAY") {
                ele.max_parlay_all = amount;
            } else if (type === "LIVE_PARLAY") {
                ele.max_parlay_live = amount;
            } else if (type === "PAYOUT") {
                ele.max_parlay_payout = amount;
            }
        })
        setUpdatedLimits(new_limits)
    }

    const updateVal = (item, val, type) => {
        let new_limits = [...limits];
        new_limits.forEach((ele) => {
            if (ele.league === item.league) {
                if (type === "PRE_ALL") {
                    ele.max_bet_all = val;
                } else if (type === "LIVE_ALL") {
                    ele.max_bet_live = val;
                } else if (type === "PRE_PARLAY") {
                    ele.max_parlay_all = val;
                } else if (type === "LIVE_PARLAY") {
                    ele.max_parlay_live = val;
                } else if (type === "PAYOUT") {
                    ele.max_parlay_payout = val;
                } else if (type === "ACTIVE") {
                    ele.is_activate = val;
                }
            }
        })
        setUpdatedLimits(new_limits)
    }

    return (
        <>
            {!limits ? <ListLoader /> :
                <div className="overflow-x overflow-y" style={{ maxHeight: window.innerHeight - height }}>
                    <table className={`table table-custom-dark`}>
                        <thead>
                            <tr>
                                <th scope="col" className="align-top">Sports</th>
                                <th scope="col" className="align-top">Activate</th>
                                <th scope="col" className="align-top text-nowrap">
                                    Max Bet All
                                    <div>
                                        <input type="number" min={0} className="form-control chip-input-custom-height bg-dark text-white" placeholder="Amount: Apply All" onChange={(e) => updateAllVal(e.target.value, "PRE_ALL")} />
                                    </div>
                                </th>
                                <th scope="col" className="align-top">
                                    Max Bet Live
                                    <div>
                                        <input type="number" min={0} className="form-control chip-input-custom-height bg-dark text-white" placeholder="Amount: Apply All" onChange={(e) => updateAllVal(e.target.value, "LIVE_ALL")} />
                                    </div>
                                </th>
                                <th scope="col" className="align-top">
                                    Max Bet Parlay
                                    <div>
                                        <input type="number" min={0} className="form-control chip-input-custom-height bg-dark text-white" placeholder="Amount: Apply All" onChange={(e) => updateAllVal(e.target.value, "PRE_PARLAY")} />
                                    </div>
                                </th>
                                <th scope="col" className="align-top">
                                    Max Parlay Live
                                    <div>
                                        <input type="number" min={0} className="form-control chip-input-custom-height bg-dark text-white" placeholder="Amount: Apply All" onChange={(e) => updateAllVal(e.target.value, "LIVE_PARLAY")} />
                                    </div>
                                </th>
                                <th scope="col" className="align-top">
                                    Max Parlay Pay
                                    <div>
                                        <input type="number" min={0} className="form-control chip-input-custom-height bg-dark text-white" placeholder="Amount: Apply All" onChange={(e) => updateAllVal(e.target.value, "PAYOUT")} />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {limits.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.league}</td>
                                        <td><input type="checkbox" checked={item.is_activate} onChange={(e) => updateVal(item, e.target.checked, "ACTIVE")} /></td>
                                        <td><input type="number" min={0} value={item.max_bet_all} onChange={(e) => updateVal(item, e.target.value, "PRE_ALL")} className="form-control chip-input-custom-height bg-dark text-white" placeholder="Amount" /></td>
                                        <td><input type="number" min={0} value={item.max_bet_live} onChange={(e) => updateVal(item, e.target.value, "LIVE_ALL")} className="form-control chip-input-custom-height bg-dark text-white" placeholder="Amount" /></td>
                                        <td><input type="number" min={0} value={item.max_parlay_all} onChange={(e) => updateVal(item, e.target.value, "PRE_PARLAY")} className="form-control chip-input-custom-height bg-dark text-white" placeholder="Amount" /></td>
                                        <td><input type="number" min={0} value={item.max_parlay_live} onChange={(e) => updateVal(item, e.target.value, "LIVE_PARLAY")} className="form-control chip-input-custom-height bg-dark text-white" placeholder="Amount" /></td>
                                        <td><input type="number" min={0} value={item.max_parlay_payout} onChange={(e) => updateVal(item, e.target.value, "PAYOUT")} className="form-control chip-input-custom-height bg-dark text-white" placeholder="Amount" /></td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </div>}
            <div className="text-end mt-4">
                <button type="button" disabled={isDisable} className="btn btn-danger btn-orange btn-sm" onClick={saveLimits}>Save changes</button>
            </div>
        </>
    )
}
