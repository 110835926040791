import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { SEARCH_USER_URL, getValuebet, TRANSFER_TOKEN_URL, postValuebet } from '../../../../../../API-Handle/services/auth_curd'
import { successNotification } from '../../../../../../Components/CustComp/PPHAlert'
import user4 from "../../../../../../Static/UserManagement/images/common/default-user-avtar.png";
import { BASEURL } from '../../../../../../API-Handle/config';

export default function Index({ token, fetchTokens }) {
    const [transModal, setTransModal] = useState(false);
    const [result, setResult] = useState(null)
    const [uuid, setUuid] = useState('')

    const toggleTransModal = () => {
        setTransModal(!transModal)
    }

    const transfer = async () => {
        let data = {
            userid: result.id,
            tokenid: token.id
        }
        let res = await postValuebet(TRANSFER_TOKEN_URL, data)
        if (res) {
            successNotification(res.message)
            fetchTokens()
        }
    }

    const fetchUser = async () => {
            if (uuid === '') return
            let res = await getValuebet(`${SEARCH_USER_URL}?uuid=${uuid}`)
            if(res){
                setResult(res)
                setUuid('')
            }
    }

    return (
        <>
            <Modal isOpen={transModal} toggle={toggleTransModal} className="modal-dialog-centered modal-lg">
                <div className="modal-header">
                    <h5 className="modal-title my-0">Transfer Token To Valuebet User</h5>
                    <button onClick={toggleTransModal} type="button" className="text-white btn-close" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody>
                    <div>
                        <div className='mb-2'>
                            <label>Search & Transfer Token: </label>
                            <div className="input-group input-group-sm">
                                <span className="input-group-text bg-dark text-white">@</span>
                                <input type="text" className="form-control bg-dark text-white" value={uuid} onChange={(e) => setUuid(e.target.value)} placeholder="Recipient's uuid" aria-label="Recipient's uuid" aria-describedby="basic-addon1" />
                                <button type="button" className="btn btn-outline-danger" onClick={fetchUser}>Search</button>
                            </div>
                        </div>
                        {!(result) &&
                            <div className="alert alert-info" role="alert">
                                Please type exact uuid and search to transfer token to another user!
                            </div>}

                        {result &&
                            <>
                                <ul className="list-group">
                                    <li className="list-group-item d-flex justify-content-start lh-condensed bg-dark">
                                        <img alt='Avtar' src={result.profile_picture ? BASEURL + result.profile_picture : user4} className="rounded-circle thumb-md" />
                                        <div className='ms-3 mt-1 me-auto'>
                                            <h6 className="my-0 text-white">{result.username}</h6>
                                            <small className="text-light">{result.email}</small>
                                        </div>
                                        <div>
                                            <button type="button" className="btn btn-outline-danger desk-tokentransferbutton" onClick={transfer}>Confirm Transfer</button>
                                        </div>
                                    </li>
                                    <div className='d-flex justify-content-center mt-3'>
                                        <button type="button" className="btn btn-outline-danger mob-tokentransferbutton" onClick={transfer}>Confirm Transfer</button>
                                    </div>
                                </ul>
                            </>}
                    </div>
                </ModalBody>
            </Modal>
            <button className='transfer-action-button btn-outline-custom-orange' onClick={toggleTransModal}>
                Transfer
            </button>
        </>
    )
}
