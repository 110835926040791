import { useContext, useEffect, useState } from "react";
import ValuebetContext from "../../../../../../../context/Valuebet/ValuebetContext";
import { handleClickOnMarket } from "../Utils/BetData";
import { OFFLINE_ODD } from "../../../../../../../constant";

const HandicapOdd = ({ team, odd, data, item, title }) => {
    const { betDetails, setBetDetails, removeSelectedBet } = useContext(ValuebetContext);
    const [isSelected, setIsSelected] = useState(false);
    let odd_points=odd.odd_points.toString();
    let odd_money=odd.odd_money.toString();

    useEffect(() => {
        if (betDetails.bets.some((bet) => bet.id === odd.odd_id)) {
            setIsSelected(true);
        }
        else {
            setIsSelected(false);
        }

    }, [betDetails])
    return (
        
        (odd_points === OFFLINE_ODD || odd_points === `+${OFFLINE_ODD}` || odd_money === OFFLINE_ODD) || odd_money === `+${OFFLINE_ODD}` ?
            <div id={odd.id} className="odd-disable-button mt-3"> - </div> :
            <div
                key={odd.odd_id}
                role="button"
                className={`d-flex justify-content-between odd-border p-1 rounded-1 bet-container mt-3 ${isSelected ? "selected-odd" : ""}`}
                onClick={() => handleClickOnMarket(team, data, betDetails, setBetDetails, removeSelectedBet, odd, item, title)}
            >
                <div className="header-title ps-2 props-odd-font-size">
                    {odd_points}
                </div>
                <div className="header-title pe-2 props-odd-font-size">
                    {odd.odd_money > 0 ? `+${odd_money}` : odd_money}
                </div>
            </div>

    )

}
export default HandicapOdd;
