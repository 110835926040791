import React from 'react'
import { Col, Row } from 'reactstrap';
import facebook from '../../Static/UserManagement/images/social_ic/fb.svg';
import instagram from '../../Static/UserManagement/images/social_ic/insta.svg';
import tw_twitter from '../../Static/UserManagement/images/social_ic/twitter.svg';
import lk_linkdin from '../../Static/UserManagement/images/social_ic/linkd.svg';

export default function PayFooter() {
    return (
        <div className="container text-center">
            <Row>
                <Col md={4} className='mb-5'>
                    <div className='bg-dark sub-card p-3 rounded'>
                        <h4 className='reguler-heading mt-4'> Connect With Us </h4>
                        <div className='d-flex justify-content-center'>
                            <li className="ftco-animate mx-2"><a href="https://www.facebook.com/valuebetapp" rel="noreferrer" target='_blank' data-toggle="tooltip" data-placement="top" title="" data-original-title="fb"><img src={facebook} className="img-fluid" alt="f_logo" /></a></li>
                            <li className="ftco-animate mx-2"><a href="https://www.instagram.com/valuebet.app/" rel="noreferrer" target='_blank' data-toggle="tooltip" data-placement="top" title="" data-original-title="fb"><img src={instagram} className="img-fluid" alt="f_logo" /></a></li>
                            <li className="ftco-animate mx-2"><a href="https://twitter.com/valuebet_app" rel="noreferrer" target='_blank' data-toggle="tooltip" data-placement="top" title="" data-original-title="fb"><img src={tw_twitter} className="img-fluid" alt="f_logo" /></a></li>
                            <li className="ftco-animate mx-2"><a href="https://www.linkedin.com/company/valuebet-llc/" rel="noreferrer" target='_blank' data-toggle="tooltip" data-placement="top" title="" data-original-title="fb"><img src={lk_linkdin} className="img-fluid" alt="f_logo" /></a></li>
                        </div>
                    </div>
                </Col>
                <Col md={4} className='mb-5'>
                    <div className='bg-dark sub-card p-3 rounded'>
                        <h4 className='reguler-heading mt-4'> Return to Website </h4>
                        <a className='btn btn-orange text-white' href={window.location.origin} rel="noreferrer"> RETURN </a>
                    </div>
                </Col>
                <Col md={4} className='mb-5'>
                    <div className='bg-dark sub-card p-3 rounded'>
                        <h4 className='reguler-heading mt-4'> Chat with Support </h4>
                        <a className='btn btn-success' href="https://t.me/+SrsT9XiwBkA3NTVh" rel="noreferrer" target={"_blank"}> CHAT NOW </a>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
