import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import moment from 'moment'
import { getValuebet, PLAYER_GET_SUMMARY } from '../../../../API-Handle/services/auth_curd';
import { useLocation } from 'react-router-dom'
import CustomWeekPicker from '../../../../Components/Calendar/CustomWeekPicker';
import { APP_OFFSET } from '../../../../constant';
import Spinner from '../../../../Components/CustLoader/Spinner';

const columns = [
    {
        name: 'Days',
        selector: row => row.cust_day,
        sortable: false,
        width: "16%"
    },
    {
        name: 'Beginning Of Week',
        selector: row => row.starting_balance,
        sortable: false,
    },
    {
        name: 'Win/Loss',
        selector: row => row.win_loss,
        sortable: false,
    },
    {
        name: 'Chips In/Out',
        selector: row => row.in_out,
        sortable: false,
    },
    {
        name: 'Vig Chips Burned',
        selector: row => row.vig_paid,
        sortable: false,
    },
    // {
    //     name: 'Kickback',
    //     selector: row => row.kickback,
    //     sortable: false,
    // },
    {
        name: 'Unsettled',
        selector: row => row.unsettled,
        sortable: false,
    },
    {
        name: 'Player Chips',
        selector: row => row.balance,
        sortable: false,
    }
];

export default function Index({club}) {
    const [isLoading, setIsLoading] = useState(false);
    const [week, setWeek] = useState(null);
    const [summary, setSummary] = useState([]);
    const [total, setTotal] = useState({});
    const location = useLocation();

    useEffect(() => {
        if (week) getSummary();
    // eslint-disable-next-line
    }, [week])

    const getSummary = async () => {
        let club_id = location?.state?.club ? location?.state?.club?.id: club?.id;
        if (!club_id) return;
        
        setIsLoading(true);

        let player_id = location?.state?.member?.id
        let request_url = `${PLAYER_GET_SUMMARY + club_id}?report_time=${week}`
        if (player_id) {
            request_url += `&player_id=${player_id}`;
        }
        let res = await getValuebet(request_url)
        if (res) {
            res.forEach((item) => {
                // This line adding kickback with in/out chip
                // when kickback feature will enable this line need to comment.
                item['in_out'] = (item.in_out + item.kickback).toFixed(2);
                item['win_loss'] = item['win_loss'].toFixed(2);
                item['kickback'] = item['kickback'].toFixed(2)
                item['vig_paid'] = item['vig_paid'].toFixed(2)
                item['unsettled'] = item['unsettled'].toFixed(2)
                item['balance'] = item['balance'].toFixed(2)
                item['cust_day'] = `${item.day} (${moment.utc(item.date).utcOffset(APP_OFFSET).format('D/M')})`;
            })
            setSummary(res);
            let total_win_loss = 0;
            let total_in_out = 0;
            let total_kickback = 0;
            let total_vig_paid = 0;
            let total_unsettled = 0;
            let balance = Number(res.at(-1)['balance']);
            for (let hist of res) {
                total_win_loss += Number(hist['win_loss']);
                total_in_out += Number(hist['in_out']);
                total_kickback += Number(hist['kickback']);
                total_vig_paid += Number(hist['vig_paid']);
                total_unsettled += Number(hist['unsettled']);
            }

            let total_obj = {
                cust_day: "Total",
                starting_balance: null,
                win_loss: total_win_loss.toFixed(2),
                in_out: total_in_out.toFixed(2),
                kickback: total_kickback.toFixed(2),
                vig_paid: total_vig_paid.toFixed(2),
                unsettled: total_unsettled.toFixed(2),
                balance: balance.toFixed(2)
            }
            setTotal(total_obj)
        }
        setIsLoading(false);
    }

    return (
        <div>
            <CustomWeekPicker setWeek={setWeek} className={"btn btn-danger btn-sm"} position={"bottom left"}/>
            {!isLoading ?
                <div className="no-tbl-header">
                    <DataTable
                        columns={columns}
                        data={summary}
                        theme="dark"
                        dense={true}
                        header={false}
                        highlightOnHover={true}
                        footer={total}
                    />
                </div> :
                <Spinner />}
        </div>
    )
}
