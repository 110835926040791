import React from 'react'
import { Button } from 'reactstrap'
import { CREATE_NOTES, GET_NOTES, getValuebet, postValuebet } from '../../../../../../API-Handle/services/auth_curd'
import { successNotification } from '../../../../../../Components/CustComp/PPHAlert'

function General({ club, item }) {
  const [notes, setNotes] = React.useState("")

  React.useEffect(() => {
    getNotes();
  }, [])

  const getNotes = async () => {
    let url = GET_NOTES + `?club_id=${club.id}&user_id=${item.id}`;
    const response = await getValuebet(url);
    if (response) {
      setNotes(response)
    }
  }

  const onSaveNote = async () => {
    const body = {
      club_id: club.id,
      user_id: item.id,
      notes: notes
    };
    const response = await postValuebet(CREATE_NOTES, body)
    if (response) {
      successNotification(response.message)
    }
  }

  return (
    <div className='col-md-12'>
      <div className="col-md-6 my-1">
        <label htmlFor="inputprofit" className="form-label text-white font-size-12px">Notes:</label>
        <textarea
          className="form-control bg-dark text-white form-control-sm border-secondary"
          id="exampleFormControlTextarea1"
          rows="3"
          value={notes}
          onChange={(e) => { setNotes(e.target.value) }}
        ></textarea>
      </div>
      <div className='mb-btn col-md-2 my-1'>
        <Button className='w-100 sett-limit-btn' size='sm' color='danger' onClick={onSaveNote} >Save</Button>
      </div>
    </div>
  )
}

export default General
