import React, { useEffect } from 'react'
import { Modal } from "reactstrap"

export default function RewardFAQ({ isVisible, modToggle }) {

    useEffect(() => {
        if (isVisible && !localStorage.getItem('is_reward_faq_viewed')) {
            localStorage.setItem('is_reward_faq_viewed', true);
        }
    }, [isVisible])

    return (
        <Modal isOpen={isVisible} toggle={modToggle} className="modal-dialog-centered modal-xl">
            <div className="modal-header bg-vb-dark">
                <h5 className="modal-title my-0">Reward FAQ</h5>
                <button onClick={modToggle} type="button" className="text-white btn-close" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className='modal-body letter-space'>
                <h6 className='vb-text-orange'>What is the Valuebet.App Reward Network?</h6>
                <p>The Valuebet.App Reward Network is our way of <span className='text-warning'>awarding our members</span> for helping us grow. We award users with Valuebet Coins if their referrels win Valuebet Coins in our challenges.</p>

                <h6 className='vb-text-orange'>Who can be earn Rewards?</h6>
                <p><span className='text-warning'>Anyone who has an account</span> at Valuebet.App is already set up to be earn Reward. There are no tedious Sign-Up processes or additional requirements. All you have to do is start referring others using your User ID.</p>

                <h6 className='vb-text-orange'>How does it work?</h6>
                <p>Give whoever is creating an account at Valuebet.App <span className='text-warning'>your User ID as reference.</span> Once registered, that user is your Tier 1 Referral. If they refer someone, then that someone becomes their Tier 1 Referral, and your Tier 2 Referral, and so forth.</p>

                <h6 className='vb-text-orange'>How many Tier Referrals can we have?</h6>
                <p>Everyone can have <span className='text-warning'>up to Tier 5</span> Referrals. This means you refer Tier 1, Tier 1 referrals is your Tier 2, Tier 2 referrals is your Tier 3, Tier 3 referrals is your Tier 4, Tier 4 referrals is your Tier 5.</p>

                <h6 className='vb-text-orange'>What are the Referral Awards?</h6>
                <p>We are currently <span className='text-warning'>awarding users Valuebet Coins</span> if anyone in their Reward Network wins Valuebet Coins from any of our Challenges.</p>

                <h6 className='vb-text-orange'>How much are the Referral Awards?</h6>
                <p>We are currently awarding <span className='text-warning'>10% of an Referral's winnings.</span> So if anyone in your Reward Network wins 100 Valuebet Coins, you are automatically awarded 10 Valuebet Coins.</p>

                <h6 className='vb-text-orange'>Are there any additional requirements?</h6>
                <p>Yes! You are <span className='text-warning'>required to participate by submitting a bet</span> in a challenge in order to receive any referral awards for that challenge. If you do not submit a bet for a Daily Challenge, you will not receive any Referral awards for that Daily Challenge.</p>
            </div>
        </Modal>
    )
}
