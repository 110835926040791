import { Avatar, AvatarGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BASEURL } from "../../../../../API-Handle/config";

const CustomAvatar = ({ data, name }) => {
  const [avatarColor, setAvatarColor] = useState(null);
  const [avatarTextColor, setAvatarTextColor] = useState(null);

  const customColors = [
    { avatarColor: "#2196F3", textColor: "#FFFFFF" },
    { avatarColor: "#4CAF50", textColor: "#FFFFFF" },
    { avatarColor: "#FFC107", textColor: "#212121" },
    { avatarColor: "#FF5722", textColor: "#FFFFFF" },
    { avatarColor: "#9C27B0", textColor: "#FFFFFF" },
  ];

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * customColors.length);
    setAvatarColor(customColors[randomIndex].avatarColor);
    setAvatarTextColor(customColors[randomIndex].textColor);
  // eslint-disable-next-line 
  }, []);

  return (
    name ? (
      <Avatar
        sx={{
          width: "24px",
          height: "24px",
          fontSize: "14px",
          backgroundColor: avatarColor,
          color: avatarTextColor,
        }}
      >
        {name.toUpperCase()}
      </Avatar>
    ) : (
      <AvatarGroup max={5}>
        {data.map((user) =>
          user.profile_picture ? (
            <Avatar
              key={user.id}
              alt="user-image"
              src={`${BASEURL}/media/${user.profile_picture}`}
              sx={{ width: "24px", height: "24px" }}
            />
          ) : (
            <Avatar
              sx={{
                width: "24px",
                height: "24px",
                fontSize: "14px",
                backgroundColor: avatarColor,
                color: avatarTextColor,
              }}
              key={user.id}
            >
              {user.sender_name[0].toUpperCase()}
            </Avatar>
          )
        )}
      </AvatarGroup>
    )
  );
};

export default CustomAvatar;
