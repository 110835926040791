import React, { useState } from 'react'
import { Collapse } from "reactstrap";

import LeagueButton from './LeagueButton';

function SideList({ item, idx }) {
    const [open, setOpen] = useState(false);

    const toggle = () => {
        setOpen(!open)
    };

    const getImg = (i) => {
        if (i.name === 'FOOTBALL') {
            return <i className='img-football'></i>
        } else if (i.name === 'BASKETBALL') {
            return <i className='img-basketball'></i>
        } else if (i.name === 'BASEBALL') {
            return <i className='img-baseball'></i>
        } else if (i.name === 'HOCKEY') {
            return <i className='img-hockey'></i>
        } else if (i.name === 'FIGHTING') {
            return <i className='img-boxing'></i>
        } else if (i.name === 'SOCCER') {
            return <i className='img-soccer'></i>
        } else if (i.name === 'CRICKET') {
            return <i className='img-cricket'></i>
        }
    }

    return (
        <div>
            <button onClick={toggle} className='side-button'>
                {getImg(item)}
                <p>{item?.name}</p>
            </button>
            <Collapse className='side-list' isOpen={open}>
                {
                    item?.leagues?.map((value, index) => (
                        <LeagueButton key={index} item={item} value={value} />
                    ))
                }
            </Collapse>
        </div>
    )
}

export default SideList