import React, { useState, useRef, useContext } from "react";
import { Modal, Button } from "reactstrap";
import { TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {
  postValuebet,
  UPLOAD_MEDIA_CHAT,
} from "../../../../API-Handle/services/auth_curd";
import { useDispatch, useSelector } from "react-redux";
import ValuebetContext from "../../../../context/Valuebet/ValuebetContext";
import { clubchatAction } from "../../../../redux/features/clubchatSlice";
import BlockLoader from "../../../../Components/CustLoader/BlockLoader";

function ChatImageUploader ({ onClose, selectedFile, imageViewer }) {
  const [message, setMessage] = useState("");
  const [isDisable, setisDisable] = useState(false);
  const inputRef = useRef(null);
  const { chatConnection } = useContext(ValuebetContext);
  const { selectedChannelID, replyMessage } = useSelector(
    (state) => state.clubChat
  );
  const dispatch = useDispatch();

  const onMessageSend = async () => {
    setisDisable(true);
  
    try {
      let imageUrl = await uploadUrl();
  
      if (!imageUrl) {
        setisDisable(false);
        return;
      }
  
      inputRef.current.focus();
      dispatch(clubchatAction.setReplyMessage({ data: null }));
  
      let msgData = {
        command: "message_added",
        channel_id: selectedChannelID,
        media_url: imageUrl,
        media_type: "PHOTO",
      };
  
      if (message.trim()) {
        msgData.message = message;
      }
  
      if (replyMessage) {
        msgData.reply_to = replyMessage.id;
      }
  
      chatConnection.current.sendMessage(msgData);
      setMessage(""); // Clear the input field
      onClose();
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setisDisable(false);
    }
  };
  

  const onChange = (e) => {
    setMessage(e.target.value);
  };

  const handleOnKeyDown = (event) => {
    event.stopPropagation();
    if (event.key === "Enter") {
      event.preventDefault();
      onMessageSend();
    }
  };

  const uploadUrl = async () => {
    let imgUrl = null;
    let formData = new FormData();
    let URL = UPLOAD_MEDIA_CHAT + selectedChannelID;
    formData.append("media_file", selectedFile);
    formData.append("channel_id", selectedChannelID);
    let res = await postValuebet(URL, formData);
    if (res) imgUrl = res.file;
    return imgUrl;
  };
  return (
    <Modal
      isOpen={true}
      centered={true}
      className="text-center align-item-center justify-content-center p-4"
    >
      <>
      <div className="modal-header">
        <div />
        <button
          onClick={onClose}
          type="button"
          className="text-white btn-close"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="m-2 align-self-center pb-4">
        <img src={imageViewer} className="w-50" alt="" />
      </div>

      <div className="d-flex p-2">
        <TextField
          className="form-control-sm me-3"
          placeholder="Your message..."
          onChange={onChange}
          value={message}
          inputRef={inputRef}
          id="chat-input"
          onKeyDown={(e) => handleOnKeyDown(e)}
          multiline
          minRows={1}
          maxRows={5}
          variant="outlined"
          sx={{
            flex: 1,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(224, 63, 0, 0.3215686275)",
              },
              "&:hover fieldset": {
                borderColor: "rgba(224, 63, 0, 0.3215686275)",
              },
              "&.Mui-focused fieldset": {
                borderColor: "rgba(224, 63, 0, 0.3215686275)",
              },
            },
            "& .MuiInputBase-root": {
              overflow: "auto",
            },
          }}
        />
        <Button
          className="font-size-10px"
          size="sm"
          color="danger"
          disabled={isDisable}
          onClick={onMessageSend}
        >
          <SendIcon />
        </Button>
      </div>
      </>
      {isDisable &&<BlockLoader className="root-loader-absolute" />}
    </Modal>
  );
}

export default ChatImageUploader;
