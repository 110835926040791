import React, { useState } from 'react'
import { Link } from "react-router-dom";
import logoag from "../../../../../Static/UserManagement/images/common/club_avtar.png";
import { BASEURL } from '../../../../../API-Handle/config';
import CopyText from '../../../../../Components/CustComp/CopyText';
export default function PrimaryClubInfo({ item }) {
    return (
        <div className='d-flex justify-content-start'>
            <div className='club-logo-setup'>
                {(item.label == "Owner") | (item.label == "Manager") ? (
                    <Link to="/upload-club-icon" state={{ club_data: item }}>
                        {item.club_icon ? (
                            <img className="club-icon" src={BASEURL + item.club_icon} />
                        ) : (
                            <img className="club-icon" src={logoag} />
                        )}
                    </Link>
                ) : item.club_icon ? (
                    <img className="club-icon" src={BASEURL + item.club_icon} />
                ) : (
                    <img className="club-icon" src={logoag} />
                )}
            </div>
            <div className="club-owner-inner mt-1">
                {item.name?.toUpperCase()}
                <div className="font-size-14px mt-1">
                    {" "}User ID : <span className='text-success'>{item.user_id.substring(0, 8)}</span> <CopyText text={`${window.location.origin}?referral_id=${item.user_id}`} />
                </div>
                <div className="font-size-12px mt-1">
                    Daily Chips: <span className='vb-text-orange letter-space'> {item?.user_chips?.player_chips.toFixed()} ({item?.user_chips?.locked_chips.toFixed()}) </span>
                </div>
            </div>
        </div>
    )
}
