import React, { useEffect, useState } from 'react'
import {
    Button,
} from "reactstrap";
import { errorNotification } from '../../../../../../Components/CustComp/PPHAlert';
import { CREATE_INVOICE_URL, postValuebet } from '../../../../../../API-Handle/services/auth_curd';
import { PaymentConfirm } from '../../../../../../Components/SweetAlerts/PaymentConfirm';

export default function TokenDetails({ item, setAllDisabled, allDisabled, discount, code }) {
    const [isTokenBuy, setIsTokenBuy] = useState(false);
    const [cost, setCost] = useState(item.cost);
    const [qty, setQty] = useState(1);

    const handleClick = async () => {
        let confirmation = await PaymentConfirm();
        if (confirmation) {
            setIsTokenBuy(true);
            setAllDisabled(true);
            let data = { item_type: "Token", item_id: item.id, no_of_items: qty }
            if (discount && code !== '') data['coupon_code'] = code;
            let res = await postValuebet(CREATE_INVOICE_URL, data)
            if (res) {
                window.location.href = res.invoice_url;
            }
            setIsTokenBuy(false);
            setAllDisabled(false);
        }
    }

    useEffect(() => {
        setCost(item.cost * qty);
        // eslint-disable-next-line
    }, [qty])

    const onQtyChange = (e) => {
        let val = e.target.value;
        val = Number.parseInt(val);
        if (val) {
            if (val < 1) val = 1;
            if (val > item.remaining_token) val = item.remaining_token;
            setQty(val)
        } else {
            setQty(1)
        }
    }

    const increment = () => {
        let new_qty = qty + 1;
        if (new_qty > item.remaining_token){
            errorNotification("No more tokens are available!")
            new_qty = item.remaining_token;
        } 
        setQty(new_qty)
    }

    const decrement = (e) => {
        let new_qty = qty - 1;
        if (new_qty < 1) new_qty = 1;
        setQty(new_qty)
    }

    return (
        <tr>
            <td className='align-middle w-lg'>{item.name}</td>
            <td className='align-middle ps-3 min-width-170'>{item.description}</td>
            <td className='align-middle text-nowrap'>{item.airdrop_amount} ({item.airdrop_frequency})</td>
            <td className='align-middle'>{item.lifetime_deal ? "Yes" : "No"}</td>
            <td className='align-middle'>{discount ? <span className='text-danger text-decoration-line-through'>${cost}</span> : <span>${cost}</span>}</td>
            {discount && <td className='align-middle'><span className='text-success ps-2'>${cost - (cost * (discount / 100))}</span></td>}
            <td className='align-middle'>
                <div className="input-group flex-nowrap input-group-sm w-0">
                    <span className="input-group-text height-28 bg-dark text-white" role='button' onClick={decrement}>-</span>
                    <input type="number" className="form-control bg-dark text-white coin-input-custom-height" aria-label="Qty" value={qty} onChange={onQtyChange} />
                    <span className="input-group-text height-28 bg-dark text-white" role='button' onClick={increment}>+</span>
                </div>
            </td>
            <td className='align-middle'>
                <Button
                    disabled={isTokenBuy || allDisabled}
                    color='success'
                    className='btn-sm text-nowrap'
                    onClick={handleClick}>
                    {isTokenBuy ? <>&nbsp;<span className="spinner-grow spinner-grow-sm text-white mx-3" role="status"></span>&nbsp;</> : 'Buy Now'}
                </Button> </td>
        </tr>
    )
}
