import React, { useState, useContext, useEffect } from 'react';
import Header from '../../../Components/UserHeader'
import { Row, Col } from 'reactstrap';
import Footer from '../../../Components/Footer';
import ValuebetContext from "../../../context/Valuebet/ValuebetContext";
import ClubAction from '../Dashboard/ClubCard/ClubComponants/ClubAction';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_ALL_CLUB_MEMBER, getValuebet } from '../../../API-Handle/services/auth_curd'
import MembersTable from './MembersTable';
import SmSpinner from '../../../Components/CustLoader/SmSpinner';

export default function Index() {
  const { activeClub, fetchSingleClub } = useContext(ValuebetContext);
  const [cardData, setCardData] = useState(null);
  const navigate = useNavigate();
  const { clubId } = useParams();

  const getMembers = async() => {
    let res = await getValuebet(`${GET_ALL_CLUB_MEMBER + clubId}?detailed=${true}`);
    if (res){
      setCardData(res);
    }
  }

  useEffect(() => {
    if (!clubId) {
      navigate("/")
    } else {
      fetchSingleClub(clubId);
      getMembers();
    }
  // eslint-disable-next-line
  }, [clubId])

  return (
    <React.Fragment>
      <Header />
      <div className="sec-header container-fluid">
        <Row>
          <div className="d-flex justify-content-start align-items-center">
            <h6 className="sec-header-title">
              Members
            </h6>
            {activeClub && <ClubAction item={activeClub} />}
          </div>
        </Row>
      </div>
      <div className='layout-mt-112 fill-view pb-3'>
        <div className="container-fluid mt-4">
          {cardData && activeClub ?
            <div>
              {cardData.length ?
                <MembersTable
                  val={cardData}
                  activeClub={activeClub}
                  dataCallback={getMembers}
                /> :
                <Col className='text-white text-center pt-5 mt-5' md="12"><div>Currently, You have no members in this club.</div> </Col>}
            </div> :
            <SmSpinner text={"Loading Members..."} />}
        </div>
      </div>
      <Footer />
    </React.Fragment>
  )
}