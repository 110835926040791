import React from 'react'
import DataTable from 'react-data-table-component-footer';

function CustomTable(props) {
  return (
    <div className="no-tbl-header">
        <DataTable
			    pagination
			    theme="dark"
			    {...props}
		    />
    </div>
  )
}

export default CustomTable