import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import { REWARD_MEMBERS_DETAILS } from '../../../../API-Handle/services/auth_curd';
import { getValuebet } from '../../../../API-Handle/services/auth_curd';
import NestedExpand from './NestedExpand';
import ListLoader from '../../../../Components/CustLoader/ListLoader';

function CustomHeading({ label1, label2, total }) {
    return (
        <div className='py-1'><div className='tbl-head-first-row'>{label1} <br/>{label2}</div> <div className='tbl-head-second-row'>{total}</div></div>
    )
}

const init_columns = [
    {
        name: <CustomHeading label1="Username" label2="‎" total="Tier 1" />,
        selector: row => row.username,
        // width:"150px",
        width:"185px",
    },
    {
        name: <CustomHeading label1="Total" label2="Referrals" total="0" />,
        selector: row => row.total_referrals,
    },
    {
        name: <CustomHeading label1="Coins Won" label2="By Affiliates" total="0" />,
        selector: row => row.coins_won_by_affiliates,
        minWidth:"110px"
    },
    {
        name: <CustomHeading label1="Coins Awarded" label2="To Affiliates" total="0" />,
        selector: row => row.coins_awarded_to_affiliates,
        minWidth:"120px"
    },
    {
        name: <CustomHeading label1="W-L By" label2="Affiliates" total="0-0" />,
        selector: row => row.wl_by_affiliates,
        minWidth:"110px"
    },
    {
        name: <CustomHeading label1="Coins Won" label2="By User" total="0" />,
        selector: row => row.coins_won_by_user,
        minWidth:"110px"
    },
    {
        name: <CustomHeading label1="Coins Awarded" label2="To User" total="0" />,
        selector: row => row.coins_awarded_to_user,
        minWidth:"120px"
    },
    {
        name: <CustomHeading label1="Signed Up" label2="Date" total="‎" />,
        selector: row => row.signup_date,
        minWidth:"105px",
    },
    {
        name: <CustomHeading label1="Last Active" label2="Date" total="‎" />,
        selector: row => row.last_active,
        minWidth:"115px"
    },
];


const conditionalRowStyles = [
    {
        when: row => row,
        style: {
            backgroundColor: '#fd4f3f82',
            color: 'white',
        },
    },
];

export default function Members() {
    const [report, setReport] = useState(null)
    const [columns, setColumns] = useState(init_columns);

    const getReports = async () => {
        setReport(null)
        let resp = await getValuebet(REWARD_MEMBERS_DETAILS);
        if (resp) {
            setColumns((prev) => {
                let main_info = resp.total;
                prev[1].name = <CustomHeading label1="Total" label2="Referrals" total={main_info.total_referrals} />
                prev[2].name = <CustomHeading label1="Coins Won" label2="By Affiliates" total={main_info.coins_won_by_affiliates} />
                prev[3].name = <CustomHeading label1="Coins Awarded" label2="To Affiliates" total={main_info.coins_awarded_to_affiliates} />
                prev[4].name = <CustomHeading label1="W-L By" label2="Affiliates" total={main_info.wl_by_affiliates} />
                prev[5].name = <CustomHeading label1="Coins Won" label2="By User" total={main_info.coins_won_by_user} />
                prev[6].name = <CustomHeading label1="Coins Awarded" label2="To User" total={main_info.coins_awarded_to_user} />
                return prev;
            })
            setReport(resp.affiliate_details);
        }
    }

    useEffect(() => {
        getReports();
    }, [])

    return (
        <div className="no-tbl-header pt-0 responsive-expand split-header">
            {report ?
                <DataTable
                    columns={columns}
                    data={report}
                    theme="dark"
                    dense={true}
                    header={false}
                    expandableRows
                    expandableRowsComponent={<NestedExpand level={2}/>}
                    conditionalRowStyles={conditionalRowStyles}
                /> :
                <ListLoader />}
        </div>
    )
}
