import React from 'react'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Popover from '@mui/material/Popover';

export default function CustTooltip({ id, icon = false, text}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <div>
            <div
                role='button'
                aria-owns={open ? "popover" + id : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                className='d-flex align-items-center ms-2'
            >
                {icon ? icon : <HelpOutlineOutlinedIcon className='text-white' sx={{ fontSize: 17 }} />}
            </div>
            <Popover
                id={"popover" + id}
                sx={{ pointerEvents: 'none' }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                disableScrollLock={true}
            >
                <p className='px-1 py-2 font-size-12px mb-0' style={{maxWidth:300}}>{text}</p>
            </Popover>
        </div>
    )
}
