import React, { useContext } from 'react'
import NavigationBar from '../../../Components/SportsbookHeader/NavigationBar'
import { Row } from 'reactstrap'
import ValuebetContext from '../../../context/Valuebet/ValuebetContext';
import TabSetup from './TabSetup';

function Index() {
    const { activeClub } = useContext(ValuebetContext);
    return (
        <div>
            <NavigationBar />
            <div className="sec-header container-fluid">
                <Row>
                    <div className="py-1 d-flex justify-content-start align-items-center">
                        <h6 className="sec-header-title font-size-14px">
                            {activeClub ? activeClub?.name?.toUpperCase() : ''} Club's Reports
                        </h6>
                    </div>
                </Row>
            </div>
            <div className='layout-mt-112 fill-view'>
                <div className="container-fluid mt-4">
                    <TabSetup club={activeClub} />
                </div>
            </div>
        </div>
    )
}

export default Index;