import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment-timezone';
import { APP_OFFSET } from '../../constant';
import Popup from 'reactjs-popup';
import { ArrowDropDown } from '@mui/icons-material';

function getDateRangeOfWeek(date) {
  let startdate = moment(date).startOf('isoWeek')
  let enddate = moment(date).endOf('isoWeek')
  return startdate.format("L") + " - " + enddate.format("L");
};

const getCurrentPSTISOWeek = () => {
  return moment().utcOffset(APP_OFFSET).isoWeekYear() + "-W" + moment().utcOffset(APP_OFFSET).format("WW")
}

const lastDateOfCurrentWeek = () => {
  return moment().utcOffset(APP_OFFSET).endOf('week').toDate()
};

const getISOWeek = (date) => {
  let momentTime = moment(date);
  return momentTime.isoWeekYear() + "-W" + momentTime.format("WW")
};

export default function CustomWeekPicker({ setWeek, className, position }) {
  const [value, setValue] = useState(moment().utcOffset(APP_OFFSET).toDate());
  const [selectedWeek, setSelectedWeek] = useState(getCurrentPSTISOWeek());
  const [dateRange, setDateRange] = useState("This Week");

  useEffect(() => {
    setWeek(selectedWeek)
    // eslint-disable-next-line
  }, [selectedWeek])

  const tileClassName = ({ date }) => {
    const isoWeek = getISOWeek(date);
    let className = '';
    if (isoWeek === selectedWeek) className = 'custom-tile-highlighted';
    return className;
  };

  const onSelectWeek = (date, close) => {
    setValue(date)
    let isoWeek = getISOWeek(date);
    setSelectedWeek(isoWeek);
    close();
    if (isoWeek === getCurrentPSTISOWeek()) {
      setDateRange("This Week")
    } else {
      let date_range = getDateRangeOfWeek(date)
      setDateRange(date_range)
    }
  }

  return (
    <>
      <Popup trigger={
        <button type="button" className={className}> {dateRange} <ArrowDropDown fontSize="small" /></button>}
        position={position}
        contentStyle={{zIndex:"99999999"}}
      >
        {close => (
          <div>
            <Calendar
              value={value}
              tileClassName={tileClassName}
              onChange={(date) => onSelectWeek(date, close)}
              maxDetail="month"
              maxDate={lastDateOfCurrentWeek()}
            />
          </div>)}
      </Popup>
    </>
  );
}
