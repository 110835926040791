import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import { REWARD_MEMBERS_DETAILS, getValuebet } from '../../../../API-Handle/services/auth_curd';

export default function NestedExpand({ data, level }) {
    const [affiliates, setAffiliates] = useState(null);
    const [columns, setColumns] = useState([
        {
            name: "",
            selector: row => row.username,
            // width: level !==  5 ? 157.5-(level*7.5) + "px" : "129px",
            width: level !==  5 ? 192.5-(level*7.5) + "px" : "162px",
        },
        {
            name: "",
            selector: row => row.total_referrals,
        },
        {
            name: "",
            selector: row => row.coins_won_by_affiliates,
            minWidth:"110px"
        },
        {
            name: "",
            selector: row => row.coins_awarded_to_affiliates,
            minWidth:"120px"
        },
        {
            name: "",
            selector: row => row.wl_by_affiliates,
            minWidth:"110px"
        },
        {
            name: "0",
            selector: row => row.coins_won_by_user,
            minWidth:"110px"
        },
        {
            name: "0",
            selector: row => row.coins_awarded_to_user,
            minWidth:"120px"
        },
        {
            name: "",
            selector: row => row.signup_date,
            minWidth:"105px",
        },
        {
            name: "",
            selector: row => row.last_active,
            minWidth:"115px"
        },
    ]);

    const conditionalRowStyles = [
        {
            when: (row) => level === 2,
            style: {
                backgroundColor: '#bf6158b8',
                color: 'white',
            },
        },
        {
            when: (row) => level === 3,
            style: {
                backgroundColor: '#9f736f9e',
                color: 'white',
            },
        },
        {
            when: (row) => level === 4,
            style: {
                backgroundColor: '#3429289e',
                color: 'white',
            },
        },
        {
            when: (row) => level === 5,
            style: {
                backgroundColor: '#0000007a',
                color: 'white',
            },
        },
    ];

    const expandableIcon = {
        collapsed: <Add style={{ fontSize: 18-(level*2) }} />,
        expanded: <Remove style={{ fontSize: 18-(level*2) }} />,
    };

    const getAffiliates = async () => {
        setAffiliates(null)
        let resp = await getValuebet(REWARD_MEMBERS_DETAILS+"?affiliate_id="+data.user_id);
        if (resp) {
            setAffiliates(resp.affiliate_details);
        }
    }

    useEffect(()=>{
        getAffiliates()
        // eslint-disable-next-line
    }, [data, level])
        
    return (
        <div className={`py-1 ps-${level !== 5 ? "2" : "5"} responsive-expand`}>
            {affiliates &&
            <DataTable
                columns={columns}
                data={affiliates}
                theme="dark"
                dense={true}
                header={false}
                noTableHead={true}
                expandableIcon={expandableIcon}
                expandableRows={level !== 5 ? true : false}
                expandableRowsComponent={<NestedExpand level={level+1}/>}
                conditionalRowStyles={conditionalRowStyles}
            />}
        </div>
    )
}
