import React, { useState } from 'react'
import NavigationBar from '../../../Components/SportsbookHeader/NavigationBar'
import { Link } from 'react-router-dom';
import RewardSummary from './RewardSummary';
import Members from './Members'


function Index() {
    const [activeTab, setActiveTab] = useState(1);

    return (
        <div>
            <NavigationBar />
            <div className='layout-mt-65-100 fill-view'>
                <div className="container-fluid mt-4">
                    <div className='border rounded border-danger'>
                        <div className="card mb-0 bg-vb-dark overflow-auto text-white">
                            <div className="card-header seperater">
                                <ul className="nav nav-tabs card-header-tabs">
                                    <li className="nav-item">
                                        <span role='button' className={activeTab === 1 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"}
                                            onClick={() => setActiveTab(1)} >Summary</span>
                                    </li>
                                    <li className="nav-item">
                                        <span role='button' className={activeTab === 2 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"}
                                            onClick={() => setActiveTab(2)} >Members</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="card-body">
                                {activeTab === 1 ?
                                    <RewardSummary /> :
                                    activeTab === 2 ?
                                        <Members /> :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index;