import React, { useState } from "react";
import MemberBets from "./MemerBets";
import MemberSummary from './MemberSummary';

const renderTabs = (type, club, item) => {
  switch (type) {
    case 1:
      return <MemberBets club={club} item={item} />;
    case 2:
      return <MemberSummary club={club} item={item} />;
    default:
      return null;
  }
};
function Wager({ club, item }) {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <div>
      <div className="btn-group" role="group">
        <button type="button" className={activeTab === 1 ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setActiveTab(1)}>
          Bets
        </button>
        <button type="button" className="btn btn-secondary me-0 ms-0 pe-0 ps-0" disabled></button>
        <button type="button" className={activeTab === 2 ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setActiveTab(2)}>
          Summary
        </button>
      </div>
      {renderTabs(activeTab, club, item)}
    </div>
  );
}

export default Wager;
