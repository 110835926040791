import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import { CLUB_BET_HISTORY } from '../../../../../API-Handle/services/auth_curd';
import { getValuebet } from '../../../../../API-Handle/services/auth_curd';
import { useLocation } from 'react-router-dom'
import CustomWeekPicker from '../../../../../Components/Calendar/CustomWeekPicker';
import Spinner from '../../../../../Components/CustLoader/Spinner';
import ExpView from './ExpView';

const conditionalRowStyles = [
    {
        when: row => row?.status === "TOTAL",
        style: {
            backgroundColor: '#ffb100',
            marginBottom: "5px",
            color: '#000000',
        },
    },
];

const columns = [
    {
        name: 'Player',
        selector: row => row.status === "TOTAL" ? <b>{row.username}</b> : row.username,
        minWidth: "200px"
    },
    {
        name: 'Risk',
        selector: row => row.risking,
    },
    {
        name: 'To Win',
        selector: row => row.winnings,
    },
    {
        name: 'Club Results',
        selector: row => row.result,
    },
    {
        name: 'Vig Chips Burned',
        selector: row => row.vig_paid,
    },
    // {
    //     name: 'Kickbacks',
    //     selector: row => row.kickback,
    // },
    {
        name: 'Club Chips Win/Loss',
        selector: row => row.net,
    },
    {
        name: 'Date',
        selector: row => row.date,
        minWidth: "200px"
    }
];

export default function Index() {
    const [preparedData, setPreparedData] = useState(null)
    const [totalForWeek, setTotalForWeek] = useState({})
    const [week, setWeek] = useState(null);

    const location = useLocation()

    const getBets = async () => {
        setPreparedData(null)
        
        let club = location?.state?.club;
        if (!club) return;

        let club_id = club?.id;        
        let request_url = CLUB_BET_HISTORY + club_id + "?bet_type=SETTLED";
        if (week) {
            request_url += `&report_time=${week}`;
        }
        let bets = await getValuebet(request_url);
        groupByDay(bets);
    }

    const groupByDay = async (data) => {
        let total_for_week = {
            id: `TOTALWEEK`, status: 'TOTALWEEK', username: `Total for Week (${week})`, 
            risking: 0, winnings: 0, result: 0, kickback: 0, vig_paid: 0, net: 0, date: "-"
        }
        let collections = []
        for (let groups of data) {
            let users = Object.values(groups)?.length ? Object.values(groups)[0] : [];
            let date = Object.keys(groups)[0]
            let total_for_day = {
                id: `TOTAL${date}`, status: 'TOTAL', username: `Total for Day (${date})`,
                risking: 0, winnings: 0, result: 0, kickback: 0, vig_paid: 0, net: 0, date: "-"
            }
            for (let user of users){
                collections.push(user);
                total_for_day['risking'] += user.risking
                total_for_day['winnings'] += user.winnings
                total_for_day['result'] += user.result
                total_for_day['kickback'] += user.kickback
                total_for_day['vig_paid'] += user.vig_paid
                total_for_day['net'] += user.net
            }
            collections.push(total_for_day)
            total_for_week['risking'] += total_for_day['risking']
            total_for_week['winnings'] += total_for_day['winnings']
            total_for_week['result'] += total_for_day['result']
            total_for_week['kickback'] += total_for_day['kickback']
            total_for_week['vig_paid'] += total_for_day['vig_paid']
            total_for_week['net'] += total_for_day['net']
        }

        // Fixed decimal number 
        for (let one_coll of collections){
            one_coll['risking'] = one_coll['risking'].toFixed(2)
            one_coll['winnings'] = one_coll['winnings'].toFixed(2)
            one_coll['result'] = one_coll['result'].toFixed(2)
            one_coll['kickback'] = one_coll['kickback'].toFixed(2)
            one_coll['vig_paid'] = one_coll['vig_paid'].toFixed(2)
            one_coll['net'] = one_coll['net'].toFixed(2)
        }
        setPreparedData(collections)

        // Fixed decimal number 
        total_for_week['risking'] = total_for_week['risking'].toFixed(2)
        total_for_week['winnings'] = total_for_week['winnings'].toFixed(2)
        total_for_week['result'] = total_for_week['result'].toFixed(2)
        total_for_week['kickback'] = total_for_week['kickback'].toFixed(2)
        total_for_week['vig_paid'] = total_for_week['vig_paid'].toFixed(2)
        total_for_week['net'] = total_for_week['net'].toFixed(2)
        setTotalForWeek(total_for_week)
    }

    useEffect(() => {
        if (week) getBets();
        // eslint-disable-next-line
    }, [week])

    return (
        <>
            <div className='inline-datepicker-container'>
                <CustomWeekPicker setWeek={setWeek} className={"settled-datepicker"} position={"bottom right"}/>
            </div>
            {preparedData ?
                <DataTable
                    columns={columns}
                    data={preparedData}
                    expandableRows
                    expandableRowsComponent={<ExpView />}
                    expandableRowDisabled={(row) => (row.status === 'TOTAL')}
                    theme="dark"
                    dense={true}
                    header={false}
                    highlightOnHover={true}
                    conditionalRowStyles={conditionalRowStyles}
                    footer={totalForWeek}
                // noTableHead={index}
                /> :
                <Spinner />}
        </>
    )
}
