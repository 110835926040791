import React, { useContext, useEffect } from 'react'
import NavigationBar from '../../../Components/SportsbookHeader/NavigationBar'
import ValuebetContext from '../../../context/Valuebet/ValuebetContext';
import SideBar from './SideBar'
import Odds from './Events'
import Bets from './Bets'
import { useLocation } from 'react-router-dom';

function Index() {
  const { getSports } = useContext(ValuebetContext);
  const location = useLocation();

  useEffect(() => {
    let club_id = location?.state?.club?.id;
    getSports(club_id);
    // eslint-disable-next-line
  }, [])


  return (
    <React.Fragment>
      <NavigationBar />
      <div className='sportbook-container-fluid' >
        <SideBar />
        <Odds />
        <Bets />
      </div>
    </React.Fragment>
  )
}

export default Index