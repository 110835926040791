import React, { useContext, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { SWITCH_MEMBERS, postValuebet } from '../../../API-Handle/services/auth_curd'
import Header from '../../../Components/UserHeader'
import Footer from '../../../Components/Footer';
import Switch from "react-switch";
import AgentCard from "./AgentCard"
import ClubAction from '../Dashboard/ClubCard/ClubComponants/ClubAction';
import ValuebetContext from '../../../context/Valuebet/ValuebetContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { DefaultConfirm } from '../../../Components/SweetAlerts/DefaultConfirm';
import SmSpinner from '../../../Components/CustLoader/SmSpinner';

export default function Index() {
  const { activeClub, setActiveClub, fetchSingleClub } = useContext(ValuebetContext);
  const [agentData, setAgentData] = useState(null);
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const dataCallback = () => {
    let club_id = location.state.club_data.id
    fetchSingleClub(club_id)
  }

  // Get Separate list of agents
  const initAgents = () => {
    let agent_data = activeClub.agents?.sort((a, b) => (a.username > b.username) ? 1 : ((b.username > a.username) ? -1 : 0));
    setAgentData(agent_data)
  }

  useEffect(() => {
    let club_data = location.state?.club_data
    if (!club_data) {
      navigate('/');
    } else {
      setActiveClub(club_data)
      dataCallback();
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (activeClub) {
      initAgents();
    }
    // eslint-disable-next-line
  }, [activeClub])

  const switchMember = async (item) => {
    let confirmation = await DefaultConfirm("Role Change", "Do you want to change role of this agent?")
    if (confirmation) {
      item['club_id'] = location.state.club_data.id;
      await postValuebet(SWITCH_MEMBERS, item)
      dataCallback()
    }
  }

  const handle = (checked) => {
    setChecked(checked)
  }

  return (
    <React.Fragment>
      <Header />
      <div className="sec-header container-fluid">
        <Row>
          <Col className='d-flex align-items-center justify-content-start'>
            <h6 className='sec-header-title'>Agents</h6>{activeClub && <ClubAction item={activeClub} />}
          </Col>
          <Col className='d-flex align-items-center justify-content-end'>
            <div>
              <label htmlFor="material-switch" className='mb-0 mt-2'>
                <Switch
                  checked={checked}
                  onChange={handle}
                  onColor="#ffac8b"
                  onHandleColor="#e03f00"
                  handleDiameter={26}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={18}
                  width={48}
                  className="react-switch"
                  id="material-switch"
                />
              </label>
            </div>
          </Col>
        </Row>
      </div>
      <div className='layout-mt-112 fill-view'>
        <div className="container-fluid mt-4">
          {agentData ?
            <Row>
              {agentData.length ? agentData.map((val, index) => {
                return (
                  <AgentCard
                    key={index}
                    val={val}
                    checked={checked}
                    agentData={agentData}
                    activeClub={activeClub}
                    switchMember={switchMember}
                    dataCallback={dataCallback}
                  />
                );
              }) :
                <Col className='text-white text-center pt-5 mt-5' md="12"><div>Currently, You have no agents in this club.</div> </Col>}
            </Row>
            :
            <SmSpinner text={"Loading Agents..."}/>}
        </div>
      </div>
      <Footer />
    </React.Fragment>
  )
}