import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap';
import SetRequests from '../../../../../../../Components/WalletCommanTabs/SetRequests';
import ChipsHistory from '../../../../../../../Components/WalletCommanTabs/ChipsHistory';

export default function PlayerWallet({ item }) {
    const [transferChipModal, setTransferChipModal] = useState(false);
    const [activeTab, setActiveTab] = useState(1);

    const toggleClubChipModal = () => {
        setTransferChipModal(!transferChipModal)
    };

    return (
        <>
            <Modal isOpen={transferChipModal} toggle={toggleClubChipModal} className="modal-dialog-centered modal-xl">
                <div className="modal-header">
                    <button onClick={toggleClubChipModal} type="button" className="text-white btn-close" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody>
                    <div className="card mb-0 text-start bg-vb-dark">
                        <div className="card-header seperater">
                            <ul className="nav nav-tabs card-header-tabs">
                                <li className="nav-item">
                                    <span className={activeTab === 1 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"} role='button' onClick={() => setActiveTab(1)}>Chips request</span>
                                </li>
                                <li className="nav-item">
                                    <span className={activeTab === 2 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"} role='button' onClick={() => setActiveTab(2)}>History</span>
                                </li>
                            </ul>
                        </div>
                        {activeTab === 1 ?
                            <div className="card-body">
                                <SetRequests item={item} />
                            </div>
                            : activeTab === 2 ?
                                <div className="card-body">
                                    <ChipsHistory club_id={item.id} wallet_type="PLAYER" />
                                </div>
                                : null}
                    </div>
                </ModalBody>
            </Modal>

            <button type="button" onClick={toggleClubChipModal} className='club-chip-button'>
                {" "}Player Wallet: {item.user_chips?.player_chips.toFixed(2)}({item.user_chips?.locked_chips.toFixed(2)}){" "}
            </button>
        </>
    )
}
