import React, { useEffect, useState } from 'react';
import {Box } from '@mui/material';
import Spinner from '../../../../../../Components/CustLoader/Spinner';
import MarketUI from './MarketUI';

const PropsTypeButtons=({menu,selectedOption,onClick})=>{
    return(
        <button
        className={selectedOption === menu.value ? 'head-button-active' : 'head-button'}
        onClick={onClick}
        >
          {menu.name}  
      </button>
    )
}

function PropsComponent({loading, item, title, propsMarketData, getPropsMarket}) {
    const menuItems = [
        { name: 'All', value: 'All' },
        { name: 'Team Props', value: 'TYPE_TEAM' },
        { name: 'Player Props', value: 'TYPE_PLAYER' },
        { name: 'Result', value: 'TYPE_RESULT' },
    ];
    const [selectedOption, setSelectedOption] = useState('All');
    const handleClick=(type)=>{
        setSelectedOption(type);
        getPropsMarket(type);
    }
    useEffect(()=>{
        getPropsMarket(selectedOption)
    // eslint-disable-next-line
    },[])

   
    return (

        <div className='mt-4'>
            {menuItems.map((menu,index)=>(
                <PropsTypeButtons menu={menu} selectedOption={selectedOption} key={index} onClick={()=>handleClick(menu.value)}/>
            ))}
            {loading?<Spinner/>:<MarketUI item={item} title={title} propsMarketData={propsMarketData}/>}
            

        </div>
    )
}

function ExtendedComponent() {
    return <div>Extended Content</div>;
}

export default function MoreDetailsTab({loading,item,title,propsMarketData,getPropsMarket}) {
    const [activeTab, setActiveTab] = useState(0);
   

    // const handleChange = (event, newValue) => {
    //     setActiveTab(newValue);
    // };

    return (
        <Box sx={{ width: '100%' }}>
            {/* <Tabs
                value={activeTab}
                onChange={handleChange}
                variant="fullWidth"
                sx={{
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#e03f00', // Underline color
                        height: '2px', // Thickness of the underline
                    },
                    '& .MuiTab-root': {
                        color: '#fff', // Inactive tab color
                    },
                    '& .Mui-selected': {
                        color: '#e03f00 !important', // Active tab text color
                    },
                }}
            >
                <Tab label="Props" />
                <Tab label="Extended" />
            </Tabs> */}
                {activeTab === 0 && <PropsComponent  loading={loading} item={item} title={title} propsMarketData={propsMarketData} getPropsMarket={getPropsMarket} />}
                {activeTab === 1 && <ExtendedComponent />}
        </Box>
    );
}
