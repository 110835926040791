import React from 'react'
import {
    Card,
    Button,
} from "reactstrap";
// import moment from 'moment';
import AgentOverview from "./AgentOverview";
import OMClubWallets from "./OMClubWallets";
// import AClubWallets from "./AClubWallets";
import PClubWallets from "./PClubWallets";
import ClubInfo from "./ClubInfo";
import ClubAction from "./ClubAction";
import { useNavigate } from "react-router";
import Leaderboard from './Leaderboard';
import ClubStats from './ClubStats';

export default function Index({ item }) {
    const navigate = useNavigate();

    return (
        <Card className="club-card-container">
            <div className='club-cap-regular'>
                <span className='mt-1 me-1 d-flex justify-content-end'>
                    <ClubAction item={item} />
                </span>
            </div>
            <div className='px-2 pb-3'>
                <div className='mt-1'>
                    <ClubInfo item={item} />
                 </div>
                <div className="mt-3">
                {
                    item.label === "Owner" || item.label === "Manager" ?
                    <OMClubWallets item={item} /> :
                    // item.label == "Agent" || item.label == "Sub-Agent" ?
                    //   <AClubWallets item={item} />:
                    item.label === "Player" ?
                        <PClubWallets item={item} />
                        : null
                }
                </div>
                
                {["Agent","Sub-Agent"].includes(item.label) && <AgentOverview item={item} />}
                {/* // TODO: Temp Unlocked */}
                {/* {(item.level_details.leaderboard && (item.leaderboard_status.daily || item.leaderboard_status.weekly || item.leaderboard_status.monthly)) ? */}
                {(item.leaderboard_status.daily || item.leaderboard_status.weekly || item.leaderboard_status.monthly) ?
                <div className='mt-2'>
                    <Leaderboard club={item} />
                </div> :
                ["Owner", "Manager"].includes(item.label) && <ClubStats item={item} />}

                <div className='px-2 mt-3'>
                    <Button className='playnowbtn' onClick={() => navigate("/sportbook/sports", {state: { club: item } })} >Play Now</Button>
                </div>
            </div>
        </Card>
    )
}
