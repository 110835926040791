import React, { useState, useEffect } from 'react';
import ChipButton from '../../../../../Components/CustComp/ChipButton';
import {
    Modal
} from "reactstrap";
import ChipsTab from '../../../../../Components/CustComp/ChipsTab';


function ChipPlayer({ row, activeClub, dataCallback }) {
    const [options, setOptions] = useState([])
    const [transferChipModal, setTransferChipModal] = useState(false);

    const initialization = () => {
        // Creating Options
        setOptions([{ member_id: row.id, username: row.username, chips: row.details.chips.player_chips, locked: row.details.chips.locked_chips, wallet_type: 'P', uuid: row.id + "P" }])
    }

    useEffect(() => {
        initialization()
    // eslint-disable-next-line
    }, [row])

    const toggleClubChipModal = () => {
        setTransferChipModal(!transferChipModal)
    };

    return (
        <>
            <Modal isOpen={transferChipModal} toggle={toggleClubChipModal} className="modal-dialog-centered modal-xl">
                <div className="modal-header">
                    {/* uncomment when agent feature will enable */}
                    {/* <h5 className="modal-title my-0"> Transfer Chips from/to {location.state?.club_data.label}'s AgentWallet</h5> */}
                    <h5 className="modal-title my-0"> Transfer Chips from/to Club's Wallet</h5>
                    <button onClick={toggleClubChipModal} type="button" className="text-white btn-close" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="card mb-0 text-start bg-vb-dark">
                    <div className="card-body pt-2">
                        {/* <ChipsTab options={options} club_id={activeClub.id} agent_id={userDetails.id} origin="AGENT" callBack={dataCallback} toggleClubChipModal={toggleClubChipModal} /> */}
                        <ChipsTab options={options} club_id={activeClub.id} agent_id={null} origin="CLUB" callBack={dataCallback} toggleClubChipModal={toggleClubChipModal} showResetStaus={false} />
                    </div>
                </div>
            </Modal>
            <ChipButton
                title={"Chips"}
                chipA={row.details.chips.player_chips}
                chipB={row.details.chips.locked_chips}
                toggleClubChipModal={toggleClubChipModal}
            />
        </>
    )
}


export default ChipPlayer;