import React from 'react'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

function BlankChat() {
  return (
    <div className='p-2 py-5 h-100 text-white m-1 d-flex justify-content-center align-items-center'>
      <div className='text-center'>
      <ChatBubbleOutlineIcon sx={{fontSize:"60px"}}/>
       <h5 className='text-center mt-3'>Select a chat to start messaging</h5>
       </div>
    </div>
  )
}

export default BlankChat
