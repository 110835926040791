import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { postValuebet, UPDATE_NOTIFICATION_STATUS, GET_NOTIFICATION_STATUS, getValuebet } from "../../API-Handle/services/auth_curd";
import Switch from "react-switch";
import { getFCMToken } from "../../firebase/firebaseConfig";
import { List, ListItem, ListItemText } from "@mui/material";
import { successNotification } from "./PPHAlert";

const NotiType = [
    { value: 'NEW_SIGNUP', name: 'NEW SIGNUP' },
    { value: 'CLUB_REQUEST', name: 'NEW CLUBS APPLICANTS' },
    { value: 'ACCEPT_USER_REQUEST', name: 'NEW APPLICANT ACCEPTED' },
    { value: 'CHIPS_TRANSFER', name: 'CHIPS TRANSFER' },
    { value: 'CHIPS_REQUEST', name: 'CHIPS REQUEST' },
    { value: 'LEAVE_CLUB', name: 'LEAVE CLUB' },
    { value: 'SUBSCRIPTION_EXPIRE', name: 'SUBSCRIPTION EXPIRE' },
    { value: 'SENT_MESSAGE', name: 'NEW MESSAGE' },
    { value: 'STOP_LIMITS', name: 'STOP LIMITS' },
    { value: 'VALUEBET_UPDATES', name: 'VALUEBET UPDATES' },
    
];

export default function PushNotiStatus() {
    const [toggleStatuses, setToggleStatuses] = useState({
        NEW_SIGNUP: false,
        CLUB_REQUEST: false,
        ACCEPT_USER_REQUEST: false,
        CHIPS_TRANSFER: false,
        CHIPS_REQUEST: false,
        LEAVE_CLUB: false,
        SUBSCRIPTION_EXPIRE: false,
        SENT_MESSAGE: false,
        STOP_LIMITS:false,
        VALUEBET_UPDATES:false

    });
    const [isNotiSettingOpen, setIsNotiSettingOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentClickIndex, setCurrentClickIndex] = useState(-1);

    useEffect(() => {
        getNotificationStatus();
    }, []);

    const getNotificationStatus = async () => {
        const res = await getValuebet(GET_NOTIFICATION_STATUS);
        if (res) setToggleStatuses(res);
    }

    const toggle = () => {
        setIsNotiSettingOpen((prev) => !prev);
    };

    const onPressToggle = async (type, index) => {
        setLoading(true);
        setCurrentClickIndex(index);
        const token = await getFCMToken(true);
        if (token) {
            const data = { fcm_token: token, noti_type: type, noti_status: !toggleStatuses[type] };
            let res = await postValuebet(UPDATE_NOTIFICATION_STATUS, data);
            if (res) {
                setToggleStatuses((prevStatuses) => ({
                    ...prevStatuses,
                    [type]: !prevStatuses[type]
                }));
                setLoading(false);
                successNotification(res.message);
            }
        }
    };

    return (
        <>
            <button onClick={toggle} id="notification-status" className="d-none">toggle</button>
            <Modal
                isOpen={isNotiSettingOpen}
                className="modal-dialog-centered modal-md"
                toggle={toggle}
            >
                <div className="modal-header">
                    <h5 className="modal-title my-0"> Push Notification Status</h5>
                    <button onClick={toggle} type="button" className="text-white btn-close" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody>
                    <div>
                        <List>
                            {NotiType.map((type, index) => (
                                <ListItem
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        transition: "background-color 0.3s",
                                        "&:hover": {
                                            backgroundColor: "action.hover",
                                        },
                                        padding: "2px 15px",
                                    }}
                                >
                                    <ListItemText primary={type.name} />
                                    <Switch
                                        onChange={() => onPressToggle(type.value, index)}
                                        checked={toggleStatuses[type.value]}
                                        onColor="#ffac8b"
                                        onHandleColor="#e03f00"
                                        handleDiameter={19}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={13}
                                        width={34}
                                        disabled={index === currentClickIndex ? loading : false}
                                        className="react-switch ms-2"
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}
