import React from 'react'
import { Modal } from "reactstrap"
import registerImg from '../../Static/UserManagement/images/services-icon/register.png'
import crtClubImg from '../../Static/UserManagement/images/services-icon/crtClub.png'
import joinClubImg from '../../Static/UserManagement/images/services-icon/joinClub.png'
import applicantImg from '../../Static/UserManagement/images/services-icon/applicant.png'
import playerSecImg from '../../Static/UserManagement/images/services-icon/playerSec.png'
import coinInfoImg from '../../Static/UserManagement/images/services-icon/coinInfo.png'
import cryptoPayImg from '../../Static/UserManagement/images/services-icon/cryptoPay.png'
import tokensImg from '../../Static/UserManagement/images/services-icon/tokens.png'
import assignTokenImg from '../../Static/UserManagement/images/services-icon/assignToken.png'
import userSettingImg from '../../Static/UserManagement/images/services-icon/userSetting.png'
import chipInfoImg from '../../Static/UserManagement/images/services-icon/chipInfo.png'
import chipTransactionImg from '../../Static/UserManagement/images/services-icon/chipTransaction.png'
import chipReqImg from '../../Static/UserManagement/images/services-icon/chipReq.png'
import clubSettingsImg from '../../Static/UserManagement/images/services-icon/clubSettings.png'
import upgradeClubImg from '../../Static/UserManagement/images/services-icon/upgradeClub.png'
import clubLimitsImg from '../../Static/UserManagement/images/services-icon/clubLimits.png'
import playerLimitsImg from '../../Static/UserManagement/images/services-icon/playerLimits.png'
import oddsLimitsImg from '../../Static/UserManagement/images/services-icon/oddsLimits.png'
import clubDashImg from '../../Static/UserManagement/images/services-icon/clubDash.png'
import betReportImg from '../../Static/UserManagement/images/services-icon/betReport.png'
import clubBetReportImg from '../../Static/UserManagement/images/services-icon/clubBetReport.png'
import chipBurnImg from '../../Static/UserManagement/images/services-icon/chipBurn.png'
import freeplayImg from '../../Static/UserManagement/images/services-icon/freeplay.png'
import freeplaySummaryImg from '../../Static/UserManagement/images/services-icon/freeplaySummary.png'
import freeplayRewardsImg from '../../Static/UserManagement/images/services-icon/freeplayRewards.png'



export default function UserGuide({ isVisible, modToggle }) {
  return (
    <Modal isOpen={isVisible} toggle={modToggle} className="modal-dialog-centered modal-xl">
      <div className="modal-header bg-vb-dark">
        <h5 className="modal-title my-0">Valuebet User Guide 3.0</h5>
        <button onClick={modToggle} type="button" className="text-white btn-close" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className='modal-body letter-space'>
        <div>
          <h6 className="vb-text-orange">Key Points</h6>
          <ul className='enable-li-stype ms-3'>
            <li>Anyone can Start/Host or Join a Club for free</li>
            <li>Bets are Host versus Club Players</li>
            <li>Chips are used to make bets & only work within their respective clubs</li>
            <li>Coins are used for buying chips, etc. & user-to-user transferable</li>
            <li>Vig Paid Chips are burned</li>
          </ul>
        </div>
        <div className="row">
          <div className="col-md-4 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>1. Starting Out:</b></span> <span>Users create a free account.</span></div>
            <img src={registerImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
          <div className="col-md-8 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>2. Create a Club:</b></span> <span>Users can create a Club and select which Sportsbook Odds to use for Club.</span></div>
            <img src={crtClubImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-6 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>3. Join a Club:</b></span> <span>Users can join a Club using the Club ID & Club Applicants can use a User ID as referral (optional).</span></div>
            <img src={joinClubImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
          <div className="col-md-6 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>4. New Members:</b></span> <span>New Applicants will show in the Application Section.</span></div>
            <img src={applicantImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-6 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>5. Players Section:</b></span> <span>All Members are listed in the Players section. Club Host can assign Members to roles: Managers or Agents (coming soon).</span></div>
            <img src={playerSecImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
          <div className="col-md-6 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>6. Valuebet Coins:</b></span> <span>Coins are used to buy chips, features & upgrade clubs. Clicking on the Coin Wallet allows users to buy more Coins.</span></div>
            <img src={coinInfoImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-6 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>7. Payment for Valuebet Coins:</b></span> <span>User can buy Valuebet Coins with multiple forms of Crypto payments. User sends payment to crypto address & receive Valuebet Coins upon confirmation.</span></div>
            <img src={cryptoPayImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
          <div className="col-md-6 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>8. Valuebet Tokens:</b></span> <span>Tokens grant users & clubs with special features. Features include Unlimited Club Use & airdrops of Valuebet Coin.</span></div>
            <img src={tokensImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-6 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>9. Assign Token To Club:</b></span> <span>User can give a Club unlimited use by assigning LifeTime-Deal to Club using ClubID.</span></div>
            <img src={assignTokenImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
          <div className="col-md-6 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>10. Upgrade User Settings:</b></span> <span>User can upgrade Profile Settings to unlock additional features with Valuebet Coins.</span></div>
            <img src={userSettingImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-6 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>11. Club Chips:</b></span> <span>New Clubs receive free Chips to use within the Club. Club owners can buy more chips with Coins.</span></div>
            <img src={chipInfoImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
          <div className="col-md-6 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>12. Club & Player Chips:</b></span> <span>Club Chips can be sent (or reclaimed) to/from Players. Player Chips are used for Wagering.</span></div>
            <img src={chipTransactionImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-6 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>13. Chip Request:</b></span> <span> Players can request chips by clicking on Player Wallet. Owners can see Chip Requests by clicking on Club Wallet.</span></div>
            <img src={chipReqImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
          <div className="col-md-6 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>14. Club Settings:</b></span> <span> Club Host can access Club Settings to edit club. Settings include Upgrading Clubs, Select Sportsbook Odds, and set Betting Limits.</span></div>
            <img src={clubSettingsImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-6 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>15. Upgrade Club:</b></span> <span> Club Host can Upgrade Club Levels using Valuebet Coins. Club Levels unlock certain club features.</span></div>
            <img src={upgradeClubImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
          <div className="col-md-6 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>16. Club Betting Limits:</b></span> <span> Club Host can set Club Limits. Club Limits is the max amount members can place on a wager.</span></div>
            <img src={clubLimitsImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-6 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>17. Player Betting Limits:</b></span> <span> Club Host can set Player Limits. Player Limits is the max a specific member can place on a wager.</span></div>
            <img src={playerLimitsImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
          <div className="col-md-6 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>18. Odds Betting Limits:</b></span> <span> Club Host can set Odds Limits. Odds Limits hides odds higher or lower than specified odds to prevent risk exposure.</span></div>
            <img src={oddsLimitsImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>19. Launch Sportsbook:</b></span> <span> User clicks Play Now to enter the Club’s Sportsbook.</span></div>
            <img src={clubDashImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
          <div className="col-md-9 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>20. Bet Reports:</b></span> <span> Players can view their Bet History & Summary.</span></div>
            <img src={betReportImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-6 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>21. Club Bet Reports:</b></span> <span> Only Club Hosts & Managers can view Club Reports. Club Reports include Player Bets, Club Summary, and Player Summary.</span></div>
            <img src={clubBetReportImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
          <div className="col-md-6 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>22. Club Bet Reports & Vig Chips Burned:</b></span> <span> Chips from losing bets are transferred to Clubs; Vig Chips are burned. Ex: Player risks 110 chips to win 100 and lose. Player loses 110 chips, Club receives 100 chips, 10 chips are burned. Player balance shows -110 loss.</span></div>
            <img src={chipBurnImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-4 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>23. Free Play Challenge:</b></span> <span> Users can enter into Free daily Sportsbetting Tournaments. Daily, Weekly, & Monthly Leaderboard winners receive Free Valuebet Coins.</span></div>
            <img src={freeplayImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
          <div className="col-md-8 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>24. Free Play Challenge Summary:</b></span> <span> Users can see history and performance of other Free Play players.</span></div>
            <img src={freeplaySummaryImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-8 py-2">
            <div className="font-size-14px pb-1"><span className='vb-text-orange'><b>25. Free Play Challenge Rewards:</b></span> <span> Users can view their Referrals & Free Play Rewards. Users receive Valuebet Coins when their referrals win Valuebet Coins up to 5-levels.</span></div>
            <img src={freeplayRewardsImg} alt='Register' className='w-100 p-1 border border-danger rounded' />
          </div>
        </div>
      </div>
    </Modal>
  )
}
