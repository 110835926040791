import React from 'react'
import CustomTable from '../../../../Components/CustComp/CustomTable';
import MemberStatus from './MemberComps/MemberStatus';
import ChipPlayer from './MemberComps/ChipPlayer';
import CopyText from '../../../../Components/CustComp/CopyText';
import { BASEURL } from '../../../../API-Handle/config';
import logoag from '../../../../Static/UserManagement/images/common/default-user-avtar.png';
import RoleActon from './MemberComps/RoleActon';
import MessageAction from './MemberComps/MessageAction';
import MemberAction from './MemberComps/MemberAction';

const UserAvata = ({data}) =>{
    return(
    <div className='d-flex align-items-center py-2'>
        {data.profile_picture ?
            <img className='tbl-avtar' src={BASEURL + data?.profile_picture} alt=''/> :
            <img className='tbl-avtar' src={logoag} alt=''/>   
        }
        <div >
            <div className='text-white text-nowrap font-size-16px'>{data?.username}</div>
            <div className='text-light font-size-13px text-nowrap mt-1'>ID: {data.id} <CopyText text={data.id} className="bg-transparent text-light border-0 px-2" /></div>
        </div>
    </div>)
}

function Index({val, activeClub, dataCallback }) {
    const columns = [
        {
            name: 'Action',
            cell: row => <MemberAction row={row} activeClub={activeClub} dataCallback={dataCallback} />,
            sortable: false,
            minWidth: '50px',
            maxWidth: '150px'
        },
        {
            name: 'Userame',
            selector:row => row.username,
            cell: row => <UserAvata data={row} />,
            sortable: true,
            minWidth: '190px',
        },
        {
            name: 'Message',
            selector:row => row.user_chips?.player_chips,
            cell: row => <MessageAction row={row} activeClub={activeClub}/>,
            sortable: true,
            minWidth: '50px',
        },
        {
            name: 'Role',
            selector:row => row.member_type,
            cell: row => <RoleActon row={row} activeClub={activeClub} />,
            sortable: true,
            minWidth: '120px',
        },
        {
            name: 'Chips',
            selector:row => row.user_chips?.player_chips,
            cell: row => <ChipPlayer row={row} activeClub={activeClub} dataCallback={dataCallback}/>,
            sortable: true,
            minWidth: '150px',
        },
        {
            name: 'Status',
            selector:row => row.status,
            cell: (row)=> <MemberStatus row={row} activeClub={activeClub}/>,
            sortable: true,
            minWidth: '100px',
        },
    ];
    
  return (
    <div className="card-body p-2 border rounded border-danger">
        <CustomTable 
            columns={columns}
			data={val}
            dense={true}
            header={false}
            highlightOnHover={true}
            pagination={false}
            footer={null}
        />
    </div>
  )
}

export default Index