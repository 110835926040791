import React, { useState, useContext, useEffect } from 'react';
import Header from '../../../Components/UserHeader'
import MembersTable from '../Members/MembersTable'
import { Row, Col } from 'reactstrap';
import Footer from '../../../Components/Footer';
import ValuebetContext from "../../../context/Valuebet/ValuebetContext";
import ClubAction from '../Dashboard/ClubCard/ClubComponants/ClubAction';
import { useNavigate, useLocation } from 'react-router-dom';
import { REMOVE_MEMBERS, SWITCH_MEMBERS, postValuebet } from '../../../API-Handle/services/auth_curd'
import { DefaultConfirm } from '../../../Components/SweetAlerts/DefaultConfirm';
import SmSpinner from '../../../Components/CustLoader/SmSpinner';

export default function Index() {
  const { activeClub, setActiveClub, fetchSingleClub } = useContext(ValuebetContext);
  const [cardData, setCardData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const dataCallback = () => {
    let club_id = location.state.club_data.id
    fetchSingleClub(club_id)
  }

  const initPlayers = () => {
    let club_data = JSON.parse(JSON.stringify(activeClub))
    let players = location.state.agent_players;
    let cardData = club_data.members.filter((itm) => players.indexOf(itm.id) != -1);
    cardData.map((item) => {
      item["label"] = "Player"
    })
    setCardData(cardData)
  }

  useEffect(() => {
    let club_data = location.state?.club_data
    if (!club_data) {
      navigate('/');
    } else {
      setActiveClub(club_data)
      dataCallback();
    }
  }, [])

  useEffect(() => {
    if (activeClub) {
      initPlayers();
    }
  }, [activeClub])

  const switchMember = async (item) => {
    let confirmation = await DefaultConfirm("Role Change", "Do you want to chnage role of this member?")
    if (confirmation) {
      item['club_id'] = location.state.club_data.id;
      await postValuebet(SWITCH_MEMBERS, item)
      dataCallback()
    }
  }

  const removePlayer = async (item) => {
    let confirmation = await DefaultConfirm("Remove Member", "Do you want to remove this member?")
    if (confirmation) {
      item['club_id'] = location.state.club_data.id;
      await postValuebet(REMOVE_MEMBERS, item)
      dataCallback()
    }
  }

  return (
    <React.Fragment>
      <div>
        <Header />
        <div className="sec-header container-fluid">
          <Row>
            <div className="d-flex justify-content-start align-items-center">
              <h6 className='sec-header-title'>{location.state?.agent_username}'s Players</h6> {activeClub && <ClubAction item={activeClub} />}
            </div>
          </Row>
        </div>
        <div className='layout-mt-112 fill-view'>
          <div className="container-fluid mt-4">
            {cardData ?
              <div>
                {cardData.length ?
                  <MembersTable
                    val={cardData}
                    switchMember={switchMember}
                    removePlayer={removePlayer}
                    activeClub={activeClub}
                    dataCallback={dataCallback}
                  />
                  : <Col className='text-white text-center pt-5 mt-5' md="12"><div>{"Currently, No player bind with " + location.state?.agent_username}</div> </Col>}
              </div> :
              <SmSpinner text={"Loading Players..."} />}
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  )
}