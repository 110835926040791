import React, { useEffect } from 'react'
import {
  Col
} from "reactstrap";
import PrimaryClub from "./PrimaryClub"
import ClubComponants from "./ClubComponants"
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { collapsePersonalChannel, collapsePublicChannel, toggleMessanger } from '../../../../redux/services/chatServices';
import { CHANNELTYPE } from '../../../../constant';
import ValuebetContext from '../../../../context/Valuebet/ValuebetContext';

export default function Index({ item }) {
 
  const { search } = useLocation();
  const {isClubChatOpen } = useSelector(state => state.clubChat)
  const dispatch = useDispatch();
  const { markNoMessage } = React.useContext(ValuebetContext);

  useEffect(() =>{
    notificationHandler();
    // eslint-disable-next-line
  },[search])

  const notificationHandler = async () => {
    const searchParams = new URLSearchParams(search);
    const noti_type = searchParams.get("type");
    const club_id = searchParams.get("club_id");
    const channel_type = searchParams.get("channel_type");
   if(club_id === item.id){
    if(['MESSAGE', 'REACT', 'SENT_MESSAGE'].includes(noti_type)){
      if (!isClubChatOpen) markNoMessage(item.id)
      dispatch(toggleMessanger(item, isClubChatOpen))
      if(channel_type === CHANNELTYPE.personal){
        dispatch(collapsePersonalChannel(false, club_id));
      }else if(channel_type === CHANNELTYPE.group){
        dispatch(collapsePublicChannel(false, club_id));
      }
    }
   }
  }
  return (
    <>
      <Col sm="12" md="6" lg="4" xl="3">
        <div className='d-flex justify-content-center'>
          {item.is_special ?
            <PrimaryClub item={item} /> :
            <ClubComponants item={item} />}
        </div>
      </Col>
      {/* {!item.is_special && <Col sm="12" md="6" lg="4" xl="3">
        <div className='d-flex justify-content-center'>
          <ClubComponants item={item} />
        </div>
      </Col>} */}
    </>
  )
}
