import React, { useEffect, useRef, useState } from "react";
import { setSelectedMember, createChannel } from "../../../../../redux/services/chatServices";
import ChatDropdown from "../../../../../Components/CustInputs/ChatDropdown";
import { useDispatch, useSelector } from "react-redux";
import { CHANNELTYPE } from "../../../../../constant";
import { useLocation } from "react-router";

const SearchMembers = () => {
  const { search } = useLocation();
  const [dropDownData, setDropDownData] = useState([])
  const { isClubAdmin, clubMember, selectedClub, allPersonalChat } = useSelector(
    (state) => state.clubChat
  );
  const allPersonalChatRef = useRef([]);
  const dispatch = useDispatch();

  const onSelectMember = (item) => {
    if (item !== null) {
      setChatActive(item.id);
    }
  }

  const setChatActive = (member_id, force_create=true) => {
    let allMembers = [];
    let activeChannel = null;

    allPersonalChat?.forEach((pch) => {
      allMembers.push(pch?.detail?.id)
      if (member_id === pch?.detail?.id) {
        activeChannel = pch;
      }
    });
    let is_exists = allMembers.some((it) => it === member_id);

    if (is_exists) {
      dispatch(setSelectedMember(activeChannel, activeChannel.id));
    } else if (force_create) {
      dispatch(createChannel(selectedClub.id, null, [member_id], CHANNELTYPE.personal, selectedClub));
    }
  };

  const prepareSearchOptions = () => {
    let search_data = (clubMember && clubMember?.length) > 0 ? clubMember.filter((it) => it.id !== selectedClub.user_id) : [];
    if (search_data.length !== dropDownData.length) setDropDownData(search_data);
  }

  useEffect(() => {
    if (clubMember?.length) {
      prepareSearchOptions()
    }
    // eslint-disable-next-line
  }, [clubMember])

  const onSelectByMemberSection = (force_create) => {
    const searchParams = new URLSearchParams(search);
    const redirect_type = searchParams.get("type");
    const member_id = searchParams.get("member_id");
    if (redirect_type === 'MEM_CHAT' && dropDownData.length) setChatActive(member_id, force_create);
  }
  
  useEffect(() => {
    onSelectByMemberSection(true);
    // eslint-disable-next-line
  }, [dropDownData])


  useEffect(() => {
    if (allPersonalChatRef.current?.length !== allPersonalChat?.length) {
      onSelectByMemberSection(false);
    }
    return () => {
      allPersonalChatRef.current = allPersonalChat
    }
  // eslint-disable-next-line
  }, [allPersonalChat])

  return (
    <>
      {isClubAdmin && (
        <div className="py-2">
          <ChatDropdown
            placeholder="Search"
            options={dropDownData}
            // value={activeChannel}
            onChange={onSelectMember}
            isDisabled={false}
          />
        </div>
      )}
    </>
  );
};

export default SearchMembers;
