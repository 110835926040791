import React from "react";
import { Box } from "@mui/material";

import { Modal } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedMember, toggleMessanger } from "../../redux/services/chatServices";
import ChatHeader from "./ChatHeader";
import ChatLeftSection from "./ChatLeftSection";
import MessageContainer from "./MessageContainer";


function ClubMessage() {
  const dispatch = useDispatch();
  const { isClubChatOpen } = useSelector((state) => state.clubChat);

  const onCloseChat = () => {
    dispatch(toggleMessanger(null, isClubChatOpen));
    dispatch(setSelectedMember(null));
  };

  return (
    <Modal 
      isOpen={isClubChatOpen} 
      toggle={onCloseChat} 
      fullscreen={true}
      inert
    >
      <Box sx={{ display: "flex" }}>
        <ChatHeader  />
        <ChatLeftSection />
        <MessageContainer />
      </Box>
    </Modal>
  );
}

export default ClubMessage;
