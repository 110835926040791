import React, { useContext, useState, useEffect } from 'react'
import ValuebetContext from '../../../../../../context/Valuebet/ValuebetContext';
import { UPDATE_CLUB_URL, postValuebet } from '../../../../../../API-Handle/services/auth_curd';
import { successNotification, errorNotification } from '../../../../../../Components/CustComp/PPHAlert';
import UpgradeLevel from './UpgradeLevel';
import LineSelector from './LineSelector';
import { refinHeading } from '../../../../../../Utils/comman';
import LeaderboardConf from './LeaderboardConf';

export default function BasicInfo({ item, toggleClubSetting }) {
    const [clubName, setClubName] = useState('');
    const { fetchSingleClub } = useContext(ValuebetContext);

    const handleChange = (e) => {
        setClubName(refinHeading(e.target.value))
    }

    const renameClub = async () => {
        if (!clubName) {
            errorNotification("Please enter valid Club Name.");
            return;
        } else if (!clubName.length) {
            errorNotification("Please enter valid Club Name.");
            return;
        } else if (clubName.length > 20) {
            errorNotification("Club name has max 20 characters limit.");
            return;
        }
        let data = { "name": clubName };
        let res = await postValuebet(`${UPDATE_CLUB_URL + item.id}`, data);
        if (res) {
            successNotification(res.message);
            fetchSingleClub(item.id);
        }
    }

    useEffect(() => {
        setClubName(item.name)
    }, [item])

    return (
        <>
            <div className='mb-4'>
                <div className='d-flex align-items-center justify-content-between mb-2 flex-wrap  gap-3'>
                    <span className="px-2 py-1 border border-danger text-white rounded">Max Members - {item.member_counts.players + item.member_counts.agents + item.member_counts.managers}/{item.level_details.member}</span>
                    {/* <span className="px-2 py-1 border border-danger text-white">Max Agents - {item.member_counts.agents + item.member_counts.managers}/{item.level_details.agent}</span> */}
                    <span className="px-2 py-1 border text-white border-danger rounded">Level - {item.level_details.level}</span>
                    <LineSelector clubInfo={item} />
                    <div className='font-size-12px text-end'>
                        {item.club_status === 'ACTIVE' &&
                        <div className='px-2'> {item.level_details.level === 1 ? 
                            <span className='text-light'>(Default Level)</span> : 
                            <span className='text-success'>({item.level_details.remaining_days} Remaining Days)</span>}  
                        </div>}
                        {item.club_status === 'UNLIMITED' &&
                            <div className='px-2 text-success'> (Unlimited Access) </div>}
                        {item.club_status === 'DISABLED' &&
                            <div role='button' className="text-danger border border-danger rounded px-2 py-1" onClick={()=>errorNotification("Disabled due to Unassignment of Token. Please reassign a Token to club to reactivate.")}> DISABLED </div>}
                    </div>
                </div>
                <div className="horizantal-line my-3" />
                <div className='d-flex align-items-end flex-wrap'>
                    <div className='me-3'>
                        <LeaderboardConf clubInfo={item} />
                    </div>
                    <div className='mb-3 '>
                        <UpgradeLevel clubInfo={item} />
                    </div>
                </div>
            </div>

            <div className="form-group">
                <label className="form-label text-light">Club name</label>
                <input type="text" placeholder='Club name' className="form-control mb-1 bg-dark text-white" value={clubName} onChange={(e) => handleChange(e)} />
            </div>
            <div className="text-end mt-3">
                <button onClick={renameClub} type="button" className="btn btn-danger btn-orange btn-sm">Save changes</button>&nbsp;
                <button onClick={toggleClubSetting} type="button" className="btn btn-secondary btn-sm">Cancel</button>
            </div>
        </>
    )
}
