import React from 'react'
import Avatar from 'react-avatar-edit'
import Footer from '../../../Components/Footer';
import Header from '../../../Components/UserHeader';
import { postValuebet, UPDATE_PROFILE_PICTURE_URL } from '../../../API-Handle/services/auth_curd'
import ValuebetContext from '../../../context/Valuebet/ValuebetContext';
import { successNotification, errorNotification } from '../../../Components/CustComp/PPHAlert';
import withRouter from '../../../Components/CustComp/withRouter'

class UploadPic extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      preview: null,
      src:''
    }
    this.onCrop = this.onCrop.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  onClose() {
    this.setState({ preview: null })
  }

  onCrop(preview) {
    this.setState({ preview })
    // console.log('preview =>', preview)
  }

  fileGenerater = (blob) => {
    let name = JSON.parse(localStorage.getItem("userData")).username
    let byteString = atob(blob.split(',')[1]);

    var mimeString = blob.split(',')[0].split(':')[1].split(';')[0];
    let extention = mimeString.split('/')[1]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new File([ab], name + "." + extention, { type: mimeString });

  }

  async updatePicture() {
    if (!this.state.preview) {
      errorNotification("Please select a picture.")
      return
    }
    let formData = new FormData()
    let file = this.fileGenerater(this.state.preview)
    formData.append('picture', file)
    let res = await postValuebet(UPDATE_PROFILE_PICTURE_URL, formData)
    if(res){
      successNotification(res.message)
      this.props.navigate('/profile-setting');
    }
  }

  static contextType = ValuebetContext;
  render() {
    return (
      <>
        <Header />
        <div className="fill-view">
            <div className='pt-5'>
                <div className="wrapper-page upld-container d-flex flex-column justify-content-center align-items-center">
                  <h4>Upload Profile Picture</h4>
                  <p>Picture should be <b>.jpg</b> or <b>.png</b> format.</p>
                  <Avatar
                    width={250}
                    height={200}
                    onCrop={this.onCrop}
                    onClose={this.onClose}
                    src={this.state.src}
                    label={<span className='text-white'> Choose a Picture </span>}
                  />
                  <button className="mt-4 btn btn-danger btn-orange" type="button" onClick={() => this.updatePicture()}>
                    Change Picture
                  </button>
              </div>
            </div>
          </div>
        <Footer />
      </>
    )
  }
}

export default withRouter(UploadPic);