/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import StopLimit from "./StopLimit";
import BetLimit from "./BetLimit";

const renderItems = (id, club, item) => {
  switch (id) {
    case 1:
      return (<StopLimit club={club} item={item} />);
    case 2:
      return (<BetLimit club={club} item={item} />);
    default:
      return null;
  }
};
function Limits({ club, item }) {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <div>
      <div className="btn-group" role="group">
        <button type="button" className={activeTab === 1 ? "btn btn-danger btn-sm": "btn btn-secondary btn-sm"} onClick={() => setActiveTab(1)}>
          Stop Limit
        </button>
        <button type="button" className="btn btn-secondary me-0 ms-0 pe-0 ps-0" disabled></button>
        <button type="button" className={activeTab === 2 ? "btn btn-danger btn-sm": "btn btn-secondary btn-sm"} onClick={() => setActiveTab(2)}>
          Bet Limit
        </button>
      </div>
      {renderItems(activeTab, club, item)}
    </div>
  );
}

export default Limits;
