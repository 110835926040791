import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import { PLAYER_BET_HISTORY_REPORT } from '../../../../../../../API-Handle/services/auth_curd';
import { getValuebet } from '../../../../../../../API-Handle/services/auth_curd';
import Spinner from '../../../../../../../Components/CustLoader/Spinner';
import CustomWeekPicker from '../../../../../../../Components/Calendar/CustomWeekPicker';

const columns = [
    {
        name: '',
        selector: row => row.detail,
    },
    {
        name: 'Total Wager',
        selector: row => row.total_wagers,
    },
    {
        name: 'No. Wager',
        selector: row => row.number_of_wagers,
    },
    {
        name: 'Ave. bet',
        selector: row => row.average_bet,
    },
    {
        name: 'Mon',
        selector: row => row.Mon,
    },
    {
        name: 'Tue',
        selector: row => row.Tue,
    },
    {
        name: 'Wed',
        selector: row => row.Wed,
    },
    {
        name: 'Thu',
        selector: row => row.Thu,
    },
    {
        name: 'Fri',
        selector: row => row.Fri,
    },
    {
        name: 'Sat',
        selector: row => row.Sat,
    },
    {
        name: 'Sun',
        selector: row => row.Sun,
    },
];

export default function DailyMemberHistory({club, item}) {
    const [report, setReport] = useState(null)
    const [week, setWeek] = useState(null);

    useEffect(() => {
        if(week) getDailyReports();
        // eslint-disable-next-line
    }, [week])

    const getDailyReports = async () => {
        setReport(null)
        let request_url = PLAYER_BET_HISTORY_REPORT + club.id + `?user_id=${item.id}&report_type=Daily&report_time=${week}`;
        let reports = await getValuebet(request_url);
        if (reports) setReport(reports);
    }

    return (
        <>
            <div className='inline-datepicker-container'>
                <CustomWeekPicker setWeek={setWeek} className={"settled-datepicker"} position={"bottom right"}/>
            </div>
            {report ?
                <DataTable
                    columns={columns}
                    data={report}
                    theme="dark"
                    dense={true}
                    header={false}
                    highlightOnHover={true}
                /> :
                <Spinner />}
        </>
    )
}
