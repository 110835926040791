import React, { useEffect} from "react";
import logoag from "../../../../../Static/UserManagement/images/common/default-user-avtar.png";
import { Avatar } from "@mui/material";
import { BASEURL } from "../../../../../API-Handle/config";
import { useDispatch} from "react-redux";
import { setSelectedMember } from "../../../../../redux/services/chatServices";
import { useLocation } from "react-router";

function DirectItem({ item }) {
  const dispatch = useDispatch();
  const { search } = useLocation();

  useEffect(() => {
    notificationHandler();
    // eslint-disable-next-line
  }, [search]);

  const notificationHandler = async () => {
    const searchParams = new URLSearchParams(search);
    const channel_id = searchParams.get("channel_id");
    if (channel_id === item.id) {
      dispatch(setSelectedMember(item, item?.id));
    }
  };
  return (
    <div
      className="d-flex align-items-center justify-content-between"
      id={item.id}
      onClick={() => {
        dispatch(setSelectedMember(item, item?.id));
      }}
      role="button"
    >
      <div className="d-flex align-items-center">
        <Avatar
          alt="Remy Sharp"
          src={
            item?.detail?.profile_picture
              ? BASEURL + item?.detail?.profile_picture
              : logoag
          }
          sx={{ width: 34, height: 34, marginRight: 2 }}
        />
        <div className="d-block">
          <strong className="font-size-14px text-light">
            {" "}
            {item?.detail?.username}
          </strong>
          <div className="font-size-12px fw-normal text-light direct-last-msg">
            {item.last_message?.media_type && item.last_message?.media_type === 'PHOTO' ? 'Photo' : item.last_message?.message}
          </div>
        </div>
      </div>
      {item.unread_count>0 && <div className="badge bg-secondary">{item.unread_count}</div>}
    </div>
  );
}

export default DirectItem;
