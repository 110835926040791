import React, { useContext } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import LockOutlined from '@mui/icons-material/LockOutlined';
import { errorNotification, successNotification } from '../../../../../../Components/CustComp/PPHAlert';
import { postValuebet, UPDATE_LEADERBOARD_STATUS_URL } from '../../../../../../API-Handle/services/auth_curd';
import ValuebetContext from '../../../../../../context/Valuebet/ValuebetContext';

const LeaderboardConf = ({ clubInfo }) => {
  const { fetchSingleClub } = useContext(ValuebetContext);

  const handleChange = async (event) => {
    let data = { lead_type: event.target.name }
    let res = await postValuebet(UPDATE_LEADERBOARD_STATUS_URL + clubInfo.id, data);
    if (res) {
      successNotification(res.massage);
      fetchSingleClub(clubInfo.id);
    }
  };

  const wantMessage = () => {
    {/* // TODO: Temp Unlocked */}
    // if (!clubInfo.level_details.leaderboard) {
    //   errorNotification("Upgrade Club to Unlock!")
    // }
  }

  return (
    <div onClick={wantMessage}>
      {/* // TODO: Temp Unlocked */}
      {/* <label className='text-white'>Display Leaderboard:</label> {!clubInfo.level_details.leaderboard && <LockOutlined fontSize='12' className='icon-bet-no mb-1' />} */}
      <label className='text-white d-flex'>Display Leaderboard: {!clubInfo.level_details.leaderboard && <div className='d-flex'><LockOutlined fontSize='small' className='icon-bet-no' /><div className="text-warning font-size-10px mb-1">Preview</div></div>}</label>

      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={clubInfo.leaderboard_status.daily}
              onChange={handleChange}
              name="Daily"
              // disabled={!clubInfo.level_details.leaderboard} {/* // TODO: Temp Unlocked */}
            />
          }
          label="Daily"
          sx={{ color: "white" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={clubInfo.leaderboard_status.weekly}
              onChange={handleChange}
              name="Weekly"
              // disabled={!clubInfo.level_details.leaderboard} {/* // TODO: Temp Unlocked */}
            />
          }
          label="Weekly"
          sx={{ color: "white" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={clubInfo.leaderboard_status.monthly}
              onChange={handleChange}
              name="Monthly"
              // disabled={!clubInfo.level_details.leaderboard} {/* // TODO: Temp Unlocked */}
            />
          }
          label="Monthly"
          sx={{ color: "white" }}
        />
      </FormGroup>
    </div>
  );
};

export default LeaderboardConf;
