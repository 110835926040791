import React from 'react'
import Skeleton from '@mui/material/Skeleton';

export default function ListLoader({ rowsCount=2, headHeight=60, rowsHeight=45  }) {
    return (
        <>
            <div>
                <Skeleton height={headHeight}/>
                <Skeleton animation="wave" height={rowsHeight}/>
                <Skeleton animation={false} height={rowsHeight}/>
                {(Array(rowsCount).fill(0)).map((item, index)=>{
                    return(
                        <div key={index}>
                            <Skeleton height={rowsHeight}/>
                            <Skeleton animation="wave" height={rowsHeight}/>
                            <Skeleton animation={false} height={rowsHeight}/>
                        </div>
                    )
                })}
            </div>
        </>
    )
}
