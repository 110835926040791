import React, { useContext } from 'react'
import NavigationBar from '../../../Components/SportsbookHeader/NavigationBar'
import { Row } from 'reactstrap'
import ValuebetContext from '../../../context/Valuebet/ValuebetContext';
import TabSetup from '../ClubReports/TabSetup';

function Index() {
    const { activeClub } = useContext(ValuebetContext);
    return (
        <div>
            <NavigationBar />
            <div className='layout-mt-65-100 fill-view'>
                <div className="container-fluid mt-4">
                    <TabSetup club={activeClub} />
                </div>
            </div>
        </div>
    )
}

export default Index;