import React, { useEffect, useRef, useState } from "react";
import { Box, Toolbar } from "@mui/material";
import ChatFooter from "./ChatFooter";
import { useSelector } from "react-redux";
import BlankChat from "./BlankChat";
import RenderMessage from "./RenderMessage";

const drawerWidth = 300;
function Index() {
  const chatContainerRef = useRef(null);
  const [messageBodyHeight, setMessageBodyHeight] = useState(null);
  const [firstMessageDate, setFirstMessageDate] = useState(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollTimeoutRef = useRef(null);
  const {
    activeChannel,
    selectedChannelMessages,
    chatInputFieldHeight,
    replyMessage,
  } = useSelector((state) => state.clubChat);
  let lastMessageDate = null;

  const formatDateString = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const getRelativeDate = (dateString) => {
    const messageDate = new Date(dateString);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    if (messageDate.toDateString() === today.toDateString()) {
      return "Today";
    } else if (messageDate.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else {
      return formatDateString(dateString);
    }
  };

  const getFirstVisibleMessageTimestamp = () => {
    const container = chatContainerRef.current;
    if (container) {
      const children = Array.from(container.children);
      for (const child of children) {
        const rect = child.getBoundingClientRect();
        if (rect.top >= container.getBoundingClientRect().top) {
          const timestamp = child.getAttribute("time");
          if (timestamp) {
            const formatTime = getRelativeDate(timestamp);
            setFirstMessageDate(formatTime);
          }
          break;
        }
      }
    }
  };

  const getHeight = () => {
    const replyMessageHeight = replyMessage !== null ? 50 : 0;
    let hgt = window.innerHeight - 120 - chatInputFieldHeight - replyMessageHeight;
    setMessageBodyHeight(hgt);
  };

  const handleScroll = () => {
    setIsScrolling(true);
    // Clear the previous timeout
    if (scrollTimeoutRef.current !== null) {
      clearTimeout(scrollTimeoutRef.current);
    }
    // Set a new timeout to set the state to false after 150ms
    scrollTimeoutRef.current = setTimeout(() => {
      setIsScrolling(false);
    }, 500);

    // Set message timing
    getFirstVisibleMessageTimestamp();
  };

  useEffect(() => {
    if (replyMessage) {
      setMessageBodyHeight((prevHeight) => prevHeight - 58)
    }
    else {
      setMessageBodyHeight((prevHeight) => prevHeight + 58)
    }
  }, [replyMessage])

  useEffect(() => {
    getHeight();
    window.addEventListener("resize", getHeight);
    return () => window.removeEventListener("resize", getHeight);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.body.style.height = '100vh';
    const container = chatContainerRef.current;
    if (container) {
      getFirstVisibleMessageTimestamp();
      // Initial check to set the first message timestamp

      container.scrollTop = container.scrollHeight;
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      document.body.style.height = 'auto';
      if (container) container.removeEventListener("scroll", handleScroll);
      if (scrollTimeoutRef.current !== null) clearTimeout(scrollTimeoutRef.current);
    };
    // eslint-disable-next-line
  }, [selectedChannelMessages]);

  return (
    <>
      {activeChannel !== null ? (
        <Box
          component="main"
          sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
          className="overflow-hidden"
        >
          <Toolbar />
          <div className="chat-container">
            <div className="date-fixed" style={{ width: `calc(100% - ${drawerWidth}px)` }}>
              <div className="msg-date-section" hidden={!isScrolling}>{firstMessageDate}</div>
            </div>
            <div
              className="message-container overflow-auto p-3"
              ref={chatContainerRef}
              style={{ height: messageBodyHeight }}
            >
              {selectedChannelMessages && selectedChannelMessages.length > 0
                ? selectedChannelMessages?.map((ite, index) => {
                  let currMessageDate = getRelativeDate(ite.time_stamp);
                  let showDate = lastMessageDate !== currMessageDate;
                  lastMessageDate = currMessageDate
                  return (
                    <RenderMessage msg={ite} key={index} showDate={showDate} msgDate={lastMessageDate} />
                  )
                })
                : null}
            </div>
            <ChatFooter width={`calc(100% - ${drawerWidth}px)`} />
          </div>
        </Box>
      ) : (
        <Box
          component="main"
          sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
          className="overflow-hidden"
        >
          <Toolbar />
          <div className="blank-chat-container">
            <BlankChat />
          </div>
        </Box>
      )}
    </>
  );
}

export default Index;
