import React from 'react'
import { Navigate, useLocation } from 'react-router'

export default function CheckAuth() {
    const location = useLocation();
    return (
        <div>
            {
                localStorage.getItem("authToken") ?
                    <Navigate
                        to={{ pathname: "/dashboard", search:location.search }}
                    />
                    :
                    <Navigate
                        to={{ pathname: "/home", search:location.search }}
                    />
            }
        </div>
    )
}
