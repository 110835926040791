import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Box,
  Alert,
  Tabs,
  Tab,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  LinearProgress,
  Drawer,
  IconButton,
  useTheme,
  useMediaQuery,
  ButtonGroup
} from '@mui/material';
import { 
  TrendingUp,
  AccountBalance,
  Casino,
  SportsEsports,
  AttachMoney,
  ArrowForward,
  AccessTime,
  ShowChart,
  Menu as MenuIcon,
  ThumbUp,
  ThumbDown
} from '@mui/icons-material';

const ValueMarkets = () => {
  const [markets, setMarkets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedSubCategory, setSelectedSubCategory] = useState(0);
  const [mobileOpen, setMobileOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const categories = [
    {
      name: "Crypto",
      icon: <TrendingUp />,
      subCategories: ["Bitcoin", "Ethereum", "DeFi", "NFTs"]
    },
    {
      name: "Finance", 
      icon: <AccountBalance />,
      subCategories: ["Stocks", "Commodities", "Forex", "Interest Rates"]
    },
    {
      name: "Sports",
      icon: <Casino />,
      subCategories: ["Football", "Basketball", "Baseball", "Tennis"] 
    },
    {
      name: "Politics",
      icon: <SportsEsports />,
      subCategories: ["US Elections", "Global Events", "Policy Changes"]
    }
  ];

  useEffect(() => {
    fetchMarkets();
  }, [selectedCategory, selectedSubCategory]);

  const fetchMarkets = async () => {
    try {
      const response = await axios.get(`https://gamma-api.polymarket.com/events`, {
        params: {
          category: categories[selectedCategory].name,
          subCategory: categories[selectedCategory].subCategories[selectedSubCategory]
        }
      });
      setMarkets(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching markets:', error);
      setError('Failed to load markets data. Please try again later.');
      setLoading(false);
    }
  };

  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue);
    setSelectedSubCategory(0);
    fetchMarkets();
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const handleSubCategoryChange = (event, newValue) => {
    setSelectedSubCategory(newValue);
    fetchMarkets();
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleBuyYes = (marketId) => {
    // Implement buy YES logic here
    console.log('Buying YES for market:', marketId);
  };

  const handleBuyNo = (marketId) => {
    // Implement buy NO logic here 
    console.log('Buying NO for market:', marketId);
  };

  const drawer = (
    <Paper elevation={0} sx={{ bgcolor: 'background.default', height: '100%' }}>
      <List component="nav" sx={{ py: 0 }}>
        {categories.map((category, index) => (
          <ListItem 
            button 
            key={index}
            selected={selectedCategory === index}
            onClick={(e) => handleCategoryChange(e, index)}
            sx={{
              borderRadius: 1,
              mb: 1,
              '&.Mui-selected': {
                bgcolor: 'primary.main',
                color: 'white',
                '&:hover': {
                  bgcolor: 'primary.dark',
                }
              }
            }}
          >
            <ListItemIcon sx={{ color: selectedCategory === index ? 'white' : 'inherit' }}>
              {category.icon}
            </ListItemIcon>
            <ListItemText primary={category.name} />
          </ListItem>
        ))}
      </List>
    </Paper>
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Box sx={{ display: { xs: 'block', md: 'none' }, mb: 2 }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
      </Box>

      <Grid container spacing={3}>
        {/* Left Sidebar */}
        <Grid item xs={12} md={3}>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', md: 'none' },
              '& .MuiDrawer-paper': { width: 240 },
            }}
          >
            {drawer}
          </Drawer>
          
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            {drawer}
          </Box>
        </Grid>

        {/* Main Content */}
        <Grid item xs={12} md={9}>
          <Paper elevation={0} sx={{ bgcolor: 'background.default', p: 3 }}>
            {/* Sub Categories */}
            <Tabs 
              value={selectedSubCategory}
              onChange={handleSubCategoryChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ 
                mb: 4,
                '& .MuiTab-root': {
                  textTransform: 'none',
                  fontWeight: 600,
                  minWidth: 120
                }
              }}
            >
              {categories[selectedCategory].subCategories.map((subCat, index) => (
                <Tab key={index} label={subCat} />
              ))}
            </Tabs>

            {/* Market Cards */}
            <Grid container spacing={3}>
              {markets.map((market) => (
                <Grid item xs={12} md={6} lg={4} key={market.id}>
                  <Card 
                    elevation={1}
                    sx={{ 
                      height: '100%',
                      borderRadius: 2,
                      '&:hover': {
                        boxShadow: 3,
                        transform: 'translateY(-4px)',
                        transition: 'all 0.3s ease'
                      }
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
                        {market.title}
                      </Typography>

                      <Box sx={{ mb: 3 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <ShowChart sx={{ mr: 1, color: 'text.secondary' }} />
                          <Typography variant="body2" color="text.secondary">
                            ${(market.volume || 0).toLocaleString()} Volume
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <AccessTime sx={{ mr: 1, color: 'text.secondary' }} />
                          <Typography variant="body2" color="text.secondary">
                            Ends {market.endDate ? new Date(market.endDate).toLocaleDateString() : 'TBD'}
                          </Typography>
                        </Box>
                      </Box>

                      {market.markets && market.markets.map((subMarket, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                          <Typography variant="body2" gutterBottom>
                            {subMarket.question}
                          </Typography>
                          <Box sx={{ mb: 1 }}>
                            <Typography variant="body2" color="success.main" gutterBottom>
                              Yes ({((parseFloat(subMarket.outcomePrices[0]) || 0) * 100).toFixed(1)}%)
                            </Typography>
                            <LinearProgress 
                              variant="determinate" 
                              value={(parseFloat(subMarket.outcomePrices[0]) || 0) * 100}
                              color="success"
                              sx={{ height: 8, borderRadius: 1 }}
                            />
                          </Box>
                          <Box sx={{ mb: 2 }}>
                            <Typography variant="body2" color="error.main" gutterBottom>
                              No ({((parseFloat(subMarket.outcomePrices[1]) || 0) * 100).toFixed(1)}%)
                            </Typography>
                            <LinearProgress 
                              variant="determinate" 
                              value={(parseFloat(subMarket.outcomePrices[1]) || 0) * 100}
                              color="error"
                              sx={{ height: 8, borderRadius: 1 }}
                            />
                          </Box>
                          <ButtonGroup variant="contained" fullWidth>
                            <Button 
                              startIcon={<ThumbUp />}
                              color="success"
                              onClick={() => handleBuyYes(market.id)}
                              sx={{ flex: 1 }}
                            >
                              Buy Yes
                            </Button>
                            <Button
                              startIcon={<ThumbDown />}
                              color="error"
                              onClick={() => handleBuyNo(market.id)}
                              sx={{ flex: 1 }}
                            >
                              Buy No
                            </Button>
                          </ButtonGroup>
                        </Box>
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ValueMarkets;
